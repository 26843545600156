.versions__list__relative-wrapper {
  position: relative;
}

.versions__list__item {
  flex-direction: column;
  cursor: pointer;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  display: block;
  position: relative;
  width: 25rem;
  height: 25rem;

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  will-change: transform;
  transition: transform 0.2s ease-in;

  .insight-read {
    i {
      font-size: 2rem;
    }
    position: absolute;
    text-align: center;
    width: 100%;
    border-radius: 6px;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    color: white;
    height: 14rem;
    padding-top: 2rem;
    z-index: 100;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1rem;
    h3 {
      color: white;
      font-size: 1.5rem;
      font-family: "Roboto";
      font-weight: 500;
    }
    .insight-read-date {
      font-size: 1.1rem;
    }
  }

  &::before {
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.03);
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }
  &:hover::before {
    opacity: 0;
  }
  &::after {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }
  &:hover::after {
    opacity: 1;
  }
  &.transparent {
    opacity: 0.2;
  }
  &-app-icon {
    position: absolute;
    right: -7px;
    bottom: -7px;

    overflow: hidden;
    width: 53px;
    height: 53px;
    border-radius: 4px;
    box-shadow: -2px -1px 7px 0 rgba(0, 0, 0, 0.24);
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &:hover {
    transform: translateY(-1px);
  }
  &-top {
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    height: 25%;
  }
  &-importance-indicator {
    position: absolute;
    right: -6px;
    top: -6px;
    width: 15px;
    height: 15px;
    background-color: $color-redful;
    border-radius: 50%;
    &.importance-indicator-animating {
      box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
      animation: pulse 2s cubic-bezier(0.66, 0.33, 0, 1);
      animation-iteration-count: infinite;
    }
  }
  &-header {
    padding: 1.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    flex: 1 1;
    display: flex;
    height: 100%;
    align-items: center;

    div {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-abtest {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    color: $color-brown-grey;
    padding: 1rem 1.2rem;
    display: flex;
    justify-content: center;
    color: white;
    text-transform: capitalize;
    &-active {
      color: $color-blueful;
    }
    &-integrated {
      color: $color-greenful-2;
    }
    &-abandoned {
      color: $color-redful;
    }
  }
  &-thumbnail {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;

    height: 75%;
    background-color: #f6f5f5;
    img,
    &-loader {
      display: inline-block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    img.default {
      width: auto;
      height: 37%;
      display: block;
      margin: auto;
      margin-top: 9rem;
    }
  }
  &-viewed-indicator {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: rgba(255, 255, 255, 0.95);
    color: #09af00;
    box-shadow: 0px -24px 24px -9px rgba(0, 0, 0, 0.24);
    & > img {
      margin-left: 0.7rem;
    }
  }
  &-loader {
    @extend %animated-images;
    @include respond(tab-land) {
      width: 300px;
      height: 208px;
    }
    @include respond(tab-port) {
      width: 360px;
      height: 240px;
    }
    @include respond(phone) {
      width: 310px;
      height: 206px;
    }
  }
}
