@import '../../sass-config/abstracts/variables.scss';

.nav2 {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  .lock-ico {
    margin-left: 1rem;
    width: 14px;
  }

  margin-bottom: 1rem;
  cursor: default;

  .top-link-container {
    margin-right: 1.6rem;
  }

  .top-link,
  .sub-link {
    display: flex;
    align-items: center;
    padding: 0.6rem;
    margin-bottom: 0.6rem;

    a {
      color: #202020;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;

        img {
          margin-right: 1.4rem;
        }
      }
    }

    &:hover:not(.disable, .no-link) {
      background: rgba(232, 240, 249, 0.44);
      border-radius: 4px;
    }

    &.act {
      pointer-events: none;
    }

    &.act:not(.disable) {
      a {
        font-weight: 400 !important;
      }

      background: #e8f0f9;
      border-radius: 4px;
    }

    &.disable {
      opacity: 0.3;
      cursor: default;

      a {
        cursor: default;
        pointer-events: none;
      }
    }

    &.no-link {
      cursor: default;

      a {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .submenu-menu-wrapper {
    margin-left: 1.28rem;
    padding-left: 1.28rem;

    border-left: 1px solid $color-border-layout;
  }

  .nav2-customer-app {
    align-items: center;
    display: flex;
    gap: 0.8rem;

    span {
      color: #202020;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
    }

    img {
      border-radius: 0.8rem;
      width: 4rem;
      height: 4rem;
    }
  }

  .top-sub-menu {
    margin-top: 1rem;
    margin-right: 1.6rem;
    cursor: default;

    &:last-of-type {
      border-bottom: none;
    }

    .top-menu-title {
      cursor: default;
      margin-bottom: 1.2rem;

      span {
        color: #64748b;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        display: flex;
        align-items: center;
      }
    }
  }

  img.ab_tests-ico {
    position: relative;
    top: -1rem;
  }

  &.normal {
    margin-top: 4.2rem;

    .nav2-customer-app {
      margin-left: 1rem;

      span {
        display: none;
      }

      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .menu-collapsed-title {
      color: #64748b;
      margin-bottom: 1.6rem;
    }

    .lock-ico,
    .submenu-menu-wrapper,
    .menu-label span {
      display: none;
    }

    .top-sub-menu,
    .top-link-container,
    .top-menu-title {
      margin: 0;
    }

    .top-menu-title {
      padding-left: 1.3rem;
    }

    .top-link,
    .sub-link {
      margin-bottom: 1.6rem;

      a {
        display: flex;
        justify-content: center;
        padding-left: 0rem;

        img {
          margin-right: 0rem !important;
        }
      }
    }

    .has-child-menu {
      cursor: pointer;
    }

    img.ab_tests-ico {
      position: relative;
      top: 0rem;
    }
  }
}

.submenu-popover {
  .ant-popover-arrow {
    left: 1px !important;
  }

  .ant-popover-inner-content {
    min-width: 16rem;

    .submenu-menu-title {
      color: #64748b;
      padding: 0 1rem 1rem;
    }

    .submenu-menu-title,
    a {
      font-weight: 500;
      font-size: 1.4rem;
    }

    .sub-link {
      &.act {
        background-color: #1c2650;
      }
    }

    a {
      color: #ffffff;
      display: inline-block;
      width: 100%;
      padding: 1rem;
    }
  }
}

.new-tag {
  margin-left: 1rem;
  font-size: 1rem;
  border-radius: 2rem;
  font-weight: 500;
  border-radius: 200px;
  background: #240202;
  color: #ffffff;
  padding: 0 0.8rem;
}
