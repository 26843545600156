.ant-modal-title {
  font-weight: 700;
}

.view__modal {
  font-family: $font-primary;
  &__tooltip{
    z-index: 1000 !important;
  }
  &__content {
    margin: 2px 0;
  }

  &__copy {
    display: flex;
    align-items: center;
    background-color: $color-very-light-grey;
    height: 4rem;
    position: relative;
    overflow: hidden;

    &-link {
      padding: 1rem 1rem;
      font-size: 1.4rem;
      flex: 1;
      @include ellipsis;
    }

    &-img {
      height: 100%;
      display: flex;
      align-items: center;
      background-color: black;
      padding: 1rem 2rem;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        background-color: black;
      }

      &:active {
        background-color: $color-blueful-dark-3;
      }

      img {
        height: 110%;
      }
    }
  }

  &__details {
    color: #6c6c6c;
    font-family: Roboto;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &__tooltip {
    z-index: 1001 !important;

    .ant-tooltip-inner {
      width: auto !important;
    }
  }

  .ant-modal>.ant-modal-content>.ant-modal-close {
    display: none;
  }

  .ant-modal-header {
    border: none;
    padding-bottom: 0;
    padding-top: 3.5rem;

    div {
      font-size: 2.4rem;
      font-family: "Roboto Slab", sans-serif;
    }
  }

  .ant-modal-footer {
    border: none;
    padding-top: 0;
    padding-bottom: 3rem;
    text-align: left;
    padding-left: 2.5rem;

    button {
      height: 5rem;
      width: 12rem;
      border: 1.26px solid #dedede;
      color: #000000;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 21px;
    }
  }

  h3 {
    color: #000000;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 2.5rem;
  }

  hr {
    margin-top: 1rem;
    border: 1px solid #eceef5;
  }

  textarea {
    width: 100%;
    border: 1px solid rgba(39, 39, 39, 0.5);
    border-radius: 2px;
    background-color: #ffffff;
    height: 13rem;
    //box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    font-size: 1.8rem;
    letter-spacing: 0;
    line-height: 21px;
    padding: 1.5rem;

    &::placeholder {
      opacity: 0.19;
      color: #000000;
      font-family: Roboto;
      font-size: 1.8rem;
      letter-spacing: 0;
      line-height: 21px;
      // padding: 1rem;
    }
  }

  .share-via-email .ant-select-selection-search {
    top: 1.2rem;
  }

  label.share-via-email-label {
    position: relative;
    background: white;
    top: 1.1rem;
    z-index: 2;
    left: 1rem;
    padding: 0.8rem;
  }

  .ant-select-auto-complete {
    // border: 1px solid #272727;
    border: 1px solid rgba(39, 39, 39, 0.5);
    border-radius: 2px;
    height: 5.6rem;
    background-color: #ffffff;

    //box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    .ant-select-selection__placeholder {
      margin-top: 0.1rem;
      padding-left: 1rem;
    }

    input {
      border: none;
      margin-top: 1rem;
      font-size: 1.8rem;
      padding-left: 1rem;
      margin-left: 1rem;
    }
  }

  .share-checkbox-domain label {
    margin-top: 1rem;
    span:nth-child(2) {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      
    }
  }
}

li.ant-select-dropdown-menu-item.share-discovery-recipients-option {
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  height: 4.3rem;
  display: flex;
  align-items: center;

  .ant-avatar {
    margin-right: 1rem;
    border: 1px solid black;
    background: white;
    color: black;
    text-transform: uppercase;

    .ant-avatar-string {
      font-size: 1.5rem;
    }
  }
}