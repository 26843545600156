.app-graph-view {
  .instructions {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #4D4D4E;
    margin-top: 2rem;
  }
  .graph-wrap {
    display: flex;
    .graph-canvas {
      .zoom-manage {
        position: absolute;
        top: 35rem;
        left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 20;
        border: 1px silver solid;
        border-radius: 5px;
        button {
          width: 3.5rem;
          height: 3.5rem;
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: white;
          border-radius: 5px;
          border: none;
          &:nth-child(1) {
            border-bottom: 1px silver solid;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }
      .legend-wrapper {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        box-shadow: 0px 0px 0px rgba(60, 69, 84, 0.23);
        border-radius: 4px;
        position: absolute;
        bottom: 3rem;
        left: 1rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        .type {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.2rem;
          text-transform: uppercase;
          display: flex;
          margin: 0.3rem;
          .circle{
            border-radius: 7px;
            width: 10px;
            height: 10px;
            margin-right: 0.3rem;
          }
        }
      }
      margin-top: 3.1rem;
      margin-right: 1rem;
      position: relative;
      #graph-back-grid {
        position: absolute;
        top: 0;
        left: 0;
        border: 1px silver solid;
      }
    }
    .node-screenshot {
      margin-top: 0rem;
      .node-title {
        margin: 0rem auto 1.5rem;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-align: center;
        text-transform: capitalize;
      }
      .node-screenshot-wrap {
        background: #3c4554;
        height: 572px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .graph-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
    width: 720px;
    .graph-data {
      display: inline-flex;
      border: 1px silver solid;
      & > div {
        border-right: 1px silver solid;
        padding: 1rem 2rem;
        .data-name {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.2rem;
          text-align: center;
          color: #8c8c8c;
        }
        .data-value {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.4rem;
          text-align: center;
          color: #3c4554;
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .eye {
            background: #FFFFFF;
            border: 1px solid #E9E9E9;
            border-radius: 1.625px;
            margin-right: 0.5rem;
            cursor: pointer;
            img {
              padding: 3px;
            }
          }
        }
      }
    }
    .insights {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      max-width: 35rem;

      .insight-link-wrapper {
        display: flex;
        flex-direction: row;
        border: 1px solid #E9E9E9;
        align-items: center;
        margin-left: 0.5rem;
        cursor: pointer;
        .info {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          padding: 2px 7px;
          display: flex;
          align-items: center;
          text-align: center;
          color: white;
        }
        .type {
          padding: 2px 8px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
        }
      }
    }
  }
}

.last-graph-link,
.graph-link {
  margin-left: 1rem;
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 1.5px;
  &:hover {
    border: 1px solid #a4a3a3;
  }
  .graph-ico-wrap {
    background: #ff5c00;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px white solid;
  }
  .graph-link-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: #303030;
    padding: 0rem 1rem;
  }
}
.graph-link {
  margin: 1.2rem !important;
  display: flex !important;
  .graph-ico-wrap {
    background: #ff2741;
    border: 1px white solid;
  }
}
