.i-sidebar-filter-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: fixed;

  .i-simple-filter {
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;
    border-radius: 8px;
    background-color: #f9f9fb;
    cursor: pointer;

    &:hover {
      background: #f3f6ff;
    }

    &.selected {
      background: #f3f6ff;
      border: 1px solid #7d99f0;
    }

    .title {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }

  .i-sidebar-filter {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #f9f9fb;

    &.disabled {
      opacity: 0.4;
    }

    cursor: pointer;

    &:hover {
      background: #f3f6ff;
    }

    &.selected {
      background: #f3f6ff;
      border: 1px solid #7d99f0;
    }

    .i-sidebar-filter-heading {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
    }

    .i-sidebar-filter-options {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      margin-top: 1.2rem;

      .i-sidebar-filter-option {
        padding: 0.8rem;
        border-radius: 4px;
        margin-left: 0;

        &.all {
          max-width: fit-content;
        }

        .ant-checkbox-inner {
          background-color: transparent;
          border: 1px solid black;
        }

        .ant-checkbox-checked::after {
          border: none;
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
          border: 2px solid black;
          border-top: 0;
          border-left: 0;
        }
      }
    }
  }
}

.all-checkbox-half-selected {
  .ant-checkbox-inner:after {
    border: 1px solid black;
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
    transform: rotate(90deg) scale(1) translate(-35%, -16%);
    opacity: 1;
  }
}

.i-sidebar-filter-container-mobile {
  .i-sidebar-filter-options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    .ant-checkbox-inner {
      background-color: transparent;
      border: 1px solid black;
    }

    .ant-checkbox-checked::after {
      border: none;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid black;
      border-top: 0;
      border-left: 0;
    }
  }
}
