.ab-tests-tabs {
    .tab {
        &__header {
            display: flex;
            align-items: center;
            height: 4rem;
            text-transform: capitalize;
            &-all {
                transform: translateX(-8px);
            }
        }
    }
    
    .ant-tabs-nav-container {
        @include firefox {
            width: 90vw;
        }
    }
    
    .ant-tabs-nav-scroll {
        overflow-x: scroll !important;
    }
    
    .ant-tabs-bar {
        background: transparent;
        margin-bottom: 0 !important;
    }
    
    .ant-tabs-tab-disabled {
        cursor: default !important;
        &:hover {
            color: rgba(0, 0, 0, .25) !important;
        }
    }
}