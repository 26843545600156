$brightBorder: #eceef5;
$imageMargin: 0.75rem;
$imageSize: 4.2rem;

.sidebar-wrap.large {
  .nav {
    padding-right: 2rem;
  }
}

.sidebar {
  position: sticky;
  top: $height-topbar;
  height: 91vh;
  display: flex;
  flex-direction: column;
  padding: 1rem 2.56rem;
  width: 29rem;
  border-right: 1px solid $color-border-layout;

  @media (min-width: $max-width-secondary) {
    height: 90vh;
  }
}

.products-tracked {
  min-height: 5rem;
  border-top: 1.2px solid $brightBorder;
  padding-bottom: 3rem;
  flex-grow: 1;
  padding-left: 1rem;

  .currently-tracking {
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    padding-top: 2rem;
  }

  .search-wrap {
    padding-right: 1rem;
  }

  .search {
    margin-bottom: 1rem;
    padding-top: 1rem;

    .ant-input-affix-wrapper {
      border: none;
      border-bottom: 1px solid #e9ebf3;
      background: transparent;
      height: 3rem;

      input {
        margin-left: 0rem;
        height: 3rem;
        background: transparent;
        &::placeholder {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 1.3rem;

          color: #b7b7b7;
        }
      }
    }

    .ant-input-affix-wrapper-focused {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0px;
    }

    input {
      color: black;
      opacity: 1;
    }
  }

  .icon-container {
    padding-top: 0rem;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    @include scrollStyle(4px, 7px, transparent, #e9ebf3, 3rem);
    //background: ;
    border-radius: 4px;
    padding-left: 0.3rem;

    .image-item {
      height: 3.9rem;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      padding-left: 0.8rem;
      position: relative;
      margin-right: 0.8rem;

      a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }

      // min-width: 4.4rem;
      // min-height: 4.4rem;
      &:hover {
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.09);
        border-radius: 4px;
      }

      &.with-dis::after {
        // INFO: if needed in the red dot on top of apps is future uncomment this
        // content: '';
        // position: absolute;
        // top: 0.2rem;
        // right: 0.2rem;
        // width: 0.6rem;
        // height: 0.6rem;
        // background: #F31E28;
        // border-radius: 50%;
      }
    }
  }

  &.normal {
    .icon-container {
      padding: 0rem;
      padding-left: 0.2rem;

      .image-item.with-dis {
        padding-left: 0rem;
      }

      .image-item.with-dis::after {
        // INFO: if needed in the red dot on top of apps is future uncomment this
        // top: 0.4rem;
        // right: -0.4rem;
      }
    }

    .products-pr-title {
      display: none;
    }
  }
}

.search-account {
  .ant-select-selection--single {
    height: 3.2rem;

    .ant-select-selection__rendered {
      height: 2.97rem;
      background-color: white;

      .ant-select-selection-selected-value {
        font-size: 1.4rem;
        line-height: 3rem;
      }
    }
  }
}

@media (max-width: ($max-width-secondary - 1px)) {
  .sidebar {
    width: 9rem;
  }

  .products-tracked {
    .icon-container {
      justify-items: center;
      padding: 1rem 1.6rem;
    }
  }
}

@media (min-width: 1800px) and (max-width: 2399px) {
  .products-tracked .icon-container .image-item {
    height: 4.7rem;
  }
}

@media (min-width: 2400px) {
  .products-tracked {
    .currently-tracking {
      font-weight: 500;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  .search {
    .ant-input-affix-wrapper {
      input {
        height: 4rem !important;
        &::placeholder {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500 !important;
          font-size: 1.6rem !important;

          color: #b7b7b7;
        }
      }
    }
  }
}
