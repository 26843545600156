.segment-container {
  .extra-header {
    position: absolute;
    top: 0.5rem;
    right: 3.8rem;
    display: flex;
    
    align-items: center;
    .label{
      color: #FFFFFF;
      font-family: Roboto;
      font-size: 11px;
      letter-spacing: 0.09px;
      line-height: 13px;
      margin-right: 1rem;
    }
  }
}
