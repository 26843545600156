.performance-sticky-header {
  display: flex;
  background: #fbfbfb;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  width: 50%;

  &:nth-child(1) {
    padding-right: 1.35rem !important;
  }

  &:nth-child(2) {
    padding-left: 1.35rem !important;
  }

  .performance-sticky-header-content {
    display: flex;
    align-items: center;
    user-select: none;
    padding: 0;
    margin-right: 0rem;
    margin-left: 0rem !important;
    padding-left: 1rem;
    width: 100%;
    height: 5.5rem;
    justify-content: space-between;

    &>div {
      max-width: 12rem;
    }

    .performance-sticky-header-app-name {
      display: flex;
      align-items: center;

      .performance-sticky-header-app-name-title {
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        max-width: 3.2rem;
        object-fit: scale-down;
        margin-right: 1rem;

      }
    }

    .flag-icon {
      display: inline-block !important;
    }

    .performance-sticky-header-location {
      padding-right: 2rem;
    }

  }

  // width: 85%;




  .ant-badge-not-a-wrapper {
    background-color: transparent !important;
  }
}