.intelligence-marketing {
  position: relative;
  button.ant-btn.btn-create-subscription {
    position: absolute;
    top: 38rem;
    right: 0rem;
    z-index: 222;
  }
  .ant-select-selection {
    border-color: #d9d9d9;
  }

  .country-filter {
    width: 20.8rem;
    margin: auto 0rem auto 1rem;
    .flag-icon {
      margin-left: 1.5rem;
    }
    .ant-select {
      width: 100%;
    }
  }

  .marketing__sub-header-new {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    top: 4rem;
    z-index: 44;
  }
  .filter-count {
    margin-left: 0.8rem;
    background-color: black;
    color: white;
    padding: 0.15rem 0.4rem;
    border-radius: 2px;
    font-family: Roboto;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.6rem;
    padding: 0.3rem !important;
  }
  .marketing__sub-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 200;
    color: black;
    right: 0;
    top: 32rem;
    font-family: 'Roboto';
    z-index: 4;
    width: 62rem;
    .marketing-select-label {
      margin-right: 1rem;
      color: #9fa5ad;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 14px;
    }

    .filter-btn:focus {
      .filter-count {
        background-color: white;
        color: black;
      }
    }

    &__select {
      & > label {
        font-family: 'Roboto', serif;
        font-weight: 200;
      }
    }
    &__last-updated {
      user-select: none;
      margin-bottom: 2rem;
      font-family: 'Roboto', serif;
      font-weight: 200;
      & > div {
        font-family: 'Roboto Slab', serif;
        font-weight: 500;
      }
      @include respond(tab-land) {
        width: 93.5%;
      }
    }
  }

  .performance-app-drawer__apps-search-input {
    position: sticky;
    top: 0rem;
    z-index: 10;
    input {
      border: none;
      outline: none;
      border-bottom: 1px solid #edebeb;
      height: 5rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      border-radius: 0;
      &:hover {
        border-color: #edebeb;
      }
      &:focus {
        border-color: black;
        border-right-width: 0;
        outline: 0;
        box-shadow: none;
      }
    }
  }

  .ant-tabs-bar {
    position: relative;
    border-bottom: none;
    &:after {
      content: '';
      background: #e0e0e0;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }

  .ant-tabs-tab {
    strong {
      margin-left: 0.5rem;
    }
  }

  font-family: 'Roboto';
  .ant-tabs-nav {
    font-size: 1.6rem;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: black;
    font-weight: 500;
  }

  .ant-tabs-ink-bar {
    background-color: black;
  }

  .ant-tabs-tabpane {
    padding-top: 2rem;
  }

  .tabs {
    margin-top: 6rem;
  }

  .ant-tabs-content {
    min-height: 60rem;
  }
  h3.cat-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 4rem;
    img {
      margin-right: 1rem;
      width: 1.8rem;
    }
  }
  .campaigns-wrap {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }

  .importance-indicator {
    position: absolute;
    right: -6px;
    top: -6px;
    width: 15px;
    height: 15px;
    background-color: $color-redful;
    border-radius: 50%;
    z-index: 102;
    &.importance-indicator-animating {
      box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
      animation: pulse 2s cubic-bezier(0.66, 0.33, 0, 1);
      animation-iteration-count: infinite;
    }
  }
}

.marketing-insight,
.marketing-discovery-wrap {
  position: relative;

  h2.campaign-preview-title {
    color: #c3c3c3;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    text-align: center;
  }

  .view-right-side {
    h2 {
      color: #1a1818;
      font-family: 'Roboto';
      font-size: 2.3rem;
      margin-top: 5rem;
    }
    .extra-text {
      color: #676767;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.15px;
      margin-bottom: 3rem;
    }
  }
  .rel-insight {
    margin-bottom: 6rem;
    .versions__list__item {
      width: 20rem;
      height: 17rem;
      .versions__list__item-thumbnail {
        height: 68%;
        img.default {
          margin: 4rem auto auto !important;
        }
      }
      .versions__list__item-top {
        height: 32%;
        .versions__list__item-header {
          font-size: 1.1rem;
        }
      }
    }
    .app-data {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
    img.app-icon {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.8rem;
    }
    .app-title {
      color: #1b1b1b;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
    }
    .ins-release-date {
      color: #000000;
      font-family: Roboto;
      font-size: 1.7rem;
      margin: 0.4rem 0;
    }
  }

  .mark-hover {
    display: none;
  }
  .like-button:hover {
    .mark-hover {
      display: inline-block;
    }
    .mark-normal {
      display: none;
    }
  }

  .view__header__prev-next {
    margin-left: auto;
    top: 0rem;
    position: absolute;
    right: 1rem;
    &__prev-arrow {
      margin-right: 0.5rem;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2.6px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      /* font-size: 1.2rem !important; */
      position: relative;
      top: -0.1rem;
    }
    &__next-arrow {
      margin-left: 0.5rem;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2.6px;
      transform: rotate(135deg);
      transform: rotate(-45deg);
      position: relative;
      top: -0.1rem;
    }
    &__position {
      font-family: 'Roboto';
      text-align: center;
      margin-top: 1rem;
      font-size: 1.2rem;
      color: #6c6c6c;
      font-weight: 400;
    }
    .ant-btn-group {
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.82);
      & > button {
        background-color: #ffffff;
        &:disabled {
          border: solid 1px rgba(110, 110, 110, 0.2);
        }
        &:not(:disabled):hover {
          box-shadow: inset 0px 0px 17px -8px rgba(0, 0, 0, 0.18);
          span {
            color: white;
            border-color: white;
          }
        }
        &:disabled:hover {
          background-color: #ffffff !important;
        }
      }

      & > :nth-child(1) {
        // back button
        border: solid 1px #d9d9d9;
        margin-right: 0.1rem;
      }

      & > :nth-child(2) {
        // next button
        border: solid 1px #d9d9d9;
      }
    }

    & button span {
      color: rgba(0, 0, 0, 0.82);
      font-size: 1.4rem;
    }

    & button:disabled span {
      color: #cacaca !important;
      border-color: #cacaca;
    }
  }

  .marketing-insight-app-arrow {
    cursor: pointer;
  }

  .hr-row {
    background: linear-gradient(45deg, #e0e0e0, transparent);
    height: 1px;
    margin: 4rem 0 8rem;
  }

  &-app {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-icon {
      img {
        border-radius: 2.8px;
        background-color: #ffffff;
        box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.08);
        width: 5rem;
        margin: auto 2rem;
      }
    }
    &-name {
      color: #6c6c6c;
    }
  }
  .hr-row {
    margin: 2rem 0 4rem;
  }
  .marketing-insight-data {
    display: flex;

    .insight-descr {
      margin: 4rem 0 6rem;
    }

    .marketing-insight-content {
      h1 {
        font-family: 'Roboto Slab';
      }
      flex-basis: 70%;
      margin-right: 5rem;
      max-width: 76rem;
    }
  }
  .insight-details {
    .ant-col {
      margin-right: 7rem;
      color: #8f8f8f;
      font-family: 'Roboto';
      font-weight: 300;
      font-size: 1.4rem;

      div {
        font-size: 1.2rem;
        color: #8f8f8f;
      }
      h3 {
        font-family: 'Roboto Slab';
        text-transform: capitalize;
        font-weight: 400;
      }
    }
  }
  .insight-flags {
    flex-basis: 30%;
    h3 {
      margin-top: 3rem;
    }
    .extra-buttons {
      display: flex;
      justify-content: flex-end;
      & > div {
        margin-right: 2rem;
      }
    }
  }

  .insight-image {
    max-width: 25rem;
    img {
      width: 100%;
    }
  }
  .marketing-insight-app-name {
    h2 {
      font-family: 'Roboto';
      font-weight: 300;
      color: #6c6c6c;
    }
  }
  .insight-cam-images {
    display: flex;
    flex-wrap: wrap;
    .insight-image {
      width: 10rem;
      cursor: pointer;
      margin-right: 2rem;
      img {
        border-radius: 5px;
      }
      &:hover {
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .slider-arrow {
    font-size: 2.4rem;
    color: black;
    background: white;
    width: 5rem;
    line-height: 5rem;
    border-radius: 3rem;
    height: 4rem;
    right: auto;
    position: relative;
    top: 0.1rem;
    border: 1px solid #e7eaed;
    border-radius: 21.5px;
    background-color: #ffffff;
    box-shadow: 2px 0px 6px 0 rgba(0, 0, 0, 0.3);

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      will-change: opacity;
      transition: opacity 0.2s ease-in;
    }
    &:hover::before {
      opacity: 0;
    }
    &::after {
      box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
      opacity: 0;
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      will-change: opacity;
      transition: opacity 0.2s ease-in;
    }
    &:hover::after {
      opacity: 1;
    }

    &.arrow-right {
      transform: rotate(180deg);
    }
    img {
      width: 2rem;
      position: relative;
      top: -0.3em;
      left: 1rem;
    }
  }
  .timeline {
    margin-top: 3rem;
    min-width: 31rem;
    .hided-dates {
      overflow: hidden;
      transition: height 0.3s ease-in;
      &.hide {
        height: 0;
      }
    }
    &-step {
      display: flex;
      min-height: 5rem;
      height: auto;
      align-items: center;
      font-family: 'Roboto';
      color: #060606;
      font-weight: 500;
      font-style: 1.2rem;
      position: relative;
      .number-hide {
        font-size: 1.1rem;
        color: #9fa5ad;
      }
      .btn-change-hide {
        text-decoration: underline;
        color: black;
        margin-left: 0.5rem;
        cursor: pointer;
        user-select: none;
        img {
          width: 0.8rem;
          transition: all 0.3s ease-in;
          margin-left: 0.3rem;
        }
        &.hide {
          img {
            transform: rotate(180deg);
          }
        }
      }

      &.even {
        background-color: #f6f6f6;
      }
      &-day {
        flex-basis: 45%;
        padding-left: 1.5rem;
      }
      &-event {
        flex-basis: 80%;
        padding-right: 1.5rem;
        padding: 0.7rem;
      }
      &-line {
        position: absolute;
        border-left: 2px solid black;
        width: 2px;
        height: 100%;
        left: 8rem;
        &.first {
          height: 50% !important;
          top: 50%;
        }
        &.last {
          height: 2.5rem !important;
          top: 0rem;
        }
        &.split {
          border-left: 2px dashed black;
        }
      }
      &-dot {
        position: absolute;
        border: 2px solid black;
        width: 1.5rem;
        height: 1.5rem;
        background-color: white;
        left: 7.35rem;
        border-radius: 50%;
        &.first {
          background-color: black;
        }
        &.last {
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: #f4442e;
          background-color: white;
        }
      }
    }
  }
  .view__header__buttons {
    top: 3rem;
  }
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: black;
}
.ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
  color: black;
}
@keyframes blink {
  0% {
    background-color: white;
  }
  49% {
    background-color: white;
  }
  50% {
    background-color: #18b663;
  }
  99% {
    background-color: #18b663;
  }
  100% {
    background-color: white;
  }
}

.profiles {
  position: absolute;
  right: 0;
  display: flex;
  top: 11.7rem;

  .active-profiles-online-symbol {
    position: absolute;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    right: 1rem;
    top: 1rem;
    animation: blink 2s linear infinite;
  }

  .active-profiles {
  }

  & > div {
    max-width: 42.3rem;
    display: block;
    text-align: center;
    padding: 0 0.5rem;
    border-radius: 3px;
    & > div:nth-child(1) {
      margin-bottom: 0.5rem;
      padding-top: 1.75rem;
      min-width: 10rem;
    }

    .active-profiles-title {
      color: #b9b9b9;
      font-size: 1.2rem;
    }

    .active-profiles-count {
      font-family: 'Roboto';
      font-size: 3.5rem;

      color: black;
      font-weight: 400;
      position: relative;
      height: 9rem;
      border: 1px #f1f1f1 solid;
      img {
        margin-right: 0.7rem;
        position: relative;
        top: -0.2rem;
      }
    }
    .country-profiles-flag {
      padding-top: 0.2rem !important;
      padding-bottom: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      height: 9rem;
      border: 1px #f1f1f1 solid;
      transition: all 0.1s ease-in-out;
      align-items: center;
      &.flex-align-center {
        justify-content: center;
      }
      & > span {
        display: flex;
        flex-direction: column;
        .flag-icon {
          height: 2.36rem;
          width: 3.1rem;
          margin: 0.4rem 0.5rem;
        }
        .country-profiles-value {
          color: #9d9fa4;
          font-family: Roboto;
          font-size: 1.2rem;
          letter-spacing: 0.1px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }
  .country-profiles {
    position: relative;
  }
  .toggle-view {
    position: absolute;
    bottom: 4.5rem;
    right: 1.2rem;
    color: #000000;
    font-size: 1.08rem;
    letter-spacing: 0.09px;
    line-height: 12px;
    text-align: center;
    height: 2.6rem;
    width: 11.7rem;
  }
  .country-profiles.full {
    .country-profiles-flag {
      height: auto !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
      padding-bottom: 5rem;
      z-index: 22;
    }
  }
}

.filter-wrap {
  width: 73rem;
  border-radius: 0 0 2px 2px;
  background-color: #ffffff;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  .ant-popover-inner {
    box-shadow: none;
  }
  .ant-popover-inner-content {
    background-color: white;
    color: black;
  }
  h3 {
    color: #000000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    margin: 2.6rem 0 1.2rem;
  }

  .ant-select-open,
  .ant-select-selection,
  .ant-select-show-search {
    border-color: black !important;
  }
  .ant-checkbox + span {
    font-size: 1.5rem;
    color: black;
    font-family: 'Roboto' !important;
  }
  .ant-checkbox-group-item.ant-checkbox-wrapper {
    border: 1px solid #efefef;
    border-radius: 3px;
    padding: 0.75rem 0.9rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

    background: #f4f4f4;
    .ant-checkbox {
      border-radius: 2px;
    }
    .ant-checkbox::after {
      border-color: black;
    }
  }
  .ant-checkbox-group-item.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    border: 1px solid #000000;
    background-color: white;
    .ant-checkbox-checked .ant-checkbox-inner {
      border: 1px solid black;
      border-radius: 2px;
      background-color: #000000;
      color: white;
    }
    .ant-checkbox-checked::after {
      border-color: black;
    }
  }
  .radio-buttons {
    position: absolute;
    top: 2rem;
    right: 4rem;
    .clear-filter-btn {
      color: black;
      border-color: black;
      font-family: 'Roboto';
    }
    .ant-radio-checked .ant-radio-inner {
      border: 1px solid black;
      border-radius: 20px;
      color: white;
    }
    .ant-radio-inner::after {
      background-color: #000000;
    }
  }

  .filter-h1 {
    font-family: 'Roboto Slab';
  }
  .ant-select-selection,
  .ant-select-show-search {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
  }
  .ant-select-selection-item {
    border: 0.9px solid #3f4653 !important;
    border-radius: 13.5px !important;
    background-color: #ffffff !important;
    color: #1a1818;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selection__placeholder {
    font-family: 'Roboto';
  }
  .ant-select-arrow {
    cursor: pointer;
  }
}
.filter-menu {
  padding: 1rem 0.6rem;
  .rc-virtual-list-holder-inner {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  ul {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  .ant-select-dropdown-menu-item-selected {
    display: none !important;
  }
  .ant-select-dropdown-menu-item,
  .ant-select-item-option {
    position: relative;
    display: inline-block;
    color: #1a1818;
    font-family: Roboto;
    font-size: 1.2rem;
    letter-spacing: 0.1px;
    text-align: center;
    border: 1px solid black !important;
    margin: 0.7rem 0.5rem;
    border-radius: 1.35rem;
    padding-right: 1rem !important;
    text-transform: uppercase;
    min-height: 2.5rem;
    height: 2.5rem;
    line-height: 1.3rem;
    .ant-select-item-option-content {
      display: inline-block;
    }
    &:hover {
      background-color: black !important;
      border-color: black !important;
      color: white !important;
    }
    .ant-select-selected-icon {
      display: none !important;
    }
  }
}
.filter-btn:focus {
  background-color: black;
  border-color: black;
  color: white;
}

.email-preview {
  h3 {
    margin: 2rem 1rem 2rem;
    color: #797979;
    font-weight: 300;
  }
}

.ant-checkbox-wrapper {
  .flag-icon {
    margin-left: 1.5rem;
  }
}
