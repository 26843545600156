.ab-tests-page {
  position: relative;
  .app-search-wrap {
    position: absolute;
    top: 22rem;
    z-index: 36;
    left: calc(50% - 25rem);
  }
  .ant-tabs {
    margin-top: 2rem;
  }
  .ab-test-filters {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .app-ab-tests {
    margin-bottom: 5rem;
    margin-top: 1rem;
    .discoveries-list {
      padding-bottom: 0rem;
    }
    .ab-test-view-link {
      text-align: right;
      margin-top: 3rem;
    }
  }

  .app-name-ab-test {
    display: flex;
    align-items: center;
    img {
      width: 3.8rem;
      margin-right: 1rem;
      border-radius: 0.8rem;
    }
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    color: #000000;
    .ab-test-count {
      display: inline-block;
      margin-left: 0.7rem;
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-nav-container,
  .ant-tabs-nav-wrap,
  .ant-tabs-nav-scroll,
  .ant-tabs-nav, 
  .ant-tabs-nav-list,
  .ant-tabs-tab {
    overflow: visible;
  }

  .tab-ab-tests-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tab-ab-tests-count {
    position: absolute;
    z-index: 33;
    top: 5.5rem;
    width: 100%;
    text-align: center;
    left: 0rem;
  }
  .ant-tabs-content {
    padding-top: 9rem;
  }
  .filter-btn-new.platform-mult-selector {
    width: 23rem;
  }
  .filter-btn-new.platform-mult-selector span {
    width: 20rem;
    padding-right: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filter-select-new.ab-test-type {
    width: 30rem;
  }
}
