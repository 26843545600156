.g-bar-chart-wrapper {
  margin: 3rem 3rem 1rem 5rem;
  .bx--cc--grid g.x.grid g.tick line,
  .bx--cc--grid g.y.grid g.tick line {
    stroke: #e9ebf1;
  }
  .bx--cc--chart-svg {
    overflow: visible;
  }

  g.axis.left {
    .axis-title {
      transform: rotate(-90deg) translateY(-22px);
    }
  }
  .bx--cc--tooltip {
    background-color: rgba(0, 0, 0, 0.75);

    transform: translateX(0.5rem);
    text-align: center;
    .content-box {
      color: white;
      .text {
        display: flex;
        padding: 0.1rem 0.9rem;
        .competitor-name {
          margin-right: 0.5rem;
        }
      }
    }
    .g-bar-tooltip {
      color: white;
      padding: 0.1rem 0.9rem;
    }
  }
  .ticks {
    .tick {
      text {
        fill: #a9afb6 !important;
      }
    }
  }

  .legend {
    display: flex;
    justify-content: space-around;
    background: white;
    position: relative;
    top: -1.3rem;
    padding-top: 0.2rem;
    margin-left: 2rem;
    margin-right: 0.5rem;
    .legend-item {
      color: #000000;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 11px;
      text-transform: uppercase;
      align-items: center;
      .legend-color {
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      .legend-app-icon {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        img {
          width: 3.3rem;
          height: 3.3rem;
        }
      }
      .legend-days {
        width: 20rem;
        display: flex;
        justify-content: space-between;
        color: #9aa1a9;
        font-family: Roboto;
        font-size: 9px;
        font-weight: 500;
        letter-spacing: 0.1px;
      }
    }
  }
}
