.support-slack-button {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 85px 10px rgba(211, 211, 211, 0.19);
  border: solid 1px $color-pinkish-grey;
  position: fixed;
  padding: 1rem;
  right: 2.5rem;
  bottom: 7.5rem;
  transition: bottom 0.1s;
  z-index: 275;
  &:hover {
    bottom: 8rem;
    cursor: pointer;
  }
  & > img {
    max-width: 44px;
  }
  & > div {
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0.7px;
    & > span {
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0;
      position: absolute;
      top: -2.8rem;
      left: 0;
      height: 3rem;
      width: 100%;
    }
  }
}
