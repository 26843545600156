.report-center-wrapper {
  position: fixed !important;
  .ant-popover-inner-content {
    padding: 0;
    .report-center {
      width: 31rem;
      padding: 0;

      .empty-state-report {
        padding: 3rem 5rem 6rem;
        text-align: center;
        .report-empty-header {
          font-weight: 600;
          font-size: 1.6rem;
          margin-bottom: 1.5rem;
        }
        .report-empty-icon {
          margin-bottom: 2rem;
        }
      }

      .report-ctr-header {
        padding: 1.9rem 1.6rem 1.6rem 2.8rem;
        border-bottom: 1px solid #eceef5;
        position: relative;
        h2 {
          color: #000000;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0.2px;
          line-height: 21px;
          text-align: center;
        }
        .report-buttons {
          margin: 1rem 0 0;
          display: flex;
          justify-content: space-between;
        }
        .btn-report-mark-all-seen,
        .view-all {
          height: 3rem;
          font-size: 1.08rem;
          letter-spacing: 0.09px;
          line-height: 13px;
          text-transform: none;
          width: 11.2rem;
          .anticon {
            margin-left: 0.6rem;
          }
        }
      }
      .report-list {
        min-height: 25rem;
        max-height: 49rem;
        overflow: auto;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          width: 3.5px;
          border-radius: 3.15px;
          background-color: #939393;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        .report-sub-list-wrap {
          h3 {
            color: #9a9eaa;
            font-family: Roboto;
            font-size: 12px;
            letter-spacing: 0.1px;
            line-height: 14px;
            margin: 2rem auto 0.2rem 2.9rem;
          }
        }
      }
      .report-item {
        min-height: 8.1rem;
        position: relative;
        display: flex;
        align-items: center;
        padding: 2rem 1.8rem 2rem 2.8rem;
        cursor: pointer;
        .report-app-data {
          .app-icon-wrapper {
            &.def{
              border-radius: 50%;
              img.app-icon {
                height: 1.3rem;
                width: 1.78rem;
                margin: 0.9rem 0.6rem;
              }
            }
            &.real{
              border-radius: 0;
              background-color: transparent;
              width: 4rem;
              height: 4rem;
              padding: 0rem;
              position: relative;
              border: none;
              box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .1);
              margin-left: -1rem;
              border: 1px solid #D9D9D9;
              img.app-icon {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              }
            }
            height: 3.33rem;
            width: 3.33rem;
            border: 1px solid #d8d8d8;
            background-color: #ffffff;
            box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);

          }
        }
        .report-descr {
          width: 100%;
          min-height: 4rem;
          padding-left: 1rem;
          .report-descr-text {
            color: #000000;
            font-family: Roboto;
            font-size: 12.6px;
            font-weight: 300;
            letter-spacing: 0.14px;
            line-height: 18px;
            padding-right: 1.1rem;
          }
          .report-time-diff {
            color: #7b7b7b;
            font-family: Roboto;
            font-size: 10px;
            font-weight: 300;
            letter-spacing: 0.08px;
            line-height: 11px;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            img {
              margin-right: 0.3rem;
            }
          }
        }

        &.unread {
          .report-descr {
            position: relative;
            &::after {
              content: "";
              top: 0.2rem;
              right: 0.2rem;
              height: 0.65rem;
              width: 0.65rem;
              border-radius: 50%;
              background-color: #e34444;
              position: absolute;
            }
            .report-descr-text {
              font-weight: 500;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 2.8rem;
          height: 1px;
          width: 26.9rem;
          border-top: 1px solid #eceef5;
        }
        &:hover {
          background: linear-gradient(270deg, #ffffff 0%, #f6f6f6 100%);
        }
      }
    }
  }
}
