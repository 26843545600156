.performance-drawer {
  font-family: "Roboto" !important;

  .matched {
    font-size: .8rem;
    display: inline-block;
    margin-left: 0.5rem;
    color: black;
  }

  .performance-drawer__top__selected-item.matched {
    padding-bottom: 0rem !important;
    margin-bottom: 0rem !important;
  }

  &.disabled {
    opacity: 0.4;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  user-select: none;

  &__top {
    position: relative;
    padding: 2rem 0rem 0 1rem;

    &__name {
      color: #9fa5ad;
      font-size: 1.2rem;
    }

    &__selected-item {
      display: flex;
      cursor: pointer;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      color: #1a1a1a;
      padding: 0rem 0;
      padding: 0rem 0 1rem;
      line-height: 1.2rem;
    }

    &__tooltip-bottom {
      color: #9fa5ad;
      font-family: "Roboto";
      font-weight: 300;
      margin: 1rem 0;
      padding-right: 7rem;
      text-align: justify;
      padding-left: 1rem;
    }

    &__chevron {
      cursor: pointer;
      transition: transform 0.5s cubic-bezier(0.5, 0.25, 0, 1);
      position: absolute;
      right: 3rem;
      top: 2.5rem;

      &.opened {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    transition: height 0.5s cubic-bezier(0.5, 0.25, 0, 1);
    height: 0px;
    max-height: 35rem;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: 1px solid #edebeb;

    &.opened {
      border-bottom: 1px #edebeb solid;
      margin-bottom: 3rem;
    }

    &-search-input .ant-input-affix-wrapper:hover input {
      border-color: black !important;
    }

    &-search-input input {
      border: none;
      outline: none;
      border-bottom: 1px solid #edebeb;
      height: 5rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      border-radius: 0;

      &:hover {
        border-color: #edebeb;
      }

      &:focus {
        border-color: black;
        border-right-width: 0;
        outline: 0;
        box-shadow: none;
      }
    }

    &-item {
      padding: 1.3rem 1rem 1.3rem 4rem;
      cursor: pointer;
      color: currentColor;
      font-weight: 500;
      display: flex;
      align-items: center;

      &>div {
        display: inline;
      }

      .anticon {
        position: absolute;
        top: 1.7rem;
        right: 2rem;
        color: silver;
        font-size: 2rem;
      }

      // @include ellipsis;

      &:hover {
        background-color: #f4442e !important;
        color: #ffffff;

        * {
          color: #ffffff;
        }
      }

      &.disabled {
        filter: grayscale(1);
        cursor: not-allowed;
        color: grey;
      }
    }

    &-divider {
      width: 100%;
      border-bottom: 1px dashed red;
    }
  }

  .matched-item {
    color: #9fa5ad;
    font-weight: 400;
    display: inline-block;
    margin-left: 1rem;
    font-family: "Roboto";
  }

  .device-os-version {
    font-size: 1.3rem;
    display: inline-block;
    margin-left: 0.7rem;
  }
}

.performance-drawer:nth-child(2) {
  margin-left: 4rem;
}

.performance-drawer__list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.performance-drawer__list::-webkit-scrollbar:vertical {
  width: 11px;
}

.performance-drawer__list::-webkit-scrollbar:horizontal {
  height: 11px;
}

.performance-drawer__list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.performance-drawer__list::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}