.view,
.app-graph-view {
  position: relative;
  margin-top: 3rem;

  .discovery-loader {
    position: relative;
    top: 7rem;
  }

  .view-left-side {
    width: 73%;
    display: inline-block;

    .view__experiment-tabs {
      min-height: 50rem;

    }
  }

  .discovery-view-content{
    display: flex;
  }

  .ant-badge-status-text {
    font-weight: 400;
  }

  .assets-data-wrapper {
    position: relative;

    .blurry-back {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(6px);
    }
  }

  .view-right-side {
    width: 19.5%;
    display: inline-block;
    margin-left: 11.2rem;
    vertical-align: top;
    top: 6.5rem;
    position: relative;

    h2 {
      color: #1a1818;
      font-family: "Roboto";
      font-size: 2.3rem;
    }

    .extra-text {
      color: #676767;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.15px;
      margin-bottom: 3rem;
    }

    .rel-insight {
      margin-bottom: 6rem;

      .versions__list__item {
        width: 20rem;
        height: 17rem;

        .versions__list__item-thumbnail {
          height: 69%;

          img.default {
            margin: 4rem auto auto !important;
          }
        }

        .versions__list__item-top {
          height: 31%;

          .versions__list__item-header {
            font-size: 1.1rem;
          }
        }
      }
    }

    .app-data {
      display: flex;
      align-items: center;
    }

    img.app-icon {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.8rem;
    }

    .app-title {
      color: #1b1b1b;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
    }

    .ins-release-date {
      color: #000000;
      font-family: Roboto;
      font-size: 1.7rem;
      margin: 0.4rem 0;
    }

    .ver-platform {
      display: flex;
      align-items: center;
      color: #a9afb6;
      margin-bottom: 2rem;
      font-size: 1rem;

      .insight-ver {
        color: #bbbbbb;
        font-family: Roboto;
        font-size: 10px;
        margin-right: 1rem;
      }

      .app-platform {
        color: #c3c3c3;
        font-size: 1rem;
        margin-left: 1rem;

        img {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 0.5rem;
          position: relative;
          top: -0.1rem;
        }
      }
    }
  }

  .platform {
    color: #1a1a1a;
    font-size: 1.2rem;
    margin: 0.4rem 2rem;
    position: relative;

    .app-platform-android:after {
      background-image: url(../../assets/images/icons/playstore_icon.png);
    }

    .app-platform-ios:after {
      background-image: url(../../assets/images/icons/appstore_icon.png);
    }

    .app-platform-web:after {
      background-image: url(../../assets/images/icons/webapp.svg);
    }

    &::after {
      content: "";

      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      background-size: contain;
      top: 0rem;
      left: -2rem;
      z-index: 2;
    }
  }

  .hr-row {
    margin: 2rem 0 0rem;
    background-color: #e0e0e0;
  }

  .view-insight-app {
    cursor: pointer;

    &.demo {
      cursor: default;
    }
  }

  .view-insight {
    .hr-row {
      background: linear-gradient(45deg, #e0e0e0, transparent);
      height: 1px;
      margin: 4rem 0 8rem;
    }

    &-app {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        .view-insight-app-release {
          max-width: 40rem;
        }
      }

      &-release {
        color: #000000;
        font-family: Roboto;
        font-size: 1.5rem;
        letter-spacing: 0.13px;
        margin-left: 2rem;
        position: relative;
        top: 0.15rem;
        max-width: 0rem;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        white-space: nowrap;
      }

      &-icon {
        img {
          border-radius: 2.8px;
          background-color: #ffffff;
          box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.08);
          width: 5rem;
          margin: auto 2rem;
        }
      }

      &-name {
        h2 {
          color: #6c6c6c;
          display: flex;
          align-items: center;
          .draft {
            color: red;
            margin-left: 1rem;
            padding: 0.3rem 1rem;
          }
        }
      }
    }

    .hr-row {
      margin: 2rem 0 4rem;
    }
  }

  background-color: $color-white;

  &__overview {
    margin-top: 2.2rem;

    &-header {
      font-family: $font-primary;
      font-size: 1.8rem;
      font-weight: 700;
    }

    &-text {
      font-size: 1.4rem;
      max-width: 825px;
      min-width: 500px;
      white-space: pre-line;
      font-family: "Roboto";
      font-weight: 300;
      margin-bottom: 4rem;

      @include respond(phone) {
        width: 100%;
        min-width: 0;
      }
    }
  }

  .slider-arrow {
    font-size: 2.4rem;
    color: black;
    background: white;
    width: 5rem;
    line-height: 5rem;
    border-radius: 3rem;
    height: 4rem;
    right: auto;
    position: relative;
    top: 0.1rem;
    border: 1px solid #e7eaed;
    border-radius: 21.5px;
    background-color: #ffffff;
    box-shadow: 2px 0px 6px 0 rgba(0, 0, 0, 0.3);

    &.arrow-right {
      transform: rotate(180deg);
    }

    img {
      width: 1.5rem;
      position: relative;
      top: -0.3em;
      left: 1.2rem;
    }
  }
}

.discovery-view.is-marketing {
  .view .view-left-side {
    width: 67%;
  }

  .view .view-right-side {
    width: 20%;
    margin-left: 6%;
    top:6rem;
  }

  .view-insight-app {
    height: 6rem;
  }
}

.modal__overlay_gallery {
  overflow-y: scroll;

  &.Web .ReactModal__Content,
  &.Windows .ReactModal__Content {
    width: 70%;

    .gallery-discovery-descr {
      width: 100%;
    }

    .slick-image-slider-wrapper {
      width: 100%;
    }

    .view__experiment-tabs .control-image,
    .view__experiment-tabs .variants-images {
      width: 49%;
    }

    .gallery-discovery-descr {
      max-width: 100% !important;
    }

    .slick-list {
      height: 100% !important;
    }

    .view__experiment-image.view__experiment-image-video {
      width: 100% !important;
    }
  }

  &.marketing .ReactModal__Content {
    width: 34%;
  }

  .view__experiment-image.view__experiment-image-video {
    width: 25rem !important;
  }

  .ReactModal__Content {
    padding: 0;
    background-color: transparent;
    overflow: visible;
    border: none;
    display: flex;
    flex-direction: column;
    height: 100%;

    .view__experiment-tabs {
      height: 100%;
      justify-content: space-between;

      .image-wrapper {
        &>div {
          position: relative;
          height: inherit;
          width: fit-content;
        }

        //width: 37rem;
        .image-zoom {
          position: absolute;
          z-index: 33;
          height: 100%;
          width: 100%;
          background-repeat: no-repeat;
          cursor: zoom-out;
        }

        img {
          object-fit: contain;
          margin: auto !important;
        }
      }
    }

    .view__experiment-tabs .control-image,
    .view__experiment-tabs .variants-images {
      min-width: 35rem;
      margin-left: 0rem;
      position: relative;
    }

    .gallery-discovery-descr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem 0rem 1rem;
      max-width: 100rem;

      h1 {
        font-family: Roboto Slab;
        font-style: normal;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 2.9rem;

        color: #ffffff;
        margin-bottom: 2rem;
      }

      &-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.9rem;

        color: #ffffff;
      }

      color: white;
    }
  }

  .ant-tabs-bar {
    border-bottom: none !important;
  }

  .view__experiment-tabs .variants-images:not(.no-control):not(.long)::before {
    display: none;
  }

  h2,
  h5 {
    color: white !important;
    margin-right: 0rem;

    img {
      display: none;
    }
  }

  h5 {
    display: none;
  }

  .ant-tabs-tab {
    color: white;
  }

  .ant-tabs-tab-btn {
    color: white !important;
  }

  .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
    color: white !important;
  }

  .ant-tabs-ink-bar {
    background-color: white;
  }

  .view__experiment-images-text p {
    color: white;
  }

  .slick-image-slider-wrapper {
    flex-grow: 1;
    position: relative;
  }

  .slider-arrow {
    font-size: 2.4rem;
    color: black;
    background: white;
    width: 5rem;
    line-height: 5rem;
    height: 4rem;
    right: auto;
    position: relative;
    top: 0.1rem;
    border: none;
    border-radius: 21.5px;
    box-shadow: 2px 0px 6px 0 rgba(0, 0, 0, 0.3);

    &.arrow-right {
      transform: rotate(180deg);
    }

    img {
      width: 1.5rem;
      position: relative;
      top: -0.3em;
      left: 1.2rem;
    }
  }

  .slick-prev {
    position: absolute;
    left: -5%;
  }

  .slick-next {
    right: -3%;
    position: absolute;
  }

  .slick-arrow {
    background-color: transparent;
    border: none;
  }

  .slick-dots {
    width: 130%;
    left: -15%;

    li button:before {
      color: white;
    }

    li.slick-active button:before {
      color: white;
    }
  }

  .tags__tags-tag-element {
    color: white;
    background-color: transparent;
    border-color: white;
  }

  .gallery-discovery-descr-data {
    display: flex;
    margin-top: 3rem;

    strong {
      margin-left: 0.8rem;
    }

    &-type {
      margin-right: 1.7rem;
    }

    &-date {
      margin-left: 1.2rem;
    }
  }

  .view__experiment__modal-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: auto;
    width: auto;
  }

  .view__experiment-tabs .view__experiment-image {
    border-radius: 0;
    margin: 0rem !important;
    width: auto;
    box-shadow: none;
    max-width: 100%;
    max-height: 100%;
  }

  .close-image-gallery {
    position: fixed;
    top: 2rem;
    right: 0rem;
    cursor: pointer;
    padding: 1.5rem;

    @media (max-width: $max-width-small) {
      top: 16rem;
    }
  }

}

@media screen and (max-height: 1000px) {
  .modal__overlay_gallery .ReactModal__Content .view__experiment-tabs .image-wrapper {
    height: 50rem;
  }
}

@media screen and (max-height: 850px) {
  .modal__overlay_gallery .ReactModal__Content .view__experiment-tabs .image-wrapper {
    height: 41rem;
  }
}

@media screen and (max-height: 700px) {
  .modal__overlay_gallery .ReactModal__Content .view__experiment-tabs .image-wrapper {
    height: 35rem;
  }
}