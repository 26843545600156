.versions__empty {
    height: 53rem;
    width: 100%;
    position: relative;
    background-color: #f8f8f8;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    flex-direction: column;
    animation: fadeIn 1s;
    &-img {
        margin: 1rem auto 5rem;
    }
    &-title {
        color: #000000;
        font-family: Roboto;
        font-size: 2.2rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 2.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }
    &-paragraph {
        color: #4e4e4e;
        font-family: Roboto;
        font-size: 1.7rem;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 2rem;
        text-align: center;
    }
    .filter-warning {
        position: absolute;
        width: 25rem;
        padding: 1.5rem;
        top: 2rem;
        right: 2rem;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        color: #4e4e4e;
        font-family: Roboto;
        font-size: 1.45rem;
        font-weight: 300;
        display: flex;
        align-items: center;
        img {
            margin-right: 1rem;
        }
    }
}
