.csr-container {
  height: 56px;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 92vw;

  .close {
    width: 20%;
    height: 100%;
    border-radius: 8px;
    background: #eaeaea;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .crs-results {
    display: flex;
    width: 80%;
    height: 100%;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #2e5fff;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
  }
}
