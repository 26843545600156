.Mui-focused {
    &.MuiFormLabel-root {
        color: #59b0f6 !important;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #59b0f6 !important;
    }
}
.MuiInputBase-root:hover {
    .MuiOutlinedInput-notchedOutline {
        border-color: rgba($color: #59b0f6, $alpha: 0.5) !important
    }
}
.material-input-field {
    margin-top:10px !important;
    margin-bottom: 20px !important;
    legend {
        width: auto !important;
        // width: 40px !important;
    }
    .error-message {
        font-size: 12px;
    }
}