.ctl-timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  img {
    cursor: pointer;
  }

  .ctl-timeline-start-date {
    color: #2f3337;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 18.6px */
    text-transform: capitalize;
  }

  .ctl-timeline-end-date {
    color: #2f3337;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 18.6px */
    text-transform: capitalize;
  }
}
