.uj-filters {
  display: flex;
  align-items: end;
  gap: 8px;

  .uj-select-filter {
    cursor: pointer;
    padding: 8px 16px !important;
    border: 1px solid #d3d3d3;
    border-radius: 0px;
    width: 180px;
    height: 58px;
    display: flex;
    gap: 4px;
    flex-direction: column;

    .uj-select-title {
      color: rgba(0, 0, 0, 0.5);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .uj-select-cur-app {
      color: #000;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .uj-select-caret {
      align-self: flex-end;
      position: relative;
      top: -30px;
      cursor: pointer;
    }

    &:hover {
      background-color: white !important;
    }
  }

  .uj-personas {
    width: 24rem !important;
  }
}
