@keyframes pulse {
  //   0% {
  //     box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
  //   }
  //   33% {
  //     box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
  //   }
  //   100% {
  //     box-shadow: 0 0 0 12px transparent, 0 0 0 14px rgba($color-redful, 0);
  //   }
  to {
    box-shadow: 0 0 0 12px transparent, 0 0 0 14px rgba($color-redful, 0);
  }
}

@keyframes squareRotate3d {
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }

  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(-180deg);
  }

  75% {
    transform: perspective(100px) rotateX(0) rotateY(-180deg);
  }

  100% {
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
}

@keyframes squareRotate {
  from {
    transform: perspective(100px) rotateY(0deg);
  }
  to {
    transform: perspective(100px) rotateY(360deg);
  }
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 25px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    transform: rotate(360deg);
  }
}

%animated-images {
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 100%;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes shadowFade {
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow: $shadow-big;
  }
}

@keyframes dropDownOpen {
  from {
    max-height: 0;
    overflow: hidden;
  }
  to {
    max-height: 85vh;
    overflow: hidden;
  }
}
