.i-releases-list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  gap: 8px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .card {
    height: fit-content;
    padding: 24px 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;

    &.selected {
      border-radius: 8px;
      border: 1px solid #2e5fff;
      background: #f9f9fb;
    }

    &:hover {
      border-radius: 8px;
      background: #f9f9fb;
    }

    .card-heading {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .card-heading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        .date {
          color: #6c757d;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }

        .version {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          text-transform: capitalize;
        }

        .status {
          color: #017637;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          text-transform: capitalize;
          display: flex;
          width: 168px;
          margin-top: 8px;
          padding: 8px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: #e0fcdf;

          &.new {
            color: #017637;
            background: #e0fcdf;
          }

          &.empty {
            color: #606060;
            background: #f9f9fb;
          }
        }
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;

      .insights-count {
        color: #6c757d;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}

.i-timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  img {
    cursor: pointer;
  }

  .i-timeline-start-date {
    color: #2f3337;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 18.6px */
    text-transform: capitalize;
  }

  .i-timeline-end-date {
    color: #2f3337;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 18.6px */
    text-transform: capitalize;
  }
}

.i-releases-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 24px;
}
