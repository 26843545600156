@import "../../../sass-config/abstracts/variables.scss";

// BroadwayTheater
.modal-broadway-theater {
  background: rgba(30, 30, 30, 0.85) !important;
  padding: 5rem 27vw !important;

  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-modal {
    height: 90vh;
  }

  .ant-modal-content {
    background-color: transparent;
    min-width: auto;
    height: 90vh;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0rem;
    width: 100%;
    height: 90vh;
  }

  .close-image-gallery {
    position: fixed;
    top: 2rem;
    right: 5vw;
    cursor: pointer;
    padding: 1.5rem;
    z-index: 1600;

    @media (max-width: $max-width-small) {
      top: 16rem;
    }
  }

  .view__experiment-video {
    width: 100%;
    height: 100%;
  }

  .control-image.long,
  .variants-images.long {

    width: 200% !important;
    right: auto !important;
    .different-orientations {
      max-width: 100%;
    }
    .image-wrapper{
      border: none !important;
    }
    .slick-slide{
      padding: 0 10px;
    }
  }

  .variants-images.long {
    left: -50% !important;
  }

  .view__experiment-tabs {
    margin-bottom: 0rem;

    &>div {
      position: relative;

      .control-image {
        position: absolute;
        top: 0rem;
        width: 100%;
        padding: 0;
        right: 4rem;
      }

      .variants-images {
        position: absolute;
        top: 0rem;
        width: 100%;
        padding: 0;
        left: 4rem;

        .slick-arrow {
          top: 48%;
        }
      }

    }

    .cover-size {
      height: calc(100vh - 23rem);
      margin-top: 5rem;
      visibility: hidden;
    }


  }

  .slick-image-slider-wrapper {
    height: 100%;
  }

  .view__experiment-tabs .control-image,
  .view__experiment-tabs .variants-images {
    height: 85vh;
  }

  .view__experiment-images-text {
    display: none;
  }


  &.marketing {
    width: 34%;
  }

  .view__experiment-tabs {
    height: 100%;
    justify-content: center;
    width: 100%;

    .image-wrapper {
      border: 1px solid #7A7A7A;
      overflow: hidden;
      height: calc(100vh - 23rem);
      display: inline-block;
      width: 100%;

      &>div {
        position: relative;
        height: 100%;
        display: inline-block;
      }

      .image-zoom {
        position: absolute;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        cursor: zoom-out;
        z-index: 1500;
      }

      img {
        object-fit: contain;
        margin: auto !important;
        position: relative;
        z-index: 1499;
        width: 100%;
      }

      .cache-blurred-img {
        position: absolute;
        z-index: -1;
        width: 1rem;
        left: -20rem;
        height: 1rem;
        display: none;
      }
    }
  }

  .view__experiment-tabs .control-image,
  .view__experiment-tabs .variants-images {
    position: relative;
    padding: 0 !important;
    margin: 0rem 1rem !important;
  }

  .ant-tabs-bar {
    border-bottom: none !important;
  }

  .view__experiment-tabs .variants-images:not(.no-control):not(.long)::before {
    display: none;
  }

  h2,
  h5 {
    color: white !important;
    margin-right: 0rem;

    img {
      display: none;
    }
  }

  h5 {
    display: none;
  }

  .ant-tabs-tab {
    color: white;
  }

  .ant-tabs-tab-btn {
    color: white !important;
  }

  .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
    color: white !important;
  }

  .ant-tabs-ink-bar {
    background-color: white;
  }

  .view__experiment-images-text p {
    color: white;
  }

  .slick-image-slider-wrapper {
    flex-grow: 1;
    position: relative;
  }

  .slider-arrow {
    background-image: url("../../../assets/images/new_design/broadway-arrow.svg");
    background-size: 80%;
    background-position: center 40%;
    width: 7rem;
    height: 7rem;

    img {
      display: none;
    }

    &.arrow-left {
      transform: rotate(180deg);
      background-position: center 100%;
    }
  }

  .slick-prev {
    position: absolute;
    left: -5.5rem;
  }

  .slick-next {
    right: -5.5rem;
    position: absolute;
  }

  .slick-arrow {
    background-color: transparent;
    border: none;
    width: 7rem;
    height: 7rem;
    top: 48%;

    &:hover {
      opacity: 0.7;
    }
  }

  .slick-dots {
    position: absolute !important;
    bottom: -6rem !important;

    li button {
      width: 2rem;
      height: 2rem;
    }

    li button:before {
      content: ' ';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #000000;
      border: 0.5px solid #FFFFFF;
    }

    li.slick-active button:before {
      width: 4px;
      height: 4px;

      background: white;
    }
  }

  .tags__tags-tag-element {
    color: white;
    background-color: transparent;
    border-color: white;
  }

  .highlights-btn {
    position: fixed !important;
    top: calc(100vh - 15rem);
    right: 5vw;

    button {
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid #FFFFFF;
      border-radius: 3px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      color: #FFFFFF;
      width: 15rem;
      transition: none;

      &[disabled] {
        background-color: rgba(0, 0, 0, 0.5) !important;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        opacity: 0.2;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.5) !important;
        border: 1px white solid !important;
      }
    }

  }


  .gallery-discovery-descr-data {
    display: flex;
    margin-top: 3rem;

    strong {
      margin-left: 0.8rem;
    }

    &-type {
      margin-right: 1.7rem;
    }

    &-date {
      margin-left: 1.2rem;
    }
  }

  .view__experiment__modal-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: auto;
    width: auto;
  }

  .view__experiment-tabs .view__experiment-image {
    border-radius: 0;
    margin: 0rem !important;
    width: auto;
    box-shadow: none;
    max-width: 100%;
    max-height: 100%;
    border: none;
  }

  .view__experiment-tabs .image-wrapper>div {
    display: block;
  }

  .slider-wrapper {
    position: relative;
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 14px;
  }

  &.Web,
  &.Windows {
    padding: 15rem 10rem !important;

    .ant-modal-content {
      height: auto;
    }

    .ant-modal-body {
      height: auto;
    }

    .close-image-gallery {
      position: fixed;
      top: 10vh;
      right: 5vw;
    }

    .cover-size {
      display: none;
    }

    .view__experiment-tabs {
      height: 80%;

      &>div {
        width: 100%
      }
    }

    .gallery-discovery-descr {
      width: 100%;
    }

    .slick-image-slider-wrapper {
      width: 100%;
      display: flex;
    }

    .view__experiment-tabs .control-image {
      margin-left: auto;
    }

    .view__experiment-tabs .control-image,
    .view__experiment-tabs .variants-images {
      display: flex;
      flex-direction: column;
      justify-content: start;
      max-width: 45vw;
      width: 100%;
      right: 0;
      left: 0;
      position: relative;
      padding: 0 4rem;
      height: 100% !important;
    }

    .slick-list {
      min-height: auto !important;
      height: 100% !important;
    }

    .view__experiment-tabs .image-wrapper {
      height: auto;
      width: 100%;
    }

    .view__experiment-image.view__experiment-image-video {
      width: 100% !important;
    }

    .highlights-btn {
      position: absolute !important;
      left: calc(50% - 7.5rem);
      top: auto !important;
      bottom: -10rem;
      right: auto;
      display: inline-block;
    }

    .slick-arrow {
      top: 23rem
    }



    .slick-dots {
      bottom: -6rem !important;
    }

    .slick-next {
      right: calc(50% - 10rem);
      position: absolute;
      bottom: -11.5rem;
      top: auto !important;
    }

    .slick-next {
      right: calc(50% - 10rem);
      position: absolute;
      bottom: -11.5rem;
      top: auto !important;
    }

    .slick-prev {
      left: auto !important;
      right: calc(50% + 5rem);
      position: absolute;
      bottom: -11.5rem;
      top: auto !important;
    }

    .slider-count-4 {
      .slick-next {
        right: calc(50% - 11rem);
      }

      .slick-prev {
        right: calc(50% + 5rem);
      }
    }

    .slider-count-5 {
      .slick-next {
        right: calc(50% - 12rem);
      }

      .slick-prev {
        right: calc(50% + 6rem);
      }
    }

    .slider-count-6 {
      .slick-next {
        right: calc(50% - 13rem);
      }

      .slick-prev {
        right: calc(50% + 7rem);
      }
    }

    .slider-count-7 {
      .slick-next {
        right: calc(50% - 14rem);
      }

      .slick-prev {
        right: calc(50% + 8rem);
      }
    }

  }


}

.modal__overlay_gallery.iOS,
.modal__overlay_gallery.Android {
  .slick-image-slider-wrapper {
    width: 100rem;
  }
}