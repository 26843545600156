.platform-drawer-container {
  padding: 1.6rem;
  min-width: 200px;

  .platform-drawer-options {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .x-close {
      width: 24px;
      height: 24px;
      margin-left: auto;
    }

    h5 {
      color: #858585;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .all {
      padding: 0.8rem;
      border-radius: 4px;
      border: 1px solid #e3e4e3;
      background: #f9f9fb;
      margin-left: 0;
      width: fit-content;
    }

    .platform-drawer-option {
      cursor: pointer;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      height: 56px;
      border: 1px solid #d4d4d4;

      &.selected {
        background: #f7f7fb;
      }

      img {
        height: 2.4rem;
        width: 2.4rem;
      }
    }

    .ant-checkbox-inner {
      background-color: transparent;
      border: 1px solid black;
    }

    .ant-checkbox-checked::after {
      border: none;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid black;
      border-top: 0;
      border-left: 0;
    }
  }
}
