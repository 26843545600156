.uj-persona-filter-container {
  padding: 1rem 5rem 1rem 2rem;

  .uj-persona-filter-heading {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
  }

  .uj-persona-filter-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
