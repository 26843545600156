$mainColor: rgba(245, 166, 35, 1);
$compareColor: rgba(96, 148, 191, 1);

.divider {
  width: 100%;
  height: 1px;
  background-color: $color-pinkish-grey;
  margin: 0.5rem 0;
  &.perf {
    // &.main {
    //   background: $mainColor;
    // }
    margin: 3rem 0;
    //   &.secondary {
    //     background: $compareColor;
    //   }
    //   &.bold {
    //     border-radius: 4px;
    //     height: 1rem;
    //   }
    //   height: 4px;
    //   display: inline-block;
    //   &.compare {
    //     width: 50%;
    //   }
  }
}
