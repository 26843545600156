.modal{
    border-radius: 8px !important;

    &__overlay{
        background: rgba(#000,0.85);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 1000;
        padding: 5rem;
    }
}
.ReactModal__Body--open{
    // overflow: hidden;
}

.ReactModal__Content {
    border: 1px solid $color-brown-grey;
    background-color: $color-white;
    padding: 2rem !important;
    border-radius: 8px;
    position: absolute;
    height: fit-content;
    @include absCenter;
    opacity:0;

    @include respond(tab-port){
      padding: 1.5rem !important;
    }

    @include respond(phone){
        padding: 1rem !important;
    }

    &--after-open{
        opacity: 1;
        @include transition(opacity,.9s,!important);
    }
}