@import './mixins.scss';
@import '../../sass-config/abstracts/variables.scss';

.dashboard__container .dashboard__main .mo-container {
  position: relative;
  padding: 0;
  background-color: $color-light;

  .loading-container {
    position: absolute;
    top: 50vh;
    left: 50%;
  }

  .no-data-content {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(125%);
  }

  .mo-top-section {
    z-index: 200;
    position: sticky;
    top: 50px;
    background-color: white;
    padding-top: 2rem;
    padding-bottom: 1rem;

    .mo-heading {
      color: #000;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .mo-content {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding: 3.2rem;

    .mo-section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .two-charts {
      display: flex;
      gap: 3.2rem;
      width: 100%;
      box-sizing: border-box;

      > div {
        flex: 1 1 50%;
        overflow: auto;
      }

      @media (max-width: $max-width-secondary) {
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: 1400px) and (min-width: 600px) {
  .multi-select-container {
    min-width: 220px !important;
    max-width: 220px !important;
  }

  .dashboard__container {
    grid-template-columns: 5rem auto !important;
  }

  .sov-container {
    .sov-charts-container {
      min-width: 550px !important;

      .sov-chart-container {
        min-width: 550px !important;
      }
    }
  }
}

// MOBILE
@media only screen and (max-width: 520px) {
  .filter-wrap .ant-checkbox + span {
    font-size: 14px !important;
  }

  .channel-select-container {
    padding-top: 30px !important;
  }

  .mo-container {
    width: 100%;

    .mo-top-section {
      top: 40px;
      padding-bottom: 2rem;

      .mo-heading {
        font-size: 24px;
        line-height: 100%;
      }

      // Filters on top
      .mo-filters {
        margin-top: 2rem;

        .mo-filters-container {
          background-color: transparent;
          padding: 0;

          .select-filter {
            border-radius: 4px;

            .select-filter-title {
              font-size: 12px;
            }

            .select-body {
              font-size: 12px;
              padding: 2px 8px;
              height: 36px;
              border: none;
              border-radius: 4px;
              color: #4b4b4b;
              background-color: #f0f0fe;
              text-align: center;

              img {
                display: none;
              }

              .time-frames {
                height: unset;
              }
            }
          }
        }
      }
    }

    // Container for all the graphs
    .mo-content {
      margin-top: 24px;
      margin-left: 0;
      margin-right: 1rem;
      gap: 10rem;

      // General section
      .mo-section {
        overflow-x: auto;

        @include custom-scrollbar;

        .ma-cards-container {
          .ma-app-card {
            padding: 8px 4px;

            .ma-app-card-image {
              width: 32px;
              height: 32px;
            }

            .ma-app-card-name {
              font-size: 12px;
            }

            .ma-app-card-count {
              font-size: 14px;
            }
          }
        }

        // header with tooltip
        .heading-tooltip-container {
          flex-wrap: wrap;

          .heading-tooltip-title {
            font-size: 16px;
          }

          .heading-chart-percentage-toggle {
            margin-left: auto;
            font-size: 12px;
          }
        }

        // Campaigns channel breakdown
        .mccb-container {
          .mccb-container-body,
          .mccb-col-small {
            height: 283px;
          }
        }

        // Campaigns calendar
        .cc-container {
          .cc-container-body {
            height: 400px;
          }
        }

        // Campaigns per user type chart container
        .cput-contianer {
          width: 90vw;
          padding-bottom: 2rem;
          flex-direction: column;
          height: unset;

          .cput-chart {
            padding: 8px;
            max-height: 400px;

            .cput-app-container {
              .cput-app-thumbnail {
                width: 40px;
                height: 40px;
              }

              .cput-line {
                width: 84%;
              }
            }
          }
        }

        // multi select (sov & cput) charts
        .multi-select-container {
          min-width: 30rem;
          position: sticky;
          left: 0;
          border: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          padding-left: 0;
          min-height: 10rem;
          flex-wrap: wrap;

          .multi-select-clean {
            align-self: auto;
            .clean-selection {
              display: none;
            }
          }
        }

        // Promo Type chart
        .sov-container {
          flex-direction: column;
          max-height: unset;
        }

        .sov-chart-container {
          min-width: unset;

          .right-part {
            padding-left: 18px;
          }

          .left-part {
            position: absolute;
            min-width: fit-content;
            max-height: 250px;

            img {
              width: 40px;
              height: 40px;
            }

            .persona-container {
              display: none;
              gap: 1rem;

              .persona-container {
                max-width: 60px;
              }

              .persona-name {
                font-size: 12px;
                width: 70px;
              }

              .persona-count {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .app-drawer-container {
    max-height: 100vh !important;

    .apps-selection {
      .app-selection-item {
        width: 100% !important;

        img {
          width: 32px !important;
          height: 32px !important;
        }

        .app-name {
          font-size: 12px !important;
        }
      }
    }
  }
}
