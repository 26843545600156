.ctl-header {
  padding: 16px 24px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;

  .ctl-header-title {
    color: #202020;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 190%; /* 45.6px */
    text-transform: capitalize;
  }
}
