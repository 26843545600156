.footer-wrapper {
  background-color: #f8f8fa;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6.9rem;
  z-index: 995;

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2.4rem 0rem;
    .watchful-img {
      margin-right: 1.4rem;
      width: 2rem;
    }
    .watchfulAi2021The360CompetitiveM {
      display: flex;
      margin-right: 1.4rem;
      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0rem;
      text-align: left;
      margin-left: 1rem;
      .watchfulAi2021The360CompetitiveMEmphasis0 {
        margin-right: 1rem;
        font-weight: 500;
      }
      .blended {
        font-weight: 200;
        color: #b9b9b9;
      }
    }

    .privacyEndUserLicenseAgreement {
      margin-left: 1.4rem;
      a {
        color: black;
        text-decoration: underline;
      }

      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2rem;
      letter-spacing: 0rem;
      text-align: left;
    }
  }
}
