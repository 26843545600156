.fastest-app-item {
  flex-basis: 31%;
  position: relative;
  font-family: "Roboto";
  margin-bottom: 4rem;
  &.clickable {
    .fastest-app-record {
      cursor: pointer;
      transition: all 0.2s ease-in;
      &:hover {
        transform: translateY(-1px);
      }
      &::after {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        opacity: 0;
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        will-change: opacity;
        transition: opacity 0.2s ease-in;
      }
      &:hover::after {
        opacity: 1;
      }
    }
  }
  .ranks-top-place {
    font-size: 1.5rem;
    font-weight: 300;
  }
  .fastest-app-icon-wrap {
    width: 5rem;
    margin-right: 2rem;
    position: relative;
    img {
      width: 100%;
    }
  }

  .fastest-app-data {
    height: 5rem;
    display: flex;

    .fastest-app-name-model {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 6rem;
      .fastest-app-extra {
        display: flex;
        color: black;
        .fastest-app-loc {
          margin-left: 1.5rem;
          position: relative;
        }
      }
    }
  }

  h3 {
    font-size: 1.9rem;
    font-weight: 500;
    height: 8rem;
    display: flex;
    align-items: flex-end;
    width: 28rem;
    margin-bottom: 1.5rem;
    .anticon {
      color: #c3c3c3;
      position: relative;
      top: -0.5rem;
      left: 1rem;
    }
  }

  .fastest-app-record {
    border: 1px #e8e8e8 solid;
    min-height: 30rem;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;

    .fastest-app-record-value {
      text-align: center;
      margin-top: 1.5rem;
      &-time {
        font-size: 6rem;
        font-weight: 600;
        color: black;
        span {
          font-weight: 300;
          font-size: 2.5rem;
        }
      }
      &-handicap {
        color: #18b663;
        font-weight: 500;
        margin-top: -1rem;
      }
    }

    .fastest-app-record-info {
      font-size: 1rem;
      color: #9fa5ad;
      font-weight: 200;
      bottom: 3rem;
      text-align: center;
      position: absolute;
      width: 100%;
      left: 0;
      font-size: 1.3rem;
      .count-apps-event {
        color: #828282;
        font-weight: 500;
      }
    }
  }

  .winner-tag {
    position: absolute;
    background-color: red;
    top: 1rem;
    right: 1rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: white;
    font-family: "Roboto";
    padding: 0 1rem;
  }
  .fastest-app-data {
    display: flex;
    margin-top: 2rem;
    h4 {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 1.8rem;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      height: 3rem;
      text-overflow: ellipsis;
      position: relative;
      top: -0.5rem;
    }
  }
  .device-os-version {
    font-size: 1.2rem;
    display: block;
  }
}

.fastest-app-event-tooltip {
  .ant-tooltip-inner {
    padding: 1.5rem;
  }
}

.fastest-app-wrapper, .ranks-top-3{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  justify-content: space-between;
  margin-bottom: 12rem;
  &:after {
    content: "";
    justify-content: flex-start;
    flex-basis: 32%;
  }
}