.dash-overview {
  font-family: 'Roboto';
  position: relative;
  .h1-title {
    font-size: 3rem;
  }

  .overview-page__watch .app-watch {
    height: 4.5rem;
  }

  .apps-on-watch {
    margin-top: 0rem !important;
  }
  .dash-intel-apps {
    position: relative;
  }

  .versions__list__item-top {
    height: 7rem;
    z-index: 22;
    position: relative;
    background: white;
  }
  .versions__list__item .insight-read {
    bottom: -3rem;
  }

  .ant-collapse-arrow {
    border: 2px solid black;
    padding: 0.65rem 0;
    width: 2.1rem;
    height: 2.1rem;
    font-size: 3rem !important;
    left: -1rem !important;
    top: -0.5rem !important;
    border-radius: 3px;
    line-height: 0rem;
    padding-left: 0.2rem;
    position: relative;
    &.collapse-state-plus {
      font-size: 1.7rem !important;
      padding: 0.75rem 0;
      font-weight: 500;
      text-indent: 0.3rem;
      top: -0.7rem !important;
    }
  }

  .sticky-menu {
    position: fixed;
    top: 10rem;
    width: 23rem;
    left: 2rem;
    .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
      width: auto !important;
    }
    & > ul > li > .ant-tree-node-content-wrapper span {
      font-size: 1.55rem !important;
      cursor: default;
    }
    .active-tree-0 {
      & > li:nth-child(1) > span.ant-tree-node-content-wrapper > span {
        font-weight: 500;
      }
    }

    .active-tree-1 {
      & > li:nth-child(2) > span.ant-tree-node-content-wrapper > span {
        font-weight: 500;
      }
    }

    .active-tree-2 {
      & > li:nth-child(3) > span.ant-tree-node-content-wrapper > span {
        font-weight: 500;
      }
    }

    .ant-tree li span.ant-tree-switcher {
      position: relative;
      left: -0.5rem;
      z-index: 33;
    }
    .ant-tree-treenode-switcher-open {
      .sticky-menu-minus {
        display: block;
      }
      .sticky-menu-plus {
        display: none;
      }
    }
    .ant-tree-treenode-switcher-close {
      .sticky-menu-minus {
        display: none;
      }
      .sticky-menu-plus {
        display: block;
      }
    }
    .ant-tree-title {
      padding: 0.3rem 1rem !important;
      font-size: 1.4rem;
      color: black;
      display: block;
      &:hover {
        .disabled-item {
          font-weight: 300;
        }
      }
      .disabled-item {
        &:hover {
          font-weight: 300;
        }
        position: relative;
        left: 0.5rem;
        opacity: 0.5;
        cursor: default;
        font-size: 1.4rem;
        color: gray;
        font-weight: 300 !important;

        div {
          display: inline-block;
          font-size: 1.55rem;
        }
        .sticky-menu-plus {
          margin-right: 1rem;
          font-size: 1.3rem;
        }
      }
    }
    .ant-tree-node-selected {
      background-color: transparent !important;
      .ant-tree-title {
        color: white;
        border-radius: 0.5rem;
        background-color: #000000 !important;
      }
    }
    span {
      font-weight: 300;
    }
    .ant-tree-node-content-wrapper:hover {
      background-color: transparent !important;
    }
    .ant-tree-title:hover {
      font-weight: 500 !important;
    }
  }
  .ant-collapse-header {
    font-size: 2.7rem;
    margin: 1rem 0;
    font-family: 'Roboto Slab';
    strong {
      font-weight: 700;
    }
  }
  .ant-collapse-borderless {
    background-color: transparent !important;
  }

  .ant-collapse-content {
    padding-left: 0rem;
    .ant-collapse-content-box {
      padding-left: 0rem;
    }
  }
  @media (max-width: 1440px) {
    .sticky-menu {
      left: 0.2rem;
      .ant-tree-title {
        font-size: 1rem !important;
        left: -0.3rem;
        position: relative;
        padding: 0 0.5rem !important;
      }
      & > ul > li > .ant-tree-node-content-wrapper span {
        font-size: 1.3rem !important;
      }
    }
  }
  @media (max-width: 1260px) {
    .sticky-menu {
      display: none;
    }
  }
  .overview-page__watch {
    margin: 1rem 0 5rem;
    .extra-text {
      display: none;
    }
    .ant-scroll-number.ant-badge-count {
      p.ant-scroll-number-only-unit.current {
        color: white !important;
      }
    }
  }

  .app-data {
    display: flex;
    align-items: center;
    img {
      width: 4.5rem;
      border-radius: 5px;
      margin-right: 1.5rem;
    }
    h3 {
      font: 'Roboto';
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  .empty-insights {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    div {
      margin-left: 2rem;
      font-weight: 300;
      font-family: 'Roboto';
    }
  }
  .empty-marked-insights {
    position: relative;
    margin-top: 4rem;
    .mrk-img {
      width: 100%;
    }
  }

  .crit-insights {
    margin-bottom: 5rem;
    .discoveries-list {
      padding-bottom: 2rem;
    }
    h4.versions__list__item-header {
      font-weight: 500;
    }
    .versions__list__item .insight-read {
      bottom: 0rem;
    }
    .versions__list__item {
      width: 22rem;
      height: 19rem;
      background-color: #f6f5f5;
      .versions__list__item-thumbnail img.default {
        margin-top: 4rem;
      }
    }
  }

  .release-radar {
    .extra-text,
    .extra-text-2,
    .title:nth-child(1) {
      display: none;
    }
    .title {
      font-size: 1.6rem !important;
      font-weight: 500;
    }
    .overview-page__analyzed-versions {
      box-shadow: none;
      border-radius: 5px;
      padding: 0;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  .profiles-apps {
    margin-top: 10rem;
    .app-data {
      display: flex;
      position: relative;
      top: -3rem;
      margin-right: 4rem;
      margin-bottom: 7rem;
      height: 12.2rem;
      .active-profiles-count img {
        width: 1.8rem;
      }
      h3 {
        left: 0rem;
        position: absolute;
        top: -4rem;
      }
    }
  }
  .profiles {
    left: 7rem;
    position: absolute;
    top: 1rem;
  }

  span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
    position: relative;
    left: -4rem;
  }

  span.ant-tree-node-content-wrapper {
    left: -1.8rem;
    position: relative;
  }

  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open,
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close {
    font-size: 1.65rem;
  }

  .versions__list__item-thumbnail {
    height: 64%;
  }

  li.ant-tree-treenode-switcher-open {
    padding: 0.55rem 0;
  }

  .ant-collapse-header {
    margin: 2.7rem 0 !important;
    align-items: center !important;
  }

  .ant-collapse-item:nth-child(1) .ant-collapse-header {
    margin-top: 0 !important;
    display: flex;
    align-items: center;
  }

  .disabled-panel {
    .h1-title {
      color: gray;
      opacity: 0.5;
    }

    .ant-collapse-content-active {
      display: none;
    }

    .collapse-state-minus {
      display: block !important;
      border-color: rgba(128, 128, 128, 0.5);
    }
    .collapse-state-plus {
      display: none !important;
      border-color: rgba(128, 128, 128, 0.5);
    }
  }
  .ant-tree > li {
    margin-bottom: 1.2rem;
  }
  .ant-tree li ul {
    padding: 0 0 0 22px !important;
  }

  .sep-last-update {
    margin: 0 1rem;
    color: #979797;
  }

  .last-update {
    font-family: 'Roboto';
    display: inline-block;
    font-size: 1.4rem;
    color: #b2b7bf;
    position: relative;
    top: -0.2rem;
    span {
      display: inline-block;
      margin-left: 0.7rem;
      color: #505050;
    }
  }
  .ranks-table .ranks-table-head > div:nth-child(3),
  .ranks-table .ranks-table-app-data > div:nth-child(3) {
    flex-basis: 19%;
  }
  .ranks-table .ranks-table-head > div:nth-child(4),
  .ranks-table .ranks-table-app-data > div:nth-child(4) {
    flex-basis: 18%;
  }
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem;
}

.release-radar {
  border: 1px #e8e8e8 solid;
  border-radius: 5px;
  padding: 0rem 3rem 0;
  margin-bottom: 2.2rem;
  overflow: hidden;
}
.dash-over-ranks-table {
  position: relative;
  .over-bench-ranks-filters {
    position: absolute;
    top: 1rem;
    right: -2.5rem;
    display: flex;
    align-items: center;
    .filter-label {
      color: #9fa5ad;
      font-size: 1.2rem;
    }
  }
  i.anticon.anticon-info-circle.ranks-filter-info {
    margin-left: 1rem;
    color: silver;
  }
  .dash-over-bench-tables {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 4rem;
    h3 {
      font-weight: 500;
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
    & > div {
      flex-basis: 48%;
    }
  }

  .ranks-table .ranks-table-head > div:nth-child(1),
  .ranks-table .ranks-table-app-data > div:nth-child(1) {
    flex-basis: 13%;
  }

  .compare-line-wrap {
    height: 0.7rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5;
    .compare-line-val {
      background: black;
      border-radius: 0 5px 5px 5px;
      height: 100%;
    }
  }
  .aggr-device {
    margin: 4rem 0 6em;
    strong {
      margin-left: 1rem;
    }
  }
  .ranks-table {
    margin-bottom: 3rem;
  }
}

.aggr-products-wrap {
  .title {
    font-size: 2.2rem !important;
    margin-bottom: 2rem !important;
  }
  .instructions {
    color: #b2b7bf;
    font-family: 'Roboto';
    font-weight: 100;
  }
}
