@import '../../../mixins.scss';
@import '../../../../../sass-config/abstracts/variables.scss';

.sov-container {
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding-bottom: 2.4rem;
  position: relative;

  @include custom-scrollbar;

  .sov-app-container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $color-border-layout;
    flex: 1;

    .sov-app-title {
      font-size: 1.4rem;
      color: #212121;
      gap: 0.4rem;
      display: flex;
      align-items: center;
      margin-bottom: 2.4rem;

      img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
      }
    }
  }

  .sov-charts-container {
    display: flex;
    gap: 2rem;
    min-width: 600px;
    flex: 1;

    .sov-chart-container {
      border-radius: 8px;
      background: #fdfdff;
      display: flex;
      justify-content: center;
      align-items: center;

      &.customer-app {
        border: 1px solid #728ad8;
        background: #f6f6f6;
      }

      .left-part {
        display: flex;
        flex-direction: column;
        width: 22.5rem;
        overflow-y: auto;
        padding-right: 0.4rem;

        @include custom-scrollbar;

        .persona-container {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .persona-color {
            width: 12px;
            height: 12px;
            border-radius: 2000px;
          }

          .persona-name,
          .persona-count {
            text-overflow: ellipsis;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      .right-part {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
