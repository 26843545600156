.dashboard {
  &__container {
    background: white;
    color: black;
    width: 100%;
    display: flex;

    &.blurry {
      filter: blur(6px);
      position: relative;
    }
  }

  &__main {
    margin: 0 auto;
    width: 100%;
    overflow: auto;

    > :first-child {
      padding: 3.2rem;
    }
  }

  .h1-title {
    font-size: 4.2rem;
    font-family: 'Roboto';
    font-weight: 300;
    .header {
      display: flex;
      flex-direction: row;

      .medium {
        font-weight: 400;
        margin-left: 0.5rem;
      }
    }
  }

  .hr-row {
    background: linear-gradient(45deg, #e0e0e0, transparent);
    height: 1px;
    margin: 2rem 0 8rem;
  }

  .extra-text {
    color: #676767;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: 'Roboto';

    strong {
      font-weight: 600;
    }
  }

  .extra-text-2 {
    color: #c7c7c7;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: 'Roboto';
  }

  .title {
    font-size: 2.7rem;
    margin: 1rem 0;
    font-family: 'Roboto Slab';

    strong {
      font-weight: 700;
    }
  }

  .h1-title {
    font-size: 4.2rem;
    font-family: 'Roboto';
    font-weight: 300;
    .header {
      display: flex;
      flex-direction: row;

      .medium {
        font-weight: 400;
        margin-left: 0.5rem;
      }
    }
  }

  .hr-row {
    background: linear-gradient(45deg, #e0e0e0, transparent);
    height: 1px;
    margin: 2rem 0 8rem;
  }

  .extra-text {
    color: #676767;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: 'Roboto';
    strong {
      font-weight: 600;
    }
  }
  .extra-text-2 {
    color: #c7c7c7;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: 'Roboto';
  }

  .title {
    font-size: 2.7rem;
    margin: 1rem 0;
    font-family: 'Roboto';
    font-weight: 300;
    strong {
      font-weight: 700;
    }
  }
}

.scroll-top {
  position: fixed;
  bottom: 18rem;
  right: 1rem;

  width: 10rem;
  color: #00000031;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.09px;
  line-height: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .scroll-top-img {
    margin-top: 0.7rem;
    display: block;
    width: 5.2rem;
    border: 0.89px solid #000000;
    height: 5.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-color: white;

    img {
      width: 2rem;
    }

    &:hover {
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

@media (max-width: $max-width-secondary) {
  .dashboard {
    &__container {
      grid-template-columns: 5rem auto 5rem;
    }
  }
}

.main__loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
}

.sidebar-beta {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  left: 0;
  background: black;
  display: flex;
  z-index: 111;
  justify-content: center;
  align-items: center;

  .beta-tag {
    height: 1.8rem;
    padding: 0.1rem 0.5rem;
    width: 3.5rem;
    position: static;
    margin-right: 1rem;
  }

  .text-page-is-beta {
    font-size: 1.2rem;
    min-width: 91rem;
    font-weight: 300;
    color: white;
  }

  i.anticon.anticon-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 2rem;
    color: white;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .beta-page-descr {
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .ant-btn-default {
    color: black;
    border: 1px black solid;
    padding: 0.5rem 1rem;
    height: 3rem;
    font-size: 1.2rem;
    border-radius: 2.7px;
    transition: all 0.1s ease-in;
    font-weight: 600;

    &:hover {
      background: linear-gradient(270deg, #f4442e 0%, #f5222d 100%);
      box-shadow: 0 3px 15px 0 rgba(244, 68, 46, 0.15);
      color: white;
    }
  }
}
