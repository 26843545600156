.performance-history-graphs {
  font-family: "Roboto" !important;
  margin-top: 6.5rem !important;
  user-select: none;
  border-radius: 5px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    margin: -0.2rem 1.5rem 2rem 0rem;
    position: absolute;
    z-index: 30;

    & img {
      margin-top: -0.4rem;
    }

    &__show {
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: 300;
    }
  }

  &__graph-wrapper {
    width: 100% !important;
    margin-top: 2rem;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    padding: 3rem 3rem 1rem 2rem;
    display: flex;
    flex-direction: column;
  }

  span.tooltip-ver {
    font-size: 1.2rem;
  }

  .performance-metrics__legend-footer {
    display: flex;
    padding: 1rem 0;
    justify-content: start;
    margin-bottom: 2rem;
    position: relative;
    margin-left: 3rem;
  }

  .new-ver-legend {
    display: flex;
    margin-left: 2.8rem;
    align-items: center;
    position: relative;
    font-size: 1.7rem;
    color: black;
    font-weight: 300;
  }

  span.new-ver-separator {
    font-size: 2.5rem;
    margin-right: 2rem;
    color: #dadada;
    line-height: 1.5rem;
  }

  .new-ver-legend-sign {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    border: 2px solid red;
    margin-right: 1rem;
  }

  .graph-new-version {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    z-index: 10;
    border: 2px solid red;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    pointer-events: none;

    &:hover,
    &.hover {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .performance-history-graphs__line-wrapper {
    position: relative;
    margin: auto;
  }




}

.new-ver-legend {
  display: flex;
  margin-left: 2.8rem;
  align-items: center;
  position: relative;
  font-size: 1.7rem;
  color: black;
  font-weight: 300;
}

span.new-ver-separator {
  font-size: 2.5rem;
  margin-right: 2rem;
  color: #dadada;
  line-height: 1.5rem;
}

.new-ver-legend-sign {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: 2px solid red;
  margin-right: 1rem;
}

.ant-badge-status-text {
  font-family: "Roboto" !important;
  color: black;
  font-weight: 300;
}

.new-ver-tag {
  color: white;
  background: #f4452e;
  padding: 0.15rem 0.8rem;
  margin-left: 0.8rem;
  font-size: 1rem;
  top: 1rem;
  right: 1rem;
  box-shadow: 0 0 5px 0 rgba(244, 68, 46, 0.36);
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 3px;
}

#chartjs-tooltip,
.chartjs-tooltip-history-graph {
  opacity: 1;
  position: absolute;
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  transform: translate(20px, -50%);
  font-family: "Inter" !important;
  text-align: center;
  padding: 1rem 2rem !important;
  font-size: 1.2rem !important;
  min-width:fit-content;
  box-shadow: 0 2px 4px 0 rgba(57, 73, 93, 0.27);
  background-color: rgba(26, 26, 26, 0.75);
  z-index: 21;

  .tooltip-ver {
    font-size: 1.15rem;
    margin-left: 1rem;
  }

  .tooltip-time {
    color: #bdbdbd;
    font-size: 1.2rem;
  }

  table td {
    text-align: left;
    padding: 0.3rem 0;
  }

  &::before {
    position: absolute;
    top: 52%;
    left: -3px;
    display: block;
    width: 0;
    height: 0;
    margin: auto;
    background-color: rgba(26, 26, 26, 0.73);
    transform: translateY(-6.53553px) rotate(45deg);
    content: "";
    pointer-events: auto;
    border-top: 3px solid transparent;
    border-right: 5px solid rgba(26, 26, 26, 0.73);
    border-bottom: 3px solid transparent;
  }

  tbody {
    font-size: 1.5rem !important;
  }

}

.chartjs-tooltip-key {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 10px;
  border-radius: 3.5px;
}

#chartjs-tooltip-history-graph-duration,
#chartjs-tooltip-history-graph-memory,
#chartjs-tooltip-history-graph-data-rec,
#chartjs-tooltip-history-graph-data-ts {
  background-color: white;
  color: black;

  &::before {
    background-color: white !important;
    border-color: white !important;
    box-shadow: 0px 2px 1px 0px rgba(57, 73, 93, 0.27);
  }
}

.back-area-color {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}