.uj-page {
  margin-top: 2rem;
  margin-left: 3rem;

  .uj-heading {
    display: flex;
    align-items: end;
    gap: 15px;

    .uj-title {
      color: #000;
      font-family: Roboto;
      font-size: 42px;
      font-style: normal;
      font-weight: 300;
      line-height: 42px;
      border-right: 1px solid #999;
      padding-right: 10px;
    }

    .uj-subtitle {
      color: #000;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .uj-body {
    margin-top: 3rem;

    .uj-flows {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .uj-flows-heading {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 22.4px; /* 112% */
        text-transform: uppercase;
      }
    }

    .uj-history {
      margin-top: 3rem;
      display: flex;
      gap: 2rem;

      .uj-history-selected-versions {
        border-left: 1px solid #999;
        padding-left: 2rem;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;

        .uj-history-version {
          color: #000;
          leading-trim: both;
          text-edge: cap;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */

          border-radius: 4px;
          border: 1px solid #656565;
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            cursor: pointer;
          }

          .uj-history-version-date {
            color: #656565;
          }
        }
      }
    }
  }
}
