.slick {
  &-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: $z-index-arrow;
    top: 50%;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-color: rgb(247, 247, 247);
    border: 1px solid $color-light-grey;
    border-radius: 0.5rem;
    transition: all 0.2s;
    &:hover {
      background-color: rgb(247, 247, 247);
    }
    &:hover:not(.slick-disabled) {
      //background-color: $color-blueful-light-2;
      //border: 1px solid rgba($color-blueful, 0.5);
    }
    &::before {
      content: "";
    }
    span {
      border: solid $color-blueful;
      position: absolute;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      top: 35%;
    }

    &__versions {
      width: 4rem;
      height: 3.2rem;
      background-color: #ffff;
      border: none;
      &:hover:not(.slick-disabled) {
        border: none;
        span {
          border-color: $color-blueful;
        }
      }
      span {
        padding: 2.7px;
        border-width: 0 2.4px 2.4px 0;
        top: 40%;
      }
    }
    &__overview,  &__marketing{
      background-color: transparent;
      box-shadow: unset !important;
      border: none !important;
      &:hover {
        border: none !important;
        background-color: transparent !important;
      }
      &.slick-disabled{
        opacity: 0.1;
      }
    }
  }
  &-prev {
//    left: -1.6rem;
    //box-shadow: 10px 2px 9px 0 rgba(0, 0, 0, 0.25);
    span {
      left: 40%;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    &__versions {
      left: -4rem;
      //transform: translateY(-9px);
      box-shadow: 0 4px 8px 0 rgba(26, 21, 21, 0.12);
      span {
        left: 42%;
      }
      @include respond(phone) {
        left: -2.5rem;
      }
    }
    &__overview,  &__marketing {
      left: 0px !important;
    }
  }
  &-next {
    //right: -1.6rem;
    //box-shadow: -10px 2px 9px 0 rgba(0, 0, 0, 0.25);
    span {
      right: 40%;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    &__versions {
      right: -4rem;
      //transform: translateY(-9px);
      box-shadow: 0 4px 8px 0 rgba(26, 21, 21, 0.12);
      span {
        right: 45%;
      }
      @include respond(phone) {
        right: -2.5rem;
      }
    }
  }
  &-disabled {
    cursor: default;
    span {
      border-color: $color-pinkish-grey;
    }
  }
  &-dots {
    li button:before {
      font-size: 1rem;
    }
    // li.slick-active button:before {
    //   color: $color-blueful;
    // }
  }
  &-slide {
    border-radius: 10px;
  }
}
