.popular-days-wrapper {
  .bx--cc--chart-svg {
    overflow: visible;
    path.bar {
      //fill: #16161680 !important;
      &.hovered {
        fill: black !important;
      }
    }
  }
}
