$color-performance-main: #083d77;
$color-performance-compare: #f0a202;

$color-red: #f4442e;

$color-white: #ffffff;
$color-black: #1a1515;
$color-black-table: #1a1a1a;
$color-very-light-grey: #edebeb;
$color-light-grey: #e7e7e7;
$color-light-grey-2: #c5c4c4;
$color-dark-gray: #323a46;
$color-pinkish-grey: #ccc6c6;
$color-brown-grey: #807a7a;
$color-brown-2: #bf8f86;
$color-visited-grey: hsl(207, 0%, 37%);
$color-greyish-brown: #4d4646;
$color-blueful-light: #a3d6ff;
$color-blueful-light-2: #d4ecff;
$color-blueful-light-3: #b2d4ff;
$color-blueful-light-4: #e3f2fd;
$color-blueful-button: #c7e5fc;
$color-blueful-button-hover: #59b0f6;
$color-blueful: #2196f3;
$color-blueful-2: #40a9ff;
$color-blueful-dark: #1766a6;
$color-blueful-dark-2: #3273a8;
$color-blueful-dark-3: #096dd9;
$color-blueful-darker: #0b3252;

$color-redful: #f22801;
$color-redful-dark: #b31e00;
$color-greenful: #00b347;
$color-greenful-2: rgba(89, 158, 15, 0.902);
$color-greenful-light: #6cbf13;
$color-orange: #f5a623;
$color-dark-green: #99b37d;

$color-border-layout: #eceef5;
$font-headings: 'Roboto Slab', sans-serif;
$font-primary: 'Roboto', sans-serif;
$color-subtitle: #64748b;
$height-topbar: 6rem;
$height-topbar-small-breakpoint: 5rem;
$z-index-arrow: 5;
$z-index-backdrop: 150;
$z-index-view: 160;
$z-index-overlay: 170;
$z-index-header: 200;
$z-index-nav: 300;
$z-index-modal-overlay: 400;
$shadow-small: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
$shadow-big: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
$shadow-search-bar-row: 0 4px 8px 0 rgba(26, 21, 21, 0.12);
$max-width-primary: 1450px;
$max-width-secondary: 1280px;
$max-width-small: 768px;

// # Form Colors # //
// COLORS
$color-light: #f8f8f8;
$color-white: #ffffff;
$color-dark-weak: rgba(26, 26, 26, 0.65);
$color-dark-2: #1a1515;
$color-dark: #1a1a1a;
$color-danger: #ff0000;
$color-danger-bg: rgba(242, 40, 0, 0.12);
$color-blue: #2196f3;
$color-border: #ccc6c6;
$color-border-2: #807a7a;
$box-shadow: 0px 2px 4px 0px rgba(26, 21, 21, 0.08);
$box-shadow-2: 0px 2px 6px 0px rgba(26, 21, 21, 0.24);
$color-modal-layover: rgba(26, 21, 21, 0.75);
$color-input: rgba(33, 150, 243, 0.1);

$color-performance-compare-1: #2763ff;
$color-performance-compare-2: #ff2775;
