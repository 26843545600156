.versions__slider {
  overflow: visible;
  position: relative;
  .slick-next__versions {
    right: -5.5rem;
  }
  .slick-arrow {
    box-shadow: 0;
    
    &:hover {
      background-color: transparent !important;
    }
    .slider-arrow {
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 3px 0px 8px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }
  .slider-arrow.arrow-left {
    &::before {
      content: "";
      position: absolute;
      height: 21rem;
      width: 9.5rem;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%);
      z-index: -1;
      top: -7rem;
      left: 1rem;
    }
  }
  .slider-arrow.arrow-right {
    &::before {
      content: "";
      position: absolute;
      height: 21rem;
      width: 8.5rem;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%);
      z-index: -1;
      top: -7rem;
      right: -10rem;
    }
  }
  // .beatiful-white-gradient-for-orr {
  // }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  width: 93%;
  @include respond(phone) {
    margin-top: 2rem;
  }
  &__item {
    cursor: pointer;
    padding: 2.5rem 0;
    margin: 3.6rem 0;
    text-align: center;
    border-radius: 5px;

    min-height: 17rem;
    display: flex !important;
    flex-direction: column;
    &.analysis_pending, &.analysis_in_progress.nonvis {
      opacity: 0.3;
      &:hover {
        cursor: default;
        .versions__slider__item {
          cursor: default !important;
        }
        .versions__slider__item__line-dot:not(.versions__slider__item__line-dot-future) {
          border-color: #eceef5;
          cursor: default;
          // color: white;
        }
        .versions__slider__item__line-border:not(.versions__slider__item__line-border-future) {
          background-color: #eceef5;
          cursor: default;
        }
      }
    }

    &-active {
      animation: shadowFade 0.2s linear;
      border: 1px solid #eceef5;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
      .versions__slider__item__line-active {
        position: relative;
        top: -0.1rem;
      }
    }
    &__box {
      .state {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        span {
          padding: 0.35rem 1.2rem;
        }
        &.state-completed span,
        &.state-published span,
        &.state-in_review span {
          color: #18b663;
          background-color: rgba(24, 182, 99, 0.1);
        }
        &.state-analysis_in_progress span  {
          background-color: rgba(251, 140, 0, 0.1);
          color: #fb8c00;
        }
        &.state-analysis_pending span, &.state-new span, &.state-analysis_in_progress.nonvis span {
          background-color: #9fa5ad;
          color: black;
        }
      }

      &__latest {
        user-select: none;
        position: absolute;
        left: 50%;
        top: -4.3rem;
        transform: translate(-50%, 0);
        &.active {
          color: $color-blueful;
        }
      }
      position: relative;
      display: flex;
      flex-direction: column;
      font-family: "Roboto";
    }
    &-version {
      font-size: 1.44rem;
      color: #4d4646;
      font-weight: 500;
      &-active {
        color: black;
        font-size: 1.56rem;
        line-height: 2.3rem;
        font-weight: 600;
      }
    }
    &-changes {
      font-size: 1.2rem; // margin-bottom: .7rem;
      padding-top: 0.2rem;
      color: #a1a1a1;
      text-transform: uppercase;
      .so-far {
        color: #b0b0b0;
        margin-left: 0.5rem;
      }
      &-active {
        color: $color-black;
      }
    }
    &__line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 1.3rem 0;
      &-dot {
        position: absolute;
        transform: translateY(-0.3px);
        left: 47%;
        height: 12px;
        width: 12px;
        color: white;
        border-radius: 50%;
        vertical-align: 2px;
        border: 0.2rem solid #eceef5;
        margin: 0 1px;
        display: inline-block;
        @include transitionHover;
        z-index: 200;
        background-color: #fff;
        &.has_digits {
          height: 20px;
          width: 20px;
          line-height: 16px;
          border-color: #e53935;
          background-color: #e53935;
          color: white;
        }
        &-active {
          border-color: $color-blueful-darker;
        }
        &.importance-indicator-animating {
          background: $color-redful !important;
          border-color: $color-redful !important;
          box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
          animation: pulse 2s cubic-bezier(0.66, 0.33, 0, 1);
          animation-iteration-count: infinite;
        }
      }
      &-border {
        display: inline-block;
        flex: 1;
        height: 0.14rem;
        background-color: #eceef5;
        position: relative;
        @include transitionHover;
        &-last {
          // background-image: linear-gradient( 105deg, rgba(#fff, .9) 0%, rgba($color-pinkish-grey, .8) 20%, $color-pinkish-grey 65%);
        }
        &-active {
          // background-image: linear-gradient( 105deg, rgba(#fff, .9) 0%, rgba($color-blueful, .8) 20%, $color-blueful 65%);
          background-image: none;
        }
        &-active:not(.versions__slider__item__line-border-last) {
          // background-color: $color-blueful;
          // background-image: none;
        }
      }
    }
    &-date {
      display: inline-block;
      font-size: 1.2rem;
      color: #a1a1a1;
      font-family: "Roboto";
      &-active {
        color: black;
      }
    }
    &:hover {
      div > .versions__slider__item__line-dot:not(.versions__slider__item__line-dot-future) {
        border-color: black;
        //background-color: #E53935;
        // color: white;
      }
      div > .versions__slider__item__line-border:not(.versions__slider__item__line-border-future) {
        background-color: black;
      }
    }
  }
  .slick-slider {
    @include respond(phone) {
      padding: 0 1.2rem;
    }
  }
  .slick-slider > .slick-list > .slick-track > .slick-active > div {
    display: flex;
    flex-direction: row-reverse;
  }
  .slider-arrow {
    font-size: 2.4rem;
    color: black;
    background: white;
    width: 5rem;
    line-height: 5rem;
    height: 4rem;
    right: auto;
    position: relative;
    top: 0.1rem;
    border: 1px solid #e7eaed;
    border-radius: 21.5px;

    box-shadow: 2px 0px 6px 0 rgba(0, 0, 0, 0.3);

    &.arrow-right {
      transform: rotate(180deg);
    }
    img {
      width: 1.5rem;
      position: relative;
      top: -0.3em;
      left: 1.2rem;
    }
  }
  .versions-last-versions {
    position: absolute;
    top: 11rem;
    width: 7rem;
    right: -8rem;
    font-size: 1.2rem;
    color: #909090;
    text-transform: uppercase;
  }
}

.slider-disabled {
  cursor: not-allowed !important;
}
