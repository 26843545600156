$imgWidth : 3.6rem;
$strongOpacity: 0.13;
@mixin flexCenter($direction) {
  display: flex;
  align-items: center;
  flex-direction: $direction;
  right: 0;
  position: absolute;
}
@mixin transparentPanel($color) {
  font-family: Roboto;
  font-size: 1rem;
  color: $color;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 1.1rem;
  height: 1.5rem;
}
@mixin shadow (){
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.05);
  border-radius: 3px;
}

@mixin activeText($background) {
  background: $background;
  border-radius: 3px;
  padding-top: 0.3rem;
}
.products {
  @include flexCenter(row);
  p.ant-scroll-number-only-unit {
    color: #ffffff;
  }

  .active-card {
    transition: all 0.2s ease-in;
    padding: 0.5rem;
    .platform-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 6.2rem;

      .platform-icon{
        width: $imgWidth;
        height: $imgWidth;
        margin-right: 1rem;
        &.move-right {
          margin-left: 1rem;
        }
        &.windows.off{
          display: none;
        }
        &.chrome_extension.off{
          display: none;
        }
        .intelligence-icon {
          width: $imgWidth;
          height: $imgWidth;
          border: 1px solid #f1f1f1;
          border-radius: 3px;
          margin: 0;
          &.on {
            &:hover {
              cursor: pointer;
              @include shadow()
            }
          }
          &.off {
            opacity: $strongOpacity;
            &:hover{
              cursor: default;
            }
          }

          &.opacity{
            opacity: $strongOpacity;
          }

        }
      }

    }
    display: flex;
    flex-direction: row;
    margin-left: 1.2rem;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    align-items: center;

    &:hover {
      &.on{
        @include shadow();
      }

      &.off {
        cursor: default;

      }
    }


    //width: 18.8rem;
    height: 6.2rem;
    .image {
      width: $imgWidth;
      height: $imgWidth;
      opacity: $strongOpacity;
      border: 1px solid #979797;
      margin: 0 0.5rem 0 0.5rem;
      padding: 7px;
      &.cursorOff {
        cursor: default;
      }
    }
    .active {
      display:flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 13.5rem;
      margin-right: 0.5rem;
      .active-green {

        @include activeText(#00b3472c);
        .text {
          @include transparentPanel(#00b347);


        }

      }
      .active-grey {
        @include activeText(#6c6c6c24);
        .text {
          @include transparentPanel(#6c6c6c);

        }


      }
    }
    &:hover {
      cursor: pointer;
    }
  }


}
.text-div{
  margin: 0.25rem;
  .product {
    font-weight: lighter;

  }
  width: 12rem;

  font-family: Roboto;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 500;
}

.white-badge{
  p.ant-scroll-number-only-unit {
    color: #ffffff;
  }
}
