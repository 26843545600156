@mixin add-subs-title() {
  display: flex;
  flex-direction: row;
  margin: 3rem 0 1rem;
  line-height: 8px;
  .text {
    font-family: Roboto;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    color: #a6a8ad;
  }
  .star {
    color: red;
    margin-left: 0.2rem;
  }
}

.modal-subscription {
  .specs-wrapper {
    .ant-btn.filter-btn-new .filter-count {
      font-size: 0.8rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding: 1rem 4rem;
    .ant-modal-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;

      color: #000000;
      margin-top: 3rem;
    }
  }

  .ant-modal-body {
    padding: 1rem 4rem;
    .add-subs-title {
      @include add-subs-title();
    }
  }

  .ant-modal-footer {
    border-top: none;
    text-align: right;
    .ant-btn {
      // width: 7rem;
      height: 2.6rem;

      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 1.2rem;
    }
  }

  .specs-wrapper {
    display: flex;
  }

  .add-subs-specs {
    min-height: 15rem;
    .add-subs-title {
      @include add-subs-title();
    }
  }

  .spec {
    width: 15.5rem;
  }

  .spec-product-value {
    img {
      width: 2rem;
      margin: 0.4rem;
    }
  }

  .spec-tag-value {
    display: flex;
    flex-wrap: wrap;
    .tags__tags-tag-element {
      font-size: 0.7rem;
      &[data-tag-id="-1"] {
        color: #f4442e;
        border: 1px solid #f4442e;
      }
    }
  }
  .spec-tag-match-type {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    list-style-type: disc;
    line-height: 9px;
    padding-left: 0.9rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    .spec-tag-match-crl {
      background-color: black;
      width: 0.3rem;
      height: 0.3rem;
      display: inline-block;
      margin-right: 0.8rem;
      border-radius: 50%;
    }
  }
  .ant-btn.filter-btn-new {
    width: 100%;
    .star {
      color: red;
      margin-left: 0.3rem;
    }
  }

  .spec-licence-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 9px;
    div {
      padding-left: 1rem;
      margin-bottom: 1rem;
      .licence-mark {
        margin-right: 0.7rem;
        display: inline-block;
      }
    }
  }

  h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 8px;
    text-transform: uppercase;
    color: #a6a8ad;
    margin: 3rem auto 1rem;
  }
  .subscription-errors {
    div {
      color: #e34444;
      position: relative;
      &::before {
        content: "";
        height: 0.4rem;
        width: 0.4rem;
        top: 0.8rem;
        left: -1.5rem;
        position: absolute;
        border-radius: 50%;
        background-color: #e34444;
      }
    }
  }
  .sep-row {
    height: 1px;
    border-bottom: 1px solid #e5e5e5;
    margin: 2rem;
  }
  .subscription-freq {
    margin: 2rem 0rem 4rem;
  }
}
.subscription-licences-popup {
  height: 7rem;
  width: 22rem !important;
}

.licences-modal {
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  .licences-wrapper {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }
  .licences-btn-all {
    display: flex;
    flex-direction: row-reverse;
  }
  .ant-checkbox-wrapper {
    margin-left: 0px !important;
    margin-bottom: 0.7rem;
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem !important;
      line-height: 9px;
    }
    .ant-btn {
      font-size: 1rem;
    }
  }
}
.mandatory-wrapper {
  position: absolute;
  left: 4rem;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  .star {
    margin-right: 0.5rem;
    color: red;
    padding-top: 3px;
  }
  .text {
    font-family: Roboto;
    font-weight: 500;
    font-size: 0.8rem;

    color: #a6a8ad;
  }
}
.filters-popup.tags {
  li.ant-select-selection__choice[title="critical insight"] {
    color: #f4442e;
    border: 1px solid #f4442e;
    svg {
      fill: #f4442e;
    }
  }
}
