body {
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1.6;
}

.heading {
    font-family: $font-headings;
    &-h2 {
        font-size: 2.5rem;
        font-weight: 400 !important;
    }
    &-h3 {
        font-size: 1.8rem;
        font-weight: 700;
    }
    &-h4 {
        font-size: 1.6rem;
    }
}