.i-header-container {
  display: flex;
  flex-direction: column;

  .i-filters-container {
    display: flex;
    min-width: 100%;
    justify-content: flex-start;
    gap: 1rem;
    align-items: flex-end;

    .select-filter {
      &.right-divider {
        border-right: 1px solid #e3e4e3;
        margin-right: 8px;
        padding-right: 16px;
      }

      &.selected {
        .select-body {
          border: 1px solid #7d98f0;
          background: #e5eafc;
        }
      }

      .select-filter-title {
        color: #898989;
        /* Body/regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }

      .select-body {
        cursor: pointer;
        display: flex;
        height: 48px;
        padding: 8px 8px 8px 8px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e3e4e3;
        background: #fff;
        gap: 8px;

        span {
          color: #161515;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%; /* 22.4px */
        }

        .select-body-app-image {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          margin-right: 4px;
        }

        .select-body-platform-image {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 4px;
        }

        .select-body-time-frame {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .time-frames {
            display: flex;
            justify-content: space-between;
            height: 18px;
          }

          .dates {
            color: #161515;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%; /* 22.4px */
          }
        }

        .select-app-icon {
          width: 31px;
          height: 31px;
          border-radius: 4px;
          flex-shrink: 0;
        }
      }
    }
  }
}

.i-selected-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 40px;

  .i-select-option {
    align-items: center;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #cdd4df;
    background: #f9f9fb;

    span {
      color: #161515;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
    }

    .filter-icon {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    .close-icon {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  .total-releases {
    align-self: center;
    margin-left: auto;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    text-transform: capitalize;
  }
}

.mobile-full-screen {
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;

  .ant-popover-inner-content {
    width: 100vw !important;
    height: 100vh !important;
  }
}
