@import '../../../mixins.scss';

.promotions-calendar {
  .options-wrapper {
    display: flex;
    align-self: flex-end;
    align-items: center;

    .segmentation-options {
      margin-right: 1.5rem;
    }
  }

  .cc-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;

    @include custom-scrollbar;

    .cc-container-body {
      position: relative;
      height: 40rem;
      width: 100%;
    }

    .cc-app {
      cursor: pointer;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #e3e4e3;
    }
  }

  .cc-app-tooltip {
    opacity: 0.8;
    border-radius: 2rem;

    .ant-popover-inner-content {
      padding: 12px 16px;
      background: #eee;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}
