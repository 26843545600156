.view__experiment__separate {
    margin-left: 7px;
    height: 97.5%;
    border-left: 1px dashed $color-light-grey-2;
    @include respond(phone) {
        height: 225px;
        margin-left: 3px;
    }
    &-lr {
        height: 103%;
    }
}