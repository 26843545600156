.fourOhFour {
  height: 95vh;
  user-select: none;
  &__bg-large-text {
    z-index: -5;
    color: #f1f1f1;
    opacity: 0.49;
    position: absolute;
    font-size: 25vw;
    font-family: "Nexa Bold";
    letter-spacing: 39.26px;
    text-align: center;
    // width: 100%;
    // top: 25%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__content-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &__logo {
      object-fit: none;
      flex: 0 0 20%;
      margin-top: 15rem;
    }
    &__content {
      margin-top: 15rem;
      justify-content: center;
      align-self: center;
      &__header {
        margin-bottom: 7rem;
        text-align: center;
        font-family: Nexa;
        font-size: 5rem;
        letter-spacing: 0.19px;
        color: #1a1515;
      }
      &__text {
        font-family: Nexa;
        font-size: 2rem;
        letter-spacing: 0.06px;
        text-align: center;
        color: #1a1515;
        max-width: 35rem;
      }
      &__button {
        user-select: none;
        cursor: pointer;
        margin: 7rem auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        border-radius: 2px;
        background-color: rgba(33, 150, 243, 0.8);
        padding: 0.6rem 6rem 0.2rem 6rem;
        & > span {
          text-align: center;
          font-family: "Nexa Bold";
          color: #ffffff;
          font-size: 1.6rem;
        }
      }
    }
  }
}
