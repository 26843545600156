.block-status-descr {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  background-color: rgba(216, 216, 216, 0.29);
  padding: 1rem;
  width: 76rem;
  color: black;
  border-radius: 3px;

  h3 {
    font-size: 1.3rem;
    margin: 0 1rem;
    font-weight: 600;
  }

  &.integrated {
    i.anticon.anticon-check-circle {
      color: #18b663;
      font-size: 1.7rem;
    }
  }

  &.abandoned {
    i.anticon.anticon-close-circle {
      color: #e53935;
      font-size: 1.7rem;
    }
  }
}

.view__experiment-tabs {
  margin-bottom: 5rem;
  position: relative;

  .slick-list {
    min-height: 40rem;
  }

  h5 {
    color: #6c6c6c;
    font-family: Roboto;
    font-size: 1rem;
    letter-spacing: 0.17px;
    position: relative;
    top: 0.8rem;
  }

  display: flex;

  .ant-tabs-ink-bar {
    height: 3px;
  }

  .ant-tabs-tab {
    font-size: 1.6rem;
    font-family: "Roboto";
  }

  .variants-images {
    width: 34rem;

    &.long {
      width: 74rem;
    }

    margin-left: 8rem;
    position: relative;

    .ant-tabs-tab {
      margin-right: 0rem;
      width: 11.2rem;
    }

    &:not(.no-control):not(.long)::before {
      content: "";
      border-left: 1px dashed #979797;
      height: 80%;
      top: 5%;
      left: -4rem;
      width: 2px;
      position: absolute;
    }

    &.no-control {
      margin-left: 0rem;
    }

    &.status-integrated.variant-0 {
      .ant-tabs-tab:nth-child(1) {
        color: #18b663;
        position: relative;

        &:before {
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #18b663;
          top: 1rem;
          right: 0.5rem;
          left: auto;
        }
      }
    }

    &.status-integrated.variant-1 {
      .ant-tabs-tab:nth-child(2) {
        color: #18b663;
        position: relative;

        &:before {
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #18b663;
          top: 1rem;
          right: 0.5rem;
          left: auto;
        }
      }
    }

    &.status-integrated.variant-2 {
      .ant-tabs-tab:nth-child(3) {
        color: #18b663;
        position: relative;

        &:before {
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #18b663;
          top: 1rem;
          right: 0.5rem;
          left: auto;
        }
      }
    }

    &.status-integrated.variant-3 {
      .ant-tabs-tab:nth-child(4) {
        color: #18b663;
        position: relative;

        &:before {
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #18b663;
          top: 1rem;
          right: 0.5rem;
          left: auto;
        }
      }
    }

    &.status-integrated.variant-e {
      .ant-tabs-tab:nth-child(5) {
        color: #18b663;
        position: relative;

        &:before {
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #18b663;
          top: 1rem;
          right: 0.5rem;
          left: auto;
        }
      }
    }

    &.cur-tab-integr {
      .ant-tabs-ink-bar {
        background-color: #18b663;
      }
    }
  }

  .view__experiment-image {
    border-radius: 0;
    width: 32rem;
    margin: 1rem;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);

    .video-react.video-react-fluid {
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .control-image {
    width: 34rem;

    &.long {
      width: 74rem;
    }

    h2,
    &-h2 {
      margin: 1.6rem 0 2.25rem;
      color: #000000;
      font-family: Roboto;
      font-size: 1.6rem;
      font-weight: 500;

      img {
        margin-right: 1rem;
        position: relative;
        top: -0.2rem;
      }
    }
  }
}

.ant-tabs.ant-tabs-top.tabs.ant-tabs-line.ant-tabs-no-animation {
  overflow: visible;
}

.ant-tabs-tab {
  font-family: "Roboto";
  font-size: 1.6rem;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: black;
}

.ant-tabs-ink-bar {
  background-color: black;
}

.image-wrapper {
  position: relative;
}

.view__experiment-tabs.blurred {
  .slick-arrow {
    display: none;
  }
}

