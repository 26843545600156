.ctl-sidebar {
  border-right: 1px solid #dee2e6;

  &.collapsed {
    border-right: none;
  }

  .ctl-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    height: 32px;
    background: #f5f5f5;

    .ctl-sidebar-collapse {
      display: flex;
      gap: 8px;
      cursor: pointer;

      span {
        color: #575757;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 155%; /* 18.6px */
        text-transform: capitalize;
      }
    }

    .ctl-sidebar-expand {
      cursor: pointer;
    }
  }

  .ctl-current-app {
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #e1e1e1;

    img {
      width: 24px;
      height: 24px;
      border-radius: 8px;
    }

    span {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      text-transform: capitalize;
    }
  }

  .ctl-current-persona {
    padding: 16px 24px;
    display: flex;
    gap: 18px;
    flex-direction: column;
    border-bottom: 1px solid #e1e1e1;

    .ctl-current-persona-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        padding: 8px 6px;
        cursor: pointer;
        border-radius: 4px;
        background: #f5f5f5;
      }

      span {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        text-transform: capitalize;
      }
    }

    .ctl-persona-details {
      display: flex;
      gap: 8px;

      img {
        width: 46px;
        height: 46px;
        border-radius: 8px;
      }

      .persona-title-info {
        display: flex;
        flex-direction: column;

        .persona-title {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 21px */
          text-transform: capitalize;
        }

        .persona-active-for {
          color: #575757;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 155%; /* 21.7px */
          text-transform: capitalize;
        }
      }
    }

    .persona-more-details {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .persona-field-group {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .persona-field {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .field-name {
            color: #575757;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 155%; /* 21.7px */
            text-transform: capitalize;
          }

          .field-value {
            color: #000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ctl-events-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e1e1e1;

    .events-list-header {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .events-list-title {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        text-transform: capitalize;
      }

      .search-input {
        border-radius: 4px;
        border: 1px solid #d4d4d4;
        background: #fff;
        padding: 0 8px;
      }
    }

    .events {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .event {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        cursor: pointer;
        width: 100%;
        padding: 8px;

        &.selected {
          background: #ebf2fa;
          border-radius: 4px;
          cursor: default;
        }

        .event-title {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 21px */
          text-transform: capitalize;
        }

        .event-count {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 155%; /* 21.7px */
          text-transform: capitalize;
        }
      }
    }
  }
}
