@import '../../../../../sass-config/abstracts/variables.scss';

.channel-select-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .clear {
    text-decoration: underline;
    font-size: 1.4rem;
    color: #696f8c;
    cursor: pointer;

    &:hover {
      color: $color-black;
    }
  }

  .items-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;

    .items-selected-count {
      color: #000;

      font-family: Roboto;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
    }
  }

  .channel-select-cb {
    display: flex;
    align-items: center;
    height: 4.8rem;
    width: 100%;
    border-radius: 0.8rem;
    padding: 0.8rem;
    border: 1px solid #d4d4d4;

    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }
}
