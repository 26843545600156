.cancel-reset-password-modal {

    font-family: 'Roboto' , serif !important;
    .text {
        font-family: 'Roboto' , serif !important;
    }
    h2 {
        font-family: 'Roboto Slab' , serif !important;
    }    

    .ant-btn-primary{
        border-color: #F4442E !important;
        background-color: #F4442E !important;
    }

    .ant-btn-default:hover{
        border-color: #F4442E !important;
        color: #F4442E !important;
    }

    .bold {
        font-weight: bold;
    }
    .text {
        margin: 30px 0; 
    }
    .ghost {
        color: #ccc6c6
    }
    .ant-modal-content {
        top: 100px;
    }
    .ant-modal-body {
        padding: 32px 25px 24px;
    }
    .ant-modal-confirm-btns {
        width: 100%;
        display: flex;
        .ant-btn {
            border-radius: 2px;
            &-primary {
                flex: 1
            }
            &-default {
                flex: 2
            }
        }
    }
}

.forgot-password {
    &__trigger {
        color:#F4442E;
        // padding-left: 0px;
        font-size: 13px;
        padding-right: 0;
    }
    &__modal {
        font-family: 'Roboto' , serif !important;
        .reset-password-view h3 {
            font-family: 'Roboto' , serif !important;
        }
        h2 {
            font-family: 'Roboto Slab' , serif !important;
        }

        .Mui-focused {
            &.MuiFormLabel-root {
                color: #F4442E !important;
            }
            .MuiOutlinedInput-notchedOutline {
                border-color: #F4442E !important;
            }
        }
        .MuiInputBase-root:hover {
            .MuiOutlinedInput-notchedOutline {
                border-color: rgba($color: #F4442E, $alpha: 0.5) !important;
            }
        }
        .ant-btn-primary {
            background-color:#F4442E !important;
            border-color: #F4442E !important;
        }
        .ant-modal-body {
            padding: 39px 45px 50px 45px;
        }
        .ant-modal-close {
            right: -12px;
            top: -12px;
        }
        .ant-btn-block {
            height: 43px !important;
            // background-color: #F4442E;
            border-radius: 2px;
        }
        .ant-modal-close-x {
            width: 30px;
            height: 30px;

            img {
                width: 100%;
                height: 100%;
            }
            // background-color: #fff;
            // border-radius: 50%;
            // border: 2px solid #0b3252;
            // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            color: #0b3252;
            line-height: 21px;
            .ic {

            }
        }
        .bold {
            font-weight: bold;
        }
        .reset-password-view {
            margin-top: 31px;
            h3 {
                font-family: 'Nexa';
                color: #807a7a;
                font-size: 15px;
            }
            &__email {
                margin-top:27px;
            }
        }
        .confirm-password-view {
            margin-top: 31px;
            &__text {
                color: #1a1515;
                font-size: 17px;
                font-weight: 'Nexa Bold';
                
            }
            &__text-extra {
                font-size: 15px;
                letter-spacing: -0.58px;
            }
            &__form {
                margin-top: 20px;
            }
            &__send-again-text {
                position: relative;
                top: 15px;
                text-align: center;
                color: #807a7a;
                font-size: 12px;
                font-weight: 300;
            }
            &__send-again-text-btn {
                color: #F4442E;
                cursor: pointer;
                transition: 0.1s;
                &:hover {
                    color: #807a7a;
                }
            }
        }
        .set-new-password-view {
            margin-top: 31px;
            &__text {
                .head {
                    margin-bottom: 25px;
                }
                .requirements {
                     margin-bottom: 35px;
                     padding-left: 15px;
                     list-style: none;
                     li {
                        font-size: 15px;
                        // font-family: 'Nexa Regular';
                     }
                     li::before {
                         content: "\2022";
                        color: #F4442E;
                        font-weight: bold;
                        display: inline-block; 
                        width: 1em;
                        margin-left: -1em;
                     }
                }
            }
        }
        .success-password-view {
            &__text{
                color: #0b3252;
                font-size: 14px;
                margin-top: 31px;
                margin-bottom: 37px;
                img {
                    margin-right: 5px;
                }
            }
        }
    }
}