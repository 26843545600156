.mkc-modal-container {
  .ant-modal-content {
    border-radius: 1rem;

    .ant-modal-body {
      padding: 3rem;

      .mkc-insight-preview-container {
        display: flex;

        .mkc-preview-left {
          max-height: 85vh;
          padding-right: 10px;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            margin-left: 20px;
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }

          overflow-y: auto;

          .mkc-preview-left-top {
            display: flex;
            align-items: center;
            gap: 16px;

            .email_campaign {
              display: flex;
              padding: 8px 16px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 4px;
              background: #dde4f9;
              color: #4367dd;
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
            }

            .push_notification_campaign {
              display: flex;
              padding: 8px 16px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 4px;
              background: #ffd1ea;
              color: #b20362;
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
            }

            .inapp_campaign {
              display: flex;
              padding: 8px 16px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 4px;
              background: #ffe7d1;
              color: #b26203;
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
            }

            .mkc-preview-platform {
              color: #7d7d7d;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
              border-right: 1px solid #e0e0e0;
              padding-right: 8px;
            }

            .mkc-preview-platform-value {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
            }
          }

          .mkc-preview-title {
            margin-top: 2.5rem;
            color: #201b1b;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .mkc-date-priority {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;

            .mkc-preview-date {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              text-transform: capitalize;
            }

            .priority-high {
              color: #b11515;
              padding: 4px 16px;
              border-radius: 200px;
              background: #ffb0b0;
              text-transform: capitalize;
            }

            .priority-medium {
              color: #ff9411;
              padding: 4px 16px;
              border-radius: 200px;
              background: #ffdbb0;
              text-transform: capitalize;
            }

            .priority-low {
              color: #0e8a16;
              padding: 4px 16px;
              border-radius: 200px;
              background: #b0ffb0;
              text-transform: capitalize;
            }
          }

          .mkc-preview-list {
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 4px;

            &.s-mt {
              margin-top: 1rem;
            }

            .mkc-preview-list-head {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
              padding-right: 8px;
              border-right: 1px solid #e0e0e0;
              margin-right: 1rem;
            }

            .mkc-preview-list-item {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
              padding: 4px 16px;
              border-radius: 200px;
              background: #eaeaea;
            }
          }

          .mkc-preview-double-box-container {
            display: flex;
            gap: 16px;

            .mkc-preview-content-box {
              width: 50%;
            }
          }

          .mkc-preview-content-box {
            margin-top: 32px;
            border: 1px solid #e5e5e5;
            border-radius: 8px;

            img {
              cursor: pointer;
            }

            .mkc-seen-in-all {
              color: #403b3b;
              padding: 4px 16px;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              text-transform: capitalize;
              border-radius: 200px;
              background: #ffdc24;
            }

            .mkc-preview-content-box-head {
              display: flex;
              height: 40px;
              padding: 16px;
              justify-content: space-between;
              align-items: center;
              border-radius: 8px 8px 0px 0px;
              background: #eaeaea;
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              text-transform: capitalize;
            }

            .mkc-preview-content-box-body {
              display: flex;
              gap: 8px;
              flex-wrap: wrap;
              padding: 24px 16px;
              color: #616065;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 190%; /* 26.6px */
              letter-spacing: 0.14px;
              max-height: 150px;
              overflow-y: auto;
              margin-bottom: 0;

              &::-webkit-scrollbar {
                -webkit-appearance: none;
                margin-left: 20px;
                width: 10px;
                height: 10px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.3);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
              }

              .mkc-preview-content-box-user-type-container {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                padding: 4px 16px;
                border-radius: 8px;
                background: #eaeaea;

                .mkc-preview-content-box-user-type-text {
                  color: #403b3b;
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  text-transform: capitalize;
                  padding-right: 10px;
                }

                .mkc-preview-content-box-user-type-icon-container {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }

        .mkc-preview-right {
          margin-left: 1rem;

          .mkc-preview-right-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;

            .mkc-preview-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              padding: 6px 8px;
              border-radius: 4px;
              border: 1px solid #e5e5e5;
              background: linear-gradient(0deg, #f5f5f5 0%, #f5f5f5 100%),
                #f5f5f5;
              min-width: 39px;
              min-height: 35px;

              &.right {
                margin-right: auto;
              }

              &.no-bg {
                background: none;
                border: none;
              }
            }
          }

          .mkc-preview-right-images {
            margin-top: 1rem;
            padding: 24px;
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            overflow-y: auto;
            max-height: 615px;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            div {
              border: 1px solid #e0e0e0;
            }

            span {
              font-size: 16px;
            }

            &::-webkit-scrollbar {
              -webkit-appearance: none;
              height: 0;
              margin-left: 20px;
              width: 10px;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.3);
              -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }

            .mkc-preview-asset-image {
              width: 100%;
              height: 100%;
              cursor: zoom-in;
              object-fit: contain;
            }

            .mkc-preview-asset-iframe {
              cursor: zoom-in;
              width: 100%;
              min-height: 500px;
            }
          }
        }
      }
    }
  }
}

.ant-tooltip {
  z-index: 99999 !important;
}
