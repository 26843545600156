.dis-comments-list,
.modal_comments {

  margin-bottom: 4rem;

  input:focus,
  textarea:focus,
  input:active,
  textarea:active {
    border: 1px solid #5790FF !important;
    border-radius: 4px;
  }

  .dcl-title {
    color: #1a1818;
    font-family: "Roboto Slab";
    font-size: 2.3rem;
  }

  .dcl-add-comment-buttons,
  .ac-add-comment-buttons {
    display: none;
    margin-top: 1rem;
    justify-content: end;

    button {
      width: 9rem;
      margin-left: 1rem;
    }
  }

  .dcl-add-comment-input:focus+.dcl-add-comment-buttons,
  .ac-add-comment-input:focus+.ac-add-comment-buttons {
    display: flex;
  }

  .dcl-comments {
    padding-top: 2rem;
  }

  .dcl-comments-more {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    text-decoration-line: underline;
    margin: 1rem 0rem 0rem;
    color: #000000;
    cursor: pointer;
    user-select: none;
  }

  .ac-list {
    max-height: 35rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #D9D9D9;
      width: 8px;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .dcl-comment:last-child {
      border: none !important;
    }
  }

  .ant-modal-title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.9rem;
    color: #000000;
    margin-bottom: 2rem;
  }

  .ac-input-wrap {
    height: 10rem;
    border-top: 1px #e8e8e8 solid;
    padding-top: 2rem;
  }


  .dcl-comment {
    border-bottom: 1px #D9D9D9 solid;
    margin-bottom: 2rem;

    .dcl-comment-edited {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #A9A8A8;
      display: inline-block;
      margin-left: 0.5rem;
    }

    &:hover {
      .dcl-comment-management {
        display: block !important;
      }
    }

    .dcl-comment-header {
      display: flex;
      position: relative;

      .dcl-comment-management {
        display: none;
        position: absolute;
        top: 0rem;
        right: 0rem;
        background-color: white;

        img {
          margin: 0rem 0.5rem;
          cursor: pointer;
        }
      }

      .dcl-comment-avatar {
        .ant-avatar {
          background: transparent;
          border: 2px solid black;
          margin-right: 1rem;
          font-size: 1.7rem;
          line-height: 2.7rem;
          color: black;
          width: 3rem;
          height: 3rem;

          .ant-avatar-string {
            line-height: 27px !important;
          }
        }
      }

      .dcl-comment-name-time-cont {
        .dcl-comment-name {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          color: #000000;

          &.dis {
            color: #848484;
            .removed {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.1rem;
              position: relative;
              top:-0.2rem;

            }
          }
        }

        .dcl-comment-time {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          color: #848484;
        }
      }
    }

    .dcl-comment-text {
      margin-top: .9rem;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      color: #848484;
      margin-bottom: 1.5rem;
    }
  }

  .dcl-comment-edit {
    margin: 1rem 0rem;

    .ant-input {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #D9D9D9;
        width: 8px;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    .dcl-comment-edit-btns {
      margin-top: 1rem;
      display: flex;
      justify-content: end;

      button {
        width: 9rem;
        margin-left: 1rem;
      }
    }
  }

  .dcl-comments {
    .dcl-comment-edit-btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      button {
        width: 46%;
        margin-left: 0rem;
      }
    }

  }

  .dcl-add-comment-buttons {
    justify-content: space-between;

    button {
      width: 46%;
      margin-left: 0rem;
    }
  }
}