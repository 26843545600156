.versions__list__header-img {
    margin-top: 2px;

}
.versions__charts {
    margin-top: 2.5rem;
    &__content {
        display: flex;
        width: 100%;
        @include respond(phone) {
            flex-direction: column;
        }
    }
    &__bar {
        width: calc(100% - 25.8rem);
        height: 25rem;
        @include respond(phone) {
            width: 100%;
            height: 20rem;
        }
    }
    &__stats {
        display: flex;
        width: 25.8rem;
        margin-left: 1.7rem;
        flex-direction: column;
        @include respond(phone) {
            width: 100%;
        }
    }
    &__card {
        height: 50%;
        display: flex;
        border-radius: 8px;
        box-shadow: $shadow-small;
        justify-content: center;
        align-items: center;
        @include respond(phone) {
            height: 10rem;
        }
        &-img {
            margin-right: 3rem;
            &-arrow {
                height: 3.1rem;
                width: 2.7rem;
            }
            &-device {
                height: 4rem;
                width: 3.1rem;
            }
        }
        &__details {
            display: flex;
            flex-direction: column;
        }
        &-header {
            font-size: 1.3rem;
        }
        &-numbers {
            font-size: 2rem;
            font-family: $font-headings;
            font-weight: 700;
        }
    }
}