@import "../../../../sass-config/abstracts/variables.scss";

.performance-sub-events-new {
  margin-top: 5rem;
  margin-bottom: 4rem;
  padding: 2.5rem 0 1rem;
  display: flex;
  justify-content: space-between;

  &>div {
    min-height: 24rem;
  }

  .timeLine {
    width: 100%;
    background-color: #F5F5F5;
    top: 0;
    left: 0;
    height: 0.5rem;
    position: absolute;

    &>div {
      left: 0;
      height: 0.5rem;
    }

    .t-1 {
      background-color: $color-performance-compare-1;
    }

    .t-2 {
      background-color: $color-performance-compare-2;
    }
  }

  .performance-no-data {
    margin-top: 0rem !important;
  }

  .subevents-box,
  .performance-no-data {
    // common css for each of the subevents component wrapper div
    flex-basis: 48.6%;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    padding: 3.5rem 5rem 2.5rem 5rem;
    position: relative;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #E7EAED;
    font-family: "Roboto";

    .sub-event-badge {
      position: absolute;
      bottom: 2rem;
      left: 0;
      text-align: center;
      width: 100%;

      &.disabled {
        opacity: 0.5;
      }
    }

    &__bg {
      position: absolute;
      margin: 0;
      width: 100.7%;
      height: 12px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      border-radius: 0 0 5px 5px;
      bottom: -2px;

      &.main {
        background-color: $color-performance-compare-1;
      }

      &.compare {
        background-color: $color-performance-compare-2;
      }
    }

    &__row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 300;
      color: #1a1515;
      margin-bottom: 1.5rem;
      line-height: 1.8rem;

      &.disabled {
        opacity: 0.3;
      }

      &__name {
        flex-basis: 70%;
      }

      &__start {
        flex-basis: 15%;
        font-weight: 500;

        & .suffix {
          text-transform: uppercase;
          font-weight: 300;
          color: #6c6c6c;
        }
      }

      &__duration {
        flex-basis: 15%;

        & .suffix {
          text-transform: uppercase;
          font-weight: 300;
          color: #6c6c6c;
        }
      }
    }

    &__header {
      font-size: 1.2rem;
      font-weight: 600;
      color: #6c6c6c;
    }
  }

  .compare {
    justify-self: end;
  }
}