.btn {
  color: $color-black;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: $font-headings;
  @include transitionHover;
  display: flex;
  justify-content: center;
  @include firefox {
    display: inline-block;
  }
  &:not(.btn-loader) {
    &:disabled {
      background-color: rgba($color-brown-grey, 0.12);
      color: rgba($color-black, 0.5);
      opacity: 1;
      cursor: default;
    }
  }
  &__focus {
    &:not(:disabled) {
      &:focus {
        outline: none;
        background-color: $color-blueful-button-hover;
        color: $color-white;
      }
    }
  }
  &:not(:disabled) {
    &:focus {
      outline: none;
    }
  }
  &-primary {
    color: #ffffff;
    background-color: $color-blueful-button;
    &:hover {
      background-color: $color-blueful-button-hover;
      color: $color-white;
    }
    &:active {
      background-color: $color-blueful;
      color: $color-white;
    }
  }
  &-loader {
    &:disabled {
      cursor: default;
      background-color: $color-blueful-button-hover !important;
    }
  }
  &-xs {
    width: 11rem;
    height: 3.5rem;
    font-size: 1.3rem;
  }
  &-s {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }
  &-m {
    padding: 1rem 4.3rem;
    font-size: 1.5rem;
  }
}

.ant-btn-dark {
  color: #1a1515;
  border-color: #1a1515;
  padding: 0.5rem 1.7rem;
  text-transform: uppercase;
  height: auto;
  font-size: 1.4rem;
  &.lowercase {
    text-transform: capitalize;
  }
}

.ant-btn-light {
  color: #000000;
  font-family: Roboto;
  border: 1px solid #eceef5;
  border-radius: 3px;
  font-size: 1.4rem;
}

.ant-btn-red {
  color: #f10202;
  font-family: Roboto;
  border: 1px solid #f10202;
  border-radius: 3px;
  font-size: 1.4rem;
  text-transform: capitalize;
  &:hover {
    background-color: #f10202;
  }
}

.ant-btn-blue {
  color: #1c9dfc;
  font-family: Roboto;
  border: 1px solid #1c9dfc;
  border-radius: 3px;
  font-size: 1.4rem;
  text-transform: capitalize;
  &:hover:not([disabled]), &:focus {
    background-color: #1c9dfc !important;
    border-color: #1c9dfc !important;
  }
}

.ant-btn-blue-filled {
  border: 1px solid #1c9dfc;
  background-color: #1c9dfc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 1rem;
  }
  &:hover:not([disabled]),
  &:focus {
    background-color: #0093fc !important;
    color: white !important;
  }
}
.ant-btn:disabled {
  background-color: white;
}
