.user-type-popup {
  width: 470px;
  max-height: 370px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .items-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .items-selected-count {
      color: #000;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .filters-content {
    display: flex;
    gap: 10px;

    .filters-content-checkbox-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .user-type-popup-checkbox-group-item {
    border-radius: 8px;
    padding: 8px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    min-width: 170px;

    .ant-checkbox + span {
      display: flex;
      flex-wrap: nowrap;
      flex-basis: 100%;
      justify-content: space-between;

      .user-type-popup-checkbox-group-tem-label-text {
        padding-right: 10px;
      }

      .user-type-popup-checkbox-group-tem-label-icon-container {
        display: inline-flex;
        justify-content: center;
      }
    }
  }
}
