.ctl-content {
  max-width: calc(100% - 366px) !important;
  padding: 0 40px;

  &.closed {
    max-width: calc(100% - 22px) !important;
  }

  .main-content {
    margin-top: 4px;
    border-radius: 4px;
    border-right: 1px solid #f9f9fb;
    background: #f4f4f4;
    padding: 40px 0;
    display: flex;
    justify-content: center;

    overflow-y: auto;
    height: calc(100vh - 530px);

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
