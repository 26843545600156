.products-list {
  display: flex;
  flex-wrap: wrap;
}

.products-card {
  height: 13rem;
  padding: 2rem 3rem;
  width: 30rem;
  border: 1px rgba(192, 192, 192, 0.158) solid;
  margin: 2rem;
  cursor: pointer;

  img {
    width: 3rem;
  }
}

.products-page {
  position: relative;

  .pr-header {
    position: relative;
    display: flex;
    margin: 2rem 0 8rem;

    img {
      width: 6.2rem;
      margin-right: 2.5rem;
      border-radius: 5px;
      box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.05);
    }

    h2 {
      color: #1a1a1a;
      font-family: Roboto;
      font-size: 2.2rem;
      font-weight: bold;
      letter-spacing: 0.37px;
      max-width: 35rem;
    }

    .app-owner {
      color: #c2c2c2;
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: 300;
      letter-spacing: 0.18px;
      line-height: 18px;

      .owner-name {
        color: #1a1a1a;
      }
    }
  }

  .hr-row-3 {
    position: absolute;
    width: 100%;
    top: 19rem;
    border-bottom: 1px #e8e8e8 solid;
    left: 0;
  }

  .stacked-bar-container {
    width: 100%;
    height: 51rem;
    margin-bottom: 13rem;
    border: 1.1px solid #eceef5;
    border-radius: 5.51px;
    background-color: #ffffff;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
    position: relative;

    .product-chart.slick-prev {
      left: 9rem;
    }

    .product-chart.slick-next {
      right: 1.5rem;
    }

    h3 {
      margin: 3rem 6rem;
      color: #000000;
      font-family: "Roboto";
      font-size: 23px;
      letter-spacing: 0.25px;
    }

    .stacked-bar-wrap {
      margin: 4rem;
      height: 36.5rem;

      canvas {
        cursor: pointer;
      }
    }
  }

  .gallery-discoveries-wrap {
    margin-top: 9rem;
    position: relative;
    min-height: 50rem;
  }

  .center-of-row {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  button.ant-btn.filter-btn {
    position: absolute;
    top: 0rem;
    right: 0rem;
  }

  button.mark-all-dis-seen {
    position: absolute;
    top: 0rem;
    right: 14rem;
  }

  .filter-count {
    margin-left: 0.8rem;
    background-color: black;
    color: white;
    border-radius: 2px;
    font-family: Roboto;
    letter-spacing: 0;
    line-height: 1.6rem;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.2rem 0.5rem;
  }

  .performance-history-graphs {
    background-color: #fff;

    .title {
      padding-top: 2rem;
    }

    .performance-history-graphs__graph-wrapper {
      position: relative;
      box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
      border: 1.1px solid #eceef5;
      border-radius: 5.51px;

      .performance-history-graphs__header {
        display: flex;
      }
    }
  }

  .product-changes-wrapper {
    h3 {
      display: flex;
      align-items: center;
      position: relative;

      .prd-unread-notes-count {
        height: 2.5rem;
        width: 2.5rem;
        border: 2.5px solid #ffffff;
        background-color: #ff4d50;
        color: #ffffff;
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 1.4rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
      }

      .prd-mark-all-wrap {
        position: absolute;
        top: 0;
        right: 0rem;
      }
    }

    .prd-notes-header {
      border-top: 0.9px solid #eceef5;
      border-bottom: 0.9px solid #eceef5;
      display: flex;
      align-items: center;
      width: 100%;
      height: 3.8rem;
      color: #9a9eaa;
      font-family: Roboto;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.1px;

      &>div:nth-child(1) {
        width: 6rem;
      }

      &>div:nth-child(2) {
        width: 20rem;
      }

      &>div:nth-child(3) {
        flex-grow: 1;
      }

      &>div:nth-child(4) {
        width: 14rem;
        text-align: right;
      }

      &>div:nth-child(5) {
        width: 6rem;
      }
    }

    .products-notifications {
      height: 36rem;
      padding-bottom: 1rem;
      overflow: auto;

      -webkit-mask-image: linear-gradient(180deg, #000 0, 5%, #000 75%, rgba(0, 0, 0, 0) 100%);

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      .prd-grp-notes-header {
        color: #e8e8e8;
        font-family: Roboto;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.14px;
        padding: 3rem 6rem 1rem;
      }

      .products-note {
        display: flex;
        align-items: center;
        width: 100%;
        height: 6rem;

        &>div {
          height: 100%;
          display: flex;
          align-items: center;
        }

        &>div:nth-child(1) {
          width: 6rem;
        }

        &>div:nth-child(2) {
          width: 20rem;
          color: #000000;
          font-family: Roboto;
          font-size: 1.2rem;
          font-weight: 300;
          letter-spacing: 0.14px;
          border-bottom: 0.9px solid #eceef5;
          position: relative;

          .app-overlay-platform {
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            bottom: 0.67em;
            left: 2.4rem;
          }

          .prd-note-icon {
            height: 3.4rem;
            width: 3.4rem;
            border: 1px solid #000000;
            border-radius: 50%;
            margin-right: 1.2rem;
          }

          .prd-note-product {
            display: inline-block;
            margin-right: 0.6rem;
          }
        }

        &>div:nth-child(3) {
          flex-grow: 1;
          border-bottom: 0.9px solid #eceef5;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .prd-note-descr {
            color: #000000;
            font-family: Roboto;
            font-size: 1.3rem;
            font-weight: 300;
          }

          .prd-note-type {
            color: #7b7b7b;
            font-family: Roboto;
            font-size: 1.15rem;
            font-weight: 300;
          }
        }

        &>div:nth-child(4) {
          width: 14rem;
          justify-content: flex-end;
          color: #7b7b7b;
          font-family: Roboto;
          font-size: 11px;
          font-weight: 300;
          letter-spacing: 0.09px;
          border-bottom: 0.9px solid #eceef5;
        }

        &>div:nth-child(5) {
          width: 6rem;
        }

        &.clickable {
          cursor: pointer;
        }

        &:hover {
          box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
        }

        &.unread {
          &>div:nth-child(2) {
            font-weight: 500;
            position: relative;

            &::after {
              content: "";
              top: 2.6rem;
              left: -2rem;
              height: 0.65rem;
              width: 0.65rem;
              background-color: #e34444;
              border-radius: 50%;
              position: absolute;
            }
          }

          &>div:nth-child(3) {
            .prd-note-descr {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

#chartjs-tooltip.chartjs-tooltip-product {
  background: white !important;
  color: black;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.15);
  padding: 2rem 2rem !important;

  th {
    display: flex;
    height: 4.5rem;

    img {
      width: 3rem;
      display: inline-block;
      height: 3rem;
      margin-right: 1rem;
    }

    .app-data {
      display: inline-block;

      h4 {
        color: #000000;
        font-family: Inter;
        font-size: 12.13px;
        letter-spacing: 0.09px;
        line-height: 18px;
        text-align: left;
      }

      div {
        color: #6c6c6c;
        font-family: Roboto;
        font-size: 9.7px;
        font-weight: 500;
        letter-spacing: 0.07px;
        line-height: 11px;
      }
    }
  }

  table td {
    font-family: Roboto;
    font-size: 1.15rem;
    text-transform: uppercase;
    height: 2.5rem;

    span {
      width: 7px;
      height: 7px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  &::before {
    border-color: white !important;
    border-right: 5px solid white !important;
    background-color: white;
  }
}

.filter-wrap.all-discoveries-type.product-page {
  width: 60rem;

  position: relative;

  .filter-header {
    padding: 2rem 2rem;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0rem;
    background-color: white;
    z-index: 12;

    .ant-btn-dark {
      position: absolute;
      top: 2.5rem;
      right: 0rem;
    }
  }

  .filters-popup.state {
    padding: 0rem 2rem;

    button {
      position: absolute;
      top: 1.2rem;
      right: 1rem;
    }
  }

  .sorting-select .ant-select-selection {
    border: 1px solid #9fa5ad !important;
    width: 16rem;
    margin-left: 1rem;
    border-radius: 4px !important;

    .ant-select-arrow {
      top: 1rem;
    }
  }
}

.link-to-all-dis {
  height: 18.1rem;
  border-radius: 4px;
  background-color: #fbfbfb;
  padding: 3rem 12rem;
  margin-top: 7rem;

  .cont {
    display: flex;

    .product-info {
      border-right: 1px dotted silver;
      width: 33rem;
      margin-right: 12rem;
    }

    .describe-link {
      width: 39rem;

      &>div {
        color: #000000;
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.15px;
        margin-bottom: 1.2rem;
      }

      h4 {
        color: #000000;
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      .btn-link {
        padding: 0.5rem 4rem;
      }
    }
  }

  .pr-header {
    display: flex;
    margin: 2rem;

    img {
      width: 6.2rem;
      margin-right: 2.5rem;
      border-radius: 5px;
    }

    h2 {
      color: #1a1a1a;
      font-family: Roboto;
      font-size: 2.2rem;
      font-weight: bold;
      letter-spacing: 0.37px;
    }
  }
}

.product-bench-ranks {
  border: 1.1px solid #eceef5;
  border-radius: 5.51px;
  background-color: #ffffff;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  min-height: 28rem;

  .loader {
    top: 18rem !important;
  }

  .pr-bench-header {
    padding: 2.5rem 3.5rem;
    border-bottom: 1px solid #eceef5;
  }

  .pr-bench-content {
    padding: 2.5rem 0rem;
    display: flex;
  }

  .pr-b-section {
    width: 55rem;
    padding: 0rem 2rem;

    &:nth-child(1) {
      border-right: 1px dashed #eceef5;
    }

    h3 {
      color: #000000;
      font-family: Roboto;
      font-size: 1.8rem;
      font-weight: 300;
      letter-spacing: 0.2px;
      display: flex;
      align-items: center;

      .plat {
        font-weight: 500;
        margin-right: 0.7rem;
      }

      img {
        width: 2.2rem;
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        height: 2.2rem;
        margin-right: 0.7rem;
      }
    }

    .grid-events {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.2rem;
      grid-row-gap: 1.2rem;
      margin-top: 2rem;

      .ev-section {
        width: 23rem;
        height: 14rem;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.02);
        transition: all 0.3s ease-in-out;

        .header-wrap {
          display: flex;
          align-items: baseline;

          h3 {
            color: #000000;
            font-family: Roboto;
            font-size: 1.5rem;
            font-weight: 500;
            letter-spacing: 0.16px;
            padding: 1.65rem 0 0rem 1.65rem;
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 19rem;
            overflow: hidden;
            display: block;
          }

          i {
            color: #c8c7c7;
            margin-left: 0.6rem;
          }
        }

        .content-wrap {
          display: flex;

          &>div {
            width: 9rem;
            margin: 1rem;

            .rank-value {
              font-family: Roboto;
              font-size: 2.3rem;
              font-weight: 500;
              line-height: 2.7rem;
              text-align: center;
              border-radius: 4px;
              height: 5.9rem;
              padding: 0.8rem;

              &.green {
                background-color: rgba(24, 182, 99, 0.1);
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.02);
                color: #18b663;
              }

              &.orange {
                background-color: #fb8c001a;
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.02);
                color: #fb8c00;
              }

              &.red {
                background-color: rgba(229, 57, 53, 0.1);
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.02);
                color: #e53935;
              }

              .sharp {
                font-size: 1.1rem;
              }

              .extra-info {
                font-family: Roboto;
                font-size: 1rem;
                font-weight: 300;
                letter-spacing: 0.11px;
                line-height: 11px;
                text-align: center;
              }
            }

            .time-value {
              background-color: rgba(170, 170, 170, 0.1);
              box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.02);
              color: #000000;
              font-family: Roboto;
              font-size: 2.3rem;
              font-weight: 300;
              letter-spacing: 0.25px;
              text-align: center;
              height: 5.9rem;
              padding: 0.4rem 0.8rem;

              .sec {
                font-size: 1rem;
              }

              .extra-info {
                font-family: Roboto;
                font-size: 1rem;
                font-weight: 300;
                letter-spacing: 0.11px;
                line-height: 11px;
                text-align: center;

                .red {
                  color: #e53935;
                }

                .green {
                  color: #18b663;
                }

                .perc {
                  font-size: 0.9rem;
                  color: #848484;
                }

                .chevron {
                  margin: 0rem 0.3rem;
                }
              }
            }

            .time-text,
            .rank-text {
              opacity: 0.47;
              color: #000000;
              font-family: Roboto;
              font-size: 1rem;
              font-weight: 300;
              letter-spacing: 0.1px;
              line-height: 26px;
              text-align: center;
            }
          }
        }

        &:hover {
          box-shadow: 0 1.4rem 2.5rem 0 rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }
    }
  }

  .show-more-events {
    opacity: 0.57;
    color: #000000;
    font-family: Roboto;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.6rem;
    text-align: center;
    margin: 2.5rem auto;
    height: 0rem;
    cursor: pointer;
    text-decoration: underline;
  }

  .empty-state-bench {
    width: 44.8rem;
    height: 29.3rem;
    margin-top: 2rem;
    border: 1px solid #eeeeee66;
    border-radius: 2px;
    background-color: #eeeeee66;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 2.5rem;
      filter: grayscale(1);
      margin-bottom: 1.5rem;
    }

    .descr-1,
    .descr-2 {
      color: #000000;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 14.4px;
      text-align: center;
    }

    .descr-1 {
      font-weight: 500;
      margin-bottom: 1.5rem;
      font-size: 1.35rem;
    }
  }
}

.confirm-mark-seen-dis {
  .anticon-question-circle {
    display: none;
  }

  .ant-modal-confirm-title {
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.3rem;
  }

  .ant-modal-confirm-content {
    margin-left: 0rem !important;
    text-align: center;
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
    width: 100%;

    .ant-btn-dark {
      text-transform: initial;
    }
  }
}

.unseen-sig-dis {
  margin: 9rem 0 12rem;
  position: relative;

  .mark-all-seen-btn {
    position: absolute;
    top: 1rem;
    right: 0rem;
  }

  .big-discovery-card-wrapper {
    margin-top: 5.5rem;
  }

  .big-discovery-card-cont {
    position: relative;

    .versions__list__item-importance-indicator {
      z-index: 22;
    }

    .share-button-wrapper {
      position: absolute;
      top: 2rem;
      right: 4rem;
      z-index: 44;

      .ant-btn {
        font-size: 1.2rem;
        height: 2.5rem;
        width: 8rem;
      }
    }
  }

  .unseen-sig-dis-all-btn {
    text-align: center;
    margin-top: 6rem;
  }
}

.big-discovery-card {
  height: 16.5rem;
  margin-bottom: 2rem;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  margin-bottom: 3.3rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.02);

  .dis-img {
    width: 29.3rem;

    img {
      width: 29.3rem;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 3px 0 0 3px;
    }

    &.default {
      background: rgba(0, 0, 0, 0.05);

      img {
        object-fit: fill;
        height: 8rem;
        margin-top: 4rem;
      }
    }
  }

  .dis-data {
    padding: 1.7rem 3.8rem 1.7rem 3.2rem;

    h2 {
      color: #000000;
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.16px;
    }

    .dis-tags-app-data {
      display: flex;
      align-items: center;
      height: 3rem;

      .app-data {
        display: flex;
        color: #656565;
        font-family: Roboto;
        font-size: 1.1rem;
        letter-spacing: 0.09px;
        line-height: 13px;
        align-items: center;
        position: absolute;
        right: 4rem;
        bottom: 2rem;

        img {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.8rem;
          border-radius: 1.8px;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
        }
      }

      .discovery-type {
        margin-left: 1.5rem;
        width: auto;
      }
    }

    .tags__tags {
      padding-top: 0rem;
      width: 55rem;
      overflow: hidden;

      .tags__tags-tag-element {
        font-size: 1.2rem;

        &.red {
          color: #f4442e;
          border-color: #f4442e;
        }
      }
    }
  }

  .dis-descr {
    height: 6rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    color: #4c4c4c;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.13px;
    margin: 1rem 0 1rem;
  }

  &:hover {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #c3c3c3;
  }
}
.ant-collapse-content-box{
  padding-right: 0px !important;
}
.products-list-wrapper{
  margin-bottom: 10rem;
  .products-list {
    margin-bottom: 2rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15.5rem, 1fr));
    grid-auto-rows: auto;
    gap: 1rem;
    &.partial-products-list {
      grid-template-rows: repeat(3, 4rem);
      overflow: hidden;
      height: 14.6rem;
      padding: 0 0.3rem;
    }

    .image-item {
      width: 100%;
      position: relative;
      margin: 0rem;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      height: 4rem;
      display: block;
      transition: box-shadow 0.2s ease-in-out;
      max-width: 17rem;
      a {

        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      img {
        width: 2.6rem;
        margin-left: 1rem;
      }

      &.with-dis::after {
        // INFO: if needed in the red dot on top of apps is future uncomment this
        // content: '';
        // position: absolute;
        // top: 0.2rem;
        // right: 0.2rem;
        // width: 0.6rem;
        // height: 0.6rem;
        // background: #F31E28;
        // border-radius: 50%;
      }

      &:hover {
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
      }

      &.inactive {
        opacity: 0.3;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        a {
          cursor: default;
        }
      }
    }
  }

  .show-more {
    position: absolute;
    right: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #2D91FF;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 8rem;
    min-width: 79px;
    .chevron {
      margin-right: 1rem;
      transition: all 0.2s;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}