.ant-btn:not([disabled]).btn-create-subscription {
  height: 3.2rem;
  border: 1px solid #797979ad !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  img {
    width: 1.2rem;
    margin-right: 1rem;
  }
}

.ant-btn:hover:not([disabled]).btn-create-subscription {
  background-color: white !important;
  border: 1px solid #000;
  color: black !important;
}
