$imageBackgroundColor: white;
.donut-chart-wrapper {
  position: relative;
  height: 100%;
  .bx--cc--chart-svg {
    overflow: visible;
    .bx--chart-holder {
      height: 36rem !important;
      width: 100%;
    }

    g.bx--cc--pie {
      transform: translate(16.5rem, 15.9rem);
    }

    g.callouts {
      display: none;
      height: 0;
    }

    .labels {
      transform: scale(0.7);
    }

    .pie-label {
      fill: #ffffff;
      font-family: Roboto;
      font-size: 2rem;
      font-weight: 500;
    }
    path.slice {
      stroke: $imageBackgroundColor;
      stroke-width: 2px;
    }
  }
  .donut-center-wrap {
    position: absolute;
    top: 10.5rem;
    left: calc(50% - 5.5rem);
    border-radius: 50%;
    background: $imageBackgroundColor;
    .donut-center {
      .donut-center-app {
        img {
          width: 11rem;
          border-radius: 50%;
          border: 0.3rem white solid;
        }
      }
    }
  }

  g.bx--cc--legend {
    -webkit-user-select: none;
    user-select: none;
    transform: translate(2rem, 2rem);
    text{
      font-size: 1rem !important;
    }
    rect {
      rx: 10;
      ry: 10;
    }
    rect.hover-stroke, g.check{
      display: none;
    }
    text {
      color: #000000;
      font-family: Roboto;
      font-size: 0.93rem;
      font-weight: 500;
      letter-spacing: 0.1px;
    }
  }

  .bx--cc--tooltip {
    background-color: rgba(0, 0, 0, 0.75);
    transform: translateX(0.5rem);
    text-align: center;

    .title-tooltip{
      display: none;
    }
    .datapoint-tooltip {

      p.value {
        margin: auto 0.8rem;
        color: #ffffff;
        font-family: "Roboto Mono";
        font-size: 1.2rem;
        font-weight: 500;
        display: block;
        text-transform: capitalize;
      }
      p.label {
        display: none;
      }
      color: white;
    }
  }
}
