.i-container {
  min-width: 75vw;
  position: relative;
  margin-bottom: 5rem;

  .i-top-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 1.6rem;
    z-index: 200;

    .header {
      display: flex;
      gap: 24px;
      padding-bottom: 8px;
      align-items: center;
      position: sticky;
      top: 54px;
      z-index: 200;
      background-color: white;

      .i-heading {
        color: black;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
      }
    }
  }
}

// MOBILE
@media only screen and (max-width: 520px) {
  .i-container {
    .i-top-section {
      top: 42px;

      .i-heading {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
      }

      .i-filters {
        .i-filters-container {
          background-color: white;
          padding: 0;
          padding-bottom: 1.6rem;

          .select-filter {
            .select-filter-title {
              display: none;
            }

            .select-body {
              padding: 0.8rem;
              height: 4.8rem;
              background-color: #f0f0fe;
              border: none;
              border-radius: 4px;
              color: #4b4b4b;
              font-size: 12px;

              display: flex;
              justify-content: space-evenly;

              .select-body-count {
                border-radius: 200px;
                background: #2e5fff;
                padding: 0 6px;
                color: #fff;
                font-family: Inter;
              }

              img {
                display: none;
              }

              .dates {
                display: none;
              }
            }
          }
        }
      }
    }

    .i-card-container {
      height: fit-content !important;

      .i-card-asset-section {
        padding: 20px 8px;

        .i-card-asset-thumbnail img {
          max-width: 305px;
        }
      }
    }

    .i-card-asset-section-top-type {
      padding: 0 2rem !important;
      height: fit-content !important;
    }
  }
}
