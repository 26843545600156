.discovery-card {
  border-radius: 5px;
  transition: all 0.2s ease-in;
  &.transparent {
    opacity: 0.3;
  }
  &:hover {
    transform: translateY(-3px);
  }
  &::before {
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.03);
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }
  &:hover::before {
    opacity: 0;
  }
  &::after {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }
  &:hover::after {
    opacity: 1;
  }

  .discovery-read {
    i {
      font-size: 2rem;
    }
    position: absolute;
    text-align: center;
    width: 100%;
    border-radius: 3px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    color: white;
    height: 14rem;
    padding-top: 2rem;
    z-index: 100;
    h3 {
      color: white;
      font-size: 1.5rem;
      font-family: "Roboto";
      font-weight: 500;
    }
    .discovery-read-date {
      font-size: 1.1rem;
    }
  }

  position: relative;
  .descr {
    padding: 1rem 1.2rem;
    color: black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    max-height: 13rem;
    margin-bottom: 0.6rem;
    position: relative;
  }
  .dis-card-roles {
    display: flex;
    padding: 0rem 1.2rem 0.5rem;
    font-size: 1rem;
    color: #6f6f6f;
    margin-bottom: 0.5rem;
    z-index: 222;
    align-items: center;
   .roles-img {
      margin-right: 0.5rem;
      width: 2rem;
    }
    .roles-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 110.19%;
      align-items: center;
      text-transform: uppercase;
      color: #6E6E6E;
    }
  }

  img.insight-thumb.default {
    width: 53% !important;
    margin: auto !important;
    display: block;
    position: relative;
    object-fit: contain;
  }

  img.default {
    max-height: 24rem;
  }
  transform: translateY(0px);
  border: 1px solid #eeeeee;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 33rem;
  .img-wrap {
    height: 26rem;
    overflow: hidden;
    position: relative;
    border-radius: 3px 3px 0 0;
    width: auto;

    .img-shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.05);
      z-index: 10;
    }
    img {
      width: 100%;
      object-fit: cover;
      min-height: 100%;
    }
  }
  .app-data {
    padding: 0 1.2rem 1.2rem;
    color: #6c6c6c;
    font-size: 1.1rem;
    font-weight: 300;

    & > span > img {
      width: 2rem;
      margin-right: 0.6rem;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
      background-color: white;
      border-radius: 2px;
    }
    .descr {
      font-weight: 500;
      padding: 1rem 2rem;
      font-size: 1.2rem;
    }
    .platform {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      width: 2rem;
    }
    .discovery-type {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }
  .discovery-rating {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: absolute;
    z-index: 20;
    top: -2.5rem;
    img {
      width: 1.6rem;
      margin-right: 0.6rem;
    }
  }
}
.dash-discoveries-page .app-data {
  width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.importance-indicator {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 15px;
  height: 15px;
  background-color: $color-redful;
  border-radius: 50%;
  z-index: 102;
  &.importance-indicator-animating {
    box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
    animation: pulse 2s cubic-bezier(0.66, 0.33, 0, 1);
    animation-iteration-count: infinite;
  }
}

.discovery-type {
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  width: 8rem;
  img {
    width: 1.2rem;
    position: relative;
    top: -0.1rem;
    margin-right: 0.6rem;
  }
  &.mark {
    background-color: black;
    color: white;
    border: 1px solid black;
  }
  &.intel {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
}
.personas-tooltip-wrapper {
  .ant-tooltip-inner {
    width: max-content;
    .tooltip-wrapper {

    }
  }
}

