.account-menu {
  width: 14rem;
}

.account-menu .ant-list-item {
  cursor: pointer;
  padding: 1rem;
}

.account-menu .ant-list-item:hover {
  background: linear-gradient(270deg, #ffffff 0%, #f6f6f6 100%);
}
