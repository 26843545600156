.eula-wrapper {
  margin: 3rem 0rem;
  font-family: "Roboto";
  font-size: 1.6rem;
  line-height: 1.875rem;
  z-index: 1000000;
  .lean {
    font-weight: 300;
    font-family: "Roboto Slab";
  }
  .fat {
    font-weight: 500;
  }
  h1 {
    margin-bottom: 4rem;
  }
  .header {
    margin: 2rem 0rem;
    font-weight: 500;
    .large-font {
      font-size: 2rem;
    }
  }
  .place-holder {
    height: 0.4rem;
    width: 100%;
  }
  p {
    font-weight: 300;
    margin: 1.5rem 0rem;
    font-size: 1.6rem;
    text-align: justify;
    text-justify: inter-word;
    &.move-right {
      margin-left: 5rem;
      display: flex;
      .letter-margin {
        margin-right: 1rem;
      }
    }
  }
}
