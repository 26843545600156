.backdrop {
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  opacity: 0;
  z-index: 0;
  visibility: hidden;
  &-active {
    opacity: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $z-index-backdrop;
  }
  &-enabled {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    opacity: 0.95;
    z-index: 180;
    visibility: visible;
  }
}
