@import '../../sass-config/abstracts/variables.scss';

.dashboard__container .dashboard__main .mkc-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  height: 100%;

  .breadcrumb-disabled {
    color: rgba(0, 0, 0, 0.45) !important;
  }
}
