.benchmark-ranks-page {
  font-family: "Roboto";
  .title {
    font-size: 2.7rem;
    margin: 1rem 0;
    font-family: "Roboto";
  }
  .h1-title {
    font-size: 4.2rem;
    margin: 1rem 0;
    font-family: "Roboto";
    font-weight: 300;
    .header {
      display: flex;
      flex-direction: row;
      .medium {
         font-weight: 400;
          margin-right: 0.5rem;
      }
    }

  }
  .benchmark__sub-header {
    position: absolute;
    top: 15rem;
    right: 1rem;
    display: flex;
    .benchmark-select-label{
      margin-right: 4rem;
    }
    .ant-select{
      width: 15rem;
    }
  }
  .hr-row {
    background: linear-gradient(45deg,#e0e0e0,transparent);
    height: 1px;
    margin: 2rem 0 3rem;
  }

  position: relative;

  .last-update-tag {
    position: absolute;
    right: 0;
    background: #f7f7f7;
    padding: 1rem;
    top: 2rem;
    &-text {
      color: #9fa5ad;
    }
    &-value {
      color: #3e3e3e;
      font-family: "Roboto";
    }
  }

  .winner-tag {
    position: absolute;
    background-color: red;
    top: 1rem;
    right: 1rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: white;
    font-family: "Roboto";
    padding: 0 0.5rem;
  }

  .title.top-3 {
    margin-bottom: -7rem;
  }

  .ranks-filters {
    margin: 3rem 0 4rem;
    justify-content: space-between;
    color: #9fa5ad;
    top: 6rem;
    background-color: #ffffff;
    z-index: 2;
    height: 5rem;
    padding: 1rem 0;

    &.fixed {
      position: sticky;
      left: 0;
      background-color: #fbfbfb;
      box-shadow: 0 1rem 1.2rem 0 rgba(0, 0, 0, 0.07);
      height: 6rem;
      top: 6rem;
      margin: 0;
      & > div {
        max-width: 1450px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-content: center;
        height: 100%;
      }
      @media (max-width: 1250px) {
        & > div {
          padding: 0 4.6rem !important;
        }
      }
    }

    & > div {
      display: flex;
      justify-content: space-between;
    }

    .ant-select {
      margin-right: 1rem;
    }

    .ranks-filter {
      position: relative;
      & > div {
        display: inline-block;
        position: relative;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
      i.anticon.anticon-down.ant-select-arrow-icon {
        color: black;
        font-size: 1.3rem;
      }

      .ranks-filter-info {
        color: #b7b7b7;
        font-size: 1.7rem;
        position: absolute;
        bottom: 0.9rem;
        right: 4.5rem;
        background: white;
      }
    }
  }
  .compare-line-wrap {
    height: 0.7rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5;
    .compare-line-val {
      background: black;
      border-radius: 0 5px 5px 5px;
      height: 100%;
    }
  }
}
.device-version-os {
  font-size: 1.15rem;
  margin-left: 0.5rem;
}

.ranks-top-3 {
  margin-bottom: 6rem;

  .fastest-app-item:not(:nth-child(1)) {
    transform: scale(0.9);
    transform-origin: bottom right;
  }

  h3 {
    height: 6rem;
  }
  .fastest-app-data {
    margin-top: 0;
  }

  .fastest-app-record {
    min-height: 28rem;
    border-radius: 4px;
    padding-top: 3.8rem;
  }
  .ranks-event-duration {
    color: #1a1a1a;
    font-weight: 300;
    position: relative;
    top: -1rem;
    font-size: 1.4rem;
  }
  .ranks-compare-text {
    color: #242c37;
    font-size: 1.3rem;
    font-weight: 300;
    position: relative;
    top: 2rem;
  }
}

.opt-group {
  border-top: 1px solid gray !important;
}
