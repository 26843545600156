@import '../../sass-config/abstracts/variables.scss';

.filters-heading-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 1.28rem 2.56rem;
  border-bottom: 1px solid $color-border-layout;
  background-color: $color-white;

  .filters-heading {
    color: #212121;
    font-family: Roboto;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }

  .filters-container {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    border-radius: 8px;

    .select-filters-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      flex: 1;
      justify-content: flex-end;

      .select-filter {
        .select-filter-title {
          color: #898989;
          /* Body/regular */
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }

        .select-body {
          cursor: pointer;
          display: flex;
          height: 48px;
          padding: 8px 8px 8px 8px;
          justify-content: space-between;
          align-items: center;
          border-radius: 1.28rem;
          border: 1px solid #e3e4e3;
          background: #fff;
          gap: 8px;

          .select-app-icon {
            width: 31px;
            height: 31px;
            border-radius: 4px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
