@media only screen and (max-width: 520px) {
  //global
  html {
    height: auto !important;
  }
  body {
    font-size: 9px;
    height: auto !important;
  }
  .ant-scroll-number {
    font-size: 7px;
    line-height: 17px;
    padding: 0;
    min-width: 17px;
    height: 17px;
  }
  .ant-select-search__field__wrap {
    display: none;
  }
  .search-account .header__search-bar {
    border-right: none !important;
  }
  .view__header-row {
    flex-direction: row !important;
    .view__header-item {
      min-width: 18rem !important;
      margin-right: 0rem !important;
    }
  }

  .header__wrap {
    grid-template-columns: auto auto !important;
    align-items: center !important;
    background: #f7f7fb !important;

    .search-account {
      padding: 0 !important;
    }

    .dropdown {
      padding: 0 0 0 2rem;
      .dropdown__select img {
        display: none;
      }
    }
  }

  .sidebar-wrap,
  .nav2 {
    display: none;
    width: 100vw;
    background-color: white;
    height: 94vh;
    position: absolute;
    top: -2px;

    &.open {
      display: block !important;
    }
  }

  .dashboard__container {
    grid-template-columns: auto !important;
  }

  .dashboard__main {
    min-width: 100vw !important;
  }

  // header
  .header__username {
    display: none !important;
  }
  .search-auto-complete {
    min-width: 45vw !important;
  }

  //discovery-view
  .ant-breadcrumb,
  .btn-global-back,
  .view-insight-app-arrow {
    display: none !important;
  }
  .view__header {
    .view__header__primary__subs {
      display: none !important;
    }
  }
  .view__header__primary {
    margin-right: 2rem !important;
  }
  .view-left-side {
    width: 100% !important;
  }
  .view__experiment-tabs {
    flex-direction: column;
    align-items: center;
    .variants-images {
      margin-left: 0rem !important;
    }
    .variants-images:not(.no-control):not(.long)::before {
      display: none !important;
    }
    .variants-images .ant-tabs-tab {
      width: 11rem !important;
    }
  }
  .view__header__buttons {
    left: 0 !important;
    right: auto !important;
    top: 9rem !important;
    button {
      padding: 0.02rem;
      min-width: 6rem;
    }
    .download-insight {
      width: 14rem !important;
    }
  }
  .view-insight-app-icon {
    img {
      margin-left: 0 !important;
    }
  }
  .view__header-export__full {
    margin-left: 0 !important;
  }
  .discovery-view-content {
    flex-direction: column;
    align-items: center;

    .view-right-side {
      width: 80% !important;
    }

    .view-left-side {
      width: 100% !important;
    }

    .view__header-row {
      grid-template-columns: repeat(2, 1fr);
    }

    .view__experiment-tabs {
      justify-content: center;
    }
  }

  .modal__overlay.modal__overlay_gallery {
    .gallery-discovery-descr {
      display: none !important;
    }
    .slick-image-slider-wrapper {
      padding: 0 6rem !important;
    }
    ul.slick-dots {
      left: 0rem !important;
    }
  }

  //report view
  .report-view-page {
    .report-view-header {
      .logo-white {
        width: 3.8rem !important;
        overflow: hidden;
        margin-right: 1rem !important;
        img {
          width: 12rem !important;
        }
      }
    }
    .report-view-header-buttons-close {
      display: none !important;
    }
    .report-prev-btn,
    .report-next-btn {
      display: none !important;
    }
    .report-download {
      margin-right: 0rem !important;
    }
    .report-view-header-buttons {
      padding-right: 0rem !important;
      button {
        border: none !important;
        padding: 0 0.2rem;
        span {
          display: none;
        }
      }
    }
  }

  //SNEAK PEAK
  .sneak-peak-modal {
    .sneak-peak-modal__body {
      display: flex;
      flex-direction: column;
    }
    .ant-modal-close {
      right: 1rem;
      top: -28.5rem;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }
    button.ant-btn.share-btn.ant-btn-default {
      position: relative;
      top: -7.7rem;
      right: 24rem;
    }
    .bi-weekly-header .bi-weekly-descr {
      line-height: inherit;
      font-size: 1.8rem;
      max-width: 55rem;
    }
    .bi-weekly-header {
      position: absolute;
      top: -28.5rem;
    }
    img.ins-viewed {
      left: calc(50% - 3.5rem) !important;
    }
    .insight-cover {
      width: auto;
      top: 2rem;
    }
    .ant-modal-body {
      max-height: none !important;
    }
  }
  .user-rate-modal {
    .user-rating img {
      width: 2.5rem !important;
      margin: 0 1rem;
    }
  }

  .ant-modal-wrap.thanks-rate-biweekly-highlights {
    top: 20rem;
    h1 {
      font-size: 2.4rem;
    }
  }
  .castle-animation .flag img:nth-child(2) {
    bottom: 0.8rem !important;
  }
}
