.header__search-row-inner-wrapper {
  height: 12rem;
  margin: 1rem 0;
  padding: 0 1rem;
  box-sizing: border-box;
  border-radius: 4px !important;
  border: 1px solid transparent;

  &:hover {
    background-color: #f5f5f5 !important;
    box-shadow: none !important;
  }

  .header__search-row-content {
    .header__search-row-tags-type {
      display: flex;
      .discovery-type {
        margin-right: 1rem;
      }
    }
  }
}
