.dropdown-nav {
    margin-left: 1rem;
    width: 66%;
    @include respond(phone) {
        width: 80%;
    }
    font-size: 2rem;
    font-family: $font-headings;
    font-weight: bold;
    user-select: none;
    &__no-select {
        cursor: default !important;
    }
    &__select {
        display: flex;
        align-items: center;
        font-size: 1.9rem;
        font-weight: 600;
        letter-spacing: 0.2px;
        color: rgba($color-black, 90);
        span {
            @include ellipsis;
            margin-left: 1.5rem;
        }
        &-image {
            width: 32px;
            height: 32px;
            transform: translateY(-4px);
        }
        &-text {
            // transform: translateY(3px);
        }
        &-arrow {
            margin-left: 1.5rem;
            border: solid $color-brown-grey;
            border-width: 0 2.3px 2.3px 0;
            display: inline-block;
            padding: 3.5px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg) translate(-2px,-3px);
        }
    }
    &__menu {
        width: 160%;
        top: -1.8rem;
        left: -2rem;
        font-size: 1.6rem;
        font-family: $font-headings;
        color: rgba($color-black, 0.7);
        @include respond(phone) {
            width: 130%;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 1.5rem;
        }
        img {
            width: 24px;
            height: 24px;
            transform: translateY(-2px);
        }
    }
    &__loader {
        width: 24px;
        height: 24px;
        @extend %animated-images;
    }
}