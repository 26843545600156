.subscription-list-page {
  .hr-row {
    margin-bottom: 1.5rem;
  }
  .subscriptions-list-filters {
    display: flex;
    margin-bottom: 2rem;
    .sep-flex-grow {
      flex-grow: 1;
    }
  }

  .subscription-products {
    img {
      width: 2rem;
      margin: 0rem 0.6rem 0.6rem 0rem;
    }
  }

  .subscription-created {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.4rem;
    position: absolute;
    top: 1rem;
    color: #b4b4b4;
  }

  .all-tags {
    padding-left: 1.5rem;
  }

  .subscription-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    .tags__tags-tag-element {
      font-size: 0.85rem !important;
    }
  }

  .subscription-discoveries-value {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  th:nth-child(2) {
    padding-left: 2rem !important;
  }
  .ant-table-tbody tr td > :not(a) {
    text-transform: none;
    color: black !important;
  }
  .unread {
    font-weight: 500;
    color: black;
    position: relative;
  }
  span.ant-table-column-title {
    color: #9fa5ad;
    font-family: Roboto;
    font-size: 1.2rem;
    letter-spacing: 0.1px;
    line-height: 1.6rem;
  }
  .ant-table-tbody tr td {
    &:nth-child(2) {
      color: #000000;
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 2rem;
    }
    &:nth-child(3),
    &:nth-child(4) {
      color: #1b1b1b;
      font-family: Roboto;
      font-size: 1.14rem;
      letter-spacing: 0.19px;
      line-height: 1.5rem;
      text-align: center;
    }
  }

  .subscription-tags-match {
    display: block;
    text-align: left;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    &::before {
      content: "";
      position: absolute;
      top: 0.6rem;
      left: 1rem;
      background: black;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
    }
  }

  .tags__tags-tag-element {
    margin: 0 0.3rem 0.5rem;
    padding: 0rem 0.6rem;
    &[data-tag-id="1"] {
      color: #f4442e;
      border: 1px solid #f4442e;
    }
  }

  .subscription-licences-wrap {
    width: 11rem;
  }
  .subscription-frequency-wrap {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    text-align: center;
    text-transform: capitalize !important;
  }
  .subscription-licence {
    position: relative;
    padding-left: 2rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 9px;
    margin: 0.5rem 0rem;
    &::before {
      content: "";
      position: absolute;
      top: 0.25rem;
      left: 1rem;
      background: black;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
    }
  }

  .ant-table-thead {
    position: relative;
    top: -1rem;
  }
  .ant-table-column-sorter-inner-full {
    svg {
      display: none;
    }
    i {
      position: relative;
    }

    .ant-table-column-sorter-down.on::after {
      content: url(../../assets/images/new_design/table-arrow-sorting.svg);
      position: absolute;
      top: -0.5rem;
      right: -1rem;
      width: 1rem;
      height: 1rem;
      transform: rotate(180deg);
    }
    .ant-table-column-sorter-up.on::after {
      content: url(../../assets/images/new_design/table-arrow-sorting.svg);
      position: absolute;
      top: 0rem;
      right: -1rem;
      width: 1rem;
      height: 1rem;
    }
  }
  .subscription-name.unread::before {
    content: "";
    height: 0.7rem;
    width: 0.7rem;
    top: 0.8rem;
    left: -1.5rem;
    position: absolute;
    border-radius: 50%;
    background-color: #e34444;
  }

  .subscription-discoveries {
    text-align: center;
    .sub-management {
      top: 2rem;
      right: 1rem;
      position: absolute;
      font-size: 1.4rem;
      img {
        margin: 0.5rem;
        width: 1rem;
        cursor: pointer;
      }
    }
  }

  .subscriptions-table-wrapper {
    margin-top: 6rem;
    th {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        text-align: center;
      }
    }

    tr.has-tags td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        vertical-align: top;
      }
    }
    .ant-table-tbody > tr {
      position: relative;
      top: -10px;
    }

    .sub-management {
      display: none;
    }
    .ant-table-tbody > tr:hover {
      background: #ffffff;
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      .sub-management {
        display: block;
      }
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ffffff;
    }

    .subscription-icon {
      img {
        width: 3.6rem;
      }
    }
    .subscription-name {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.1rem;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .tags__tags-tag-element {
      font-size: 10px;
      font-weight: 500;
      &[data-tag-id="-1"] {
        color: #f4442e;
        border: 1px solid #f4442e;
      }
    }
    .subscription-view-header-buttons {
      display: flex;
      justify-content: space-around;
      .ant-btn {
        img {
          width: 1.1rem;
          margin-right: 0.8rem;
        }
        span {
          font-size: 1rem;
        }
        &:hover:not([disabled]) {
          color: black !important;
          background-color: rgb(241, 241, 241) !important;
          border-color: #d9d9d9 !important;
        }
      }
    }
    .ant-table-row td {
      padding: 2rem 1rem 2rem !important;
      vertical-align: middle;
      position: relative;
      font-size: 1.4rem;
      &:nth-child(2)::after,
      &:nth-child(5)::after,
      &:nth-child(6)::after {
        content: "";
        width: 1px;
        height: 65%;
        background-color: #cccccc;
        position: absolute;
        top: 2rem;
        right: 0;
      }
      &:nth-child(4)::after,
      &:nth-child(4)::before {
        content: "";
        width: 1px;
        height: 65%;
        background-color: #cccccc;
        position: absolute;
        top: 2rem;
        right: 0;
      }
      &:nth-child(4)::before {
        left: 0 !important;
      }
      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
        padding-top: 2.5rem !important;
      }
    }
  }
  .ant-table-thead tr th {
    border-bottom: none !important;
    padding-bottom: 0rem !important;
  }
}
.empty-subs {
  height: 40rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
  margin-top: 6rem;
  h3 {
    margin-top: 4rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
  }
  .empty-subs-desc {
    width: 24rem;
    margin-top: 2rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 16px;
    text-align: center;
  }
  .empty-subs-img {
    img {
      width: 20rem;
    }
    margin-top: 2rem;
  }
}

.modal-subscription-hint {
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding: 1rem !important;
  }
  .subscription-hint-wrapper {
    display: flex;
    width: 65rem;
    .image-wrapper {
      img {
      width: 39rem;
      @media (max-width: 700px) {
        width: 32rem;
        }
      }
    }
    .subscription-hint-right {
      display: flex;
      flex-direction: column;
      &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        h2 {
          font-family: Roboto Slab;
          font-style: normal;
          font-weight: 500;
          font-size: 2.2rem;
          line-height: 29px;
          margin: 2rem 0rem;
        }
        .subscription-hint-red-arrow {
          margin-right: 3rem;
        }
      }


      .subscription-hint-descr {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 22px;
        margin: 2rem 0rem;
      }

      .subscription-hint-buttons {
        display: flex;
        margin-top: 4.3rem;
        flex-direction: row-reverse;
        .ant-btn {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 14px;
          margin-right: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: 42em) {
  .ant-table-body {
    width: 80rem;
  }
}

@media (max-width: 38em) {
  .ant-table-body {
    width: 69rem;
  }
}

@media (max-width: 34em) {
  .ant-table-body {
    width: 58rem;
  }
}

@media (max-width: 30em) {
  .ant-table-body {
    width: 47rem;
  }
}

@media (max-width: 26em) {
  .ant-table-body {
    width: 36rem;
  }
}

@media (max-width: 22em) {
  .ant-table-body {
    width: 25rem;
  }
}
