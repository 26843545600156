@import '../../../../sass-config/abstracts/mixins.scss';
@import '../../../../sass-config/abstracts/variables.scss';

.mkc-table-top-scroll {
  overflow-x: auto;
  margin: 0 2.56rem;
  box-sizing: content-box;

  @include custom-scrollbar;
}

.mkc-table {
  padding: 2.56rem 2.56rem 0;
  background-color: $color-light;
  flex: 1;

  .mck-table-container {
    background-color: $color-white;
    border-radius: 3rem;
    position: relative;
    height: 100%;

    .MuiPaper-root {
      box-shadow: none;
    }
  }

  @media (max-width: 1800px) {
    .mkc-table {
      width: unset;
    }
  }

  .mck-top-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.28rem 2rem;
  }

  .mck-table-actions {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 0.8rem;
    height: 3.2rem;
    padding-left: 0.8rem;

    .MuiOutlinedInput-root {
      border-radius: 0.8rem;
    }
  }

  .mkc-table-container {
    overflow-x: auto;

    .MuiTableRow-head {
      box-shadow: none;
      align-items: center;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 0;
      margin-left: 20px;
      width: 0.64rem;
    }

    ::-webkit-scrollbar-corner {
      width: 0.64rem;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.64rem;
      background-color: #97acff;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .app-icon {
      margin: 0 auto;

      img {
        width: 2.4rem;
      }
    }

    .insight-name-container {
      position: relative;

      .insight-name {
        transition: transform 0.3s ease-in-out !important;
      }

      .expand-icon {
        transition: opacity 0.3s ease-in-out !important;
        cursor: pointer;

        position: absolute;
        left: -5px;
        top: 45px;
        opacity: 0;
      }
    }

    .MuiTableCell-root:has(.insight-name-container) {
      &:hover {
        .insight-name {
          transform: translateX(20px);
        }

        .expand-icon {
          opacity: 1;
        }
      }
    }

    .info-header {
      img {
        position: absolute;
        top: 0px;
        right: -3px;
        cursor: pointer;
      }
    }

    .list-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      .item {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0.86rem 1.7rem;
        border-radius: 16rem;
        background: #ececec;

        .item-text {
          padding-right: 10px;
        }

        .item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .view-more {
        padding: 0.86rem 1.7rem;
        border-radius: 200px;
        background: #ececec;
        color: #616065;
        cursor: pointer;
      }
    }

    .image-container {
      width: 5rem;
      height: 5rem;

      &.contained {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .merged-communication {
      width: 100%;
      text-align: center;
    }

    .priority-high {
      color: #b11515;
      padding: 2px 10px;
      border-radius: 200px;
      background: #ffb0b0;
    }

    .priority-medium {
      color: #ff9411;
      padding: 2px 10px;
      border-radius: 200px;
      background: #ffdbb0;
    }

    .priority-low {
      color: #0e8a16;
      padding: 2px 10px;
      border-radius: 200px;
      background: #b0ffb0;
    }
  }
}

.marketing-list-container-preview {
  max-height: 20rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: flex-start;
  align-items: flex-start;

  .item {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 2px 10px;
    border-radius: 200px;
    background: #ececec;
    color: #616065;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 190%;
    text-transform: capitalize;

    .item-text {
      padding-right: 10px;
    }

    .item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.MuiPopper-root {
  display: none;
}

.info-header-tooltip {
  .ant-tooltip-inner {
    padding: 10px 16px;
    background-color: #eee;
    color: black;
    opacity: 0.8;
    border-radius: 8px;
  }
}
