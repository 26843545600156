.i-card-container {
  padding: 8px 8px 16px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #e3e4e3;
  background: #fff;
  cursor: pointer;
  height: 500px;

  .i-card-asset-section {
    display: flex;
    padding: 0.8rem 0.8rem 1.6rem 0.8rem;
    flex-direction: column;
    gap: 16px;
    border-radius: 4px;
    background: #f9f9fb;
    width: 100%;

    .i-card-asset-section-top {
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }

      .i-card-asset-section-top-type {
        padding: 0.2rem 1.6rem;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
        border-radius: 0.4rem;
      }
    }

    .i-card-asset-thumbnail {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      justify-content: center;
      align-items: center;
      height: 350px;

      .i-card-asset-thumbnail-variants {
        color: #505050;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 190%; /* 22.8px */
        text-transform: capitalize;

        span {
          padding: 2px 8px;
          border-radius: 16px;
          background: #e9e9e9;
        }
      }

      img {
        max-width: 345px;
        max-height: 360px;
      }
    }
  }

  .i-card-info-section {
    .i-card-info-section-date-and-label {
      display: flex;
      justify-content: space-between;

      .date {
        color: #484141;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 190%; /* 26.6px */
        text-transform: capitalize;
      }

      .label {
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 200px;
      }
    }

    .i-card-info-section-title {
      color: #505050;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
