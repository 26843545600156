.chart-container {
  position: relative;
  border: 1px solid #eceef5;
  box-shadow: 0 1.5rem 2rem 0 rgba(0,0,0,0.02);
  height: 100%;
  .header {
    height: 5rem;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.centered {
      margin: auto;
      width: 100% !important;
      justify-content: center;
      .title-h3 {
        margin-left: 0 !important;
      }
    }
  }

  .title-h3 {
    font-family: "Roboto";
    line-height: 2.1rem;
    text-transform: uppercase;
    margin-left: 3rem;
    font-weight: 400;
    color: white;
    letter-spacing: 2px;
    font-size: 1.8rem;

    .anticon-info-circle {
      margin-left: 1rem;
      color: #c8c7c7;
      position: relative;
      z-index: 10;
    }
  }
}
