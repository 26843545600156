@mixin legend() {
  .legend-item {
    margin: auto 3rem;
    color: #000000;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 11px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .legend-color {
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    .legend-value {
      cursor: default;
    }
  }
}

.line-chart-wrapper {
  .legend {
    display: flex;
    justify-content: center;
    margin: 3rem auto 2rem;
    @include legend
  }

  margin: 3rem 3rem 1rem 5rem;
  .bx--cc--grid g.x.grid g.tick line, .bx--cc--grid g.y.grid g.tick line {
    stroke: #E9EBF1;
  }
  .bx--cc--chart-svg {
    overflow: visible;
    circle.dot {
      stroke: white !important;
      stroke-width: 3px;
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
      z-index: 22;
      transition: all 0.2s ease-in-out;
      &.hovered {
        r: 6px;
        stroke-width: 6px;
      }
    }
    .bx--cc--ruler line.ruler-line {
      stroke: #00000033;
      stroke-width: 1px;
      stroke-dasharray: 5;
    }
  }

  g.axis.left {
    .axis-title {
      transform: rotate(-90deg) translateY(-22px);
    }
  }
  .bx--cc--tooltip {
    min-width: 6rem;
    color: #000000;
    font-family: Roboto;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.18px;
    line-height: 13px;
    li {
      &:first-child{
        justify-content: center !important;
      }  
      height: 3.5rem;
      border-bottom: none !important;
      display: flex;
      align-items: center;
      flex-direction: row !important;
      width: 100% !important;
      .month {
        line-height: 1.3rem;
        font-size: 1.1rem;
        text-transform: uppercase;
      }
      .year {
        opacity: 0.5;
        margin: 0rem 0.5rem;
      }
      p.value {
        width: auto;
        margin: 1rem;
        font-size: 1.6rem;
      }
      p.label {
        color: #343a3f;
        font-family: Roboto;
        font-size: 0.92rem;
        letter-spacing: 0.1px;
        line-height: 11px;
        text-transform: uppercase;
      }
    }

    &:nth-child(odd) {
      background-color: #d8d8d81a;
    }

    &:nth-child(1) {
      
      .datapoint-tooltip {
        display: flex;
        flex-direction: row-reverse !important;
        width: 100% !important;

        p.value {
          width: auto;
          margin: 1rem;
          font-size: 1.6rem;
        }
        p.label {
          color: #343a3f;
          font-family: Roboto;
          font-size: 0.92rem;
          letter-spacing: 0.1px;
          line-height: 11px;
        }
      }

      &:nth-child(odd) {
        background-color: #d8d8d81a;
      }
      &:nth-child(1) {
      
        .datapoint-tooltip {
          display: flex;
          justify-content: center;
          p.value {
            font-weight: 500;
          }

          p.label {
            display: none;
          }
        }
      }
      &:last-child {
        display: none;
      }
    }
  }
}
