.view__header {
  display: flex;
  flex-direction: column;
  padding-top: 6rem;

  .status-abtest {
    padding: 0.1rem 1rem;
    text-transform: uppercase;
  }
  &__btns-create-see {
    top: 0;
    position: absolute;
    right: 0;
    display: flex;
    .see-all-btn {
      margin-right: 2rem;
    }
  }
  .status-abtest.integrated {
    background-color: rgba(24, 182, 99, 0.1);
    .ant-badge-status-text {
      color: #18b663;
    }
  }

  .status-abtest.abandoned {
    background-color: rgba(229, 57, 53, 0.1);
    .ant-badge-status-text {
      color: #e53935;
    }
  }

  @include respond(phone) {
    flex-direction: column;
  }
  &__prev-next {
    margin-left: auto;
    top: 0rem;
    position: absolute;
    right: 1rem;
    &__prev-arrow {
      margin-right: 0.5rem;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2.6px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      position: relative;
      top: -0.1rem;
    }
    &__next-arrow {
      margin-left: 0.5rem;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2.6px;
      transform: rotate(135deg);
      transform: rotate(-45deg);
      position: relative;
      top: -0.1rem;
    }
    &__position {
      font-family: "Roboto";
      text-align: center;
      margin-top: 1rem;
      font-size: 1.2rem;
      color: #6c6c6c;
      font-weight: 400;
    }
    .ant-btn-group {
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.82);
      & > button {
        background-color: #ffffff;
        &:disabled {
          border: solid 1px rgba(110, 110, 110, 0.2);
        }
        &:not(:disabled):hover {
          box-shadow: inset 0px 0px 17px -8px rgba(0, 0, 0, 0.18);
          span {
            color: white;
            border-color: white;
          }
        }
        &:disabled:hover {
          background-color: #ffffff !important;
        }
      }

      & > :nth-child(1) {
        // back button
        border: solid 1px #d9d9d9;
        margin-right: 0.1rem;
      }

      & > :nth-child(2) {
        // next button
        border: solid 1px #d9d9d9;
      }
    }

    & button span {
      color: rgba(0, 0, 0, 0.82);
      font-size: 1.4rem;
    }
    & button:disabled span {
      color: #cacaca !important;
      border-color: #cacaca;
    }
  }
  &-demo {
    padding-top: 4.5rem;
  }
  &__primary {
    display: flex;
    align-items: flex-start;
    margin-right: 31rem;
  }
  &__arrow {
    cursor: pointer;
    margin-right: 2.5rem;
  }
  &-title {
    color: #1a1818;
    font-size: 2.4rem;
    font-family: "Roboto";
    margin-bottom: 2rem;
  }
  &-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    font-size: 1.4rem;
    color: $color-brown-grey;
    text-transform: capitalize;
    padding-right: 0.2rem;
    &.wide {
      width: 100%;
    }
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &-item {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .countries {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .country {
        margin-right: 1rem;
        .flag-icon {
          width: 2.32rem;
          height: 1.8rem;
          border: 0px solid #e8e8e8;
        }
      }
    }
    @media screen and (max-width: 1600px) {
      .persona-role-item {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 252px;
      }
    }

    @include respond(phone) {
      margin: 0 0 0.3rem 0;
      &::after {
        height: 0 !important;
        width: 0 !important;
      }
    }
    &-header {
      margin-right: 1rem;
      color: #8f8f8f;
      font-size: 1.5rem;
      margin-bottom: 0.3rem;
      font-weight: 300;
      position: relative;
      .anticon-info-circle {
        position: absolute;
        left: -2rem;
        top: 0.3rem;
      }
      img {
        cursor: pointer;
      }
    }
    &-data {
      color: black;
      font-size: 1.5rem;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 400;
      &.abtest-roles-status div {
        display: flex;
        align-items: center;
        white-space: break-spaces;
        img {
          margin-right: 0.7rem;
        }
      }
      &.abtest-roles-status.abandoned {
        text-transform: uppercase;
        color: #ff0000;
      }
      &.abtest-roles-status.abandoned_no_data {
        text-transform: uppercase;
        color: #ff0000;
      }
      &.abtest-roles-status.integrated {
        text-transform: uppercase;
        color: #55b26b;
      }
      &.rating {
        cursor: pointer;
        .rating-count {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          color: #8f8f8f;
          display: inline-block;
          margin-right: 0.6rem;
        }
        img {
          width: 1.4rem;
          margin-right: 0.3rem;
          &:nth-child(5) {
            margin-right: 1.2rem;
          }
        }
      }
      &-importance {
        img {
          width: 1.7rem;
          height: 1.7rem;
          margin-right: 1rem;
        }
      }
      &__status {
        &-active {
          color: $color-blueful;
        }
        &-integrated {
          color: $color-greenful-2;
        }
        &-abandoned {
          color: $color-redful;
        }
      }
    }
  }
  &-export {
    &__tooltip {
      z-index: $z-index-view !important;
      .ant-tooltip-inner {
        width: auto !important;
      }
    }
  }
  &-btn {
    @include respond(phone) {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 10rem;

    & > div {
      margin-left: 1rem;
      button {
        min-width: 9rem;
      }
      .ant-btn:not([disabled]) {
        color: white !important;
        background-color: #1A93FC !important;
        border-color: #1A93FC !important;
        &:hover {
          background-color: rgba(26, 147, 252, 0.81) !important;
        }
      }
    }
  }
  &__share {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    transform: translateY(1px);
  }
  &__chat {
    cursor: pointer;
    img {
      transform: translateY(2px);
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.5rem;
    }
  }
}

.discovery-error-loading {
  text-align: center;
  .anticon-info-circle {
    display: none;
  }
  .ant-modal-confirm-title {
    h2 {
      color: #1a1a19;
      font-family: "Roboto Slab";
      font-size: 22px;
      letter-spacing: 0.24px;
      line-height: 29px;
      text-align: center;
      text-align: center;
      margin: 2rem auto 1rem;
    }
  }
  .ant-modal-confirm-content {
    p {
      color: #1a1a19;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.18px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .ant-modal-confirm-content {
    margin: auto !important;
  }
  .error-download-img {
    display: flex;
    justify-content: center;
    margin: 2rem auto 3rem;
  }
  .ant-modal-confirm-btns {
    float: inherit !important;
    margin: auto;
    text-align: center;
  }
}
.user-rate-modal {
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
    color: #9fa5ad;
  }
  .user-rating {
    img {
      width: 1.6rem;
      cursor: pointer;
    }
  }
}
