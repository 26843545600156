@import '../../sass-config/abstracts/variables.scss';

.app-drawer-container {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  position: relative;

  .app-drawer-close {
    margin-left: auto;
  }

  .apps-close-mobile {
    position: fixed;
    bottom: 0;
    background-color: white;
    padding: 20px 0;
  }

  .app-drawer-top-section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    top: -16px;
    background-color: white;
    z-index: 123;
    padding-bottom: 16px;

    .apps-drawer-title {
      display: flex;
      align-items: center;

      &-main {
        font-size: 1.6rem;
        font-weight: 500;
      }

      .max-allowed {
        color: $color-subtitle;
        margin-left: 1rem;
      }
    }

    .apps-drawer-selected-count {
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .apps-drawer-set-to-default {
      .ant-btn {
        color: #1677ff !important;
        background: transparent;
        border: none;
      }
      .ant-btn:hover:not([disabled]) {
        background: transparent !important;
      }
    }
  }

  .apps-selection {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    overflow: auto;
    padding-right: 0.5rem;

    .app-selection-item {
      display: flex;
      width: 48.5%;
      height: 4.8rem;
      padding: 4px 8px;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.8rem;
      border: 1px solid $color-border-layout;
      cursor: pointer;

      .app-selection-item-left {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }

      .app-name {
        color: #000;
        font-family: Roboto;
        font-size: 1.4rem;
        overflow: hidden;
      }

      img {
        width: 3rem;
        height: 3rem;
        border-radius: 0.4rem;
      }

      .app-count {
        font-size: 1.4rem;
      }
    }
  }
}
