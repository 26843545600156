.login {
  &__header {
    // background-image: u;
    color: $color-pinkish-grey;
    font-family: $font-headings;
    font-size: 1.3rem;
    // @include respond(phone) {
    //   height: 23%;
    //   justify-content: center;
    //   padding-left: 0;
    // }
    * {
      color: #fff;
    }
    
    .logo-icon {
      width: 43px;
      height: 31px; 
      margin-bottom: 69px;
    }
  }
  &__welcome {
    
    font-family: "Nexa";
    font-size: 23px;
    margin-bottom: 25px;
  }
  &__title {
    font-family: "Nexa Bold";
    font-weight: 900;
    font-size: 31px;
  }
  &__description {
    font-family: "Nexa";
    font-size: 20px;
    margin-bottom: 55px;
  }
}
