@import '../../../sass-config/abstracts/mixins.scss';
@import '../../../sass-config/abstracts/variables.scss';

.chart-legend-wrapper {
  display: flex;
  gap: 1.2rem;
  font-size: 1.4rem;
  flex: 1;
  overflow: auto;
  height: 100%;

  @include custom-scrollbar;

  .chart-legend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-overflow: ellipsis;
    }

    .MuiButtonBase-root {
      padding: 0;
    }

    .count {
      color: $color-subtitle;
    }
  }
}
