.carbon-select {
  width: 14rem;
  height: 4rem;

  .ant-select-selection {
    border: none;
    height: 100%;
    border-radius: 0;
  }

  .ant-select-selection-selected-value {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: 1.8rem;
    position: relative;
    top: 1rem;
    left: 1rem;
  }

  //black style
  &.carbon-select-black {
    border: none !important;
    .ant-select-selector {
      background-color: #181818;
      color: #ffffff;
      font-family: Roboto;
      border: none !important;
      border-bottom: 1px solid #8d8d8d !important;
      height: 4rem !important;
      .ant-select-selection-item{
        padding-top: 0.5rem;
        color: #b7b7b7 !important;
      }
    }

      background-color: #181818;

      .ant-select-arrow {
        color: white;
      }

    .ant-select-selection-selected-value {
      color: #ffffff;
    }
  }

  &.carbon-select-white {
    background-color: #f4f4f4;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px;
    border-bottom: 1px solid #8d8d8d !important;

    .ant-select-selector{
      background: transparent !important;
      box-shadow: none !important;
      &:focus, &:active{
        box-shadow: none !important;
      }
    }
    .ant-select-selection-item{
      color: black !important;
      font-weight: 500 !important;
      padding: 0.3rem 0.4rem;
    }
    .ant-select-dropdown-menu-item{
      color: #161616;
      font-family: Roboto;
      border-bottom: 1px solid #8d8d8d;
    }

    .ant-select-selection {
      background-color: #f4f4f4;

      .ant-select-arrow {
        color: black;
      }
    }
    .ant-select-selection-selected-value {
      color: #161616;
    }
  }
}

.carbon-select-dropdown {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 0;
  background-color: black;
  border-radius: 0;

  .ant-select-dropdown-menu-item, .ant-select-item.ant-select-item-option {
    color: #c6c6c6 !important;
    margin: 0 !important;
    background-color: #676767 !important;
    font-size: 1.4rem;
    letter-spacing: 0.16px;
    position: relative;
    cursor: pointer;
    height: 4rem;
    padding: 1.1rem 1.6rem 1.1rem 1.6rem !important;
    overflow: hidden;

    font-weight: 400 !important;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    &:hover, &:focus{
      background-color: #353535!important;
      color: #fff!important;
    }
  }

  //black-style
  &.carbon-select-black-dropdown {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    background-color: black;
    .ant-select-dropdown-menu {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(185, 185, 185, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .ant-select-dropdown-menu-item {
      color: rgb(198, 198, 198) !important;
      &:hover {
        background-color: rgb(53, 53, 53) !important;
        color: white !important;
      }
      &.ant-select-dropdown-menu-item-selected,
      &.ant-select-dropdown-menu-item-active {
        background-color: rgb(76, 76, 76) !important;
        color: white !important;
      }
    }
  }

  //white-style
  &.carbon-select-white-dropdown {
    background-color: #f4f4f4;
    .ant-select-item-option{
      color: black !important;
      &:hover{
        background-color: #c7c7c7!important;
        color: #161616!important;
      }
    }
    .ant-select-dropdown-menu {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(185, 185, 185, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .ant-select-dropdown-menu-item {
      color: #161616;
      &:hover {
        background-color: rgb(199, 199, 199) !important;
        color: #161616  !important;
      }
      &.ant-select-dropdown-menu-item-selected,
      &.ant-select-dropdown-menu-item-active {
        background-color: rgb(199, 199, 199) !important;
        color: #161616  !important;
      }
    }
  }

}
