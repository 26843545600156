@mixin line-clamp($numOfLines) {
  display: -webkit-box;
  -webkit-line-clamp: $numOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.new-marketing-page {
  .hr-row {
    background: linear-gradient(45deg, #e0e0e0, transparent);
    height: 1px;
    margin: 4rem 0 4rem;
  }

  .h2-title {
    color: #1a1818;
    font-family: "Roboto";
    font-size: 2.3rem;
    letter-spacing: 0.19px;
    line-height: 0.8rem;
  }

  h3.title {
    color: #1a1818;
    font-family: "Roboto";
    font-size: 2.3rem;
    letter-spacing: 0.19px;
    line-height: 8px;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .extra-text {
    color: #676767;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0.15px;
    line-height: 18px;
    margin-bottom: 3rem;
  }

  .which-products-were {
    display: flex;
    margin-top: 3rem;
    color: #c7c7c7;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0.15px;
    line-height: 1.8rem;

    .bold {
      color: #676767;
      margin-right: 0.3rem;
    }
  }

  .cam-apps-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .cam-app-icon {
      position: relative;

      .count-discoveries {
        background-color: #f4442e;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1);
        color: #ffffff;
        font-family: Roboto;
        font-size: 9px;
        text-align: center;
        margin: auto 0.3rem;
        position: absolute;
        width: calc(100% - 0.6rem);
        top: -0.85rem;
      }

      img {
        margin: 0.3rem;
        height: 3.1rem;
        width: 3.1rem;
        border: 1px solid #979797;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        filter: grayscale(1);
      }
    }

    &.apps-2 {
      position: relative;
      top: -0.2rem;

      .cam-app-icon {
        img {
          margin: 0.2rem;
          height: 2.2rem;
          width: 2.2rem;
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .empty-day {
    text-align: center;

    img {
      width: 3.5rem;
      opacity: 0.2;
      margin: auto;
      display: inline-block;
    }
  }
  .ant-btn.filter-btn-new.black-btn-filter .filter-count {
    padding: .2rem;
  }
  .ant-picker-panel {
    .ant-picker-body {}

    th {
      text-transform: uppercase;
      text-align: center;
    }

    tr {
      height: 10px;

      td {
        border: 1px solid #e8e8e8;
        vertical-align: top;

        .ant-picker-calendar-date {
          .ant-picker-calendar-date-value {
            color: #e8e8e8;
            font-family: Roboto;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0.14px;
            line-height: 20px;
          }

        }

        .ant-picker-calendar-date-today {
          .ant-picker-calendar-date-value {
            color: black;
          }
        }
      }
    }

    .ant-fullcalendar-column-header {
      height: 2.4rem;
      border: 1px solid #e8e8e8;
      text-align: center;
      color: #000000;
      font-family: Roboto;
      font-size: 1.4rem;
      text-transform: uppercase;
      padding-bottom: 0;
    }

    .ant-fullcalendar-next-month-btn-day .ant-picker-calendar-date,
    .ant-fullcalendar-last-month-cell .ant-picker-calendar-date {
      display: none;
    }

    .ant-picker-calendar-date {
      border: none;
      margin: 0;
      min-height: 7rem;
      height: 100%;

      &:hover,
      .ant-fullcalendar-selected-day {
        .ant-fullcalendar-value {
          color: black;
        }

        .cam-app-icon {
          img {
            filter: none;
          }
        }
      }
    }

    .ant-fullcalendar-disabled-cell {
      .ant-fullcalendar-value {
        color: #e8e8e8 !important;
      }
    }

    .ant-fullcalendar-content {
      height: auto;
      position: relative;
      top: -0.5rem;
      overflow: visible;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    .ant-fullcalendar-selected-day .ant-fullcalendar-date,
    .ant-fullcalendar-cell:hover .ant-fullcalendar-date,
    .ant-fullcalendar-cell:focus .ant-fullcalendar-date {
      background-color: white;
    }

    .ant-fullcalendar-today {
      .ant-fullcalendar-value {
        color: black;
      }

      .ant-fullcalendar-date {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #00000015 100%);
        border-bottom: 3px solid #000000;
      }
    }

    .ant-fullcalendar-disabled-cell {
      opacity: 0.4;
    }
  }

  .big-calendar-header {
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: 100%;

    .dates {
      display: flex;
      justify-content: center;
    }

    .legend-holiday {
      position: absolute;
      top: 0.2rem;
      right: 1rem;
      width: 14rem;

      .index-text {
        color: #c3c3c3;
        font-family: "Roboto Mono";
        font-size: 0.8rem;
        letter-spacing: 0.09px;
        line-height: 18px;
      }

      .icon-descr {
        color: #ffffff;
        font-family: Roboto;
        font-size: 0.9rem;
        letter-spacing: 0.1px;
        line-height: 18px;

        .anticon {
          font-size: 1.2rem;
          margin-right: 0.8rem;
        }
      }
    }

    .filter-wrapper {
      position: absolute;
      top: 0rem;
      right: 2rem;
    }

    .slick-arrow-def {
      top: 22rem;
    }
  }

  .nmp-wrapper-calendar {
    margin-top: 6rem;
  }

  .holiday-symbol {
    position: absolute;
    top: 0rem;
    left: 0rem;
  }

  .wrapper-tabs {
    margin-top: 10rem;

    .mass-market-email-vo {
      font-family: Roboto Slab;
      font-size: 2.3rem;
      line-height: 2.8rem;
      letter-spacing: 0.19px;
      color: #1a1818;
      margin-top: 2.5rem;
    }

    .the-number-of-mass-m {
      color: #676767;
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }

    .gap {
      margin-top: 8rem;
    }

    .ant-tabs.sticky {
      .ant-tabs-top-bar {
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
      }
    }

    .ant-tabs-bar.ant-tabs-top-bar {
      position: sticky;
      top: 5.9rem;
      background: white;
      z-index: 11;

      .ant-tabs-nav {
        z-index: 42;
      }
    }

    .timeframe-selectbox {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      z-index: 13;
      position: relative;
      top: -0.2rem;

      .carbon-select-box-wrapper {
        margin-left: 1rem;
        z-index: 3;
      }

      .label {
        color: #9fa5ad;
        font-family: Roboto;
        font-size: 1.2rem;
        letter-spacing: 0.32px;
        line-height: 1.6rem;
        z-index: 3;
      }

      &.sticky {
        position: sticky;
        top: 6.7rem;
      }
    }
  }

  .dummy-sticky {
    height: 3rem;
  }

  .persona-container {
    width: 100%;

    .legend.index {
      justify-content: flex-start;
      margin-left: 5rem;
      margin-bottom: 2rem;

      .legend-item {
        display: flex;
        margin-right: 2rem;

        .legend-color {
          width: 0.9rem;
          height: 0.9rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.cam-apps-popover {
  overflow: hidden;

  .ant-popover-arrow {
    width: 1rem;
    height: 1rem;
    background: white;
    border-right: 1px black solid !important;
    bottom: 6px !important;
    border-width: 1px;
  }

  .ant-popover-inner-content {
    background-color: white;
    overflow: hidden;
    border: 1px solid #000000;
    height: 32rem;

    .cam-app-date {
      color: black;
    }
  }

  .cam-apps-popover-content {
    .todays-discoveries {
      display: flex;
      flex-direction: row;

      &-one {
        padding: 1rem;
        width: 30rem;
        margin-right: auto;
        margin-left: auto;

      }
    }

    padding: 0.2rem;
    padding-bottom: 0;

    .app-data {
      display: flex;
      color: #000000;
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.15px;

      img {
        width: 2rem;
        margin-right: 1rem;
        border: 1.1px solid #eceef5;
        border-radius: 3.3px;
      }
    }

    h3 {
      color: #000000;
      font-family: Roboto;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0.18px;
      margin: 1rem 0;
      @include line-clamp(2);
      min-height: 4.7rem;
      margin-bottom: 0.5rem;
    }

    .cam-longtext {
      color: #676767;
      font-family: Roboto;
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 17px;
      @include line-clamp(2);
    }

    .cam-roles {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #6F6F6F;
      margin-bottom: 0.5rem;

      img {
        margin-right: 0.5rem;
      }
    }

    .cam-thumbnail {
      margin-top: 1rem;
      -webkit-mask-image: linear-gradient(180deg, #000 0, #000 35%, rgba(0, 0, 0, 0) 63%, rgba(0, 0, 0, 0) 100%);

      img {
        width: 100%;
      }
    }
  }


}