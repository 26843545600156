$product-width-threshold: 1550px;
.intel-product {
  position: relative;
  width: 100%;
  .h1-title {
    font-size: 4.2rem;
    font-family: "Roboto";
    font-weight: 300;
  }
  &.product {
    .btns {
      position: absolute;
      right: 0rem;
      top: -10rem;
      display: flex;
      .see-all-btn {
        margin-right: 1rem;
      }
    }
  }
  &.list {
    .btns {
      position: absolute;
      right: 7rem;
      top: -4rem;
      display: flex;
      .see-all-btn {
        margin-right: 1rem;
      }
    }
  }
  .products-list {
    margin-top: 4rem;
  }
  .filter-box {
    position: relative;
    .intel-product-filter-platform {
      width: 14rem;
      position: absolute;
      right: 0rem;
      top: 0rem;
      z-index: 20;
    }
    .platform-mult-selector {
      width: 17rem;
      position: absolute;
      right: 0rem;
      top: 0rem;
      z-index: 20;
      span {
        width: 15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  .product-selector-and-data {
    display: flex;

    width: 100%;
    @media (max-width: $product-width-threshold) {
      width: 120%;
    }
    .product-drawer-wrap {
      width: 45rem;
      min-height: 10rem;
    }
    .app-drawer.type-marketing {
      margin-top: 0rem !important;
      position: absolute;
      z-index: 55;
    }
    .product-data {
      display: flex;
      flex-wrap: wrap;
      margin-left: 2rem;
      width: 47rem;
      height: 11rem;
      .product-data-item {
        width: 15rem;
        .product-data-field {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: #a0a5ac;
        }
        .product-data-value {
          margin-top: 0.4rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: #000000;
          display: flex;
          align-items: center;
          &.product-data-value-platform {
            img {
              width: 1.5rem;
              margin-right: 0.7rem;
            }
          }
        }
      }
    }
    @media (max-width: $product-width-threshold) {
      .product-data {
        width: 40rem;
        .product-data-item {
          width: 13rem;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  .hr-row {
    margin: 2rem 0 3rem;
  }

  .wrapper-loader {
    position: relative;
    height: 12rem;
  }

  .app-drawer__apps h4 {
    margin: 2.2rem 0 2rem;
  }
  .slick-next__versions {
    box-shadow: none;
  }
  .release-radar {
    border: none;
    padding: 0rem 1rem 0;
    .prev-next-buttons {
      .slick-prev {
        left: 1.2rem;
      }
      .slick-next {
        right: 1rem;
      }
    }
  }

  .slick-track {
    margin-left: 0;
    position: relative;
    .slick-slide:first-child {
      position: relative;
      left: 0.1rem;
    }
    .slick-slide:last-child {
      position: relative;
      left: -0.1rem;
    }
  }

  .group-date-tabs {
    .tags__tags {
      left: 0rem !important;
    }
  }
  .group-switcher {
    height: 3rem;
    background: #eceef4;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 0 2rem;
    div {
      height: 100%;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 1.6rem;
      color: #636363;
      transition: all 0.3s ease-in-out;
      width: 12.4rem;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      padding-top: 0.2rem;
      &.swth-date.swth-act {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.swth-release.swth-act {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &.swth-act {
        background: #000000;
        color: white;
      }
    }
  }
  .in-progress-block {
    background-color: rgba(251, 140, 0, 0.1);
    height: 7.5rem;
    position: relative;
    width: 100%;
    padding: 1.5rem 2rem;
    margin-top: 2rem;
    .close-block {
      position: absolute;
      top: 0.6rem;
      right: 1rem;
      cursor: pointer;
      color: #fb8c00;
      user-select: none;
    }
    h2 {
      color: #fb8c00;
      font-size: 1.5rem;
      font-weight: 400;
    }
    .descr {
      color: #9b5600;
      font-size: 1.3rem;
    }
  }
  @media (max-width: 1450px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }
  }

}
.versions__no-content {
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-drawer-wrap {
  margin-top: 5rem;
  margin-bottom: 6rem;
  position: relative;
  h4 {
    color: #9fa5ad;
    font-size: 1.4rem;
  }
  .app-drawer .performance-app-drawer__apps-search-input {
    padding: 0 1rem;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
  }

  .performance-app-drawer__apps-search-input {
    .ant-input-affix-wrapper:hover input {
      border-color: black !important;
    }
    input {
      border: none;
      outline: none;
      border-bottom: 1px solid #edebeb;
      height: 5rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      border-radius: 0;
      &:hover {
        border-color: #edebeb;
      }
      &:focus {
        border-color: black;
        border-right-width: 0;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .owner-divider {
    color: #c2c2c2;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 300;
    margin-top: 2rem;
  }
  .app-owner {
    font-size: 1.5rem;
    color: #1a1a1a;
    font-weight: 300;
  }
}
