.versions__header {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 5rem;
  @include respond(phone) {
    flex-direction: column;
    align-items: flex-start;
  }
  &-primary {
    font-size: 2rem;
    letter-spacing: 0.2px;
  }
  &-secondary {
    font-size: 1.3rem;
    margin-left: 1.2rem;
    color: rgba($color-brown-grey, 0.6);
    text-transform: uppercase;
    @include respond(phone) {
      margin-left: 0;
    }
  }

  .recent-changes {
    background-color: #f5f5f5;
    width: 100%;
    margin: 3rem 0 0;
    padding: 2rem;
    h3{
      color: #7B828B;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 20px;
      margin-bottom: 1.5rem;
    }
    .change{
      color: #000000;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 15px;
    }

  }
}
