.uj-carousel-container {
  margin-top: 83px;
  margin-bottom: 4rem;
  width: 56vw;

  .uj-carousel-heading {
    display: flex;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid rgba(170, 170, 170, 0.3);
    padding-bottom: 8px;

    .uj-history-app-container {
      .uj-history-app {
        color: var(--Gray-30, #a1abbd);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .uj-history-version {
        color: var(--Gray-100, #000);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
      }
    }

    .uj-carousel-name {
      color: var(--Gray-100, #000);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
    }

    .uj-carousel-app-logo {
      width: 48px;
      height: 48px;
    }

    .uj-carousel-btns-container {
      margin-left: auto;
      display: flex;
      gap: 8px;
    }

    .uj-changes-history-btn {
      display: flex;
      height: 38px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: #e0c430;
      cursor: pointer;
      border: none;
    }

    .uj-related-ins-btn {
      border: none;
      cursor: pointer;
      color: #fff;
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 16px;
      border-radius: 4px;
      background: #000;
    }
  }

  .uj-carousel-images {
    margin-top: 2rem;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    padding-bottom: 0.3rem;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 10px;
      margin-left: 20px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.light-border {
  border: 1px solid #e9ebf3;
}

.uj-carousel-image-container {
  .uj-carousel-image-heading {
    height: 36px;
    width: 100%;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    padding: 8px 16px;

    .uj-carousel-uj-status {
      margin-left: auto;
      display: flex;
      gap: 10px;
    }
  }

  .uj-carousel-image {
    // border-radius: 5px;

    cursor: zoom-in;
    position: relative;

    canvas {
      // border-radius: 5px;
    }

    .canvased-img {
      // border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.uj-image-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
