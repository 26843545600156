.dashboard__container--context-timeline {
  grid-template-columns: 25.6rem auto !important;

  .dashboard__main {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 0 !important;
  }
}

.searchAccount--context-timeline {
  padding: 1rem 0 1rem 2rem !important;
}

@media only screen and (max-width: 1400px) and (min-width: 600px) {
  .dashboard__container--context-timeline {
    grid-template-columns: 5rem auto !important;
  }
}
