.ranks-table {
  border: 1px solid #e8e8e8;
  padding: 3rem 3rem;
  .less3 {
    opacity: 0.5;
    font-weight: 400 !important;
  }
  .ranks-table-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    color: #9fa5ad;
    font-size: 1.35rem;
    padding: 0 2rem;
    align-items: baseline;
    .ant-select-selection {
      border: none;
      font-size: 1.2rem;
      color: #9fa5ad;
      width: 10rem;
      .ant-select-selection-selected-value {
        color: #9fa5ad;
      }
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }

  .ranks-table-head,
  .ranks-table-app-data {
    & > div:nth-child(1) {
      flex-basis: 6%;
    }
    & > div:nth-child(2) {
      flex-grow: 1;
    }
    & > div:nth-child(3) {
      flex-basis: 24%;
    }
    & > div:nth-child(5),
    & > div:nth-child(6) {
      flex-basis: 12%;
    }
    & > div:nth-child(4) {
      flex-basis: 8%;
    }
  }
  .ranks-table-app-data {
    & > div:nth-child(1) {
      font-weight: 600;
    }
    & > div:nth-child(2) {
      font-weight: 500;
      font-size: 1.8rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;      
    }
  }
  .ranks-table-body {
    .ranks-app-icon {
      width: 2.5rem;
      margin-right: 1rem;
    }
  }
  .ranks-table-app {
    position: relative;
    height: 8rem;
    padding: 1.5rem 2rem 0 2rem;
    &:hover {
      box-shadow: 0 0 50px 0 rgba(159, 165, 173, 0.2);
      .link-to-compare {
        display: block;
        cursor: pointer;
      }
    }
  }
  .ranks-table-app-data {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: 300;
    color: #242c37;
    font-size: 1.5rem;
    font-weight: 300;
    &-duration {
      font-size: 1.2rem;
      strong {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
    &-loc {
      text-align: left;
    }
    &-diff {
      font-size: 1.4rem;
      &.better {
        color: #0bbf68;
      }
      &.worse {
        color: #f5222d;
      }
    }
  }
  .compare-line-wrap {
    bottom: 2rem;
    left: 2rem;
    width: calc(100% - 4rem);
    .compare-line-val {
      border-radius: 4px;
    }
  }

  .link-to-compare {
    position: absolute;
    top: 0;
    right: 0;
    height: 3.2rem;
    width: 3.5rem;
    border-radius: 3px 3px 3px 2rem;
    background: linear-gradient(270deg, #f75d38 0%, #f4442e 100%);
    display: none;
    svg {
      width: 3rem;
      height: 2.5rem;
      position: relative;
      top: 0.3rem;
      left: 0.5rem;
      * {
        color: white !important;
      }
      g {
        stroke: white;
      }
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    padding-right: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .device-os-version {
    font-size: 1.2rem;
    opacity: 0.7;
    display: inline-block;
    margin-left: 0.7rem;
  }
}
.ant-select-dropdown-menu-item {
  position: relative;
  .ranks-filter-info {
    position: absolute;
    top: 0.8rem;
    right: 1rem;
  }
}
.ant-select-selection__rendered {
  .ranks-filter-info {
    display: none;
  }
}
