.ipm-modal-container {
  &.mobile {
    .ant-modal-content {
      top: 0 !important;
      height: fit-content !important;
      width: 100vw !important;
      max-width: 100vw !important;
    }

    .ant-modal-body {
      padding: 3rem 3rem 8rem 3rem !important;
      height: fit-content !important;
    }

    .ipm-preview-double-box-container {
      flex-wrap: wrap;
      gap: 0 !important;

      .ipm-preview-content-box {
        width: 100% !important;
      }
    }

    .ipm-assets-container,
    .ipm-ab-tests-container {
      .ant-col {
        display: flex !important;
        justify-content: center !important;
      }

      background: #f9f9fb !important;
      border-radius: 6px !important;
      padding: 16px 0 !important;
    }

    .slider-arrow img {
      width: 12px !important;
    }

    .variants-switch {
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-radio-button-wrapper {
        &:first-child {
          border-radius: 16px 0 0 16px;
        }

        &:last-child {
          border-radius: 0 16px 16px 0;
        }
      }
    }

    img.landscape {
      width: 45rem !important;
    }

    .mobile-top-left {
      display: flex;
      gap: 8px;
      align-items: center;

      .ipm-type {
        display: flex;
        padding: 2px 8px;
        align-items: flex-start;
        border-radius: 4px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
      }

      img {
        width: 24px;
        height: 24px;
      }

      h5 {
        color: #403b3b;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    .ipm-preview-title {
      margin: 2rem 0;
      color: #201b1b;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .slick-dots li {
      margin: 0;
    }

    .ipm-priority {
      flex-wrap: wrap;

      .ipm-ab-test-info {
        width: 80%;
      }

      .priority-high,
      .priority-medium,
      .priority-low {
        margin-left: 0 !important;
        align-self: flex-start !important;
        margin-top: 4px !important;
      }
    }
  }

  .ant-modal-content {
    border-radius: 1rem;

    .ant-modal-body {
      padding: 3rem;

      .ipm-insight-preview-container {
        .ipm-preview-left {
          &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            margin-left: 20px;
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }

          overflow-y: auto;

          .ipm-preview-left-top {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            .ipm-preview-date {
              color: #403b3b;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 190%; /* 22.8px */
              text-transform: capitalize;
              margin-left: auto;
              margin-right: 1.6rem;
            }

            img {
              width: 2.4rem;
              height: 2.4rem;
              border-radius: 0.4rem;
            }

            .release-name {
              color: #403b3b;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 190%; /* 22.8px */
              text-transform: capitalize;
            }

            .ipm-type {
              display: flex;
              padding: 4px 12px;
              align-items: flex-start;
              border-radius: 4px;
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
            }

            .ipm-preview-platform {
              color: #7d7d7d;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
              border-right: 1px solid #e0e0e0;
              padding-right: 8px;
            }

            .ipm-preview-platform-value {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
            }
          }

          .ipm-preview-title {
            margin-top: 2.5rem;
            color: #201b1b;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .ipm-priority {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2.4rem;

            .ipm-ab-test-info {
              display: flex;
              gap: 0.8rem;

              .ab-test-status {
                color: #017637;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 4px 12px;
                border-radius: 200px;
                background: #b5f0b4;
                text-transform: capitalize;

                &.abandoned {
                  color: #b11515;
                  background: #ffb0b0;
                }
              }

              .tracked-since {
                color: #403b3b;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 190%; /* 26.6px */
                text-transform: capitalize;

                span {
                  font-weight: 500;
                }
              }
            }

            .priority-high {
              margin-left: auto;
              color: #b11515;
              padding: 4px 16px;
              border-radius: 200px;
              background: #ffb0b0;
              text-transform: capitalize;
              margin-left: auto;
            }

            .priority-medium {
              margin-left: auto;
              color: #ff9411;
              padding: 4px 16px;
              border-radius: 200px;
              background: #ffdbb0;
              text-transform: capitalize;
            }

            .priority-low {
              margin-left: auto;
              color: #0e8a16;
              padding: 4px 16px;
              border-radius: 200px;
              background: #b0ffb0;
              text-transform: capitalize;
            }
          }

          .ipm-preview-list {
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 4px;

            &.s-mt {
              margin-top: 1rem;
            }

            .countries {
              display: flex;
              gap: 0.8rem;

              .country span {
                width: 2.8rem;
                height: 2.8rem;
                border: unset;
              }
            }

            .ipm-preview-list-head {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
              padding-right: 8px;
              border-right: 1px solid #e0e0e0;
              margin-right: 1rem;
            }

            .ipm-preview-list-item {
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
              padding: 4px 16px;
              border-radius: 200px;
              background: #eaeaea;
            }
          }

          .ipm-preview-double-box-container {
            display: flex;
            gap: 16px;

            .ipm-preview-content-box {
              width: 50%;
            }
          }

          .ipm-preview-content-box {
            margin-top: 32px;
            border: 1px solid #e5e5e5;
            border-radius: 8px;

            img {
              cursor: pointer;
            }

            .ipm-seen-in-all {
              color: #403b3b;
              padding: 4px 16px;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              text-transform: capitalize;
              border-radius: 200px;
              background: #ffdc24;
            }

            .ipm-preview-content-box-head {
              display: flex;
              height: 40px;
              padding: 16px;
              justify-content: space-between;
              align-items: center;
              border-radius: 8px 8px 0px 0px;
              background: #eaeaea;
              color: #403b3b;
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              text-transform: capitalize;
            }

            .ipm-preview-content-box-body {
              display: flex;
              gap: 8px;
              flex-wrap: wrap;
              padding: 24px 16px;
              color: #616065;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 190%; /* 26.6px */
              letter-spacing: 0.14px;
              max-height: 150px;
              overflow-y: auto;
              margin-bottom: 0;

              &::-webkit-scrollbar {
                -webkit-appearance: none;
                margin-left: 20px;
                width: 4px;
                height: 4px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.3);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
              }

              .ipm-preview-content-box-user-type-container {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                padding: 4px 16px;
                border-radius: 8px;
                background: #eaeaea;

                .ipm-preview-content-box-user-type-text {
                  color: #403b3b;
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  text-transform: capitalize;
                  padding-right: 10px;
                }

                .ipm-preview-content-box-user-type-icon-container {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }

        .ipm-preview-right {
          .ipm-preview-right-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;

            .ipm-preview-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              padding: 6px 8px;
              border-radius: 4px;
              border: 1px solid #e5e5e5;
              background: linear-gradient(0deg, #f5f5f5 0%, #f5f5f5 100%),
                #f5f5f5;
              min-width: 39px;
              min-height: 35px;

              &.right {
                margin-right: auto;
              }

              &.no-bg {
                background: none;
                border: none;
              }
            }
          }

          .ipm-ab-tests-container {
            .ipm-preview-asset-image {
              width: 25rem;
              min-height: 45rem;
              object-fit: contain;
              margin: auto;
              cursor: zoom-in;
              border: 1px solid #e0e0e0;
              border-radius: 0.8rem;

              &.landscape {
                width: 50rem;
                min-height: 20rem;
              }
            }

            .ipm-ab-test-control {
              div {
                color: #201b1b;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 190%; /* 26.6px */
                text-transform: capitalize;
                padding-bottom: 3.5rem;
              }
            }

            .ipm-variants {
              .variants-heading {
                color: #201b1b;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 190%; /* 26.6px */
                text-transform: capitalize;
              }

              .imp-variants-select {
                display: flex;
                gap: 0.8rem;
                margin-bottom: 0.8rem;

                .variant {
                  border-radius: 4px;
                  padding: 2px 6px;
                  background: #f1f1f1;
                  color: #505050;
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 190%; /* 26.6px */
                  text-transform: capitalize;
                  max-width: 100%;
                  overflow-x: auto;
                  cursor: pointer;

                  &.selected {
                    background: #e5eafc;
                  }
                }
              }
            }
          }

          .slick-slider {
            width: 25rem;
            min-height: 45rem;

            &.landscape {
              width: 50rem;
              min-height: 20rem;
            }
          }

          .slick-dots > li {
            display: none;
          }

          .slick-dots > li:nth-child(1),
          .slick-dots > li:nth-child(2),
          .slick-dots > li:nth-child(3),
          .slick-dots > li:nth-child(4) {
            display: inline-block;
          }

          .slick-dots::after {
            content: var(--li-count);
            display: inline-block;
            width: 26px;
            height: 20px;
            position: relative;
            top: 4px;
            padding: 0;
          }

          .slick-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
          }

          .slick-next .arrow-right img {
            rotate: 180deg;
          }

          .ipm-assets-container {
            .ipm-preview-asset-image {
              width: 25rem;
              min-height: 45rem;
              object-fit: contain;
              margin: auto;
              cursor: zoom-in;
              border: 1px solid #e0e0e0;
              border-radius: 0.8rem;

              &.landscape {
                width: 50rem;
                min-height: 20rem;
              }
            }

            .no-previous {
              max-width: 370px !important;
            }

            .ipm-assets-container-images {
              .ipm-preview-images-heading {
                color: #201b1b;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 190%; /* 26.6px */
                text-transform: capitalize;
              }
            }
          }

          .imp-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50vh;
          }

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            margin-left: 20px;
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}

.ant-tooltip {
  z-index: 99999 !important;
}

.mobile-actions-drawer {
  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
  }

  .actions-container {
    display: flex;
    flex-direction: column;

    .action {
      display: flex;
      gap: 8px;
      height: 48px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
