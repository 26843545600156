@import "../../../mixins";

.mvp-container {
  height: 376px;
  overflow-y: auto;

  @include custom-scrollbar;

  .mvp-container-body {
    height: 100%;
  }
}
