@import '../../sass-config/abstracts/variables.scss';

.dash-discoveries-page {
  min-height: 70rem;
  position: relative;

  .center-of-row {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .all-dis-loader {
    min-height: 14rem;
    position: relative;
  }

  .reset-all-wrap {
    position: absolute;
    right: 3.2rem;
    top: 2rem;
  }

  .filters {
    margin: 4rem 0rem 5rem;
    background-color: #ffffff;
    z-index: 3;

    .filter-btn-new {
      &:nth-child(2) {
        width: 16rem !important;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
    }

    &.fixed {
      position: sticky;
      left: 0;
      background-color: white;
      box-shadow: 0 1rem 1.2rem 0 rgba(0, 0, 0, 0.07);
      height: 6rem;
      top: 6rem;
      margin: 0;

      & > div {
        max-width: 1450px;
        width: 100%;
        margin: 0 auto;

        height: 100%;
      }

      @media (max-width: 1250px) {
        & > div {
          padding: 0 4.6rem !important;
        }
      }
    }

    .sep {
      width: 0.3rem;
      border-right: 1px solid #eceef5;
      width: 0.3rem;
      height: 3.2rem;
      position: relative;
      display: inline-block;
      margin: auto 1rem;
    }
  }

  .hr-row-2 {
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    border-bottom: 1px solid #e8e8e8;
    top: 19rem;
  }

  .dummy-for-scroll {
    height: 0rem;
  }
}

.discoveries-list {
  margin-top: 2rem;
  padding-bottom: 6rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 2200px) {
  .discoveries-list {
    grid-template-columns: repeat(5, 1fr);
  }
}

.filter-select-new {
  color: #222322;
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 600;
  height: auto;
  position: relative;
  width: 16rem;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  text-align: left;
  padding: 1rem;

  &.ant-select-disabled {
    opacity: 0.2;
  }

  .ant-select-selection {
    border: none;
    background-color: transparent;
  }

  .ant-select-arrow {
    transition: all 0.2s ease-in-out;
    right: 1.5rem;
    top: 2.5rem;
    img {
      width: 2.2rem;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }

  .ant-select-selection-item {
    font-weight: 600;
  }

  &:hover {
    border-radius: 3px;
    color: #222222 !important;
    //background-color: #00000006 !important;
  }

  .ant-select-selection:focus,
  .ant-select-selection:hover {
    box-shadow: none;
  }
}

.filter-by-content {
  margin: 3.6rem auto;
  width: 50rem;

  height: 3.6rem;

  .ant-input-affix-wrapper {
    height: 3.6rem;
    border-radius: 1.8rem;

    .ant-input {
      background-color: inherit;
      border: none;

      &::placeholder {
        color: #00000040;
      }
    }
  }
}

.ant-btn.filter-btn-new {
  color: #222322;
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 600;
  height: auto;
  position: relative;
  width: 19rem;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  text-align: left;
  padding: 1rem;

  &:hover {
    border-radius: 3px;
    color: #222222 !important;
    background-color: #00000006 !important;
  }

  &:after {
    background: url('../../assets/images/icons/chevron_black.svg');
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    top: 0.9rem !important;
    right: 1rem !important;
    transition: all 0.2s ease-in-out;
    animation-fill-mode: none !important;
    animation: none !important;
    left: auto !important;
    opacity: 1;
    box-shadow: none !important;
    background-size: contain;
  }

  &.ant-popover-open {
    background-color: #00000006 !important;

    &::after {
      transform: rotate(180deg);
    }
  }

  .filter-count {
    margin-left: 0.8rem;
    background-color: black;
    color: white;
    border-radius: 2px;
    font-family: Roboto;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.6rem;
    padding: 0rem;

    &-padding {
      padding: 0.15rem 0.4rem;
    }
  }

  &.black-btn-filter {
    background-color: #181818;
    color: white;
    border-radius: 0;
    width: 13rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;

    .filter-count {
      background-color: white;
      color: black;
    }

    &:after {
      background: url('../../assets/images/icons/chevron_white.svg');
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      top: 1.4rem !important;
      /* right: 1rem !important; */
      background-size: contain;
    }

    &:hover {
      border-radius: 0;
      background-color: rgba(244, 244, 244, 0.1) !important;
      color: white !important;
    }
  }
}

.popover-appdrawer {
  padding: 0;
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.08);
  max-height: 50rem;
  width: 52rem;
  color: rgba(0, 0, 0, 0.65);

  .ant-popover-inner-content {
    padding: 0;

    .app-drawer {
      margin: 0;
      height: auto;

      .app-drawer__apps {
        padding: 0;

        .app-drawer__apps-search-input {
          margin-top: 0.8rem;
          background-color: white;
          z-index: 22;

          .ant-input-outlined,
          .ant-input-outlined:focus {
            height: 4rem;
            padding: 0.8rem 0.8rem 0.8rem 1.6rem;
            border: 1px solid $color-border-layout;
            border-radius: 1.6rem;
          }
        }
      }

      .apps-grid {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        gap: 1rem;
        margin-top: 0.8rem;
      }
    }
  }

  .app-drawer-wrap {
    margin: 0rem;
  }

  .app-drawer-wrap .app-drawer__apps {
    transition: height 0.3s ease;
    padding: 0rem;
    height: 37rem;

    .android-apps,
    .ios-apps,
    .marketing-apps {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      padding: 0rem 1rem;
      grid-column-gap: 2rem;
    }
  }

  .app-drawer__apps-app {
    border: 1px solid $color-border-layout;
    border-radius: 0.8rem;
    padding: 0.8rem;
  }

  .app-drawer-wrap h4 {
    padding: 0rem 2rem;
  }
}

.filter-wrap.all-discoveries {
  border-radius: 2.4rem;
  padding: 2.4rem;

  .ant-popover-inner {
    border-radius: 2.4rem;
    padding: 0;

    .ant-popover-inner-content {
      border-radius: 2.4rem;
    }
  }

  &.fixed {
    position: fixed;
    top: 120px !important;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  h3 {
    padding: 0rem 2rem;
    font-size: 1.5rem;
    font-weight: bold;

    .ant-checkbox-wrapper {
      margin-right: 1rem;
    }
  }

  .filters-content.inner {
    padding-left: 4rem;
  }

  .ant-popover-inner-content {
    padding: 0rem;
  }

  .filters-content {
    padding: 0rem 2rem;
  }

  .items-selected {
    font-size: 1.4rem;
    position: sticky;
    top: 0rem;
    background-color: white;
    z-index: 2;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.static {
      position: relative;
    }

    &-warning {
      display: flex;
      flex-direction: row;
      font-weight: 500;
      font-family: Roboto;
      font-size: 1.4rem;

      &-count {
        color: #000000;
      }

      &-max {
        color: $color-subtitle;
        margin-left: 1rem;
      }
    }

    .all {
      position: absolute;
      top: 1.2rem;
      right: 1rem;
    }

    &-values {
      width: 28rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: #9e9e9e;
      font-weight: 300;
    }

    .clear {
      text-decoration: underline;
      font-size: 1.4rem;
      color: #696f8c;
      cursor: pointer;

      &:hover {
        color: $color-black;
      }
    }

    .button-and-radiobuttons {
      position: absolute;
      top: 1.2rem;
      right: 1rem;

      .ant-radio-checked .ant-radio-inner {
        border: 1px solid black;
        border-radius: 20px;
        color: white;
      }

      .ant-radio-inner::after {
        background-color: #000000;
      }
    }
  }

  .ant-checkbox-wrapper-disabled {
    opacity: 0.35;
  }
}

.all-discoveries-tags {
  width: 65rem;

  .items-selected {
    border-bottom: none !important;
  }

  h3 {
    padding: 0rem !important;
    margin: 0rem !important;
    margin-bottom: 1rem !important;
  }

  .tags__tags-tag-element {
    font-size: 1.1rem;
    font-weight: 400;
    height: 2rem;
  }

  .tags-header-wrapper {
    display: flex;
    flex-direction: row;
    font-family: Roboto;

    .max-tags {
      margin-left: 1rem;
      color: #fc0e0e;
    }
  }

  .filters-popup.tags {
    padding: 2rem 5rem;

    .tags__tags-tags-wrapper {
      height: 25rem;
      overflow: auto;
      align-content: flex-start;

      &::-webkit-scrollbar {
        //-webkit-appearance: none;
        width: 7px;
        background-color: rgb(231, 231, 231);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        width: 3px;
        background-color: rgba(121, 121, 121, 0.856);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.all-discoveries-apps {
  h4 {
    padding-left: 2.5rem !important;
  }
}

.all-discoveries {
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.08);
}

.all-discoveries-state {
  width: 53rem;

  .filters-content {
    padding: 2rem !important;
  }
}

.all-discoveries-type {
  width: 46rem;
  height: 37rem;
  overflow-y: scroll;
}

.pagination {
  margin: 5rem auto 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination,
.sub-pagination {
  .label {
    color: #6c6c6c;
    font-family: Roboto;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.6rem;
    margin-right: 2rem;
  }

  .ant-pagination-item {
    border-color: transparent;
  }

  .ant-pagination-item-active {
    background-color: black;
    color: white;
    border-color: black;

    a {
      color: white;
    }
  }

  .ant-pagination-item:focus:not(.ant-pagination-item-active),
  .ant-pagination-item:hover:not(.ant-pagination-item-active) {
    border: 0.55px solid #e8e8e8;
    border-radius: 2.2px;
    background-color: #ffffff;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);

    a {
      color: black;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    a {
      padding: 0rem 1.1rem;
      border-color: #e8e8e8;
      font-size: 1.5rem;
    }
  }

  .ant-pagination-prev:hover:not(.ant-pagination-disabled),
  .ant-pagination-next:hover:not(.ant-pagination-disabled) {
    a {
      color: black;
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.05);
      border-color: #e8e8e8;
    }
  }

  .ant-select-selection {
    border-color: #e8e8e8;
  }

  .ant-select-selection__rendered {
    margin-right: 3.5rem;
  }

  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: black;
  }
}

.all-discoveries-time .ant-select-selection {
  border: 1px solid black !important;
  border-radius: 5px;

  i.anticon.anticon-down.ant-select-arrow-icon {
    color: black;
    font-size: 1.3rem;
  }

  .filter-h1 {
    font-family: 'Roboto Slab';
  }
}

.wrap-time-filter {
  height: 40rem;

  display: flex;
  flex-direction: row;

  .left-side {
    width: 18rem;

    h2 {
      color: #000000;
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.8rem;
      margin: 2rem 2.8rem 0;
    }

    h4 {
      color: #c3c3c3;
      font-family: Roboto;
      font-size: 1rem;
      letter-spacing: 0;
      line-height: 1.1rem;
      margin: 2rem 2.8rem 1rem;
      padding-left: 0;
    }

    .range-value {
      width: 13rem;
      height: 3.4rem;
      color: #000000;
      font-family: Roboto;
      font-size: 1.3rem;
      line-height: 3.4rem;
      margin-left: 2rem;
      padding-left: 0.8rem;
      cursor: pointer;

      &.active {
        background-color: black;
        color: white;
        border-radius: 3px;
      }
    }
  }

  .custom-range {
    .anticon {
      margin-left: 1rem;
    }
  }

  .right-side {
    width: 59rem;

    &.hidden {
      display: none;
    }
  }

  .date-picker-wrapper {
    position: relative;
  }

  .manage-buttons {
    height: 6.5rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem 1.4rem 0;

    .month-year-select {
      .ant-select {
        width: 8.5rem;

        &:nth-child(1) {
          margin-right: 1rem;
        }
      }
    }
  }

  .manual-inputs-dates {
    display: flex;
    margin-bottom: 2rem;
    margin-top: 4rem;
    position: relative;
    z-index: 33;

    .manual-input-date {
      flex-basis: 50%;
    }

    .man-input-label {
      color: #9fa5ad;
      font-family: Roboto;
      font-size: 12px;
      padding-left: 1rem;
    }

    .man-input-field {
      padding-left: 1rem;
      color: rgba(0, 0, 0, 0.82);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    .man-input {
      height: 2.6rem;
      width: 12rem;
      border: 2px solid #000000;
      border-radius: 3px;
    }
  }

  //new antd 4
  .date-picker-wrapper {
    top: -5rem;
  }
}

.filter-select-new-dropdown.fixed {
  position: fixed;
  top: 120px !important;
}

.wf-datepicker {
  display: flex;
  width: 100%;

  & > div {
    flex-basis: 50%;
  }

  .ant-picker {
    visibility: hidden;
  }

  .ant-picker-dropdown {
    .ant-picker-panel-container {
      box-shadow: none;
    }
  }

  .ant-picker-footer {
    display: none;
  }
}

.filters-popup.tags {
  .ant-select-selection-item[title='critical insight'] {
    color: #f4442e;
    border: 1px solid #f4442e !important;
    .ant-select-selection-item-remove svg {
      fill: red;
    }
  }
}
