@import '../../sass-config/abstracts/variables.scss';

.time-filter-wrapper {
  display: flex;
  flex-direction: column;

  .ant-picker-header {
    padding-left: 0;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0 0 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
    color: #656565;
    padding: 0rem 0.8rem;
    margin-bottom: 1.6rem;
  }

  .content-wrapper {
    display: flex;
    gap: 1.6rem;
    width: max-content;
  }

  .left-side {
    border-right: 0.1rem solid $color-border-layout;
    padding-right: 1.6rem;

    .range-value.active {
      background-color: #e5eafc;
      color: #000;
    }

    .range-value {
      padding: 0.4rem 0.8rem;
      font-size: 1.4rem;
      border-radius: 0.4rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }

  .right-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: max-content;

    .manual-inputs-dates {
      .any-picker {
        padding: 0;
      }

      .ant-input-affix-wrapper > input.ant-input {
        font-size: 1.4rem;
      }

      .ant-picker-input {
        border: 1px solid $color-border-layout;
        background-color: white;
        width: 16.2rem;
        border-radius: 1.2rem;
        font-size: 1.4rem;
        color: #696f8c;
        padding: 0.8rem 1.6rem 0.8rem 1.6rem;
        display: flex;
        align-items: center;

        &:first-child {
          margin-right: 7rem;
        }

        &:first-child:before {
          content: 'From: ';
          margin-right: 0.8rem;
          pointer-events: none;
        }

        &:nth-child(3):before {
          content: 'To: ';
          margin-right: 0.8rem;
          pointer-events: none;
        }

        *::placeholder {
          color: #696f8c;
          font-size: 1.4rem;
        }
      }

      .today {
        cursor: pointer;
        align-self: flex-end;
        color: #696f8c;
      }

      .ant-picker-suffix {
        display: none;
      }
    }

    .date-pickers-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .date-pickers-inputs {
        display: flex;
        gap: 0.8rem;
      }

      .ant-picker-range {
        padding: 0;
      }
    }
  }

  .actions {
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
    align-self: flex-end;
    margin-right: 10 button {
      border-radius: 1.2rem;
      padding: 0.8rem 2.8rem;
    }

    .ant-btn-primary {
      background-color: #0066ff;
      border-color: #0066ff;
    }
  }
}

.wf-ant-range-picker-popup {
  .ant-picker-panel-container {
    box-shadow: none;
  }

  tr {
    .ant-picker-cell::before {
      height: 38px;
    }

    :first-child.ant-picker-cell::before {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    :last-child.ant-picker-cell::before {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .ant-picker-cell-in-range .ant-picker-cell-inner {
    color: #0066ff;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before {
    background-color: #e9f1ff;
    color: #0066ff;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled),
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) {
    height: 40px;
    background-color: #0066ff;

    &::before {
      background-color: #0066ff;
    }

    .ant-picker-cell-inner {
      background: none;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
