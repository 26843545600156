.uj-filter-versions {
  .uj-versions {
    background-color: #00000006 !important;
  }
}

.uj-version-select-container {
  min-width: 415px;

  .uj-version-select-count {
    padding: 16px;
    border-bottom: 1px solid #d4d4d4;
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
  }

  .uj-versions-select-title {
    padding: 16px;
    border-bottom: 1px solid #d4d4d4;
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }

  .uj-versions-select-versions {
    max-height: 350px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .uj-versions-select-option {
      padding: 8px 16px;
      border-radius: 4px;
      border: 1px solid #d4d4d4;

      .uj-checkbox {
        &:last-child {
          color: #000;
          leading-trim: both;
          text-edge: cap;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */

          .uj-date {
            margin-left: 8px;
            color: #656565;
          }
        }
      }
    }
  }
}
