.text-heading {
    h2 {
        color: #0b3252;
        font-size: 28px;
        font-family: 'Nexa Heavy';
        text-transform: uppercase;
        letter-spacing: 0.28px;
    }
    &__underline {
        height: 5px;
        background-color: #2196f3;
        width: 95px;
    }
}