@import '../../../mixins.scss';

.promo-days-of-week {
  .promo-days-chart-container {
    height: 46rem;
  }

  .app-icon-label {
    width: 3.2rem;
    height: 3.2rem;
  }
}
