@mixin dot () {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background: #18B663;
  margin-right: 0.5rem;
}

.competitors-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 8rem;

  .competitor-card {
    display: flex;
    flex-direction: row;
    margin: 2rem 0rem;
    width: 30rem;
    &.not-monitored {
      cursor: default;
      opacity: 0.4;
    }
    .icon {
      width: 4.6rem;
      height: 4.6rem;
      margin-right: 1rem;
    }
    .monitored-app {
      display: flex;
      flex-direction: column;
      &.not-monitored {
        cursor: default;
      }
      .monitored {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        color: #6a6a6a;
        font-family: Roboto;
        font-size: 1.15rem;
        letter-spacing: 0.19px;
        line-height: 1.4rem;
        .monitored-dot {
          @include dot();
          &.red-dot {
            background: #E53935;
          }
        }

      }
      .title {
        color: #1A1A1A;
        font-family: Roboto;
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.33px;
        line-height: 3.2rem;
        margin: 0rem;
        width: 18rem;
      }
    }
    .drop-down {
      position: relative;
      &__select {

        &-arrow {
          transition: all 0.1s;
          margin-left: 0.7rem;
          width: 3rem;
          cursor: pointer;
          &.open {
            transform-origin: 50%;
            transform: rotate(180deg);
          }
        }
      }
      .tracked-personas-panel {
        cursor: default;
        position: relative;
        left: -23.5rem;
        top: 3.5rem;
        width: 23.5rem;
        height: auto;
        transition: height 0.5s cubic-bezier(0.5, 0.25, 0, 1);
        .tracked-personas {
          text-transform: uppercase;
          opacity: 0.5;
          font-family: Roboto;
          font-size: 0.9rem;
          font-weight: 500;
          letter-spacing: 0.1px;
          line-height: 1.8rem;
        }

          ul {
            li {
              display: flex;
              align-items: center;
              list-style-type: none;
              font-family: Roboto;
              font-size: 1.4rem;
              letter-spacing: 0.15px;
              line-height: 1.8rem;
              margin: 1rem 0rem;
              .dot {
                @include dot();
                &.red-dot {
                  background: #E53935;
                }
              }
              .gap {
                margin: 0rem 0.5rem;
              }
              .info-icon {
                margin-left: 0.5rem;
                color: #b7b7b7;
              }
              &.gray {
                color: #c5c5c5;
              }
            }
          }

        .locations {
          margin: 2rem 0rem 0.7rem 0rem;
          text-transform: uppercase;
          opacity: 0.5;
          font-family: Roboto;
          font-size: 0.9rem;
          font-weight: 500;
          letter-spacing: 0.1px;
          line-height: 1.8rem;
        }
        .flags-container {
          display: grid;
          grid-template-columns: repeat(20, auto);
          grid-column-gap: 1rem;

          .flag-icon {
            width: 3.4rem;
            height: 2.64rem;
            border: 0px solid #e8e8e8;

          }
        }
      }
    }
  }
}