.loader-button {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 5px solid #FFFEEE;
    border-radius: 100%;
    border-top: 5px solid transparent;
    animation: load-animate infinite linear 1s;

    &-share{
        border-color: white;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        margin-left: 1rem;
        top: 0.25rem;
        position: relative;
    }
}

