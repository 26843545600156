@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
