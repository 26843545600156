@media only screen and (max-width: 1440px) and (min-width: 1280px) {
  .dashboard__container {
    grid-template-columns: 27rem auto !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 600px) {
  .dashboard__container {
    .dashboard__main {
      max-width: 100%;

      > :first-child {
        padding: 2.4rem;
      }

      .mo-container {
        min-width: unset;
      }
    }

    .right-space {
      display: none;
    }
  }
}

@media only screen and (max-width: 1251px) {
  html {
    font-size: 60.5%;
  }
}

@media only screen and (max-width: 1171px) {
  html {
    font-size: 57.5%;
  }
}

@media only screen and (max-width: 1071px) {
  html {
    font-size: 55.5%;
  }
}

@media only screen and (max-width: 1025px) {
  html {
    font-size: 53%;
    overflow-x: hidden;
  }

  .header__search-bar {
    padding-left: 1rem !important;
  }
  .view .view-right-side {
    margin-left: 7rem !important;
  }
  .wrap-time-filter {
    height: 38rem !important;
  }
  .nav__fixed .ant-menu-submenu i.ant-menu-submenu-arrow {
    right: 1px !important;
    transform: scale(0.8);
  }
  .main__loader.global .loader {
    left: calc(50% + 0rem);
    top: 53rem;
  }
  .nav__fixed .ant-menu-item {
    padding-left: 6px !important;
  }
  .stacked-bar-container {
    height: 62rem !important;
  }
  .stacked-bar-container .stacked-bar-wrap {
    height: 46.5rem !important;
  }
  .header__wrap .header-logo .sidebar-wrap.normal:hover {
    width: 100%;
    border-right: none;
  }
  .performance .sticky-header-wrapper .sticky-header-cont {
    padding: 0;
  }
  .performance .sticky-header-wrapper .sticky-header-cont > div {
    flex-basis: 50% !important;
  }
  .competitors-container {
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 5rem;
  }
  .product-bench-ranks .pr-b-section .grid-events .ev-section {
    width: 22rem;
  }
}

@media only screen and (max-width: 975px) {
  html {
    font-size: 49%;
  }
}

@media only screen and (max-width: 925px) {
  html {
    font-size: 47%;
  }

  .product-bench-ranks .pr-b-section .grid-events .ev-section {
    width: 21rem;
  }
}

@media only screen and (max-width: 875px) {
  html {
    font-size: 45.5%;
  }
  .product-bench-ranks .pr-b-section .grid-events {
    grid-template-columns: repeat(1, 1fr);
    & > a {
      margin: auto;
    }
  }
}

@media only screen and (max-width: 821px) {
  html {
    font-size: 44%;
    overflow-x: hidden;
  }

  .header__search-bar {
    padding-left: 1rem !important;
  }
  .view .view-right-side {
    margin-left: 7rem !important;
  }
  .search-auto-complete {
    position: relative;
    top: -0.4rem;
  }
  .wrap-time-filter {
    height: 44rem !important;
  }
  .sneak-peak-modal__body .view-insight-btn {
    max-width: 24rem;
    line-height: 2rem;
  }
  .main__loader.global .loader {
    left: calc(50% + 0rem);
    top: 45rem;
  }
  .nav__fixed .ant-menu-item {
    padding-left: 5px !important;
  }
  .stacked-bar-container {
    height: 62rem !important;
  }
  .stacked-bar-container .stacked-bar-wrap {
    height: 46.5rem !important;
  }
  .header__wrap .header-logo .sidebar-wrap.normal:hover {
    width: 100%;
    border-right: none;
  }
  .header__wrap .header-logo .sidebar-wrap.normal:hover {
    width: 100%;
    border-right: none;
  }
  .performance .sticky-header-wrapper .sticky-header-cont {
    padding: 0;
  }
  .competitors-container {
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 5rem;
  }
}
@media only screen and (max-width: 1500px) {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0rem !important;
  }
}

@media screen and (max-width: 750px) {
  .dashboard__main {
    min-width: 90vw;
  }

  .view__experiment-tabs {
    flex-wrap: wrap;

    .variants-images {
      margin-left: 0 !important;
    }
  }
}
