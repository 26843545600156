@import '../../sass-config/abstracts/variables.scss';

.app-drawer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.5, 0.25, 0, 1);
  background-color: #ffffff;
  height: 17.5rem;
  padding: 1rem;

  .app-drawer__apps {
    &-title {
      display: flex;
      align-items: center;

      &-main {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }

  .first-campains-app {
    color: #6c6c6c;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0.12px;
  }

  .marketing-app {
    color: #000000;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.18px;
  }

  &.opened {
    min-height: 52rem;
    max-height: 75rem;
    height: auto;
    .down-arrow {
      transform: rotate(180deg);
    }
    .down-arrow-wrap {
      background-color: black;
    }
  }

  &__current-app {
    height: 17.5rem;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: white;
    margin-bottom: 1.5rem;
    position: relative;
    align-items: end;
    border: 1px solid #f1f1f1;
    padding: 2.5rem 3.7rem;

    .app-drawer__current-app-shrink {
      flex: 1 1 auto;
    }

    .app-drawer__current-app-data {
      width: 64rem;
      display: flex;
      flex-wrap: wrap;
      .data-cell {
        flex-basis: 25%;
        margin-bottom: 2rem;
        h4 {
          color: #9fa5ad;
          font-size: 1.25rem;
        }
        .data-cell-value {
          font-size: 1.4rem;
          color: #3e3e3e;
        }
      }
    }

    .down-arrow-wrap {
      position: absolute;
      height: 100%;
      width: 3rem;
      background-color: #161a1f;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      top: -0.1rem;
      right: 0;
      border-radius: 0 5px 5px 0;
      .down-arrow {
        transition: transform 0.5s cubic-bezier(0.5, 0.25, 0, 1);
        position: absolute;
      }
    }

    .platform {
      color: #1a1a1a;
      font-size: 1.2rem;
      margin: 0.4rem 0rem;

      position: relative;
      img {
        width: 1.7rem;
        margin-right: 0.7rem;
      }
      .app-platform-android:after {
        background-image: url(../../assets/images/icons/playstore_icon.png);
      }

      .app-platform-ios:after {
        background-image: url(../../assets/images/icons/appstore_icon.png);
      }

      .app-platform-web:after {
        background-image: url(../../assets/images/icons/webapp.svg);
      }

      .app-platform-windows:after {
        background-image: url('../../assets/images/new_design/icons/dark-win-ic.svg');
      }

      .app-platform-chrome:after {
        background-image: url('../../assets/images/new_design/icons/dark-chrome-ic.svg');
      }

      &::after {
        content: '';

        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        background-size: contain;
        top: 0rem;
        left: -2rem;
        z-index: 2;
      }
    }
    .img-wrap {
      position: relative;
    }
    &-title {
      margin-left: 3rem;
      font-weight: 600;
      font-size: 2.2rem;
      position: relative;
      color: #1a1a1a;

      &-package-name {
        font-weight: 200;
        font-size: 1.4rem;
      }
    }
    &-icon {
      width: 11.5rem;
      height: 11.5rem;
      border-radius: 1.8rem;
    }
  }
  .performance-app-drawer__apps-search-input input {
    margin-left: 0rem !important;
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    left: 0;
  }
  &__apps {
    padding: 1rem 3.7rem 2.5rem;
    background: white;
    max-height: 49rem;
    overflow-y: scroll;
    .ant-input-affix-wrapper {
      background-color: white !important;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    h4 {
      margin: 2rem 0;
      display: flex;
      img {
        margin-right: 1rem;
        width: 1.3rem;
      }
      .count {
        display: inline-block;
        margin-left: 0.4rem;
        color: #676767;
        font-weight: 200;
      }
      .tail {
        flex-grow: 1;
        border-bottom: 1px dashed #f1f1f1;
        align-self: center;
        margin-left: 1rem;
      }
    }

    .android-apps,
    .ios-apps,
    .web-apps {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }

    .img-wrap {
      position: relative;
      margin-left: 0.8rem;
    }
    &-app {
      user-select: none;
      cursor: pointer;
      display: flex;
      flex-shrink: 2;
      align-items: center;
      padding: 0.5rem 1rem;
      &.chosen {
        border-left: 2px solid black;
        background-color: #efefef;
      }
      &.bw {
        filter: grayscale(100%);
      }
      &-icon {
        width: 32px;
        height: 32px;
        border-radius: 4px;
      }
      &-title {
        margin-left: 0.8rem;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
        color: black;
        font-weight: 500;
      }
    }
  }

  &.type-marketing {
    margin-top: 2rem;
    margin-bottom: 1rem;
    overflow: hidden;
    transition: height 0.5s cubic-bezier(0.5, 0.25, 0, 1);

    width: 44.8rem;
    border: 1px solid #f1f1f1;
    border-radius: 0 0 2px 2px;
    background-color: #ffffff;
    padding: 1rem;
    margin-top: 5rem;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
    max-height: 9rem;
    height: auto;

    &.opened {
      max-height: 80rem;
    }
    &:hover {
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
    }

    .app-drawer__apps {
      display: block;
      padding: 0rem 1rem;
      .apps-grid {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
      }
    }
    img.down-arrow {
      top: 1.5rem;
      right: 1rem;
    }
    .app-drawer__current-app-title//, .app-drawer__apps-app-title
    {
      width: 83%;
      color: black;
      font-family: Roboto;
      font-size: 1.8rem;
      font-weight: 300;
      .img-wrap {
        width: 5.3rem;
        height: 5.3rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .down-arrow {
      transition: transform 0.5s cubic-bezier(0.5, 0.25, 0, 1);
      position: absolute;
    }
    &.opened {
      .down-arrow {
        transform: rotate(180deg);
      }
    }

    .app-drawer__current-app {
      height: 64px;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: white;
      margin-bottom: 1.5rem;
      position: relative;
      border: none;
      padding: inherit;

      .img-wrap {
        position: relative;
      }
      .marketing-app-developer {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #a0a5ac;
        span {
          color: #6c6c6c;
        }
      }
      &-title {
        margin-left: 2rem;
        font-weight: 600;
        font-size: 2rem;
        position: relative;
        &-package-name {
          font-weight: 200;
          font-size: 1.4rem;
        }
      }
      &-icon {
        width: 6.4rem;
        height: 6.4rem;
        border-radius: 0.4rem;
      }
    }

    &__apps {
      background: white;
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .img-wrap {
        position: relative;
      }
      &-app {
        user-select: none;
        cursor: pointer;
        margin: 0.5rem 0;
        display: flex;
        flex-shrink: 2;
        align-items: center;
        &.bw {
          filter: grayscale(100%);
        }
        &-icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
        }
        &-title {
          margin-left: 1rem;
        }
      }
    }
  }
}
.performance-app-drawer-wrap {
  .performance-app-drawer__current-app-details {
    font-family: 'Roboto';
    display: flex;
    height: 5rem;
    margin-bottom: 0.5rem;
    & > div {
      flex: 0 0 28%;
      h5 {
        color: #9fa5ad;
        font-size: 1.2rem;
      }
      div {
        color: #181818;
        font-size: 1.3rem;
      }
      span.app-status-value {
        padding: 0.1rem 0.8rem;
        &.on {
          background-color: #f6ffed;
          .ant-badge-status-text {
            color: #389e0d;
          }
        }
        &.off {
          background-color: #fff1f0;
          .ant-badge-status-text {
            color: #f5222d;
          }
        }
      }
    }
  }

  .performance-app-drawer {
    border: 1px solid #f1f1f1;
    border-radius: 0 0 2px 2px;
    background-color: #ffffff;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem;
    //height: 8.8rem;
    transition: height 0.3s ease;
    border-radius: 4px;
    .down-arrow {
      transition: transform 0.5s cubic-bezier(0.5, 0.25, 0, 1);
    }
    .img-wrap {
      position: relative;
    }
    &.opened {
      height: 43rem;
      .down-arrow {
        transform: rotate(180deg);
      }
    }

    &__current-app {
      min-height: 64px;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: white;
      position: relative;
      .img-wrap {
        &::after {
          z-index: 36;
        }
      }

      &-remove {
        position: absolute;
        top: -9px;
        left: -13px;
      }
      &-title {
        width: 80%;
        margin-left: 2rem;
        font-weight: 600;
        font-size: 2rem;
        .clear-state {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          text-decoration-line: underline;
          position: absolute;
          right: -0.2rem;
          top: -0.5rem;
          color: #000000;
          cursor: pointer;
        }
        & > img {
          position: absolute;
          top: 1.5rem;
          right: 2rem !important;
        }
        &__with-tooltip {
          display: flex;
        }
        & > span {
          letter-spacing: 0.1rem;
          font-weight: 300;
          font-size: 1.5rem;
          color: $color-blueful;
          cursor: pointer;
        }
        &-platform {
          font-weight: 200;
          font-size: 1.4rem;
        }
      }
      &-icon {
        width: 6.4rem;
        height: 6.4rem;
        border-radius: 0.4rem;
      }
      &-phone-details {
        font-weight: 200;
        font-size: 1.4rem;
        display: flex;
        flex-direction: column;
        width: 250px;
      }
    }

    &__apps {
      .img-wrap {
        position: relative;
        &:after {
          width: 1.5rem;
          height: 1.5rem;
          right: -0.5rem;
          bottom: -0.7rem;
        }
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      ::-webkit-scrollbar-track:window-inactive {
        background: rgba(255, 0, 0, 0.4);
      }

      &-search-input .ant-input-affix-wrapper:hover input {
        border-color: black !important;
      }

      &-search-input input {
        border: none;
        outline: none;
        height: 5rem;
        font-size: 1.5rem;
        margin-left: 1rem;
        border-radius: 0;
        &:hover {
          border-color: #edebeb;
        }
        &:focus {
          border-color: black;
          border-right-width: 0;
          outline: 0;
          box-shadow: none;
        }
      }

      h4 {
        margin: 2rem 0;
        display: flex;
        padding-left: 2rem;
        img {
          margin-right: 1rem;
        }
        .count {
          display: inline-block;
          margin-left: 0.4rem;
          color: #676767;
          font-weight: 200;
        }
        .tail {
          flex-grow: 1;
          border-bottom: 1px dashed #f1f1f1;
          align-self: center;
          margin-left: 1rem;
        }
      }
      .android-apps {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      .ios-apps {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      transition: height 0.3s ease;
      padding: 0rem;
      height: 0;
      &.opened {
        height: 35rem;
        padding: 4rem;
        padding-top: 0rem;
        .performance-app-drawer__apps-search-input {
          position: sticky;
          top: 0rem;
          background-color: white;
          z-index: 30;
          padding-top: 2rem;
          .ant-input-affix-wrapper {
            background-color: white;
            border-bottom: 1px solid rgb(237, 235, 235);
          }
        }
      }
      padding: 0 1rem;
      overflow-y: scroll;
      overflow-x: visible;
      &-searchbox {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(26, 21, 21, 0.08);
        &-input {
          margin: 1rem;
        }
      }
      &-app {
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-shrink: 2;
        align-items: center;
        padding: 1rem 2rem;
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
          background-color: rgba(0, 0, 0, 0.03);
          color: black;
          font-weight: 500;
        }
        &.bw {
          filter: grayscale(100%);
        }
        &-icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
        }
        &-title {
          margin-left: 1rem;
        }
      }
    }
  }
}
