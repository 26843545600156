.table {
    overflow-x: auto;

    &__title-total{
        display: flex;
        img{
          margin-left: 1rem;
          position: relative;
        }
        span{
            @include respond(phone){
                width: max-content;
            }
        }
    }
    &__status {
        text-transform: capitalize !important;
    }

    &__body{

        &-row {

        &-date-none{
           margin-left: 4.5rem; 
        }

        &-status{
            text-transform: capitalize !important;
        }

        &-image{
            height: 7rem;
            width: 7rem;
        }

          &-total{
              display: flex;
              align-items: center;
              font-size: 1.5rem;

              img{
                  height: 1.7rem;
                  width: 1.7rem;
                  margin-right: 1rem;
                }

                span{
                    text-transform: capitalize;
              }
             
          }
        }
    }
}

.ant-table table {
    @include respond(tab-port) {
        min-width: 900px;
    }
    
    @include respond(phone){
        min-width: 800px;
    }
}
.ant-table-body {
    overflow-x: auto;
    @include firefox{
        
        @include respond(tab-land){
            width: 90vw;   
         }
    }

}

.ant-table-thead {
    tr {
        th {
            background-color: $color-white !important;
            font-family: $font-primary;
            font-size: 1.45rem;
        }
    }
}

.ant-table-column-sorters{
    display: flex;
    align-items: center;
}

.ant-table-column-sorter{
    position: relative !important;
    margin: 0 0 0 1rem !important;
}

.ant-table-tbody {
    tr {
        td {
            a {
                text-transform: none !important;
                &:visited{
                    color: $color-visited-grey;
                }
            }
        }
        td.ant-table-column-sort{
            background: #fff;
        }
    }
}

.ant-table-thead {
    tr {
        th {
            padding: 16px 1rem !important;
            color: $color-black-table;
            font-weight: normal;
        }
    }
}

.ant-table-tbody {
    tr {
        td {
            border-bottom: 0px !important;
            font-family: $font-primary;
            font-size: 1.5rem;
            &>a {
                text-decoration: none;
                color: $color-blueful-dark;
                text-transform: lowercase;
            }
            &> :not(a) {
                text-transform: none;
                color: rgba(#000, 0.5);
            }
        }
    }
}

.ant-table-tbody {
    margin-bottom: 2rem;
    &::before {
        display: block;
        content: "\200C";
        line-height: 16px;
        width: 100%;
    }
    tr {
        td {
            padding: .6rem 1rem !important;
        }
    }
}

    
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters{
    padding-right:  0 !important;
}

.ant-pagination-disabled {
    * {
        cursor: default !important;
    }
}


@include firefox{
    @include respond(tab-land){
        .ant-pagination {
            width: 89vw;
            float: initial !important;
            display: flex;
            justify-content: flex-end;
            padding-right: 1rem;
        }
        .ant-spin-container{
            display: initial !important;
        }
    }
}

 

