.invite-page {
  .logo_wrapper {
    width: 29rem;
    padding: 2.5rem 4.2rem;
    box-sizing: border-box;
    display: flex;
    margin: 4rem auto 6.8rem;
  }

  .logo_wrapper a {
    width: 21rem;
  }

  .title-h1 {
    color: #1a1515;
    font-family: "Roboto Slab";
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0.19px;
    line-height: 66px;
    text-align: center;
    margin-bottom: 4.8rem;
  }

  .title-h3 {
    color: #1a1515;
    font-family: Roboto;
    font-size: 2.3rem;
    letter-spacing: 0.09px;
    line-height: 3rem;
    text-align: center;
    width: 56rem;
    margin: auto;
    margin-bottom: 5.5rem;
  }

  .descr {
    opacity: 0.5;
    color: #1a1515;
    font-family: Roboto;
    font-size: 1.5rem;
    letter-spacing: 0.06px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 2rem;
  }
}
