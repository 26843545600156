.report-view-page {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  left: 0;
  background-color: #000000cc;
  height: 100%;
  .shadow {
    width: 100%;
    left: 0;
    top: 6rem;
    position: fixed;
    height: 100%;
    z-index: -1;
  }
  .report-view-header-wrapper {
    background: black;
    width: 100%;
    .report-view-header {
      height: 6rem;
      max-width: 130rem;
      padding: 0 2rem;
      margin: auto;

      color: white;
      display: flex;
      align-items: center;
      position: relative;
      .logo-white {
        width: 16.7rem;
        margin-right: 7.8rem;
        img {
          width: 100%;
        }
      }
      h1 {
        color: white;
        flex-grow: 1;
        color: #ffffff;
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.15px;
        line-height: 20px;
        text-align: center;
      }
      &-buttons {
        position: relative;
        display: flex;
        padding-right: 4rem;
        .report-download,
        .report-share {
          display: inline-block;
          img {
            margin-right: 1rem;
          }
          .ant-btn {
            color: white;
            background-color: black;
            border: 1px solid white;
            &:hover:not([disabled]) {
              background-color: rgb(73, 73, 73) !important;
              border: 1px solid white !important;
            }
          }
        }
        .report-share {
          margin-right: 1.5rem;
        }
        .report-download {
          margin-right: 3rem;
        }
        &-close {
          position: absolute;
          top: 0.5rem;
          right: 0rem;
          color: white;
          cursor: pointer;
        }
      }
      .report-btn {
        position: absolute;
        top: 41rem;
        &.ant-btn {
          border: none;
          background-color: transparent;
          &[ant-click-animating-without-extra-node="true"]::after {
            display: none !important;
          }
          &:hover {
            background-color: transparent !important;
          }
        }
        .arrow{
          color: #FFFFFF;
          font-family: Roboto;
          font-size: 1rem;
          letter-spacing: 0.11px;
          line-height: 1px;
          .opaque-arrow {
            opacity: 0.3;
          }
        }
        &.report-prev-btn {
          left: 0rem;
          &.disabled {
            background-color: transparent !important;
            border: none !important;
          }
        }
        &.report-next-btn {
          right: 0rem;
          &.disabled {
            background-color: transparent !important;
            border: none !important;
          }
        }
      }
    }
  }

  .report-pdf-wrapper {
    margin: auto;
    max-width: 800px;
    display: flex;
    height: calc(100% - 6rem);
    iframe{
      margin: 0 auto;
    }
  }
}
