.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .sk-wave {
    justify-content: space-around;
  }

  .sk-wave-rect {
    background-color: $color-blueful;
  }

  &__wrap {
    width: 100%;
    height: 100%;
  }
  &__bounceball {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/logo-icon.svg");
    background-size: cover;
    animation: squareRotate 1s linear infinite;
    // position: relative;
    // display: inline-block;
    // height: 37px;
    // width: 25px;
    // &::before {
    //   position: absolute;
    //   content: "";
    //   display: block;
    //   top: 0;
    //   width: 25px;
    //   height: 25px;
    //   border-radius: 50%;
    //   background-color: $color-redful;
    //   transform-origin: 50%;
    //   animation: squareRotate3d 2s infinite ease-in-out;
    // }
  }
  &__text {
    color: $color-redful;
    display: inline-block;
    margin-left: 10px;
    font-family: Montserrat;
    font-size: large;
  }
}

@media (max-width: $max-width-secondary) {
  .main__loader.global{
    .loader {
      left: calc(50% + 7.5rem);
    }
  }
  
}
.main__loader.global{
  .loader {
    left: calc(50% + 20rem);
  }
}
