.login {
  &__form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // @include respond(phone) {
    //   padding: 0 2rem;
    // }
    &-field {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-items: flex-start;
      &:not(:last-child) {
        width: 100%;
        margin-bottom: 1.5rem;
      }

      &-btn {
        &.submit {
          width: 100%;
        }
      }
      &-error {
        height: 3rem;
        color: red;
        font-size: 1.3rem;
      }
    }
    &-label {
      display: inline-block;
      font-size: 1.6rem;
      letter-spacing: 0.1px;
      margin-right: 3rem;
      margin-bottom: 1.5rem;
      width: calc(20% - 3rem);
      text-transform: capitalize;
      color: #807a7a;
      height: 16px;
    }
    &-input {
      width: 100%;
      padding: 1.1rem 3rem;
      margin-bottom: 2rem;
      border: none;
      border-radius: 4px;
      background-color: #f6f6f6;
      &:focus {
        outline: none;
        background-color: #f6f6f6;
      }
      &:disabled {
        color: rgba($color-black, 0.5);
        background-color: #f6f6f6;
        cursor: default;
      }
      &::placeholder {
        color: rgba(204, 198, 198, 0.65);
      }
    }
    &-btn {
      height: 4.4rem;
      width: 12.5rem;
      font-size: 1.5rem;
      width: 100%;
      background-color: #59b0f6;
      &:disabled {
        background-color: #c7e5fc !important;
        color: #ffffff !important;
      }
      &:hover {
        background-color: darken(#59b0f6, 10%);
      }
      &-link {
        background: unset;
        color: $color-blueful-darker;
        display: inline;
        &:focus,
        &:hover,
        &:active {
          background-color: unset !important;
          background: unset !important;
          color: $color-blueful !important;
        }
      }
    }
  }
}
