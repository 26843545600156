.sneak-peak-modal {
  font-family: "Roboto";
  .ant-btn {
    &:hover {
      border-color: black;
    }
  }
  .slider-arrow {
    font-size: 2.4rem;
    color: black;
    width: 4rem;
    line-height: 5rem;
    height: 4rem;
    right: auto;
    position: absolute;
    top: 33rem;
    border: 1px solid #e7eaed;
    border-radius: 21.5px;
    background-color: #ffffff;
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:hover {
      transform: none !important;
    }
    &.arrow-right:hover {
      transform: rotate(180deg) !important;
    }
    &.arrow-left {
      left: -2rem;
    }
    &.arrow-right {
      transform: rotate(180deg);
      right: -2rem;
    }
    img {
      width: 2rem;
      position: relative;
      top: -0.3em;
      left: 1rem;
    }
  }
  .insight-details {
    .ant-col {
      margin-right: 3rem;

      div {
        font-size: 1.2rem;
        color: #838383;
      }
      h3 {
        font-family: "Roboto Slab";
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }

  .view__header {
    padding-top: 0;
    .view__header__primary {
      margin-right: auto;
    }
  }
  .view__overview {
    margin-top: 0;
    flex-grow: 1;
    .view__overview-text {
      font-size: 1.5rem;
      margin: 2rem 3rem 2rem 0;
      min-height: 8rem;
      max-height: 20rem;
      overflow-y: scroll;
      min-width: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      ::-webkit-scrollbar-track:window-inactive {
        background: rgba(255, 0, 0, 0.4);
      }
    }
  }
  .link-wrapper {
    a {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;

      color: #000000;

    }
  }
  .view__overview {
    width: auto;
  }
  .ant-modal-close {
    right: -4rem;
    top: -4rem;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 21px;
  }
  .ant-modal-body {
    margin: 0;
    padding: 0;
  }
  &__header {
    padding: 3rem 5rem 3rem;
    .app-icon {
      width: 5rem;
      height: 5rem;
      border-radius: 5px;
    }
    .versions__list__item-loader {
      width: 5rem !important;
      height: 5rem !important;
    }
    .app-name {
      font-size: 1.8rem;
      font-family: "Roboto";
      font-weight: bold;
      color: #1a1515;
    }
    .platform {

    }
    .extra-buttons {
      & > div {
        display: inline-block;
      }
      button {
        color: rgba(0, 0, 0, 0.82);
        font-family: "Roboto";
        margin-left: 2rem;
        padding: 0 1rem;
        .mark-hover {
          display: none;
        }
        .like-button:hover {
          .mark-hover {
            display: inline-block;
          }

          .mark-normal {
            display: none;
          }
        }
        span {
          margin-left: 8px;
        }
      }
    }
    .version-block {
      text-align: right;
      margin-left: auto;
      font-family: "Inter";
      .app-version {
        font-size: 11px;
      }
      .insight-date {
        color: #000;

        font-size: 16px;
        font-weight: bold;
      }
    }
    .report-description{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 139.19%;
      /* or 21px */

      color: #000000;
    }
    .report-thumbnail {
      img {
        max-width: 81rem;
        border: 1px solid #E7E7E7;
      }

    }
    .report-link {
      a{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #000000;
      }

    }
  }

  &__body {
    padding: 1.5rem 5rem 5rem;
    min-height: 30rem;
    transition: all 0.1s ease-in;
    .insight-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 3rem;
      &__title {
        font-weight: bold;
        font-size: 2.5rem;
        font-family: "Roboto Slab";
        margin: 0.5rem 6rem 1rem 0;
      }

      &__tags {
        display: flex;
        margin: 2rem 0 4rem;
        flex-wrap: wrap;
      }
    }
    .insight-cover {
      border-radius: 2px;
      backdrop-filter: blur(10px);
      text-align: center;
      img {
        max-width: 100%;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.22);
        border: 2px solid #e0e0e0;
        max-height: 46rem;
      }
      width: 227px;
    }
    .main-text {
      font-size: 1.5rem;
      margin: 4rem 4rem 2rem 0;
      min-height: 8rem;
      max-width: 44rem;
      max-height: 13rem;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      ::-webkit-scrollbar-track:window-inactive {
        background: rgba(255, 0, 0, 0.4);
      }
    }
    .more-screenshots {
      font-family: "Roboto";
      font-style: italic;
      margin-top: 2rem;
    }
    .insight-cover-landscape {
      width: 100%;
      margin-bottom: 2rem;
      text-align: center;
      img {
        max-height: 20rem;
        max-width: 100%;
      }
    }
    .insight-cover-portrait {
      min-height: 48rem;
    }
    .view-insight-btn {
      color: #1a1515;
      border-color: #1a1515;
      padding: 1.1rem 1.7rem;
      max-width: 20rem;
      width: 100%;
      height: 4.4rem;
      transition: background-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      &.centered {
        display: block;
      }
      .btn-arrow {
        transform: rotate(180deg);
        display: inline-block;
        margin: 0 1.5rem;
        img {
          width: 85%;
          &.black-arrow {
            display: inline-block !important;
          }
          &.white-arrow {
            display: none !important;
          }
        }
      }
      &:hover {
        color: white;
        background-color: #1a1515;
        border-color: #1a1515;
        .btn-arrow {
          img.black-arrow {
            display: none !important;
          }
          img.white-arrow {
            display: inline-block !important;
          }
        }
      }
    }
  }
  
}

@media (max-width: 700px) {
  .sneak-peak-modal__header {
    .report-thumbnail {
      img{
        max-height: 31rem;
      }
    }
  }
}
