.mo-container {
  .marketing-filters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    gap: 0.8rem;
  }

  .dropdown-filters {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
}

// Todo check if not used and remove
.mo-filters-container {
  display: flex;
  min-width: 100%;
  gap: 1rem;
  border-radius: 8px;

  .select-filter {
    .select-filter-title {
      color: #898989;
      /* Body/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .select-body {
      cursor: pointer;
      display: flex;
      height: 48px;
      padding: 8px 8px 8px 8px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e3e4e3;
      background: #fff;
      gap: 8px;

      span {
        color: #161515;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .select-body-apps-count {
        color: #fff;
        border-radius: 200px;
        background: #2e5fff;
        padding: 2px 7px;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
      }

      .select-body-app-image {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-right: 4px;
      }

      .select-body-time-frame {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .time-frames {
          display: flex;
          justify-content: space-between;
          height: 18px;
        }

        .dates {
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          text-transform: capitalize;
        }
      }

      .select-app-icon {
        width: 31px;
        height: 31px;
        border-radius: 4px;
        flex-shrink: 0;
      }
    }
  }
}

.mo-heading-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.mobile-full-screen {
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;

  .ant-popover-inner-content {
    width: 100vw !important;
    height: 100vh !important;
  }
}
