.trial.main{
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
  box-shadow: 0px -4px 19px rgba(255, 39, 65, 0.24);
  border-radius: 4px;
  .trial-expiration-text {
    padding: 0.6rem;
    background: #FF2741;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: #FFFFFF;
    .img {
      width: 1.2rem;
      height: 0.9rem;
       margin-right: 0.3rem;
      vertical-align: baseline;
    }
  }
  .expires-in {
    background-color: white;
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
  }
  .days-left {
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 2.6rem;
    color: #FF2741;
    background-color: white;
    padding: 0.3rem;
  }
}