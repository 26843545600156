@mixin shadow() {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
}

.products-pr-container {
  position: relative;

  .icon {
    border-radius: 4px;
  }

  &.add-hover:hover {
    @include shadow();
    transform: translateY(-1px);
    z-index: 3;
  }

  &.with-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .icon{
      border-radius: 4px;
      width: 2.6rem !important;
      height: 2.6rem !important;
    }
    .products-pr-title {
      margin-left: 1rem;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      color: #000000;
      padding-right: 1rem;
      line-height: 1.5rem;

    }
  }
}

.product-name-tooltip {
  .ant-tooltip-inner {
    border-radius: 5px;
  }

  .ant-tooltip-arrow .ant-tooltip-arrow-content {
    background-color: rgba(0, 0, 0, 0.75);
  }
}