.abtest-timeline {
  width: 100%;
  margin-top: 1rem;
  .abtest-dis-wrap {
    width: 20rem;
    display: flex;
    .abtest-dis-dummy {
      flex-grow: 1;
      // max-width: 4.8rem;
      .abtest-dis-line {
        margin-top: 3.5rem;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .abtest-dis {
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-width: 9.2rem;
      .abtest-dis-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.4rem;
        text-align: center;
        text-transform: uppercase;
        padding: 0.3rem 0;
        color: #000000;
        position: relative;
        .abtest-dis-title-ico {
          position: absolute;
          top: -2rem;
          left: 43%;
        }
      }

      .abtest-dis-time {
        font-family: Roboto;
        font-style: normal;
        font-size: 1.2rem;
        line-height: 1.4rem;
        text-align: center;
        color: #000000;
        padding-bottom: 0.3rem;
      }
      .abtest-dis-timeline {
        width: 100%;
        position: relative;
        .abtest-dis-line {
          margin: 1.5rem 0rem;
          width: 100%;
          border-bottom: 1px solid #e5e5e5;
        }
        .abtest-dis-dot {
          width: 6px;
          height: 6px;
          background-color: white;
          border: 1px solid #e5e5e5;
          border-radius: 50%;
          position: absolute;
          top: -0.2rem;
          left: 49%;
          &.current {
            background-color: #e5e5e5;
          }
        }
        .abtest-dis-dot-ongoing {
          position: absolute;
          top: -1.1rem;
          left: 45%;
        }
        .abtest-dis-dot-stopline {
          position: absolute;
          top: -1.1rem;
          left: 48%;
          font-weight: bold;
        }
      }
    }

    &.step {
      &:hover,
      &.current {
        .abtest-dis {
          cursor: pointer;
          box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.13);
          .abtest-dis-title {
            background: rgba(8, 104, 249, 0.09);
            font-weight: bold;
          }
          .abtest-dis-time {
            font-weight: bold;
          }

          .abtest-dis-line {
            border-bottom-color: #0868f9;
          }
          .abtest-dis-dot {
            &.current {
              background-color: #0868f9;
            }
            border-color: #0868f9;
          }
        }
      }
    }

    &.integ {
      .abtest-dis {
        .abtest-dis-dot-stopline {
          color: #55b26b;
        }

        .abtest-dis-title {
          background: #effaf3;
          font-weight: bold;
          color: #55b26b;
        }
      }
      &:hover,
      &.current {
        .abtest-dis {
          .abtest-dis-line {
            border-bottom-color: #55b26b;
          }
          .abtest-dis-title {
            background: #effaf3;
            font-weight: bold;
            color: #55b26b;
          }
        }
      }
    }

    &.aband {
      .abtest-dis {
        .abtest-dis-dot-stopline {
          color: #ff0000;
        }

        .abtest-dis-title {
          background: #faefef;
          color: #ff0000;
          font-weight: bold;
        }
      }
      &:hover,
      &.current {
        .abtest-dis {
          .abtest-dis-line {
            border-bottom-color: #ff0000;
          }
          .abtest-dis-title {
            background: #faefef;
            color: #ff0000;
            font-weight: bold;
          }
        }
      }
    }
  }

  .abtest-dis-wrap.ongoing,
  .abtest-dis-wrap.integ,
  .abtest-dis-wrap.aband,
  .abtest-dis-wrap.step-0 {
    width: 13rem;
  }

  .slick-slide:nth-child(1) {
    .abtest-dis {
      .abtest-dis-line {
        margin: 1.5rem 0rem 1.5rem 50%;
        width: 50%;
      }
    }
  }
  .slick-slide:nth-last-child(1) {
    .abtest-dis {
      .abtest-dis-line {
        margin: 1.5rem 50% 1.5rem 0rem;
        width: 50%;
      }
    }
  }
  .slick-disabled {
    display: none;
  }
  .slick-list {
    height: 10rem !important;
    padding-top: 2.4rem;
  }
  .abtest-timeline-select-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem;
  }
  .abtest-timeline-select {
    user-select: none;
    cursor: pointer;
    margin-left: 1rem;
    padding: 0.55rem 0.8rem;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    height: 3.2rem;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    img {
      transition: all 0.1s ease-in-out;
      margin-left: 1.5rem;
      width: 1.1rem;
    }
    &.opened {
      img {
        transform: rotate(180deg);
      }
    }
    &.closed {
      img {
        transform: rotate(90deg);
      }
    }
  }
  .abtest-tracking-non-slider {
    display: flex;
    margin-top: 3.4rem;
    justify-content: space-between;
    .abtest-dis:nth-child(1) {
      .abtest-dis-line {
        margin: 1.5rem 0rem 1.5rem 50%;
        width: 50%;
      }
    }
    .abtest-dis:nth-last-child(1) {
      .abtest-dis-line {
        margin: 1.5rem 50% 1.5rem 0rem;
        width: 50%;
      }
    }
    .abtest-dis-wrap.step.step-1, .abtest-dis-wrap.step.step-2 {
      width: 32%;
    }
    .sep-timeline {
      flex-grow: 1;
      .abtest-dis-line {
        margin-top: 3.5rem;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }
}
