.user-flows-filter {
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  .user-flow-filter-item {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px; /* 160% */
    text-transform: uppercase;
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 200px;
    border: 1px solid #000;

    &.selected {
      background: #000;
      color: #fff;
    }

    cursor: pointer;
  }
}
