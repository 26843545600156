.versions__list__header {
    &__primary {
        display: flex;
        align-items: center;
    }
    &-img {
        margin-right: 0.5rem;
    }
    &-heading {
        font-size: 1.8rem;
        font-weight: 500;
        font-family: "Roboto";
    }
    &__text {
        display: flex;
        flex-direction: column;
    }
    &__abtest {
        display: flex;
        margin-left: 4.2rem;
        list-style: none;
        @include respond(phone) {
            flex-direction: column;
        }
        &-item {
            margin-right: 2rem;
            text-transform: capitalize;
            font-size: 1.3rem;
            display: flex;
            align-items: center;
            color: $color-brown-grey;
        }
        &-link {
            margin-left: auto;
            font-size: 1.3rem;
            cursor: pointer;
            a {
                color: $color-blueful-dark;
            }
            @include respond(phone) {
                position: absolute;
                right: 3rem;
            }
        }
    }
}
