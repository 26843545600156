.topics-page {
  .h1-title {
    font-size: 4.2rem;
    margin: 1rem 0;
    font-family: "Roboto";
    font-weight: 300;

  }
  .extra-text {
    color: #676767;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0.15px;
    line-height: 18px;
    margin-bottom: 3rem;
  }
  h3.sub-header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    color: #000000;
  }

  .topics-filters {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .gallery-discovery-cards {
    margin-top: 6rem;
  }

  .topics-splitter {
    border-bottom: 2px solid #eceef4;
    margin-top: 3rem;
  }
  .all-dis-loader {
    position: relative;
  }
  .top-labels-wrap {
    margin-top: 2rem;
    .sub-header {
      margin-bottom: 2rem;
    }
    .bar-label-line-num {
      width: 3rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      color: #abacb0;
    }
    .tags__tags-tag-element {
      font-size: 1.1rem;
      display: inline-block;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.2rem;
      color: #000000;
    }
    .bar-label-line {
      display: flex;
      align-items: center;

      .bar-label-line-name {
        min-width: 17rem;
        margin-left: 1rem;
        text-align: center;
      }
      .bar-label-line-products {
        width: 100%;
      }
      .bar-label-line-cont {
        display: flex;
        height: 2.2rem;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        flex-grow: 1;

        .bar-product-part {
          height: 2.2rem;
          .dummy-tooltip {
            width: 100%;
            height: 100%;
          }
          &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
      .bar-label-line-total {
        margin-left: 1rem;
      }
    }
    .top-labels-legend {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-top: 2rem;
      flex-wrap: wrap;

      .top-labels-legend-part span {
        display: flex;
        align-items: center;
        margin-right: 4rem;
        margin-bottom: 1rem;
        .top-labels-legend-part-color {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
        .top-labels-legend-part-icon {
          img {
            margin-left: 1rem;
            width: 2rem;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .topics-tags-filter {
    position: relative;
    margin-top: 2rem;
    box-shadow: 0px 17px 19px rgba(0, 0, 0, 0.05);
    .items-selected.static {
      position: absolute;
      right: 1rem;
      top: 3rem;
    }
    .ant-select {
      width: 74% !important;
      .ant-select-arrow {
        display: none;
      }
    }
    .ant-select-selection-item {
      border: 0.9px solid #3f4653 !important; 
      border-radius: 13.5px !important;
      background-color: #ffffff !important;
      color: #1a1818;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-select-selection {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      box-shadow: none;
      &:focus {
        border-bottom-color: black !important;
      }
    }
    .tags__tags-tags-wrapper {
      max-height: 20rem;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
  .gallery-discovery-cards {
    .discoveries-list {
      @media screen and (max-width: 1685px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.bar-pr-tooltip {
  .ant-tooltip-arrow::before {
    background-color: white !important;
  }
  .ant-tooltip-arrow-content{
    background-color: white !important;
  }
  .ant-tooltip-inner {
    background-color: white !important;
    color: black !important;
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #595959;
  }
  .bar-pr-tooltip-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #595959;
  }
  .bar-pr-tooltip-count {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #000000;
  }
}
