.view__experiment-arrow {
    align-self: center;
    span {
        border: solid $color-blueful;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4.6px;
        transform: rotate(315deg);
        -webkit-transform: rotate(315deg);
        @include respond(phone) {
            padding: 3px;
            border-width: 0 2px 2px 0;
        }
    }
    &-disable {
        @include respond(phone) {
            opacity: 0;
        }
    }
}