@import '../../../../sass-config/abstracts/variables.scss';

.heading-tooltip-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  border-bottom: 1px solid $color-border-layout;
  padding-bottom: 1rem;
  margin-bottom: 1.92rem;

  .top-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  .heading-tooltip-title {
    color: #000;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }

  .heading-tooltip-subtitle {
    color: $color-subtitle;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: capitalize;
  }

  img {
    cursor: pointer;
  }

  .heading-chart-percentage-toggle {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .heading-chart-percentage {
      cursor: pointer;
      color: $color-black;

      &:first-of-type {
        border-right: 1px solid black;
        padding-right: 0.5rem;
      }

      &.active {
        color: #2e5fff;
      }
    }
  }

  .heading-chart-switch-toggle {
    margin-left: auto;
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }
}
