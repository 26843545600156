.overview-page {
  .title {
    font-size: 2.7rem;
    margin: 1rem 0;
    font-family: "Roboto Slab";
    strong {
      font-weight: 700;
    }
    .record-symbol {
      position: relative;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      left: 1rem;
      top: -0.5rem;
      animation: blink-red 2s linear infinite;
    }
  }
  .extra-text {
    color: #676767;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Roboto";
  }
  .extra-text-2 {
    color: #c7c7c7;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Roboto";
  }
  &__header {
    .current-date {
      color: #c7c7c7;
      font-size: 1.3rem;
    }
    .hr-row {
      background: linear-gradient(45deg, #e0e0e0, transparent);
      height: 1px;
      margin: 2rem 0 8rem;
    }
    color: #0b3252;
    font-size: 21px;
    .greet-daytime {
      font-weight: 500;
      // margin: 1rem 0;
      font-family: "Roboto Slab";
    }
    .first-name {
      font-weight: 700;
      display: inline-block;
    }
    .first-name::first-letter {
      text-transform: capitalize !important;
    }
  }
  &__watch {
    margin: 6rem 0 8rem;

    .title {
      margin-bottom: 1rem;
      font-size: 2.7rem;
      font-family: "Roboto";
      font-weight: 300;
    }

    .slick-slide {
      width: 100%;
      padding-top: 10px;
    }
    .apps-on-watch {
      position: relative;
      margin-top: 3rem;
      width: 102%;
      .apps-per-platform{
        margin-bottom: 2rem;
      }
      .apps-platform-separator {
        width: 24rem;
        margin: 0.2rem 0;
      }
      &.short {
        .apps-platform-separator {
          display: none;
        }
        .apps-per-platform {
          margin-right: 3rem;
        }
      }
      h4 {
        margin-bottom: 1rem;
        img {
          vertical-align: baseline;
          display: inline-block;
          margin-right: 1rem;
        }
        .count {
          color: #676767;
        }
      }

    }
    .app-watch {
      cursor: pointer;
      position: relative;
      margin: 1.5rem 2rem 0.5rem 0;
      box-shadow: 0 2px 4px 0 rgba(26, 21, 21, 0.08);
      // box-shadow: 0 0 0 1px rgba(0,0,0,.05);
      will-change: transform;
      transition: transform 0.2s ease-in;
      &.inactive {
        opacity: 0.3;
      }

      .img-wrap {
        border-radius: 5px;
        overflow: hidden;
        width: 4.5rem;
        height: 4.5rem;
        img {
          width: 100%;
        }
      }
    }
    .badge {
      position: absolute;
      border-radius: 5px;
      width: 1.5rem;
      height: 1.5rem;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 2.2rem;
      font-size: 1.1rem;
      background-color: #fff1f0;
      right: -0.6rem;
      top: -0.6rem;
      .badge-inner-circle {
        background-color: #f5222d;
        border-radius: 50%;
        margin: auto;
        width: 0.7rem;
        height: 0.7rem;
        position: relative;
        top: 0.4rem;
      }
      &.active {
        .badge-inner-circle {
          background-color: #389e0d;
          border-radius: 50%;
          margin: auto;
          width: 0.7rem;
          height: 0.7rem;
          position: relative;
          top: 0.4rem;
        }
        background-color: #f6ffed;
        display: none;
      }
    }
  }
  &__analyzed-versions {
    padding: 4rem 29px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.06);
    position: relative;
    border-radius: 5px;
    margin-top: 3rem;

    .all-dis-loader {
      top: 18rem;
      position: relative;
      z-index: 333;
      width: 100%;
    }
    .dates-selectbox {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0rem;
      .month-selectbox {
        width: 17rem;
      }
      .year-selectbox {
        width: 10rem;
      }
    }
    .prev-next-buttons {
      .slick-arrow-def {
        top: 38%;
        z-index: 333;
      }
    }

    .title {
      font-weight: bold;
      font-size: 14px;
      color: #1a1818;
      margin-bottom: 23px;
      font-family: "Inter";
    }
    .chart-container {
      position: relative;
      box-shadow: none;
      border: none;
      .chart-item {
        width: 20px;
        height: 20px;
      }
      .version-item {
        position: absolute;
        border-radius: 50%;
        overflow: visible;
        border: solid 1px #e9ebf1;
        background-size: cover;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        transition: all 0.3s;
        cursor: pointer;
        background-color: white;
        lottie-player {
          position: absolute;
          top: -1.5rem;
          right: 0.1rem;
        }
        .importance-indicator.importance-indicator-animating {
          width: 7px;
          height: 7px;
          right: 1px;
          top: 0px;
          box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
          animation: pulse-small 2s cubic-bezier(0.66, 0.33, 0, 1);
          animation-iteration-count: infinite;
        }
        &.mult {
          box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.25);
        }
        &.not-clickable,
        &.customer-own-app {
          cursor: default !important;
        }
        &:hover:not(.customer-own-app) {
          transform: scale(1.1);
          border: solid 1px #4d4646;
        }
      }
    }
    .analyzed-apps {
      .slider-arrow {
        font-size: 0.4rem;
        color: black;
        background: white;
        width: 2rem;
        line-height: 5rem;
        height: 5rem;
        right: auto;
        position: relative;
        top: -0.2rem;
        border: 1px solid #e7eaed;
        left: -1.5rem;

        &::before {
          content: "";
          display: block;
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 5px;
          will-change: opacity;
          transition: opacity 0.2s ease-in;
        }
        &:hover::before {
          opacity: 0;
        }
        &::after {
          box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
          opacity: 0;
          content: "";
          display: block;
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 5px;
          will-change: opacity;
          transition: opacity 0.2s ease-in;
        }
        &:hover::after {
          opacity: 1;
        }

        &.arrow-right {
          transform: rotate(180deg);
          left: 1.5rem;
        }
        .slick-slide:last-child {
          position: relative;
          left: -0.1rem;
          margin-right: 1rem;
        }
        img {
          width: 1rem;
          position: relative;
          top: -0.3em;
          left: 0.3rem;
        }
      }
      .analyzed-app {
        &:hover {
          border-color: #676767;
        }
        position: relative;
        display: flex;
        padding: 0.7rem;
        margin-bottom: 0.5rem;
        align-items: center;
        margin-right: 1.2rem;
        border: 1px solid #edebeb;
        border-radius: 2px;
        font-family: "Inter";
        min-width: 15rem;
        margin-top: 1rem;
        img {
          border-radius: 50%;
        }
        .text {
          margin-left: 9px;
          color: black;
        }
        &__title {
          font-size: 9px;
          color: black;
        }
        &__version {
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }
        &-count-children {
          position: absolute;
          top: -0.8rem;
          right: -0.8rem;
          width: 1.8rem;
          height: 1.8rem;
          background-color: white;
          border: 1px #676767 solid;
          border-radius: 50%;
          font-size: 1rem;
          text-align: center;
          line-height: 1.6rem;
          font-weight: 700;
        }
        .load-indicator {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          width: 100%;
          animation: GradientPaint 2s ease infinite;
          -webkit-animation: GradientPaint 2s ease infinite;
          -moz-animation: GradientPaint 2s ease infinite;
          background: linear-gradient(270deg, #aae7ff, #2196f3);
          background-size: 400% 400%;
        }
        @-webkit-keyframes GradientPaint {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        @-moz-keyframes GradientPaint {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        @keyframes GradientPaint {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      }
    }
  }

  .versions__list__item {
    width: 25rem;
    height: 31rem;
  }
  .versions__list__grid {
    display: grid;
    padding: 2rem 0 3.2rem 0;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
  }
}

.versions__list.fade {
  .versions__list__item {
    opacity: 1;
    transition: opacity 0.6s ease-in;
    &.fade-out {
      opacity: 0;
    }
  }
}

.version-tooltip {
  max-width: unset !important;
  .app-version-data {
    cursor: pointer;
  }
  .own-app {
    cursor: default;
  }
  .ant-tooltip-inner {
    border-radius: 3.85px;
    background-color: #ffffff;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    color: black;
    padding: 13px 17px;
  }
  .date {
    font-size: 0.92rem;
    font-family: Roboto;
    font-weight: 300;
    color: #6c6c6c;
  }
  .title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
    font-family: "Inter";
    max-width: 13rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .version-platform {
    background-color: rgba(108, 108, 108, 0.07);
    text-align: center;
    margin: 1.5rem 0;
    padding: 0.5rem;
    .platform {
      font-size: 0.9rem;
      color: #1a1a1a;
      font-family: "Roboto";
      position: relative;
      &::after {
        width: 1.1rem;
        height: 1.1rem;
        bottom: 0;
        right: auto;
        left: -1.5rem;
      }
    }
    .version {
      font-family: Inter;
      font-size: 1.1rem;
      color: black;
      font-weight: bold;
    }
  }

  .status-insigths {
    display: flex;
  }

  .insights {
    font-size: 0.91rem;
    color: #2bbc70;
    margin: 0 1rem;
    text-transform: uppercase;
  }
  .critical-discoveries-cnt {
    color: #ff4d50;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.06px;
    line-height: 11px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    .importance-indicator.importance-indicator-animating {
      width: 7px;
      height: 7px;
      left: 1px;
      top: 2px;
      box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
      animation: pulse-small 2s cubic-bezier(0.66, 0.33, 0, 1);
      animation-iteration-count: infinite;
    }
  }
  .state {
    font-size: 1rem;
    margin-bottom: 1rem;
    span {
      padding: 0.35rem 1.2rem;
    }
    &.state-completed span,
    &.state-published span,
    &.state-in_review span {
      color: #18b663;
      background-color: rgba(24, 182, 99, 0.1);
    }
    &.state-analysis_in_progress span {
      background-color: rgba(251, 140, 0, 0.1);
      color: #fb8c00;
    }
    &.state-analysis_pending span,
    &.state-new span,
    &.state-analysis_in_progress.nonvis span {
      background-color: #9fa5ad;
      color: black;
    }
  }

}

.overview-insights {
  margin-top: 74px;
  .title {
    color: #1a1818;
    font-size: 2.7rem;
  }
  .empty-insights {
    margin: 14rem auto;
    width: 26rem;
    text-align: center;
    h3.empty-ins-title {
      color: #676767;
      font-size: 2rem;
      font-weight: 500;
      margin: 4rem 0 1rem;
      font-family: "Roboto";
    }
    .empty-ins-text {
      color: #c7c7c7;
      font-weight: 200;
      font-family: "Roboto";
    }
  }
}

.version-tooltip {
  .ant-tooltip-arrow::before {
    background-color: rgba(255, 255, 255, 1);
  }
}

@keyframes blink-red {
  0% {
    background-color: white;
  }
  49% {
    background-color: white;
  }
  50% {
    background-color: #f4442e;
  }
  99% {
    background-color: #f4442e;
  }
  100% {
    background-color: white;
  }
}

.hover-effect {
  &::before {
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.03);
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }
  &:hover::before {
    opacity: 0;
  }
  &::after {
    box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.2);
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }
  &:hover::after {
    opacity: 1;
  }
  &:hover {
    transform: translateY(-1px);
  }
  &.arrow-right:hover {
    transform: translateY(-1px) rotate(180deg);
  }
}

.apps-on-watch-tooltip {
  .ant-tooltip-inner {
    padding: 0;
    & > div {
      & > div {
        padding: 0 2.5rem;
        text-align: center;
        .app-watch-tip-marker {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: -0.2rem;
          left: -0.5rem;
        }

        &:nth-child(2) {
          padding: 1rem 2rem;
          text-align: center;
        }
      }
    }
  }
  .app-watch-act .app-watch-tip-marker {
    background-color: rgb(56, 158, 13);
  }
  .app-watch-deact .app-watch-tip-marker {
    background-color: #f5222d;
  }

  .app-watch-act {
    background-color: #f6ffed;
    color: rgb(56, 158, 13);
  }
  .app-watch-deact {
    background-color: #fff1f0;
    color: #f5222d;
  }
}

.ant-dropdown-open {
  .analyzed-app {
    border: 1px solid #676767 !important;
  }
}
.analyzed-app {
  .icon-wrap {
    position: relative;
    &::after {
      width: 1rem;
      height: 1rem;
      bottom: -0.4rem;
      right: -0.4rem;
    }
  }
}

.analyzed-app-children-versions {
  .analyzed-app {
    background-color: white;
    position: relative;
    display: flex;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
    align-items: center;
    margin-right: 1.2rem;
    border: 1px solid #676767;
    border-radius: 2px;
    font-family: "Inter";
    min-width: 15rem;

    img {
      border-radius: 50%;
    }
    .text {
      margin-left: 9px;
      color: black;
    }
    &__title {
      font-size: 9px;
      color: black;
    }
    &__version {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.versions-tooltip-wrapper {
  display: flex;
  .more-discoveries {
    position: absolute;
    right: 1rem;
    bottom: 2rem;
    height: 2.1rem;
    width: 2.1rem;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-family: Roboto;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.07px;
    border-radius: 50%;
    cursor: pointer;
  }
  .versions-discoveries-by-type {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-left: 1.1rem;
    -webkit-mask-image: linear-gradient(180deg, #000 0, 5%, #000 55%, rgba(0, 0, 0, 0) 100%);
    padding-right: 5px;
    overflow: visible;
    &::after {
      content: "";
      position: absolute;
      top: 1rem;
      left: 0rem;
      width: 1px;
      height: 12.1rem;
      background-color: #e9ebf1;
    }
  }
  .heading.versions__list__header-heading {
    color: #1a1818;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 0.07px;
    line-height: 10px;
    margin-bottom: 0.5rem;
  }
  .versions__list__header__primary {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -0.5rem;
    left: 1rem;
    .dis-count {
      color: #1a1818;
      font-family: Roboto;
      font-size: 0.7rem;
      font-weight: 500;
      letter-spacing: 0.05px;
      line-height: 8px;
    }
    img {
      width: 2rem;
    }
  }
  .discoveries-by-type-wrapper {
    padding: 0rem 0rem 0rem 1rem;
    margin: 0 0.1rem 0;
    cursor: pointer;
    display: flex;

    .discoveries-by-type {
      height: 10.5rem;
      width: 10rem;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
      border: 1px solid #eeeeee;
      border-radius: 4px;
      background-color: #ffffff;
      position: relative;
      overflow: visible;
      margin-right: 1rem;
      &:last-child{
        margin-right: 0rem;
      }
      .importance-indicator.importance-indicator-animating {
        width: 7px;
        height: 7px;
        right: -3px;
        top: -3px;
        box-shadow: 0 0 0 0 rgba(#f0f0f0, 1), 0 0 0 0 rgba($color-redful, 0.7);
        animation: pulse-small 2s cubic-bezier(0.66, 0.33, 0, 1);
        animation-iteration-count: infinite;
      }

      &-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 2.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 0.7rem;
        font-weight: 500;
        letter-spacing: 0.06px;
        line-height: 9px;
        margin-bottom: 0.6rem;
        padding: 0.5rem;
      }
      &-image {
        height: 8.5rem;
        overflow: hidden;
        &.def {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 4rem;
            position: relative;
            top: -1rem;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-small {
  to {
    box-shadow: 0 0 0 6px transparent, 0 0 0 7px rgba(#f22801, 0);
  }
}
