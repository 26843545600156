@import "../../../sass-config/abstracts/variables.scss";

.performance {
  margin-top: 3rem;
  width: 100%;
  min-height: 100rem;
  position: relative;

  .benchmark__sub-header {
    display: flex;
    top: 10rem;
    position: absolute;
    right: 0rem;
    .benchmark-select-label{
      margin-right: 4rem;
    }
  }

  .h1-title {
    font-size: 4.2rem;
    margin: 1rem 0;
    font-family: "Roboto Slab";
    font-weight: 300;
    .header {
      display: flex;
      flex-direction: row;
      .medium {
        font-weight: 300;
      }
    }
  }

  .hr-row {
    background: linear-gradient(45deg, #e0e0e0, transparent);
    height: 1px;
    margin: 2rem 0 7.5rem;
  }

  .extra-text {
    color: #676767;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Roboto";

    strong {
      font-weight: 600;
    }
  }

  .extra-text-2 {
    color: #c7c7c7;
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Roboto";
  }

  .title {
    font-size: 2.7rem;
    margin: 1rem 0;
    font-family: "Roboto Slab";

    strong {
      font-weight: 700;
    }
  }

  .performance-app-drawers {
    display: flex;
    justify-content: space-between;
  }

  .performance-app-drawer__apps {
    transition: height 0.1s ease !important;
  }

  .performance-drawer__top {
    padding: 2rem 0rem 0 0rem;
    width: 100%;
  }

  .performance-filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #edebeb;

    .perf-filter-event {
      flex-basis: 30%;
      max-width: 15rem;
    }

    .perf-filter-device {
      flex-basis: 27%;
      max-width: 15rem;
    }

    .perf-filter-device-os {
      flex-basis: 18%;
    }

    .perf-filter-locations {
      flex-basis: 25%;
    }
  }

  .performance-app-drawers .performance-app-drawer {
    width: 100%;
    flex-basis: 48.7%;
    position: relative;
  }

  .performance__header {
    display: flex;
    justify-content: space-between;
    min-height: 14.5rem;
    position: relative;

    .performance-app-drawer-wrap {
      position: absolute;
      z-index: 40;
      width: 100%;

      .performance-app-drawers {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .perf-header-0 {
    .performance-app-drawer:not(.wout-color-line) {
      border-top: 5px solid $color-performance-compare-1;
    }

  }

  .perf-header-1 {

    .performance-app-drawer-wrap {
      .performance-app-drawer {
        border-top: 5px solid transparent;
      }
    }

    .performance-app-drawer-wrap:not(.disabled) {
      .performance-app-drawer {
        border-top: 5px solid $color-performance-compare-2;
      }
    }

  }

  .performance-app-drawer-wrap .performance-app-drawer {
    transition: height 0.1s ease;


  }

  .disabled-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.7;
    background-color: white;
    z-index: 400;
  }

  .performance-drawer__list {
    position: absolute;
    z-index: 33;
    top: 8rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    min-width: 280%;
    transition: 0.1s cubic-bezier(0.5, 0.25, 0, 1);
    border-bottom: none !important;
    overflow: none;

    &.hide-scroll {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0rem !important;
      }

      &::-webkit-scrollbar-thumb {
        display: none !important;
      }
    }

    &.opened {
      padding: 0.7rem;
    }

    .performance-drawer__list-item {
      padding: 0.4rem 0.7rem;
      height: min-content;
    }

    .performance-drawer__list-item:hover {
      background-color: black !important;
    }
  }


  .app-status-value.unk {
    width: 10rem;
    background-color: #f8f8f8;

    .ant-badge-status-dot {
      display: none;
    }
  }

  .performance-app-drawer__current-app-title {
    .text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      color: #000000;
    }
  }

  .performance-drawer {
    position: relative;

    .performance-drawer__top__name {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      color: #AFB4BA;
    }

    .performance-drawer__top__selected-item {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: #000000;
      height: min-content;
      padding: 0rem 1rem 1rem 0 !important;

      &.disabled {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        color: #AFB4BA;
        cursor: default;
      }
    }

    .performance-drawer__top__chevron {
      width: 2rem;
      right: 0rem;
      top: 4.5rem;
      transition: transform 0.1s cubic-bezier(0.5, 0.25, 0, 1), -webkit-transform 0.1s cubic-bezier(0.5, 0.25, 0, 1) !important;
    }
  }

  .bench-compare-empty-state {
    position: relative;

    .empty-state-back {
      position: relative;
      left: -1.3rem;

      width: 102%;
    }

    .empty-state-text {
      position: absolute;
      text-align: center;
      top: 15%;
      width: 25rem;
      left: calc(50% - 12.5rem);

      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        color: #000000;
      }

      div {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 1.2rem;
        color: #000000;
      }
    }
  }

  .performance__summary {
    margin-top: 5.5rem;

    .title {
      margin-bottom: 0rem;
    }

    &-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &>div {
        width: 93%;
        padding: 4.5rem 1rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        box-sizing: content-box;
        border: 1px solid #e7eaed;
      }

      .performance-no-data img {
        margin: 3rem;
      }

      &>div:nth-child(2) {
        justify-self: end;
      }

      .performance-no-data__header {
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 1.6rem;
      }

      .performance-no-data__contact {
        text-align: center;
      }
    }
  }



  .sticky-header-wrapper {
    position: sticky;
    top: 10rem;
    left: 0px;
    background-color: rgb(251, 251, 251);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1rem 1.2rem 0px;
    display: none;

    &.visible {
      transition: top 0.1s;
      cursor: pointer;
      opacity: 1;
      top: 6rem;
      height: 0rem;
      z-index: 1220;
      display: block;
    }

    .sticky-header-cont {
      transition: top 0.1s;
      cursor: pointer;
      opacity: 1;
      height: 5.5rem;
      z-index: 220;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      background: #FBFBFB;
      box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.25);

      .flag-icon {
        display: none;
      }

      @media (max-width: 1350px) {
        padding: 0 4.6rem;
      }

      .performance-sticky-header-content:first {
        padding-right: 2rem;
      }

      .performance-sticky-header:nth-child(1) {
        .performance-sticky-header-content {
          border-bottom: 5px solid $color-performance-compare-1;
        }
      }

      .performance-sticky-header:nth-child(2) {
        .performance-sticky-header-content {
          border-bottom: 5px solid $color-performance-compare-2;
        }
      }

      .performance-sticky-header-content {

        &>div:first-child {
          display: flex;
          justify-content: flex-start;
          margin-right: 0;

        }

        &>div:nth-child(2) {
          display: flex;
          justify-content: flex-start;

          padding-left: 1rem;

        }
      }


      &.visible {
        transition: top 0.1s;
        cursor: pointer;
        opacity: 1;
        top: 6rem;
        height: 5.5rem;
        z-index: 220;
        display: block;
      }

    }
  }

  .performance-tooltip {
    & span {
      font-weight: 800;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  .mertics-link {
    margin: 2rem auto 1rem;
  }


}

.performance-tooltip {
  .performance-tooltip-field {
    span {
      display: inline-block;
      margin-right: .5rem;
    }
  }
}


@media (min-width:1400px) {
  .main-page-chart .performance-history-graphs__line-wrapper {
    width: 80rem;
    left: -4rem;
  }

}

@media (min-width:1650px) {
  .main-page-chart .performance-history-graphs__line-wrapper {
    width: 92rem;
    left: -4rem;
  }
}

@media (min-width:1850px) {
  .main-page-chart .performance-history-graphs__line-wrapper {
    width: 108rem;
    left: -4rem;
  }

}


@media (min-width:2300px) {
  .main-page-chart .performance-history-graphs__line-wrapper {
    width: 132rem;
    left: -4rem;
  }

}

@media (min-width:2500px) {
  .main-page-chart .performance-history-graphs__line-wrapper {
    width: 152rem;
    left: -4rem;
  }

}