.view__experiment {
  padding: 2.5rem 0;
  width: fit-content;
  animation: fadeIn 1.2s;
  &:not(.view__experiment-last) {
    border-bottom: 1px solid $color-light-grey;
  }
  .view__experiment-images__header__text-integrated {
    border-bottom: 3px solid #18b663;
    font-weight: 500;
    padding: 0.8rem 1.6rem;
    color: #18b663;
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #18b663;
      top: 1rem;
      right: 0.5rem;
      left: auto;
    }    
  }        
  &-sm {
    display: grid !important;
    width: fit-content !important;
    grid-template-columns: 34rem 1.5rem 34rem;
    grid-gap: 2rem 3rem;
    @include respond(tab-port) {
      grid-template-columns: 26rem 1.5rem 26rem;
    }
    @include respond(phone) {
      grid-template-columns: 125px 10px 125px;
      grid-gap: 2rem 1.5rem;
      margin-bottom: 0;
    }
  }
  &-equal {
    width: 75.5rem;
    @include respond(tab-port) {
      width: 535px;
    }
    @include respond(phone) {
      width: 100%;
    }
    &__header {
      display: flex;
      .view__experiment-equal__header-last {
        .view__experiment-images__header__text {
          border-bottom: 3px solid black;
          font-weight: 500;
          padding: 0.8rem 1.6rem;
        }

      }      
      &-last {
        margin-left: 31%;
        @include respond(tab-port) {
          margin-left: 27%;
        }
        @include respond(phone) {
          margin-left: 1%;
        }
      }
    }
  }
  &-nonequal {
    display: grid;
    grid-template-columns: repeat(2, 34rem);
    grid-gap: 2rem 3rem;
    margin-bottom: 5rem;
    @media (max-width: 1440px) {
      grid-template-columns: repeat(2, 26rem);
    }
    @include respond(tab-land) {
      grid-template-columns: repeat(2, 25rem);
    }
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 26rem);
    }
    @include respond(phone) {
      grid-template-columns: 26rem;
    }
  }
  &-images {
    position: relative; // display: flex;
    // flex-direction: column;
    &__header {

      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      font-size: 1.3rem;
      width: 18rem;
      @include respond(phone) {
        margin-bottom: 1rem;
        flex-direction: column;
        align-items: flex-start;
      }
      &:not(.view__experiment-images__header-0) {
        display: none;
        @include respond(phone) {
          display: flex;
        }
      }
      img,
      &__circle {
        margin-right: 1.6rem;
        @include respond(phone) {
          margin: 0 0 0.5rem 0;
        }
      }
      &__circle {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
        font-size: 1.6rem;
        text-transform: capitalize;
        &-grey {
          background-color: rgba($color-brown-grey, 0.5);
        }
        &-blue {
          background-color: $color-blueful;
        }
        &-red {
          background-color: $color-redful;
        }
      }
      &__text {
        display: flex;
        flex-direction: column;
        text-transform: capitalize;
        font-family: "Roboto";
        font-size: 1.6rem;
        color: black;

        :last-child {
          color: $color-brown-grey;
          font-size: 1.3rem;
        }
        &-relases {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }
    &-text {
      flex: 1;
      font-size: 1.3rem;
      text-align: center;
      font-family: "Roboto";
      margin: 2rem 0;
      p {
        color: #06396f;
      }

    }

  }
  &-image,
  &-loader {
    width: 100%;
    box-shadow: $shadow-small;
    border-radius: 8px;
  }
  &-loader {
    height: 40rem;
    @include respond(tab-port) {
      height: 44rem;
    }
    @include respond(phone) {
      height: 227.6px;
    }
  }
  &-image {
    display: inline-block;
    border: 3px solid transparent; // height: calc(100% - 1rem );
    @include respond(phone) {
      border: 2px solid transparent;
    }
    &:not(.view__experiment-image-video) {
      cursor: zoom-in;
    }
    &-hashflag {
      height: 7rem;
      width: 7rem;
      cursor: default;
    }
    &-integrated {
      border-color: $color-greenful-2;
    }
    &-abandoned {
      // border-color: $color-redful;
    }
    &-video {
      // min-height: 55rem;
    }
  }
  &-video {
    border-radius: 8px;
    overflow: hidden;
    width: 32rem;
    &-button {
      box-shadow: 0 10px 11px 0 rgba(0, 0, 0, 0.3) !important;
      border: 2px solid white !important;
      line-height: 1.1em !important;
      font-size: 4em !important;
      border-radius: 50% !important;
      background: rgba(0, 0, 0, 0.7) !important;
      height: 6.2rem !important;
      width: 6.2rem !important;
      margin: 0 !important;
      transform: translate(-50%, -50%);
      &::before {
        top: 0.7rem !important;
      }
    }
  }
  &-loader {
    @extend %animated-images;
  }
  &__modal-image {
    width: 100%;
  }
}
