@import '../../../mixins';
@import '../../../../../sass-config/abstracts/variables.scss';

.cput-contianer {
  display: flex;
  gap: 1.6rem;
  height: 400px;

  .cput-legend {
    padding-right: 0.8rem;
    border-right: 1px solid $color-border-layout;
    width: 29rem;
  }

  .cput-chart {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: #fdfdff;
    overflow-y: auto;

    @include custom-scrollbar;
  }
}
