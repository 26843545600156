@import "../../../../sass-config/abstracts/variables.scss";

.video-wrapper {
  flex-basis: 48.9% !important;
  height: 48rem;
  min-width: 31rem;
  position: relative;
  transition: all 0.1s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
  min-height: inherit !important;
  .visual-preview-icon{
    margin: auto;
    position: absolute;
    bottom: 26rem;
    width: 100%;
    text-align: center;
  }
  .video-and-overlay{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 100%;
    .video-player-wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      flex-basis: max-content;
      background-color: #d9d9d9;
      display: flex;
      justify-content: center;
      max-width: 26rem;
      margin: auto;
    }
  }
  .sub-event{
    position: absolute;
    width: 100%;
    z-index: 100;
    top: 0.6rem;
    color: white;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0.9rem;
    font-family: "Roboto";
    overflow: hidden;
  }
  .blur-shadow{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.829);
    z-index: -1;
    -webkit-filter: blur(2px);
    filter: blur(3px);
    border: 3px solid black;
  }    
  & > .logo {
    position: absolute;
    bottom: 0;
    right: -19px;
    width: 70px;
    z-index: 15;
    border-radius: 4px;
    &.shadow {
      max-width: 50px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
    }
  }
  & > .video-and-overlay {
    span{
      position: relative;
    }
    .video-overlay {
      height: 100%;
      width: 100%;
      background-color: #f2f2f2;
      min-height: 49rem;
      z-index: 2;
      bottom: 0;
    }
    .timeline{
      position: absolute;
      width: 100%;
      z-index: 100;
      bottom: 0;
      color: white;
      text-align: center;
      border-radius: 0px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 1.8rem;
      font-weight: 600;
      padding: 0.6rem;
      font-family: "Roboto";
      overflow: hidden;
      &.main{
        background-color: $color-performance-compare-1 !important;
      }
      &.compare{
        background-color: $color-performance-compare-2 !important;
      }
    }

    video {
      background-color: #f2f2f2;
      border-radius: 5px;
      position: relative;
      max-height: 100%;
      margin: auto;
      width: 100%;
      &.main {
        border-color: $color-performance-compare-1 !important;
        margin-left: 0;
      }
      &.compare {
        border-color: $color-performance-compare-2 !important;
        margin-right: 0;
      }
    }
  }
  & > span {
    display: block;
    text-align: center;
    color: #ccc6c6;
    font-weight: 300;
    margin-bottom: 1rem;
  }
}
