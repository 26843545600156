@import '../../../../../sass-config/abstracts/mixins.scss';
@import '../../../../../sass-config/abstracts/variables.scss';

.mccb {
  .legend {
    display: flex;
    align-items: center;
    align-self: flex-end;

    > div {
      border-radius: 0.16rem;
      margin-right: 1rem;
    }

    .legend-color {
      width: 0.96rem;
      height: 0.96rem;
    }
  }

  .mccb-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    overflow-x: auto;

    @include custom-scrollbar;

    .mccb-col-small {
      width: 100%;
      position: absolute;
      height: 482px;
      z-index: 1;
    }

    .mccb-side-col {
      background-color: white;
      width: 25px;
      z-index: 3;
      position: sticky;
      left: 0;

      display: flex;
      align-items: flex-end;
    }

    .mccb-col-large {
      z-index: 2;
      min-width: 99%;

      .mccb-container-body {
        height: 482px;
      }
    }

    .volume-chart-wrapper:not(:last-child) {
      border-right: 1px solid $color-border-layout;
      margin-right: 1.6rem;
    }
  }
}
