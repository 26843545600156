
.more-options-wrapper {
  img {
    cursor: pointer;
    width: 3.4rem;
  }
}
.ant-popover-inner{

  .ant-popover-inner-content {
    padding: 0.5rem;
    .options-wrapper {
      display: flex;
      flex-direction: column;
      width: 13.2rem;
      .item {
        height: 4rem;
        align-self: center;
        width: 100%;
        cursor: pointer;
        padding: 1rem 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #616161;
        img, svg {
          margin: 0 0.7rem;
        }
        @media (max-width: 1100px) {
          .hide{
            display: none;
          }

        }
      }
      .item:hover {
         background: #F6F6F6;
      }
    }
  }
}
.ant-popover-placement-bottomRight {
  padding: 0 !important;
  margin-top: 1.7rem !important;
  .ant-popover-content {
    margin-top: 1rem;
    .ant-popover-arrow {
      display: none;
    }
  }
}

