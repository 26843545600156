.header-button {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(26, 21, 21, 0.08) 0px 2px 4px 0px;
  border: 1px solid $color-pinkish-grey;
  padding: 0 0.5rem;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s;
  height: 65px;
  &.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }
  &.active {
    border: 1px solid $color-blueful;
    color: #2196f3;
    -webkit-box-shadow: inset 0px 0px 9px -6px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: inset 0px 0px 9px -6px rgba(0, 0, 0, 0.6);
    box-shadow: inset 0px 0px 9px -6px rgba(0, 0, 0, 0.6);
  }
  &__icon {
    max-width: 30px;
  }
  &__title {
    font-size: 1.2rem;
  }
}
