@import '../abstracts/variables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  font-size: 10px; // 1rem=10px
}

body {
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
  font-size: 14px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
ul,
p {
  margin: 0;
  padding: 0;
  color: black;
  font-weight: 400;
}

@include respond(phone) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}

// #root {
//   width: 100%;
//   max-width: 1200px;
//   margin: 0 auto;
//   overflow: auto;
// }

// /* Tablet */
// @media (max-width: 1024px) {
//   .root {
//     max-width: 800px; /* Max width for tablets */
//   }
// }

// /* Mobile */
// @media (max-width: 768px) {
//   .root {
//     max-width: 100%;
//   }
// }

.info-glyph {
  margin: 0 0.5rem;
  cursor: pointer;
}

.app-platform-android:after {
  content: '';
  background-image: url(../../assets/images/icons/playstore_icon.png);
  width: 2rem;
  height: 2rem;
  position: absolute;
  background-size: contain;
  bottom: -0.8rem;
  right: -1rem;
  z-index: 20;
}

.app-platform-ios:after {
  content: '';
  background-image: url(../../assets/images/icons/appstore_icon.png);
  width: 2rem;
  height: 2rem;
  position: absolute;
  background-size: contain;
  bottom: -0.8rem;
  right: -1rem;
  z-index: 2;
}

.app-platform-web:after {
  content: '';
  background-image: url(../../assets/images/icons/webapp.svg);
  width: 2rem;
  height: 2rem;
  position: absolute;
  background-size: contain;
  bottom: -0.8rem;
  right: -1rem;
  z-index: 2;
}

.app-platform-windows:after {
  content: '';
  background-image: url(../../assets/images/new_design/icons/ic-windows-selected.svg);
  width: 2rem;
  height: 2rem;
  position: absolute;
  background-size: contain;
  bottom: -0.8rem;
  right: -1rem;
  z-index: 2;
}

.app-platform-chrome:after {
  content: '';
  background-image: url(../../assets/images/new_design/icons/ic-chrome-selected.svg);
  width: 2rem;
  height: 2rem;
  position: absolute;
  background-size: contain;
  bottom: -0.8rem;
  right: -1rem;
  z-index: 2;
}

.beta-tag {
  color: white;
  background: #f4452e;
  padding: 0.3rem 1rem;
  border-radius: 15px;
  font-size: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  box-shadow: 0 0 5px 0 rgba(244, 68, 46, 0.36);
  text-transform: uppercase;
  font-weight: 600;
}

.modal-upload-image,
.modal-confirm-exit-pass {
  .ant-modal-close {
    display: block;
    top: -5rem;
    right: -5rem;
    color: white;

    i {
      font-size: 2rem !important;
    }
  }
}

.ant-modal-content {
  min-width: min-content;
}

.modal-activate-location,
.modal-change-pass {
  font-family: 'Roboto';

  .ant-modal-close {
    display: block;
    top: -5rem;
    right: -5rem;
    color: white;

    i {
      font-size: 2rem !important;
    }
  }

  .beta-tag {
    font-size: 0.8rem;
    top: 5.3rem;
    padding: 0.1rem 0.5rem;
    left: 5.2rem;
    width: 3rem;
    line-height: 1.5rem;
  }

  .ant-modal-content {
    padding: 5rem;
  }

  .ant-modal-header {
    padding: 0;
    border-bottom: none;
  }

  h4 {
    font-weight: 300;
    font-size: 1.7rem;
    font-family: 'Roboto Slab';
  }

  h2 {
    margin-bottom: 3rem;
    font-family: 'Roboto Slab';
    margin-top: 1rem;
  }

  .ant-modal-body {
    padding: 0;

    .feedback-descr {
      color: #4f4f4f;
      margin-bottom: 3rem;

      span {
        display: block;
        color: #c3c3c3;
        font-style: italic;
        margin-top: 1rem;
      }
    }
  }

  .ant-select-selection__placeholder {
    line-height: 3.6rem;
    height: 3rem;
  }

  .ant-select-search__field {
    height: 3rem;
    top: 0.7rem;
    position: relative;
  }

  .ant-select-selection-selected-value {
    position: relative;
    top: 0.8rem;
  }

  .ant-select-selection {
    height: 5rem;
    border: 1px gray solid;
    box-shadow: none !important;

    .ant-select-selection--single {
      line-height: 4.6rem;

      .ant-select-arrow-icon {
        color: black;
      }
    }
  }

  .ant-modal-footer {
    border-top: none;
    padding: 0;
    margin-top: 2rem;

    .ant-btn-default {
      color: #f4442e;
      border: 1px #f4442e solid;
      padding: 0.5rem 3rem;
      height: 4rem;
      border-radius: 2.7px;
      transition: all 0.1s ease-in;

      &:hover:not([disabled]) {
        background: linear-gradient(270deg, #f75d38 0%, #f4442e 100%);
        box-shadow: 0 3px 15px 0 rgba(244, 68, 46, 0.15);
        color: white;
      }
    }
  }

  .request-sent {
    margin-top: 1rem;
    text-align: center;
    color: #029602;
  }
}

.Mui-focused {
  &.MuiFormLabel-root {
    color: black !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
    border-width: 1px;
  }
}

.MuiInputBase-root:hover {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba($color: black, $alpha: 0.5) !important;
    border-width: 1px;
  }
}

.ant-input {
  border-radius: 4px;
}

.ant-input:focus {
  border-color: black !important;
}

.ant-input:hover {
  border-color: black !important;
}

.ant-btn:disabled {
  opacity: 0.4;
}

.ant-select-selection:focus,
.ant-select-selection:hover {
  border-color: black;
}

.flag-icon {
  border: 1px #e8e8e8 solid;
}

.ant-select-arrow {
  right: 1.1rem;
  margin-top: -0.6rem;
  font-size: 1.2rem;
}

.ant-btn.black {
  border-color: black;
  color: black;
  height: 3.2rem;
  padding: 0 1.5rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;

  &::after {
    content: '';
    display: none !important;
  }
}

.ant-btn:active {
  border-color: black;
}

.ant-btn:focus {
  color: black;
  border-color: black;
}

.ant-btn:hover:not([disabled]) {
  color: white !important;
  background-color: black !important;
  border-color: black !important;
}

.ant-select-selection:focus,
.ant-select-selection:hover {
  border-color: black;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
}

.ant-checkbox-wrapper-disabled {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f5f5f5;
  }
}

.ant-input:focus {
  box-shadow: none;
}

.ant-select-dropdown {
  padding: 0rem 0.5rem;
}

.ant-select-dropdown-menu {
  padding: 0rem;
}

.ant-select-dropdown-menu-item {
  height: 3.4rem;
  margin: 0.5rem 0;

  &:hover:not(.ant-select-dropdown-menu-item-selected) {
    background-color: #f7f7f7 !important;
    color: black !important;
    box-shadow: none;
  }
}

.ant-select-dropdown-menu-item-selected {
  background-color: #000000;
  color: white !important;

  &:hover {
    background-color: #000000;
    color: white;
    box-shadow: none;
  }
}

.ant-calendar {
  box-shadow: none;
}

.ant-select-dropdown-menu {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.slick-arrow-def {
  height: 4.4rem;
  width: 4.4rem;
  border: 1px solid #e7eaed;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;

  &::before {
    content: '';
  }

  .slider-arrow.arrow-right {
    transform: rotate(180deg);
  }

  &:hover {
    box-shadow: -5px 0 20px 0 rgba(0, 0, 0, 0.18);
    background-color: white;
  }
}

.ant-btn {
  height: 3.2rem;
  font-size: 1.4rem;
}

.ant-btn-primary {
  background-color: black;
  border-color: black;
}

@media (min-width: $max-width-secondary) {
  .large {
    display: block;
  }

  .normal {
    display: none;
  }
}

@media (max-width: $max-width-secondary) {
  .large {
    display: none;
  }

  .normal {
    display: block;
  }
}

.antd-popover-black {
  .ant-popover-inner-content {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    color: white;
    padding: 1rem;
  }

  .ant-popover-arrow {
    left: 0.7rem;
    border-color: rgba(0, 0, 0, 0.75) !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    mix-blend-mode: color;
    transform: translateY(-50%) rotate(45deg);
    border-left: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    color: rgba(0, 0, 0, 0.75);
    border-right-color: rgba(0, 0, 0, 0.75);
    border-bottom-color: rgba(0, 0, 0, 0.75);
  }
}

.ant-calendar-range {
  width: 55.2rem;
}

.ant-select-auto-complete.ant-select .ant-input {
  height: 3.2rem;
  padding: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  line-height: 2.2rem;
}

.nant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 1.4rem;
  margin-right: 1rem;
}

.ant-badge-status-text {
  font-size: 1.4rem;
}

.ant-radio-checked .ant-radio-inner {
  border-color: black;
}

.ant-radio-checked::after {
  border-color: black;
}

.ant-radio-inner::after {
  background-color: black;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #4e4e4e;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}

.ant-select.selectbox-style-wout-border {
  .ant-select-selection {
    border-color: transparent !important;

    &:focus,
    &:hover {
      box-shadow: none !important;
    }
  }

  .ant-select-arrow {
    path {
      fill: black;
    }
  }

  .ant-select-selection-selected-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
  }
}

.ant-message-notice-content {
  background-color: #1c9dfc;
  color: #ffffff;

  .ant-message-error {
    svg {
      fill: white;
    }
  }
}

.antd-black-select {
  .ant-select-selector {
    background-color: black !important;
    color: white;
  }

  &.ant-select-focused {
    .ant-select-selection-search-input,
    .ant-select-selection-item {
      color: white !important;
    }
  }

  .ant-select-selection__rendered {
    display: flex;
    justify-content: center;
  }

  .ant-select-selection-selected-value {
    color: white;
  }

  .ant-select-arrow {
    svg path {
      fill: white;
    }
  }

  .ant-select-selection__placeholder {
    text-align: center;
    color: white;
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  border: none;
  background-color: #f9f9fb;
  align-items: center;

  input {
    padding-left: 1rem;
    min-height: 3.6rem;
  }

  .ant-input-prefix {
    margin: 0 0.7rem;
  }

  .ant-input-suffix {
    margin: 0 0.7rem;
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-outlined:focus-within {
  box-shadow: none;
}

.ant-tabs-tab {
  padding: 1.2rem 1.6rem !important;
}

.ant-scroll-number {
  z-index: 1;
}

.ant-tooltip-open {
  z-index: 200;
}

.ant-select-selector {
  border: none !important;
  background-color: inherit;
}

.antd-black-select-dropdown {
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-active:active {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 0rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: white !important;
    color: black !important;
    align-items: center;
  }

  .ant-select-dropdown-menu-item:not(:last-child) {
    border-bottom: 1px solid #d9d9d9 !important;
  }
}

.antd-red-button,
.antd-red-button:hover {
  &:not(:disabled) {
    background: #ff2741 !important;
    color: white;
  }
}
.ant-btn[disabled] {
  background: #ffffff !important;
  border: 1px solid #d4d4d4 !important;
  color: #d4d4d4 !important;
}

.filter-select-new-wrapper {
  span.ant-select-selection-item {
    color: #222322;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.filter-select-new-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: black;
    color: white;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:active {
  color: black;
}

.ant-btn-group .ant-btn-lg {
  display: flex;
  align-items: center;
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: black !important;
}

.ant-select-single {
  background-color: #fff;

  .ant-select-selector:focus,
  .ant-select-selector:active {
    box-shadow: 2px 2px 6px 1px #0000001f !important;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f7f7f7 !important;
    color: #000 !important;
    box-shadow: none;
  }
}

.date-picker-wrapper {
  .ant-picker.ant-picker-range {
    visibility: hidden;
  }
}

.ant-picker-dropdown-range {
  transition: none !important;
  height: 28.2rem !important;

  .ant-picker-range-arrow {
    display: none;
  }

  .ant-picker-panel-container {
    box-shadow: none;
  }

  .ant-picker-panel-container .ant-picker-panel {
    border: none;
  }
}

.ant-picker-calendar-full {
  .ant-picker-body {
    padding-bottom: 0rem !important;
  }

  .ant-picker-calendar-date {
    border-top: none !important;
    margin: 0rem !important;
  }

  .ant-picker-cell:hover {
    .ant-picker-calendar-date {
      background: transparent !important;
    }

    .ant-picker-calendar-date-value {
      color: black !important;
    }
  }

  .ant-picker-calendar-date-content {
    height: 4.7rem !important;
    overflow: visible !important;
  }

  .ant-picker-calendar-date-today {
    margin: 0rem !important;
    height: 7.6rem;
    background: none !important;
    border-top: none !important;
    border-bottom: 3px solid #000000 !important;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      #00000015 100%
    ) !important;

    .ant-picker-calendar-date-value {
      color: black !important;
    }
  }
}

.ant-select-single .ant-select-selector:active,
.ant-select-single .ant-select-selector:focus {
  box-shadow: none !important;
}

.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
  box-shadow: none !important;
  background-color: rgba(243, 243, 243, 0.6);
}

.fixed-filters {
  position: fixed;
}

.ant-select-auto-complete.search-auto-complete.ant-select-focused
  .ant-input-affix-wrapper {
  box-shadow: none !important;
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content {
  transform: translateY(-11px) rotate(224deg);
  top: -2px;
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  border-color: transparent #fff #fff transparent;
  background-color: rgba(0, 0, 0, 0.75) !important;
  transform: translateX(-50%) translateY(100%) rotate(45deg);
  border-right: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
  bottom: 2.7rem !important;

  .ant-popover-arrow-content {
    box-shadow: none !important;
  }
}

.wf-ant-range-picker-popup {
  .ant-picker-body {
    font-size: 1.2rem;
    font-family: 'Roboto' !important;

    tbody tr:not(:has(td.ant-picker-cell-in-view)) {
      display: none;
    }
  }

  .ant-picker-cell .ant-picker-cell-wrap.in-range {
    background-color: #fdecea !important;
  }

  .ant-picker-cell .ant-picker-cell-wrap {
    width: 36px;
  }

  .ant-picker-header {
    border: none;
    align-items: center;
  }

  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none !important;
  }

  button.ant-picker-header-next-btn,
  button.ant-picker-header-prev-btn {
    padding: 1rem;
    border: 1px solid $color-border-layout;
    border-radius: 0.4rem;
  }

  .ant-picker-cell,
  .ant-picker-cell-inner,
  .ant-picker-cell::before {
    transition: none !important;
  }

  .ant-picker-cell-wrap.end-date .ant-picker-cell-inner,
  .ant-picker-cell-wrap.start-date .ant-picker-cell-inner,
  .ant-picker-cell-wrap.only-today .ant-picker-cell-inner {
    position: relative;
    background-color: #f4452f !important;
    color: white;
    width: 24px;
    min-width: 24px;
    border-radius: 50%;

    &::before {
      border: 1px black solid !important;
      border-radius: 50% !important;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
    border-bottom: 1px black solid;
    border-radius: 0;
  }

  .ant-picker-cell-wrap.start-date:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 50%;
    height: calc(100% - 6px);
    width: 50%;
    background-color: #fdecea;
    z-index: 0;
  }

  .ant-picker-cell-wrap.end-date:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0%;
    height: calc(100% - 6px);
    width: 50%;
    background-color: #fdecea;
    z-index: 0;
  }

  .ant-calendar-today .ant-picker-cell-inner {
    color: rgba(0, 0, 0, 0.65) !important;
    border-color: transparent !important;
    border-bottom-color: black !important;
    font-weight: 500 !important;
  }

  .ant-picker-cell-in-view:last-child {
    .ant-picker-cell-wrap.in-range {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .ant-picker-cell-in-view:first-child {
    .ant-picker-cell-wrap.in-range {
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
    }
  }

  .ant-picker-year-panel,
  .ant-picker-month-panel {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: #f4452f !important;
    }
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-select-focused .ant-select-selection-item {
  color: black !important;
}

.ant-modal {
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn {
  border-radius: 4px;
}

.marketing-filter-type {
  .ant-select-show-search {
    border-bottom: 1px black solid;
  }
}

.ant-segmented {
  background-color: $color-white;
  border: 1px solid $color-border-layout;
  border-radius: 0.8rem;
  padding: 0;

  label {
    padding: 0rem 1rem;

    &.ant-segmented-item:not(:last-child) {
      border-right: 1px solid $color-border-layout;
      border-radius: 0;
    }

    &.ant-segmented-item {
      box-shadow: none;

      &:first-child,
      &:first-child:hover,
      &:first-child:active {
        border-radius: 0;
        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child,
      &:last-child:hover,
      &:last-child:active {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
      }
    }

    &.ant-segmented-item-selected {
      background-color: #dde3fa;
    }
  }
}

input[type='checkbox'],
.ant-checkbox-input,
.ant-checkbox-inner {
  border-color: $color-border-layout;
}

.ant-radio-group-large {
  background-color: #f1f1f1;
  border-radius: 1.6rem;
}

.ant-radio-group-large .ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
  display: none;
}

.ant-radio-group-large .ant-radio-button-wrapper:first-child {
  border-start-start-radius: 1.6rem;
  border-end-start-radius: 1.6rem;
}

.ant-radio-group-large .ant-radio-button-wrapper:last-child {
  border-start-end-radius: 1.6rem;
  border-end-end-radius: 1.6rem;
}

.ant-radio-button-wrapper {
  background-color: #f1f1f1 !important;
  border-color: #f1f1f1 !important;
}

.ant-radio-button-wrapper-checked {
  background-color: #496ffa !important;
  border-color: #496ffa !important;
  border-radius: 1.6rem;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 4rem;
  line-height: 4rem;
}

.filters-search {
  background-color: $color-white;

  .ant-input-affix-wrapper {
    background-color: $color-white;
  }

  .ant-input-outlined {
    height: 4rem;
    padding: 0.8rem 0.8rem 0.8rem 1.6rem;
    border: 1px solid $color-border-layout;
    border-radius: 1.6rem;
  }
}

.filter-checkbox {
  .ant-checkbox-inner {
    background-color: transparent;
    border: 1px solid black;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid black;
    border-top: 0;
    border-left: 0;
  }
}

.filter-checked {
  background-color: #e9f2ff;

  .ant-checkbox,
  .ant-checkbox-inner {
    border-color: #1a3dfa;
    background-color: #1a3dfa;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $color-white;
  }
}
