@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:200,300,400,500,600,700|Roboto+Slab+Light:100,200,300|Roboto:300,400,500,900|Roboto+Condensed:300,400,500,600,700&display=block');

@mixin link {
    a {
        text-decoration: none;
        color: inherit;
        transition: none;
        &:hover{
            color:#F4442e !important;
        }
    }
}

#login-page{
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    color: #000;
    font-size: 16px;


    h1 {
        font-family: 'Roboto Slab', serif;
        position: relative;
        font-size: 3.3em;
        font-weight: 200;
        letter-spacing: 0.01em;
    }
    strong {
        font-weight: 400;
    }
    button.red-button {
        position: relative;
        top: 0;
        border: none;
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 1em;
        border-radius: 2px;
        animation: move-out 0.5s ease-in-out;
        cursor: pointer;
    }
    .cond-bold {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 400;
    }
    .no-account{
       @include link;
    }
    .or-strike-through {
        position: relative;
        .strike-through {
            height: 2px;
            width: 100%;
            background-color: #BCBCBC;
        }
        .or {
            position: absolute;
            right: 40%;
            top: -1.1rem;
            background-color: white;
            padding: 0 2rem;
            color: #BCBCBC;

        }

    }
    .sso-button {
        button{
            width: 100%;
            background-color: white;
            border: 1.5px solid #BCBCBC;
            border-radius: 3px;
            box-sizing: border-box;
            margin-top: 2.5rem;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            padding: 1rem;
            color: #BCBCBC;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            a {
                color: inherit;
            }
            &:hover {
                color: #808080;
                border-color: #808080;
            }
            .login-with-sso-text{
                margin: auto;
            }
            .spinner-wrapper {

            }
        }
    }

    .btn-wrapper:hover button.red-button {
        box-shadow: none;
        background-color: #f40000;
    }
    .dashed-line {
        margin: 5px 0;
        height: 1px;
        background: repeating-linear-gradient(to right, #C3C3C3 0, #C3C3C3 5px,transparent 3px,transparent 9px)
    }


    .Mui-focused {
        &.MuiFormLabel-root {
            color: #F4442E !important;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: #F4442E !important;
            border-width: 1px;
        }
    }
    .MuiInputBase-root:hover {
        .MuiOutlinedInput-notchedOutline {
            border-color: rgba($color: #F4442E, $alpha: 0.5) !important;
            border-width: 1px;
        }
    }


}

#login-page {
    height: 100vh;
    overflow: hidden;

    .video-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
  }

  .video-overlay{
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    background-color: #191D24;
    opacity: 0.8;
  }
}
#login-page  video {
    width: 101%;
    position: absolute;
    top: -10px;
    filter: blur(11px) brightness(60%);
    left: -10px;
    min-width: 103%;
    min-height: 103%;
    object-fit: cover;
}

#login-page .login-wrapper {
    position: relative;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 949px;
    margin: auto;
    overflow: hidden;
    top: 0.6em;
    z-index: 122;
}
#login-page .login-wrapper .side-block {
    position: relative;
    padding: 3.75em 3.67em;
    background-color: #000;
    min-height: initial;
    height: 33em;
    max-width: 49.4rem;
    color: #fff;
}
#login-page .login-intro {
  color: white;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: linear-gradient(70deg, #040609 0%, #282f3a 100%);

  h3 {
    margin-top: 2.35em;
    letter-spacing: 0.09em;
    color: #CCC6C6;
    font-size: 1em;
  }

  h1 {
    font-family: 'Roboto Slab', serif;
    font-size: 1.8em;
    font-weight: 200;
    letter-spacing: 0.04em;
    margin: 0.35em 0 1.8em;
    color: white;
    line-height: 1em;
    strong{
        font-weight: 600;
        letter-spacing: 0.018em;
    }
  }  

  p {
    font-size: 1.2em;
    font-weight: 100;
    line-height: 2.1em;
    letter-spacing: 0.015em;
    margin-left: 1.5%;
    color: white;
  }
}


#login-page .login-intro .dashed-line {
    width: 56%;
    margin: 3.65em 0 2.05em;
    color: #CCC6C6;
}
#login-page .no-account-wrapper a, #login-page .no-account-wrapper span {
    font-size: 0.9em;
    display: inline-block;
    margin-right: 5.17rem;
    letter-spacing: 0.03em;
}

#login-page .no-account-wrapper a:nth-child(2) {
    text-decoration: underline;
}


#login-page .login-wrapper .login-card {
    background-color: #fff;
    max-width: 37.7rem;
    color: #000;
    padding: 2rem 2.9em 3.85em 3.3em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    .email {
        color: #242424;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.18px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 1rem;
    }
    .expired {
        display: block;
        margin: auto;

    }
    .expiration-img {
        margin-top: 4rem;
    }
    .expired-text {
        font-weight: 200;
    }
    form {
        padding-top: 0.3em;
    }
}
#login-page .login-card input[type=checkbox],
#login-page .login-card textarea {
    border-radius: 2px;
    box-shadow: 0 5px 20px 0 rgba(217,217,217,0.18);
    font-size: 1.2em;
    border: 1px solid #27272752;
    padding: 0.5em 5%;
    width: 100%;
    cursor: pointer;
}
#login-page .login-card input[type=checkbox]:focus,
#login-page .login-card textarea:focus {
    border: 1px solid #F4442E;
    box-shadow: 0 5px 20px 0 rgba(255,88,88,0.18);
    outline: none;
}
#login-page .login-card .no-account-wrapper {
    display: none;
}
#login-page .login-card .input-wrapper {
    position: relative;
    padding: 0;
    width: 100%;
    float: left;
    margin: 5.5% 3% 5.5% 0;
}
#login-page .login-card .input-wrapper:nth-child(2n) {
    margin-right: 0;
}
#login-page .login-card input[type=checkbox] {
    box-sizing: border-box;
    height: 3em;
}
#login-page .login-card .input-wrapper input[type=checkbox]:hover {
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.11);
}
#login-page .login-card #remember-checkbox {
    height: 1.1em;
    width: 1.1em;
    margin: 0;
    -webkit-appearance: none;
    box-sizing: border-box;
    padding: 0.5em;
    border-color: grey;
    box-shadow: none;
    position: relative;
    &:hover{
        border-color: rgba($color: #F4442E, $alpha: 0.5) !important;
        border-width: 1px;
    }
}
#login-page .login-card #remember-checkbox:checked {
    background-color: #F4442E;
    border-color: #F4442E;
}
#login-page .login-card #remember-checkbox:checked:after {
    content: '';
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 0.6em;
    width: 0.3em;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    -webkit-appearance: none;
    cursor: pointer;
    left: 0.33em;
    top: 0.1em;
    transition: all .3s ease-in-out;
}
#login-page .login-card .input-wrapper label {
    position: absolute;
    left: 0.4em;
    top: 35%;
    font-size: 1.1em;
    cursor: pointer;
    display: inline-block;
    background-color: #fff;
    color: #D4D4D4;
    padding: 0 5px;
    width: 90%;
    line-height: 1em;
}
#login-page form .input-wrapper input[value][type=checkbox]:not([value=""]):not(:focus) + label {
    top: -0.5em;
    color: #272727;
    width: auto;
    font-size: 0.8em;
}
#login-page .login-card .input-wrapper.required label::after {
    content: '*';
    position: absolute;
    color: #F4442E;
    right: -0.7em;
    top: -0.65em;
    font-size: 1.1em;
}

#login-page .login-card  .forgot-password {
    position: relative;
    top: -0.05em;
}
#login-page .input-wrapper input[value][type=checkbox]:not([value=""]):not(:focus) + label::after,
#login-page .input-wrapper input:focus + label::after {
    display: none;
}
#login-page .login-card .input-wrapper input[type=checkbox]:focus + label {
    display: block;
    top: -0.5em;
    color: #F4442E;
    width: auto;
    font-size: 0.8em;
}
#login-page .login-card .bottom-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
#login-page .login-card #remember-checkbox + label {
    display: inline-block;
    position: relative;
    font-size: 0.9em;
    color: #4E4E4E;
    margin-left: 0.5em;
    margin-right: 2.1em;
    line-height: 1em;
    letter-spacing: 0.018em;
    cursor: pointer;
}
#login-page .bottom-wrapper a {
    margin-right: 0;
    text-decoration: underline;
    font-size: 0.9em;
    color: #4e4e4e;;
    padding-right: 0;
    letter-spacing: 0.03em;
}
#login-page .login-card .btn-wrapper {
    width: 102%;
    height: 2.7em;
    margin: 1.15em 0;
    font-size: 1.3em;
}
#login-page .login-card button.red-button {
    background-color: #f14634;
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 0.02em;
    box-shadow: 0 12px 25px 0 rgba(255,88,54,0.07);
    width: 100%;
    height: 100%;
    margin: -0.1em 0 0 -0.1em;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    &[disabled]{
      background-color: rgba(244, 69, 46, 0.534);
    }
    .login-text {
        margin: auto;
    }
    .spinner-wrapper {
        position: absolute;
        right: 2rem;
    }
}
#login-page .login-card > span {
    font-size: 0.9em;
    display: inline-block;
    text-align: center;
    width: 100%;
    letter-spacing: 0.02em;
    color: #CCC6C6;
}
#login-page .login-card > span a {
    text-decoration: underline;
    color: #BFB9B9;
}
#login-page .login-card h2 {
    text-align: center;
    font-size: 1.7em;
    margin: 0.8em auto 0.8em 0.2em;
    font-weight: 600;
    line-height: 1em;
    font-family: 'Roboto Slab';
}

/***** animation *****/
@keyframes move-in {
    from {
        top: 0;
    }
    to {
        top: 5px;
    }
}
@keyframes move-out {
    from {
        top: 5px;
    }
    to {
        top: 0;
    }
}

@media (max-width: 780px) {
    #login-page .login-wrapper {
        position: absolute;
        height: 100%;
        flex-direction: column;
        border-radius: 0;
        max-width: unset;
        width: 100vw;
        margin: 0;
    }
    #login-page .login-wrapper .login-intro {
        height: 0;
        padding-top: 1em;
        padding-left: 1em;
        padding-bottom: 3em;
    }
    #login-page .login-wrapper .login-card {
        max-width: 49rem;
        padding-top: 50px;
        width: 100%;
    }
    #login-page .login-card .no-account-wrapper {
        display: flex;
        justify-content: space-between;
    }

    #login-page .login-card .no-account-wrapper a {
        margin: 0;
    }
}
@media (max-width: 33em) {
    #login-page .login-wrapper {
        justify-content: end;
    }
    #login-page .login-wrapper .login-intro {
        max-width: 100%;
    }
    #login-page .login-wrapper .login-card {
        max-width: 100%;
        height: 100%;
        width: auto;
    }
}