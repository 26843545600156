.performance.performance-metrics {
  .performance-app-drawer {
    flex-basis: 100%;

    .performance__header {
      width: 50%;
    }

    .performance-filters {
      justify-content: left;

      &>div {
        flex-basis: 18%;
        min-width: 18rem;
      }

      .performance-drawer__top__chevron {
        right: 3rem !important;
      }
    }
  }



  .performance-filters {
    margin-top: 2rem;
  }

  .benchmark__sub-header {
    top: 33rem
  }

  .metrics-graphics-cont {
    
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 0rem 3rem 7rem;
    margin-top: 8rem;
    position: relative;

  }

  .new-ver-legend {
    position: absolute;
    top: -4rem;
    right: 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 12px;
    text-transform: uppercase;
    color: #3C4554;

    .new-ver-legend-sign {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .metrics-graphics {
    margin: auto;
    margin-top: 7rem;
    position: relative;
    width: 100rem;

    .performance-history-graphs__line-wrapper{
      width: 100rem;
      height: 15rem;
    }

    .no-metrics-data {
      position: absolute;
      width: 26rem;
      height: 24rem;
      left: calc(50% - 13rem);
      top: 8rem;
      z-index: 10;
      background: linear-gradient(180deg, #F8F8F8 0%, #EFEFEF 100%);
      box-shadow: 0px -1px 23px 3px rgba(0, 0, 0, 0.11);
      border-radius: 10px;
      padding: 1rem 2rem;

      .no-metrics-data-title {
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 2.2rem;
        color: #000000;

      }

      .no-metrics-data-descr {
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        padding: 0rem 2rem;
      }

      .no-metrics-data-img {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
      }
    }


    .slider-arrow {
      font-size: 2.4rem;
      color: black;
      width: 4rem;
      line-height: 5rem;
      height: 4rem;
      right: auto;
      position: absolute;
      top: 25rem;
      border: 1px solid #e7eaed;
      border-radius: 21.5px;
      background-color: #ffffff;
      box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.3);
      cursor: pointer;
      z-index: 555;
      user-select: none;

      &:hover {
        transform: none !important;
      }

      &.arrow-right:hover {
        transform: rotate(180deg) !important;
      }

      &.arrow-left {
        left: -5.5rem;
      }

      &.arrow-right {
        transform: rotate(180deg);
        right: -5rem;
      }

      img {
        width: 2rem;
        position: relative;
        top: -0.3em;
        left: 1rem;
      }
    }

    .x-indicator {
      position: absolute;
      top: 0rem;
      height: calc(100% - 2rem);
      width: 2px;
      border: 1px black solid;
    }

    .performance-history-graphs {
      margin-top: 0rem !important;

      .performance-history-graphs__graph-wrapper {
        margin-top: 0rem;
        padding: 0 !important;
        border: none;
        position: relative;
        height: 18rem;
      }
    }

    .left-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      display: flex;
      text-align: center;
      text-transform: uppercase;
      color: #3C4554;
      position: absolute;
      width: 6rem;
      top: 4rem;
      left: -1.2rem;
    }
  }

  .back-btn-wrapper {
    position: absolute;
    top: 8rem;
    right: 0rem;
    z-index: 3;

    button {
      .btn-arrow {
        margin-right: 0rem;

        img {
          width: 1.1rem;
          position: relative;

          &.white-arrow {
            left: -1.1rem;
            visibility: hidden;
          }
        }
      }

      &:hover {
        img.white-arrow {
          visibility: visible;
        }
      }

      z-index: 444;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      color: #000000;
    }
  }

}


@media (max-width:1720px) {

  .metrics-graphics {
    width: 85rem !important;
  }

  .performance.performance-metrics .metrics-graphics .performance-history-graphs__line-wrapper {
    width: 85rem !important;
    height: 12rem !important;
  }

  .performance.performance-metrics .metrics-graphics .left-title {

    top: 3rem;
    left: -1.2rem;
  }
}

@media (min-width:2150px) {
  .metrics-graphics {
    width: 125rem !important;
  }

  .performance.performance-metrics .metrics-graphics .performance-history-graphs__line-wrapper {
    width: 125rem !important;
    height: 20rem !important;
  }

  .performance.performance-metrics .metrics-graphics .left-title {

    top: 7rem;
    left: -3.2rem;
  }
}


@media (min-width:2380px) {
  .metrics-graphics {
    width: 145rem !important;
  }

  .performance.performance-metrics .metrics-graphics .performance-history-graphs__line-wrapper {
    width: 145rem !important;
    height: 25rem !important;
  }

  .performance.performance-metrics .metrics-graphics .left-title {

    top: 8rem;
    left: -2.2rem;
  }
}


