@import '../../../../sass-config/abstracts/variables.scss';

.selected-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 2.56rem 2.56rem 0;

  .selected-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 0.64rem;
    border: 1px solid #e3e4e3;
    background: $color-light-grey;

    span {
      color: #161515;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }

    .selected-icon {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    .close-icon {
      cursor: pointer;
    }
  }
}
