.bi-weekly-header {
  position: absolute;
  top: -24rem;
  width: 100%;
  color: white;

  .bi-weekly-title {
    position: relative;
    img {
      width: 20rem;
    }
    .request-btn-wrap {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
  .bi-weekly-title-2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 300;
    font-size: 5rem;
    line-height: 8.4rem;
    white-space: nowrap;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .share-btn {
      box-sizing: border-box;
      border: 1px solid #FFFFFF;
      border-radius: 2px;
      background-color: transparent;
      color: white;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 73.19%;
      text-transform: uppercase;

      &:hover:not([disabled]) {
        color: white !important;
        background-color: transparent !important;
        border-color: white !important;
      }
      &:focus {
        color: white !important;
        background-color: transparent !important;
        border-color: white !important;
      }
        img {
          margin-left: 1rem;
          filter: invert(100%)
        }

    }
  }
  .bi-weekly-dates {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .bi-weekly-descr {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 73.19%;
    margin: 1rem 0rem;
  }
  .ins-lines {
    display: flex;
    height: 5rem;
    align-items: end;
    width: 100%;
    .ins-line-wrap {
      flex-grow: 1;
      margin: 0 0.4rem;
      position: relative;
      img.ins-viewed {
        position: absolute;
        bottom: 0;
        left: calc(50% - 2.5rem);
        display: none;
      }
      .ins-line {
        background-color: #3c4554;
        border-radius: 3px;
        height: 5px;

        &.dark-green {
          background-color: #2D7D08;
        }
        &.light-green {
          background-color: #52e60c;
        }
      }
    }
  }
}
.ant-modal-wrap.rate-biweekly-highlights {
  top: 20rem;

  .bi-weekly-rate-stars {
    display: flex;
    margin: 2rem auto;
    justify-content: space-between;
    width: 22rem;
    cursor: pointer;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  .rate-btn-not-now.ant-btn-default {
    font-size: 1.3rem;
    width: 20rem;
    height: 3rem;
    background-color: #e5e5e5;
    margin: 2rem auto;
  }
  .bi-weekly-rate-btns {
    display: flex;
    margin: 2rem auto;
    justify-content: space-between;
    width: 24rem;
    margin-top: 4rem;
    .rate-btn-not-now {
      background: #e5e5e5;
      &:hover:not([disabled]) {
        color: black !important;
        background-color: #e5e5e5 !important;
        border-color: black !important;
      }
    }
  }
}

.ant-modal-wrap.thanks-rate-biweekly-highlights {
  top: 20rem;
  h1 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 600;

    text-align: center;
    font-size: 1.7rem;
  }

  .bi-weekly-rate-thanks-iamge {
    position: relative;
    width: 20rem;
    height: 17rem;
    margin: 2rem auto;
    img {
      position: absolute;
      width: 20rem;
      &:nth-child(2) {
        top: 2.5rem;
        left: 3.3rem;
        width: 13rem;
      }
    }
  }
}

@keyframes flag-up {
  from {
    bottom: 3rem;
  }
  to {
    bottom: 16rem;
  }
}
@keyframes cloud-float-1 {
  from {
    left: -6rem;
  }
  50% {
    left: 0rem;
  }
  to {
    left: -6rem;
  }
}

@keyframes cloud-float-2 {
  from {
    right: -6rem;
  }
  50% {
    right: -1rem;
  }
  to {
    right: -6rem;
  }
}

.last-slide-bi-weekly {
  h1 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: #000000;
    margin: 2.5rem auto 0.5rem;
  }
  h3 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;

    line-height: 3.2rem;
    text-align: center;
    font-size: 2rem;
  }
  .last-slide-bi-weekly-animation-wrap {
    padding-top: 4rem;
    margin-bottom: 4rem;
    .castle-animation {
      width: 15rem;
      margin: auto;
      position: relative;
      .castle-img {
        position: relative;
        z-index: 3;
        width: 100%;
      }
      .flag {
        position: absolute;
        bottom: 3rem;
        left: 5rem;
        left: 50%;
        animation: flag-up 0.8s 1.3s 1 ease-out forwards;
        width: 4rem;
        img:nth-child(1) {
          width: 100%;
        }
        img:nth-child(2) {
          position: absolute;
          bottom: 0.6rem;
          left: 28%;
          width: 1.4rem;
        }
      }
      .cloud {
        position: absolute;

        &.cloud-1 {
          top: 1.7rem;
          left: -6rem;
          animation: cloud-float-1 3.7s infinite ease-in-out;
        }
        &.cloud-2 {
          top: 2rem;
          right: -6rem;
          animation: cloud-float-2 4.2s infinite ease-in-out;
        }
      }
    }
  }
  .last-slide-bw-share-wrap {
    padding-bottom: 4rem;
    padding-top: 4rem;
    display: flex;
    justify-content: center;
  }
  .share-btn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    background: transparent;
    color: rgb(95, 95, 95);
    border-color: rgb(151, 151, 151);
    &:hover:not([disabled]) {
      color: black !important;
      background-color: transparent !important;
      border-color: black !important;
    }
    &:focus {
      color: black !important;
      background-color: transparent !important;
      border-color: black !important;
    }
    img {
      margin-left: 1rem;
    }
  }
  .last-slide-bw-suggestion-rate,
  .last-slide-bw-suggestion-rated {
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
  }
  .last-slide-bw-suggestion-rate-yes-no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4rem;
    & > div {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;

      text-decoration-line: underline;
      text-transform: capitalize;
      cursor: pointer;
      color: #1355ff;
      margin: 2rem;
    }
  }
  .last-slide-bw-suggestion-rated {
    padding-bottom: 7rem;
    width: 25rem;
    margin: auto;
  }
}
