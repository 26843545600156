@import "../../../../components/charts/LineChart/WLineChart";
@import "src/sass-config/abstracts/variables";
.marketing-distributions {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 2rem;
  row-gap: 2rem;
  @media screen and (max-width: $max-width-primary) {
    grid-template-columns: repeat(2, 1fr);
  }

  .competitor-card{
    width: 33rem;
    .card-layout {
      display: flex;
      flex-direction: column;
      height: 110%;
      .pie {
        
      }
      .marketing-discoveries {
        font-family: Roboto;
        line-height: 1.5rem;
        font-weight: 500;
        letter-spacing: 0;
        font-size: 1.5rem;
        text-align: center;
        position: absolute;
        top: 36.5rem;
        width: 100%;
      }
    }
  }
}