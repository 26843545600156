.tags-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-flow: column;
  margin-top: 1rem;
  .persona-roles {
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: #000000;
    width: 100%;
    grid-row: 1;
    grid-column: 1 / 3;
  }
  &-field {
    grid-row: 1;
    grid-column: 3 / 6;


  }

}
.tags {
  &__tags {

    width: 100%;
    display: flex;
    position: relative;
    left: -0.5rem;

    &.side-search {
      position: absolute;
      left: -382px;
      width: 33%;
      @include respond(bp-mobile) {
        display: none;
      }
      top: 9rem;
      flex-wrap: wrap;
      opacity: 0;
      visibility: hidden;
      transition: all 0.8s ease;
      &.opened {
        visibility: visible;
        left: 55%;
        overflow: auto;
        opacity: 1;
      }
    }

    &-tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      &.bottom-gradient {
        margin-top: 2rem;
        overflow-y: scroll;
        max-height: 25rem;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

      }
    }
    &-tag-element {
      text-transform: uppercase;
      border-radius: 20px;
      color: black;
      border: 1px solid black;
      font-weight: 300;
      background-color: #ffffff;
      margin: 0 0.4rem 0.9rem 0.4rem;
      padding: 0.1rem 1.1rem;
      user-select: none;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-property: background-color, color, width, height, padding,
      margin;
      &.red {
        color: #f4442e;
        border: 1px solid #f4442e;
      }
      &.clickable {
        cursor: pointer;
        &.critical {
          color: #F4442E;
          border: 1px solid #F4442E;
          &:hover {
            background-color: #F4442E;
          }
          &.selected {
            color: #ffffff;
            background-color: #F4442E;
            &:hover {
              background-color: #F4442E;
            }
          }
        }
        &:hover {
          color: #ffffff;
          background-color: black;
        }
        &.selected {
          color: #ffffff;
          background-color: black;
          &:hover {
            background-color: black;
          }
        }
      }
    }
  }

  &__search-row {
    font-size: 11px;
    width: 100%;
    display: flex;

    &-tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      .tags__tags-tag-element {
        margin: 0 0.5rem 0 0;
        padding: 0 0.8rem;

      }
    }
  }

  &__performance {
    font-size: 14px;
    display: inline-block;
    &-tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      .tags__tags-tag-element {
        text-transform: capitalize;
        border-radius: 4px;
        color: $color-blueful-darker;
        background: $color-very-light-grey;
        margin: 0 0.7rem 0 0;
        padding: 0 0.8rem;
        user-select: none;

      }
    }
  }
}
