@import "../../../../sass-config/abstracts/variables.scss";


$dialColor: #f22800;
$metricsContentRightOffset: 35px;
$metricsContentLeftOffset: 56px;



// this is a global class, meaning it is living outside the actual dashboard
.bar-subevent-tooltip {
  pointer-events: none;

  .sub-event-sec {
    font-weight: normal;
  }

  .ant-tooltip-inner {
    .sub-event-time {
      font-size: 1.1rem;
      margin-top: 3px;
    }

    word-wrap: break-word;
    color: #ffffff;
    font-weight: bold;
    padding: 0;
    background-color: rgba(43, 62, 85, 0.87);
    width: fit-content;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;

    &>div {
      padding: 1.5rem 2rem;
    }

    .sub-event-value {
      background-color: #243954;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      padding: 0.5rem;
      min-height: 2.8rem;
    }
  }

  .related-subevent {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 2px solid rgba(255, 255, 255, 0.8);
    border-top-style: dashed;
    margin: 0rem 2rem !important;
    padding: 1.5rem 0rem !important;
  }
}


.video-journey-wrapper-new {
  .performance-metrics {
    position: relative;
    margin-top: 0rem !important;

    &__header {
      position: absolute;
      max-width: 300px;
      left: 6rem;
      top: 5rem;

      &__sub {
        color: $color-pinkish-grey;
        font-size: 15px;
        user-select: none;
      }

    }

    &__legend-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      left: 0rem;

      .ant-badge-status-text {
        font-size: 1.7rem;
      }

      &>span {
        background-color: transparent !important;
      }
    }

    .single-mode {
      grid-template-rows: auto 50%;
    }

    .compare-mode {
      grid-template-rows: auto 30%;
    }

    &__bars-content-wrap {
      padding: 0rem 5rem 0 9.5rem;
      z-index: 2;
      transition: all 0.2s;
      user-select: none;
      border: 1px solid #e7eaed;
      min-height: 47.8rem !important;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 6rem;
      position: relative;
      justify-content: center;
    }

    &__bars-content {
      width: 100%;
      position: relative;

      .red-mark-link {
        border-left: 1px dashed red;
        width: 1px;
        height: 15rem;
        top: 4.5rem;
        position: absolute;

        .bars-and-video {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          align-items: flex-end;
        }
      }

      .performance-metrics__bars-content-bar-part {
        &.no-radius {
          border-radius: 0 !important;
        }
      }

      .titles-and-bars {
        flex-basis: 48.6%;
        position: relative;
      }


    }

    &__bars-content-wrap {

      padding: 0rem 5rem 0 9.5rem;
      z-index: 2;
      transition: all 0.2s;
      user-select: none;
      border: 1px solid #e7eaed;
      min-height: 47.5rem;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 4rem;
      position: relative;
      justify-content: center;
    }

    &__bars-content {
      width: 100%;
      position: relative;

      .red-mark-link {
        border-left: 1px dashed red;
        width: 1px;
        height: 15rem;
        top: 4.5rem;
        position: absolute;
      }

      .app-icon {
        width: 4.5rem;
        position: absolute;
        left: -7.5rem;
        bottom: calc(50% - 1.5rem);
      }

      &.comparing {
        justify-content: center;
      }

      &-bar {
        &-wrapper {

          transition: all 0.2s;
          position: relative;
          display: flex;
          align-items: center;
          min-height: 12rem;
          width: 94%;
          border-radius: 4px;

          &:hover {

            .part-time,
            .part-time-start {
              opacity: 0;
            }

            .current-time-indicator {
              white-space: nowrap;
              opacity: 1;
              background-color: white;
            }
          }

          &>.current-time-indicator {
            transition: opacity 0.1s;
            opacity: 0;
            position: absolute;
            margin: 0;
            top: 50%;
            transform: translate(-25px, calc(-50% + 28px));
            width: 55px;
            text-align: center;
          }

          &>.red-mark {
            width: 7px;
            height: 5px;
            z-index: 100;
            background-color: red;
            position: absolute;
            top: 46.8%;
            left: 50%;

            &.main {
              top: 47%;
            }
          }

          &>.bar-gray {
            position: absolute;
            width: 100%;
            height: 3px;
            top: 49%;
            transform: translateY(-60%);
            background-color: gray;
            opacity: 0.4;

            .bar-mark {
              height: 15px;
              display: inline-block;
              position: absolute;
              top: -6px;
              width: 1.5px;
              background-color: gray;
              opacity: 0.4;
              z-index: 1;
            }
          }

          &-icon {
            cursor: default;
            transition: opacity 0.2s;
            max-width: 44px;
            position: absolute;
            margin-left: -5.5rem;
            opacity: 1;
            border-radius: 4px;
            box-shadow: 0px 0px 4px 0px rgba(26, 21, 21, 0.2);

            &.hidden {
              opacity: 0;
            }
          }
        }

        &-part {
          height: 3px;
          float: left;
          position: relative;
          overflow: visible;
          margin: 0;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 5px;

          .part-hover {
            position: absolute;
            left: -5%;
            top: -25%;
            width: 16px;
            height: 20px;
            background-color: transparent !important;

            &:hover {
              .inner {
                width: 80%;
                height: 80%;
              }
            }
          }

          &>.part-dot {
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: white;
            right: -10px;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            overflow: visible;

            &.last {
              &:hover {
                &>.inner {
                  width: unset;
                  height: unset;
                }
              }
            }

            &>.inner {
              width: 60%;
              height: 60%;
              border-radius: 50%;
              background-color: white;
              border: 3px solid;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          & .part-time {
            transition: opacity 0.1s;
            opacity: 1;
            position: absolute;
            right: -1.7rem;
            top: 2rem;
            transform: translateX(30%);
            color: #b4acac;
            min-width: 5rem;
            text-align: center;
          }

          & .part-time-start {
            transition: opacity 0.1s;
            opacity: 1;
            position: absolute;
            transform: translateX(-50%);
            top: 2rem;
            color: #b4acac;
          }

          &>.event-finished-indicator {
            color: white;
            position: absolute;
            font-size: 1.2rem;
            padding: 0.5rem 1.5rem;
            right: -3rem;
            top: -4.7rem;
            border-radius: 2px;
            transition: 0.1s;

            &.hovered {
              transform: scale(1.1);
            }

            cursor: default;

            &>span {
              position: absolute;
              margin: 0;
              left: 50%;
              transform: translateX(-50%);
              top: -20px;
            }

            &:after {
              top: 97%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-top-color: #88b7d5;
              border-width: 7px;
              margin-left: -7px;
            }
          }

          &.main {
            background-color: $color-performance-compare-1 !important;

            &>.part-dot {
              &>.inner {
                border-color: $color-performance-compare-1 !important;

                &.hovered {
                  background-color: red;
                  border-radius: 7px;
                  border: 3px solid $color-performance-compare-1 !important;
                }
              }

              &.last {
                &.hovered {
                  &>.inner {
                    background-color: red;
                  }
                }

                &>.inner {
                  background-color: $color-performance-compare-1 !important;
                  border: 0;
                  width: 40%;
                  height: 40%;
                }
              }
            }

            &>.event-finished-indicator {
              background-color: $color-performance-compare-1 !important;

              &>span {
                color: $color-performance-compare-1 !important;
              }

              &:after {
                // the end indicator arrow color
                border-top-color: $color-performance-compare-1 !important;
              }
            }
          }

          &.compare {
            background-color: $color-performance-compare-2 !important;

            &>.part-dot {
              &>.inner {
                border-color: $color-performance-compare-2 !important;

                &.hovered {
                  background-color: red;
                  border-radius: 7px;
                  border: 3px solid $color-performance-compare-2 !important;
                }
              }

              &.last {
                &.hovered {
                  &>.inner {
                    background-color: red;
                  }
                }

                &>.inner {
                  background-color: $color-performance-compare-2 !important;
                  border: 0;
                  width: 40%;
                  height: 40%;
                }
              }
            }

            &>.event-finished-indicator {
              background-color: $color-performance-compare-2 !important;

              &>span {
                color: $color-performance-compare-2 !important;
              }

              &:after {
                // the end indicator arrow color
                border-top-color: $color-performance-compare-2 !important;
              }
            }
          }
        }
      }
    }

    .playing-mode-change {
      img {
        cursor: pointer;
        margin-right: 0.8rem;
      }

      .playing-mode-change-stop[disabled] {
        cursor: default;
        opacity: 0.5;
      }

      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 22;

      .playing-mode-time {
        color: #000000;
        font-family: Roboto;
        font-size: 1.2rem;
        letter-spacing: 0.1px;
        line-height: 1.6rem;
        display: inline-block;
      }
    }
  }

  .titles-and-bars {
    flex-basis: 48.8% !important;
    position: relative;
  }



}

.ant-tooltip {
  z-index: 998 !important;
}