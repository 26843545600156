.view__header__chat {}

.view__chat__modal {
    font-family: $font-primary;
    &__content {}
    &__text {
        font-size: 1.5rem;
        padding: 0 2.4rem;
    }
    &__form {
        margin-top: 1.2rem;
        color: $color-black;
        &-group {
            display: flex;
            flex-direction: column;
            padding: 0 2.4rem;
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
        &-label {
            margin-bottom: .5rem;
        }
        &-textarea {
            resize: none;
            height: 17rem;
        }
        &-textarea,
        &-input {
            border: none;
            border: 1px solid rgb(160, 160, 160);
            border-radius: 4px;
            transition: all .2s;
            padding: 1.6rem;
            &:focus {
                outline: none;
                border-color: black;
            }
        }
        &-button {
            margin-top: 2rem;
            display: flex;
            justify-content: flex-end;
            padding: 1rem 2.5rem 4rem 2rem;
            button {
                width: 10rem;
                height: 4.2rem;
                font-size: 1.6rem;
            } // border-top: 1px solid #e8e8e8;
        }
    }
}

.view__chat__modal {
    .ant-modal-body {
        padding: 0px;
    }
    .ant-modal-header {
        border: none;
        padding-top: 3.5rem;
        .ant-modal-title {
            font-size: 2.4rem;
            font-family: $font-headings;
        }
    }
}