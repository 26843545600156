@import '../../sass-config/abstracts/variables.scss';

.user-type-popup {
  width: 50rem;
  max-height: 370px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px;
  padding: 1rem;

  .title {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .clear {
    text-decoration: underline;
    font-size: 1.4rem;
    color: #696f8c;
    cursor: pointer;

    &:hover {
      color: $color-black;
    }
  }

  .items-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .items-selected-count {
      color: #000;

      font-family: Roboto;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
    }
  }

  .filters-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .filters-content-checkbox-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1.2rem;

      .user-type-popup-checkbox-group-item {
        width: 48%;
        box-sizing: border-box;
        align-items: center;
        padding: 0.8rem;
        border-radius: 0.8rem;
        border: 1px solid $color-border-layout;
      }
    }
  }

  .user-type-popup-checkbox-group-item {
    border-radius: 8px;
    padding: 8px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    min-width: 170px;

    .ant-checkbox + span {
      display: flex;
      flex-wrap: nowrap;
      flex-basis: 100%;
      justify-content: space-between;

      .user-type-popup-checkbox-group-tem-label-text {
        padding-right: 10px;
      }

      .user-type-popup-checkbox-group-tem-label-icon-container {
        display: inline-flex;
        justify-content: center;
      }
    }
  }
}
