.suggestion-valid-email {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;


  .blurry-back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6px);
  }

  .resend-email {
    color: #FF2741;
    text-decoration: underline;
    cursor: pointer;
  }

  .modal-valid-form,
  .modal-valid-form-thanks {
    width: 43rem;
    top: 5rem;
    background-color: white;
    box-shadow: 0px 4px 122px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: auto;
    z-index: 333;
    position: relative;
    padding: 2rem 4rem;

    .svem-logo {
      width: 4.6rem;
      margin: auto;
      display: block;
      margin-top: 2rem;
    }

    h3 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 2.2rem;
      text-align: center;
      margin: 2rem auto;
    }

    .svem-descr {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      text-align: center;
    }

    .svem-form {
      margin: 0rem;
      padding: 3rem;

      .ant-select {
        display: block;
        margin: 2rem auto;
      }

      .ant-btn {
        margin: 4rem auto 0rem;
        display: block;
        width: 19.5rem;
        height: 3.2rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 1.3rem;
      }
    }

    .svem-open-form-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .svem-open-form {
      font-size: 1.9rem;
      padding: 0.8rem 2rem;
      width: 19.5rem;
      height: 3.2rem;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.5rem;
    }

    .svem-descr-thanks {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 168.19%;
      margin: 3rem auto;
      text-align: center;

      div {
        color: #277DFF;
      }
    }

    .svem-descr-resend {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 4rem;
    }


    .svem-close {
      position: absolute;
      top: 1rem;
      right: 2rem;
      cursor: pointer;
      user-select: none;

      span {
        display: inline-block;
        font-size: 2rem;
      }
    }
  }

  &.already_validated {
    .svem-status-text {
      color: #277DFF;
    }

    input {
      color: #277DFF;
    }
  }

  .svem-status-descr {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    margin: 0rem auto 0.8rem;
    text-align: center;
    width: 136%;
    margin-left: -5rem;

    a {
      display: inline-block;
      margin-left: 0.5rem;
      color: #FF2741;
    }
  }

  .svem-status-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.3rem;
  }


  &.wrong_domain, &.incorrect_domain {
    .svem-status-text {
      color: #FF2741;
    }

    input {
      color: #FF2741;
    }
  }
}