.performance-no-data {
  margin-top: 7rem;
  text-align: center;
  padding: 0rem 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35.5rem;
  margin-top: 2rem !important;

  img {
    width: 15rem;
    opacity: 0.2;
  }
}