/**
 * ? MEDIA QUERIY MANAGER ?
   **     0-600px:         Phone
   **     600px-900px :    Tablet portrait
   **     900px-1200px:    Tablet landscape
   **     [1200px-1800px]: Normal
   **     1800px+:         Big desktop

   **   breakpoint argument choices:
   **       - phone
   **       - tab-port
   **       - tab-land
   **       - big-desktop
*/

@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if $breakpoint==tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint==tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint==big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px
  }

  @if $breakpoint == bp-mobile {
    @media only screen and (max-width: 31.25em) {
      @content;
    } //500px
  }
  @if $breakpoint == bp-tablet {
    @media only screen and (max-width: 48em) {
      @content;
    } //768px
  }
  @if $breakpoint == bp-laptop {
    @media only screen and (max-width: 85em) {
      @content;
    } //1366px
  }
  @if $breakpoint == bp-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }

  @if $breakpoint == bp-laptop-bigger {
    @media only screen and (max-width: 95em) {
      @content;
    }
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin transition($property, $duration, $timing-function) {
  -webkit-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  transition: $property $duration $timing-function;
}

@mixin transitionHover {
  -webkit-transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: $value;
  -moz-#{$prop}: $value;
  -ms-#{$prop}: $value;
  -o-#{$prop}: $value;
  #{$prop}: $value;
}

@mixin firefox {
  @supports (-moz-appearance: none) {
    @content;
  }
}

@mixin scrollStyle($width, $radius, $trakColor, $thumbColor, $thumbHeight) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $trakColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: $radius;
    height: $thumbHeight;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $thumbColor;
  }
}

@mixin seperation-line($psudo-el, $marginTop: 0, $marginBottom: 0) {
  &::#{$psudo-el} {
    content: '';
    display: block;
    background-color: $color-border;
    height: 1px;
    width: 100%;
    margin-top: $marginTop;
    margin-bottom: $marginBottom;
  }
}

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
