.mb-xs{
    margin-bottom: .5rem !important;
}

.mb-s{
    margin-bottom: .8rem !important;
}

.mb-m{
    margin-bottom: 2rem !important; 
}

.mb-l{
    margin-bottom: 3rem !important; 
}

.mt-s{
  margin-top: .8rem;
}

.mt-m{
    margin-top: 2rem !important; 
}

.mt-l{
    margin-top: 3rem !important; 
}