.form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond(bp-tablet) {
    justify-content: end;
  }

  &__title {
    font-size: 2.8rem;
    font-family: $font-headings;
    letter-spacing: 0.23px;
    line-height: 4rem;

    @include seperation-line('after', 1.5rem, 2rem);
  }

  &__text-group {
    display: flex;
    justify-content: space-between;

    .form__error {
      color: $color-danger;
      font-size: 13px;
      font-family: $font-primary;
    }
  }

  &__input {
    width: 100%;
    border: 1px solid gray;
    border-color: transparent;
    border-radius: 4px;
    height: 48px;
    margin: 6px 0 12px 0;
    padding: 12px;
    font-family: $font-primary;

    &--danger {
      background-color: $color-danger-bg;
    }

    &.select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      opacity: 0.8;
      background: url('../../assets/images/icons/chevron.svg') 100% no-repeat
        $color-input;
      background-size: 30px;

      option {
        background-color: $color-input;
        height: 20px;
      }
    }
  }

  &__label {
    color: $color-dark-weak;
    font-size: 13px;
    letter-spacing: 0.1px;
  }

  &__bottom-group {
    display: flex;
    justify-content: space-between;

    @include respond(bp-mobile) {
      flex-direction: column;
      .form__group {
        margin: 0;
      }
    }

    .form__group {
      width: 100%;
      flex: 1 1 0;
    }
  }

  &__buttons-section {
    display: flex;
    justify-content: flex-end;
    @include respond(bp-tablet) {
      margin-bottom: 2rem;
    }
  }

  #g-recaptcha {
    margin-top: 2rem;
    @include seperation-line('after', 4rem, 2rem);
    @include respond(bp-mobile) {
      transform: scale(0.93);
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
