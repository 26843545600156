.notification-center-wrapper {
  position: fixed !important;
  .ant-popover-inner-content {
    padding: 0;
    .notification-center {
      width: 31rem;
      padding: 0;

      .empty-state-notification {
        padding: 6rem 5rem;
        text-align: center;
        .ntf-empty-header {
          font-weight: 600;
          font-size: 1.6rem;
          margin-bottom: 1.5rem;
        }
        .ntf-empty-icon {
          margin-bottom: 2rem;
        }
      }

      .ntf-ctr-header {
        padding: 1.9rem 1.6rem 1.6rem 2.8rem;
        border-bottom: 1px solid #eceef5;
        position: relative;
        h2 {
          color: #000000;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0.2px;
          line-height: 21px;
        }
        .btn-ntf-mark-all-seen {
          position: absolute;
          top: 1.2rem;
          right: 2rem;
          height: 3rem;
          font-size: 1.08rem;
          letter-spacing: 0.09px;
          line-height: 13px;
          text-transform: none;
          .anticon {
            margin-left: 0.6rem;
          }
        }
      }
      .notification-list {
        min-height: 25rem;
        max-height: 49rem;
        overflow: auto;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          width: 3.5px;
          border-radius: 3.15px;
          background-color: #939393;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        .notification-sub-list-wrap {
          h3 {
            color: #9a9eaa;
            font-family: Roboto;
            font-size: 12px;
            letter-spacing: 0.1px;
            line-height: 14px;
            margin: 2rem auto 0.2rem 2.9rem;
          }
        }
      }
      .notification-item {
        min-height: 8.1rem;
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 2rem 1.8rem 2rem 2.8rem;
        &.clickable {
          cursor: pointer;
        }
        .ntf-app-data {
          .app-icon-wrapper {
            position: relative;
            img.app-icon {
              height: 3.33rem;
              width: 3.33rem;
              border-radius: 50%;
              box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
              margin-right: 2rem;
            }
            img.app-overlay-platform {
              position: absolute;
              bottom: -0.6rem;
              right: 1.2rem;
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
        .ntf-descr {
          width: 100%;
          min-height: 4rem;
          .ntf-descr-text {
            color: #000000;
            font-family: Roboto;
            font-size: 12.6px;
            font-weight: 300;
            letter-spacing: 0.14px;
            line-height: 18px;
            padding-right: 1.1rem;
          }
          .ntf-time-diff {
            color: #7b7b7b;
            font-family: Roboto;
            font-size: 10px;
            font-weight: 300;
            letter-spacing: 0.08px;
            line-height: 11px;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            img {
              margin-right: 0.3rem;
            }
          }
        }

        &.unread {
          .ntf-descr {
            position: relative;
            &::after {
              content: "";
              top: 0.2rem;
              right: 0.2rem;
              height: 0.65rem;
              width: 0.65rem;
              border-radius: 50%;
              background-color: #e34444;
              position: absolute;
            }
            .ntf-descr-text {
              font-weight: 500;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 2.8rem;
          height: 1px;
          width: 26.9rem;
          border-top: 1px solid #eceef5;
        }
        &:hover {
          background: linear-gradient(270deg, #ffffff 0%, #f6f6f6 100%);
        }
      }
    }
  }
}
