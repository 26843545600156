@import '../../../sass-config/abstracts/variables.scss';

.heat-map-container {
  display: flex;
  gap: 2.4rem;
  padding-top: 2.4rem;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  .heat-map-y-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $color-subtitle;
    padding: 0 1.6rem;
    font-size: 1.4rem;
    height: 100%;

    .heat-map-y-label {
      flex: 1;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-border-layout;
    }

    .heat-map-legend {
      display: flex;
      gap: 0.8rem;
      align-items: flex-end;
      flex: 1;

      > * {
        margin-bottom: 0.3rem;
      }

      .heat-map-legend-gradient {
        border-radius: 0.4rem;
        width: 8.7rem;
        height: 2rem;
      }
    }
  }

  .heat-map-data-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .heat-map-data {
      display: grid;
      grid-gap: 0.1rem;
      height: 100%;

      .heat-map-cell:first-child {
        border-top-left-radius: 1rem;
      }

      .heat-map-cell:last-child {
        border-bottom-right-radius: 1rem;
      }

      .heat-map-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
      }

      .first-row-last-cell {
        border-top-right-radius: 1rem;
      }

      .last-row-first-cell {
        border-bottom-left-radius: 1rem;
      }
    }

    .heat-map-x-labels {
      margin-top: 2.8rem;
      display: flex;
      gap: 0.1rem;
      justify-content: space-around;
      align-items: center;

      .heat-map-x-label {
        width: 5.7rem;
        text-align: center;
      }
    }
  }
}
