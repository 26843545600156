.s-message {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #f3f3f3;

  span {
    color: #3f3f3f;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
  }

  .got-it-btn {
    cursor: pointer;
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 200px;
    background: #2e5fff;
  }
}
