.ma-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .ma-app-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #e3e4e3;
    background: #fdfdff;
    padding: 16px 8px;
    flex: 1;
    min-width: 150px;

    &.customer-app {
      border: 1px solid #728ad8;
      background: #f6f6f6;
    }

    .ma-app-card-image {
      width: 48px;
      height: 48px;
    }

    .ma-app-card-name {
      color: #858585;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
    }

    .ma-app-card-count {
      color: #202020;
      font-family: Inter;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
