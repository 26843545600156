.dot {
    &__abtests {
        &::before {
            content: "";
            height: 8px;
            width: 8px;
            margin-right: .6rem;
            border-radius: 50%;
            display: inline-block;
            vertical-align: 2px;
            border: 1.5px solid transparent;
            @include respond(phone) {
                margin-right: .5rem;
            }
        }
        &-full {
            &::before {
                height: 6px;
                width: 6px;
                margin-right: .7rem;
            }
        }
        &-active::before {
            border-color: $color-blueful;
        }
        &-active-full::before {
            background-color: $color-blueful;
        }
        &-integrated::before {
            border-color: $color-greenful-2;
        }
        &-integrated-full::before {
            background-color: $color-greenful-2;
        }
        &-abandoned::before {
            border-color: $color-redful;
        }
        &-abandoned-full::before {
            background-color: $color-redful;
        }
        &-disabled::before {
            border-color: $color-light-grey-2;
        }
        &-disabled-full::before {
            background-color: $color-light-grey-2;
        }
    }
    &::before {
        content: "";
        margin-right: 8px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: 2px;
    }
    &-s::before {
        height: 5px;
        width: 5px;
    }
    &-m::before {
        height: 6px;
        width: 6px;
    }
    &-active::before {
        background-color: $color-blueful;
    }
    &-integrated::before {
        background-color: $color-greenful-2;
    }
    &-abandoned::before {
        background-color: $color-redful;
    }
    &-grey::before {
        background-color: $color-pinkish-grey;
    }
}