.ctl-cards-list {
  padding-top: 24px;
  min-height: 250px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  gap: 8px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .gap-card {
    height: 100%;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 82px 8px 0 8px;
    white-space: nowrap;

    div {
      color: rgba(108, 117, 125, 0.91);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      letter-spacing: 0.7px;
    }
  }

  .card {
    height: fit-content;
    padding: 24px 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;

    &.selected {
      border-radius: 8px;
      border: 1px solid #2e5fff;
      background: #f9f9fb;
    }

    &:hover {
      border-radius: 8px;
      background: #f9f9fb;
    }

    .card-heading {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &.multi {
        gap: 10px;
      }

      .card-date-events {
        display: flex;
        align-items: center;
        flex-direction: column;

        .event-date {
          color: #6c757d;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 190%; /* 26.6px */
          letter-spacing: 0.7px;
        }

        .event-title {
          white-space: nowrap;
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 16.8px */
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 4px;

          .event-count {
            display: flex;
            padding: 8px 6px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            background: #e3e3e3;
            z-index: 5;

            &:hover {
              background: #d3d3d3;
            }
          }
        }
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .push-count {
        margin: 0 16px;
        text-wrap: nowrap;
        border-radius: 4px;
        padding: 8px 16px;
        background: #fec0ff;
        color: #b20362;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 190%; /* 26.6px */
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .email-count {
        margin: 0 16px;
        text-wrap: nowrap;
        padding: 8px 16px;
        border-radius: 4px;
        background: #dde4f9;
        color: #1a41c0;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 190%; /* 26.6px */
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.same-day-events-container {
  display: flex;
  padding: 8px;
  flex-direction: column;
  gap: 10px;

  .sde-title {
    color: #6c757d;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 190%; /* 22.8px */
    letter-spacing: 0.6px;
  }

  .sde-event {
    cursor: pointer;
    display: flex;
    width: 196px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #f6f6f6;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    text-transform: capitalize;
  }
}
