.ctl-filters-container {
  display: flex;
  align-items: center;
  gap: 8px;

  :last-child {
    margin-left: auto;
  }

  .select-filter {
    .select-filter-title {
      color: #898989;
      /* Body/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }

    .select-body {
      cursor: pointer;
      display: flex;
      height: 48px;
      padding: 8px 8px 8px 8px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e3e4e3;
      background: #fff;
      gap: 8px;

      span {
        color: #161515;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .select-app-icon {
        width: 31px;
        height: 31px;
        border-radius: 4px;
        flex-shrink: 0;
      }
    }
  }
}

.event-indication {
  width: 5px;
  height: 5px;
  border-radius: 1000px;
  background-color: #1d91ff;
  z-index: 1000;
  position: absolute;
  right: 45%;
}
