.dropdown {
  position: relative;

  &__select {
    cursor: pointer;

    &-arrow {
      transition: all 0.1s;
      margin-left: 0.7rem;
      width: 2rem;
      margin: auto 1rem;

      &.open {
        transform-origin: 50%;
        transform: rotate(180deg);
      }
    }
  }

  &.open {
    .dropdown__select-arrow {
      transition: all 0.2s;
      transform-origin: 50%;
      transform: rotate(180deg);
    }
  }

  &__menu {
    position: absolute;
    top: 5rem;
    z-index: 1000;
    background-color: #fff;
    -webkit-box-shadow: $shadow-big !important;
    box-shadow: $shadow-big !important;
    border-radius: 9px;
    opacity: 0;
    visibility: hidden;
    max-height: 0;

    &-active {
      opacity: 1;
      animation: dropDownOpen 0.5s;
      visibility: visible;
      max-height: 85vh;
      overflow: auto;
      min-width: 15rem;
      right: 0;
      width: 18rem;
      @include scrollStyle(1rem, 7px, $color-brown-grey, $color-light-grey-2, 3rem);
    }

    li {
      padding: 1.5rem 2rem;
      cursor: pointer;
      position: relative;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;

      color: #616161;

      display: flex;
      align-items: center;
      user-select: none;
      img {
        margin-right: 1rem
      }

      @include ellipsis;

      &:hover {
        background-color: black !important;
        color: $color-white;
        
        img {
          filter: invert(100%);
        }
      }
    }
  }

  &__label {
    &-active {
      background-color: rgba($color-blueful-light, 0.12);

      * {
        color: $color-black;
      }
    }
  }
}

// .dropdown__menu::-webkit-scrollbar {
//     width: 15px;
// }
// .dropdown__menu::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
// }
// .dropdown__menu::-webkit-scrollbar-thumb {
//     background: rgb(255, 105, 74);
//     border-radius: 10px;
//     height: 30px;
// }
// .dropdown__menu::-webkit-scrollbar-thumb:hover {
//     background: var(--color-primary-2);
// }