.dropdown-menu {
    font-size: 1.5rem;
    color: black;
    max-width: 21rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    &__select {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > span.header__username {
            @include ellipsis;
            display: inline-block;
            text-transform: uppercase;

            & > span {
                color: #000000;
                font-family: Roboto;
                font-size: 9.9px;
                font-weight: 500;
            }

            @include respond(phone) {
                margin-left: auto;
            }
        }
    }

    &__menu {
        width: 100%;
        top: -1rem;
        color: $color-black;
        max-height: 90vh;
        border-radius: 3px !important;

        @include respond(phone) {
            top: 2.3rem;
            right: unset;
        }
    }
    .dropdown-nav__no-select {
        cursor: pointer !important;
    }
}
