.report-list-page {
  .hr-row {
    margin-bottom: 1.5rem;
  }

  .reports-list-filters {
    display: flex;
    margin-bottom: 2rem;

    .sep-flex-grow {
      flex-grow: 1;
    }
  }

  th:nth-child(2) {
    padding-left: 2rem !important;
  }

  .ant-table-tbody tr td> :not(a) {
    text-transform: none;
    color: black !important;
  }

  .unread {
    font-weight: 500;
    color: black;
    position: relative;
  }

  span.ant-table-column-title {
    color: #9fa5ad;
    font-family: Roboto;
    font-size: 1.2rem;
    letter-spacing: 0.1px;
    line-height: 1.6rem;
  }

  .report-share {
    margin-bottom: .5rem;
  }

  .ant-table-tbody tr td {
    &:nth-child(2) {
      color: #000000;
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 300;
      letter-spacing: 0.15px;
      line-height: 2rem;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      color: #1b1b1b;
      font-family: Roboto;
      font-size: 1.14rem;
      letter-spacing: 0.19px;
      line-height: 1.5rem;
      text-align: center;
    }
  }

  .ant-table-column-sorter-inner-full {
    svg {
      display: none;
    }

    i {
      position: relative;
    }

    .ant-table-column-sorter-down.on::after {
      content: url(../../assets/images/new_design/table-arrow-sorting.svg);
      position: absolute;
      top: -0.5rem;
      right: -1rem;
      width: 1rem;
      height: 1rem;
      transform: rotate(180deg);
    }

    .ant-table-column-sorter-up.on::after {
      content: url(../../assets/images/new_design/table-arrow-sorting.svg);
      position: absolute;
      top: 0rem;
      right: -1rem;
      width: 1rem;
      height: 1rem;
    }
  }

  .report-name.unread::before {
    content: "";
    height: 0.7rem;
    width: 0.7rem;
    top: 0.8rem;
    left: -1.5rem;
    position: absolute;
    border-radius: 50%;
    background-color: #e34444;
  }

  .reports-table-wrapper {
    th {

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        text-align: center;
      }
    }

    tr.has-tags td {

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        vertical-align: top;
      }
    }

    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
      background: rgb(248, 248, 248);
    }

    .report-icon {
      img {
        width: 3.6rem;
      }

      &.real {
        width: 4rem;
        height: 4rem;
        padding: 0rem;
        position: relative;
        border: none;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
    }

    .report-name {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .tags__tags-tag-element {
      font-size: 10px;
      font-weight: 500;
    }

    .report-view-header-buttons {
      display: flex;
      flex-direction: column;

      .ant-btn {
        img {
          width: 1.1rem;
          margin-right: 0.8rem;
        }

        span {
          font-size: 1rem;
        }

        &:hover:not([disabled]) {
          color: black !important;
          background-color: rgb(241, 241, 241) !important;
          border-color: #d9d9d9 !important;
        }
      }
    }

    .ant-table-row td {
      border-bottom: 0.88px solid #edebeb !important;
      padding: 2rem 1.3rem 3rem !important;
      vertical-align: middle;
      position: relative;
      font-size: 1.4rem;

      &:nth-child(2)::after,
      &:nth-child(5)::after {
        content: "";
        width: 1px;
        height: 3.5rem;
        background-color: #cccccc;
        position: absolute;
        top: 2rem;
        right: 0;
      }

      &:nth-child(4)::after,
      &:nth-child(4)::before {
        content: "";
        width: 1px;
        height: 3.5rem;
        background-color: #cccccc;
        position: absolute;
        top: 2rem;
        right: 0;
      }

      &:nth-child(4)::before {
        left: 0 !important;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        text-align: center;
        padding-top: 2.5rem !important;
      }

      &:nth-child(6) {
        padding-top: 1rem !important;
      }

      &:nth-child(7) {
        padding-left: 0.4rem !important;
      }
    }
  }

  .ant-table-thead tr th {
    border-bottom: none !important;
    padding-bottom: 0rem !important;
  }

  .filter-count {
    padding: 0.2rem !important;
  }
}