.sec-container {
  display: flex;
  gap: 10px;

  .arrows {
    display: flex;
    gap: 4px;
    align-items: center;

    .arrow {
      padding: 6px 8px;
      cursor: pointer;
      background-color: white;
      border-radius: 4px;
      z-index: 20;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
