.account-page {
  .hr-row {
    margin: 3rem 0 4rem;
  }

  .wrapper-tabs {
    .extra-text {
      color: #b1b1b1;
      font-family: Roboto;
    }

    .ant-btn {
      font-weight: 600;
    }

    .def-avatar {
      background: transparent;
      color: black;
      border: 6px solid black;

      .ant-avatar-string {
        font-size: 6rem;
        font-weight: 600;
        line-height: 12rem !important;
      }
    }

    .account-form {
      display: flex;
      margin-top: 4rem;
      justify-content: space-between;

      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        &.row-pass {
          position: relative;
        }

        &.row-name>div {
          flex-basis: 48%;
        }

        &.phone {
          .form-control {
            width: 100%;
            height: 6rem;
          }
        }

        &.row-buttons {
          margin-top: 5rem;
          justify-content: flex-start;

          button {
            margin-right: 3rem;
          }
        }

        &.pass-changed {
          justify-content: center !important;

          img {
            margin-right: 1rem;
          }
        }
      }

      .right-part-avatar {
        width: 16rem;
        text-align: center;

        h6 {
          color: #9fa5ad;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 0.15px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 1.5rem;
        }

        .avatar-buttons {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;
        }
      }
    }

    .subscriptions {
      .email-pref {
        border-bottom: 1px solid rgba(166, 168, 173, 0.5);
        padding-bottom: 0rem;
      }
    }

    .subscription-buttons {
      margin-top: 7rem;
      padding-left: 2rem;

      div {
        overflow: visible;
      }

      .ant-tabs-tab {
        padding: 0rem !important;
        width: 42.6rem;
        height: 9.5rem;
        font-family: Roboto;
        font-size: 2.2rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c4c4c4;
        margin-bottom: 0rem;
        margin-top: 0rem;
      }

      .ant-tabs-tab-btn {
        width: 100%;
      }

      .ant-tabs-tab-active.ant-tabs-tab {
        background: #ffffff;
        box-shadow: 3px -33px 33px rgba(0, 0, 0, 0.09);
        position: relative;
      }

      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        visibility: hidden;
      }

      .ant-tabs-tabpane.ant-tabs-tabpane-inactive {
        height: 0px;
        display: none;
      }

      .sub-digest-freq-descr {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 1.1rem;
        line-height: 14px;
        display: flex;
        justify-content: flex-end;
        margin: 2rem;
        align-items: center;

        button.ant-btn {
          background: black;
          color: white;
          text-transform: capitalize;
          margin-left: 2rem;

          img {
            margin-left: 1rem;
            width: 1rem;
          }
        }
      }

      .email-pref {
        margin: 3rem 2rem;

        .email-pref-title {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 21px;
          margin-left: 1rem;
          display: inline-block;
        }

        .email-pref-descr {
          margin-left: 3.3rem;
          margin-top: 0.5rem;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: #a8a8a8;
        }

        .email-pref-sub-labels {
          display: flex;
          margin: 2rem 0rem 1rem;
          flex-wrap: wrap;

          .tags__tags-tag-element {
            font-size: 1rem;
          }
        }
      }

      .checkbox {
        .ant-checkbox-inner::after {
          position: absolute;
          top: 50%;
          left: 30%;
        }

        .ant-checkbox-inner {
          box-sizing: border-box;
          height: 2.3rem;
          width: 2.3rem;
          border: 1px solid rgba(26, 21, 21, 0.32);
          border-radius: 4px;
        }

        &-unchecked {
          cursor: default;

          .ant-checkbox-input {
            cursor: default;
          }
        }

        @media (max-width: $max-width-secondary) {
          font-size: 1.5rem;
        }
      }

      .ant-tabs-content.ant-tabs-content-animated.ant-tabs-left-content, .ant-tabs-content-holder {
        overflow: auto !important;
        box-shadow: -27px -29px 40px rgba(0, 0, 0, 0.09);
        height: 47rem;
        padding-bottom: 2rem;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        &::after {
          content: "";
          left: 43.5rem;
          position: absolute;
          top: 14.6rem;
          height: 48rem;
          width: 3rem;
          z-index: 33;
          background: white;
        }
      }

      .ant-tabs-tab:not(.ant-tabs-tab-active) {
        .arrow-wrap {
          opacity: 0.5;
          transform: rotate(180deg);
        }
      }

      .subscription-button {
        position: relative;

        //box-sizing: border-box;
        .sub-topic {
          flex-grow: 1;
        }

        background-color: #ffffff;
        display: flex;
        align-items: center;
        margin-left: 2rem;
        width: 80%;

        .arrow-wrap {
          position: absolute;
          top: 0rem;
          right: -2rem;
        }

        .icon {
          height: 3.8rem;
          margin: 0rem 1.5rem;

          &.w_platform {
            height: 3.2rem;
            width: 3.3rem;
          }

          &.no-fill {
            path {
              fill: none;
            }
          }

          &-disabled {
            svg path {
              fill: #9d9fa4;
              stroke: #9d9fa4;
            }
          }
        }

        &-enabled {
          fill: black;
          stroke: black;
        }

        &-disabled {
          background-color: #f9f9f9;
          border: 1px solid #f9f9f9;
        }
      }
    }

    h3.title {
      margin-top: 5rem;
      position: relative;
    }

    .ant-btn.ant-btn-lg {
      border-width: 1px;

      &[disabled] {
        opacity: 0.6;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
      }

      &.btn-save {
        border-color: black;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
        height: 4rem;
        width: 10rem;
      }
    }

    .ant-upload-list {
      display: none;
    }

    .hr-row.bottom-hr {
      margin: 4rem 0;
    }

    .row.row-pass {
      width: 44rem;
      position: relative;

      .open-modal-change-pass {
        position: absolute;
        top: 2.2rem;
        right: 2rem;
      }
    }

    .pass-changed {
      width: 44rem;
      justify-content: center !important;

      img {
        margin-right: 1rem;
      }
    }
  }

  .email-pref-management {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;

    .save-btn {
      height: 4.5rem;
      margin-left: 2rem;
    }

    .unsubscribe-button {
      width: 23.2rem;
      height: 4.5rem;
      box-sizing: border-box;
      border: 0.88px solid #f4442e;
      background-color: #ffffff;
      text-transform: uppercase;

      &:hover {
        border-color: #f4442e !important;
        background-color: #ffffff !important;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.07);
      }

      .unsubscribe-text {
        height: 1.5rem;
        width: 19.7rem;
        color: #f4442e;
        font-family: Roboto;
        font-size: 1.26rem;
        font-weight: 500;
        letter-spacing: 0.11px;
        line-height: 1.5rem;
      }
    }
  }
}

.pass-error,
.pass-not-match {
  color: red;
  text-align: center;
}

.pass-changed {
  color: #18b663;
  text-align: center;
}

.change-pass-titles {
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .change-pass-descr {
    font-weight: 300;
    color: #6c6c6c;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
}

.ant-modal-footer {
  text-align: center;

  button.ant-btn {
    border: 1px solid black;
    height: 5rem;
    font-size: 1.8rem;
    padding: 0 2rem;
    font-weight: 600;
  }
}

.modal-confirm-exit-pass {
  font-family: Roboto;

  .confirm-exit-pass-header {
    display: flex;
  }

  .warn-ico {
    margin: 1.5rem 4rem 2rem 2rem;
  }

  .buttons {
    margin: 4rem 0 1rem;

    .ant-btn {
      height: 5rem;
      padding: 0 2.5rem;
      font-weight: 600;
      font-size: 1.6rem;

      &.dont-save {
        margin-right: 11.5rem;
      }

      &.save {
        margin-left: 2rem;
        border: 1px solid black;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.modal-upload-image {
  .ant-upload-list-text {
    display: none;
  }

  .ant-modal-footer button.ant-btn:not(.save) {
    border: 1px #d9d9d9 solid;
  }

  .buttons {
    margin: 2rem 0;
  }

  .ant-upload-select {
    margin-right: 21rem;
  }
}

@media (max-width: 44em) {
  .subscription-buttons .ant-tabs-content.ant-tabs-content-animated.ant-tabs-left-content::after {
    display: none;
  }

  .ant-tabs-tab {
    width: 23rem !important;
    box-shadow: none;
  }

  span.email-pref-title {
    width: 17rem;
  }

  .wrapper-tabs .subscription-buttons .checkbox {
    font-size: 1.5rem;
    vertical-align: top;
    position: relative;
    top: 0.2rem;
  }

  .ant-tabs-content.ant-tabs-content-animated.ant-tabs-left-content {
    box-shadow: none !important;
    background-color: white;
    position: relative;
    padding-left: 0.2rem;
  }

  .subscription-buttons .checkbox .ant-checkbox-inner::after {
    position: absolute;
    top: 46% !important;
    left: 22% !important;
    transform: scale(0.8) rotate(45deg) translate(-50%, -50%) !important;
  }

  .subscription-buttons {
    padding-left: 0rem !important;

    .arrow-wrap {
      display: none;
    }

    .subscription-button {
      font-size: 1.3rem;
      width: 10rem;
    }

    img.icon {
      width: 1.9rem !important;
      margin: 0 0.9rem !important;
    }
  }
}

@media (max-width: 92em) {
  .ant-btn.unsubscribe-button {
    margin-right: 9rem !important;
  }
}

.users-management-tab {
  .invite-btn-wrap {
    position: absolute;
    top: 6.5rem;
    right: 0rem;

    button {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500 !important;
    }
  }

  h3 {
    margin-bottom: 3rem;
  }

  hr {
    margin: auto;
    margin-bottom: 4rem;
    border: 1px solid #ebeaea;
    border-top: none;
    width: 96%;
  }

  @media (max-width: 1600px) {
    .filter-by-content {
      margin: 3.6rem 0;
      width: 43rem;
    }
  }
}

.um-table-cont {
  margin-bottom: 4rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  width: 98%;
  margin: auto;
  margin-bottom: 4rem;
  position: relative;

  .ant-table-column-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: #c6cad0;
  }

  .ant-table-tbody::before {
    height: 0 !important;
  }

  table td {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: #4f4f4f;

    div {
      color: #4f4f4f;
    }
  }

  table tr th {
    border-bottom: none !important;
    padding-bottom: 0.8rem !important;

    &:first-child {
      padding-left: 3rem !important;
    }
  }

  table tr {
    height: 4.7rem;
  }

  .full-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    color: #000000;
    padding-left: 2rem;
  }

  table tr:nth-child(odd) {
    background: #f5f5f5;
  }

  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
    &:nth-child(odd) td {
      background: #f5f5f5;
    }

    &:nth-child(even) td {
      background: white;
    }

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    z-index: 33;
    position: relative;
  }

  .all-user-del {
    visibility: hidden;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 14px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #f10202;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 2rem;

    img {
      margin-right: 0.4rem;
    }
  }

  .access-requests-btn-cancel-inv {
    visibility: hidden;
    display: flex;
    align-items: center;
  }

  .ant-table-tbody>tr:hover {
    .all-user-del {
      visibility: visible;
    }

    .access-requests-btn-cancel-inv {
      visibility: visible;
    }

    .access-requests-btn-resend {
      visibility: visible;
    }
  }

  .access-requests-buttons {
    display: flex;
    justify-content: end;
  }

  .access-requests-btn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 14px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #f10202;
    margin-right: 2rem;

    img {
      margin-right: 0.5rem;
    }
  }

  .access-requests-btn-rej {
    color: #f10202;
    margin-right: 3.5rem;

  }

  .access-requests-btn-resend {
    color: black;
    width: 14rem;
    width: 7rem;
    visibility: hidden;
    text-align: right;
  }

  .access-request-result {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #4f4f4f;
    text-align: right;
  }

  .ant-table-tbody tr td.ant-table-column-sort {
    background-color: transparent !important;
  }

  .access-requests-btn-apr {
    color: #55d109;
  }

  .ant-pagination {
    float: none;
    display: flex;
    justify-content: center;
  }

  .show-hide-acc-reqs {
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    text-decoration-line: underline;
    color: #000000;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 22;
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: #999ea9;
    padding: 2rem 1rem 1rem;
  }
}

.req-history-user-modal {
  .ant-modal-body {
    padding: 0rem;
  }

  .um-table-cont {
    margin-bottom: 4rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    border: none;
    width: 100%;

    h4 {
      margin-top: 1rem;
    }
  }

  .access-request-result {
    padding-right: 2rem;
  }
}

.delete-user-modal,
.cancel-invite-user-modal {
  display: flex;
  justify-content: center;
  align-content: center;

  .ant-modal-content {
    padding: 0rem 2rem;
  }

  h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.4rem;
    color: #000000;
  }

  .mod-delete-descr {
    margin: 3rem 0rem;
  }

  .mod-delete-btns {
    display: flex;
    justify-content: right;

    button {
      margin-left: 1.5rem;
    }
  }
}

.invite-user-modal {
  h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.4rem;
    color: #000000;
  }

  .mod-invite-form {
    margin-bottom: 3rem;
    padding-top: 5rem;
  }

  .mod-invite-btns {
    display: flex;
    justify-content: right;

    button {
      margin-left: 1.5rem;
    }
  }

  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #a6a8ad;
    display: flex;
    justify-content: left;
    align-content: center;
    margin-bottom: 0.5rem;

    .star {
      display: inline-block;
      margin-left: 0.3rem;
      color: #f24637;
    }
  }
}

.ant-message-custom-content {
  i.anticon.anticon-loading {
    color: white;
  }
}