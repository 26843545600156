@import '../../../../../../sass-config/abstracts/variables.scss';

.volume-chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 1.6rem;

  .app-name {
    display: flex;
    align-items: center;

    img {
      width: 3.2rem;
      height: 3.2rem;
      object-fit: cover;
      margin-right: 0.5rem;
    }
  }

  .counters {
    .insight-count {
      margin-bottom: 0.5rem;
    }
  }

  .doughnut-container {
    flex-grow: 1;
    align-self: center;
  }

  .comparison-to-prev {
    font-size: 1.2rem;
    color: $color-subtitle;
    align-self: center;

    .diff-percent {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }
}
