$primary: black;
.ant-select-selection {
  border-color: #9FA5AD;
}
.ant-select-selection-selected-value {
  color: black;
}
.ant-select-dropdown-menu-item {
  border: none !important;
}
.ant-select-dropdown-menu-item-active {
  background-color: $primary !important;
  color: #fff !important;
}
.ant-select-dropdown-menu-item-selected {
  color: $primary;
}
.wf-select {
  //   position: relative;
  display: inline-block;

  & .chevron {
    // z-index: 2;
    cursor: pointer;
    // position: absolute;
    // margin: 0;
    margin-right: -10px;
    margin-top: -5px;

    // top: 50%;
    // transform: translateY(-50%);
    transition: transform 0.2s;
    &.flipped {
      transform: rotate(-180deg);
      //   transform: translateY(-50%) rotate(-180deg);
    }
  }
}
