.modal {
  background-color: papayawhip;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(26, 21, 21, 0.16);
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  z-index: 10;
  overflow-x: auto;

  &.confirmation-modal {
    max-width: 416px;
    max-height: 265px;

    @include respond(bp-mobile) {
      height: 333px;
      width: 311px;
      max-height: 333px;
      max-width: none;
    }
  }

  @include respond(bp-tablet) {
    height: 100%;
    width: 100vw;
    border-radius: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-modal-layover;
}
