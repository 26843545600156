@import "../../../../sass-config/abstracts/variables.scss";

.performance-summary {
  margin-top: 2rem;
  position: relative;
  border-radius: 2px;

  padding-top: 2.5rem !important;

  .winner-tag {
    position: absolute;
    background-color: red;
    top: 1.8rem;
    right: 1.5rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: white;
    font-family: "Roboto";
    padding: 0 2.3rem;
  }

  .timeLine {
    width: 100%;
    background-color: #F5F5F5;
    top: 0;
    left: 0;
    height: 0.5rem;
    position: absolute;

    &>div {
      left: 0;
      height: 0.5rem;
    }

    .t-1 {
      background-color: $color-performance-compare-1;
    }

    .t-2 {
      background-color: $color-performance-compare-2;
    }
  }

  & .value {
    color: #1A1515;
    font-size: 2.9rem;
    font-family: "Roboto";
    font-weight: 500;
  }

  & .heading {
    color: #9FA5AD;
    font-size: 1.5rem;
    position: relative;
    top: -1.8rem;
    font-family: "Roboto";
    font-weight: 300;
  }

  &__duration {
    text-align: center;
    width: fit-content;
    align-self: center;
    font-weight: 500;
    padding: 1rem;
    font-family: "Roboto";


    & .value {
      font-size: 7.8rem;
      font-weight: 600;
      color: #1a1515 !important;
    }

    padding: 1rem;

    & span {
      font-weight: 300;
      font-size: 2.3rem !important;
      color: #000000 !important;
      text-transform: uppercase;
    }
  }

  .diff-vs-period {
    background: #F8F8F8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.9rem 0;
    height: 2.4rem;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 0.9rem;
    padding-left: 0.4rem;
    flex-basis: 33%;


    &.duration{
      width: fit-content;
      margin: auto;
      padding: 0 0.5rem;
      position: relative;
      top:2rem
    }
    .performance-summary-diff-perc {
      display: inline-block;
      margin-right: 0.3rem;
      font-size: 1.3rem;
      font-weight: 700;

      &.plus {
        color: #FF4127;
      }

      &.minus {
        color: #21D603;
      }
    }
  }

  .performance-summary__diffs {
    display: flex;
    flex: 0 0 0 33%;
    margin: 0rem 1rem 0rem;
  }

  &__stats {
    display: flex;
    flex: 0 0 0 33%;
    margin: 1.5rem 1rem 1.8rem;

    &__stat {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      margin: 0 0.9rem 0;
      background-color: #F8F8F8;
      padding: 1.5em 0 0;
      height: 8rem;

      &>div {
        flex: 0 0 40%;
        /* font-size: 1.6rem !important; */
        top: 0 !important;
        font-family: "Roboto";
      }

      &-i {
        position: absolute;
        color: #9FA5AD;
        font-size: 1.6rem;
        top: 1rem;
        right: 1rem;
      }

      &-value {
        &>span {
          font-weight: 300;
          font-size: 1.5rem !important;
          color: #807a7a !important;
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.performance-summary.disabled {

  div,
  span {
    color: #9FA5AD !important;
    user-select: none;
  }
}