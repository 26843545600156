.ctl-insight {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .container {
    width: 400px;
    display: flex;
    gap: 16px;
    flex-direction: column;

    .insight-date {
      position: absolute;
      left: 25px;
      top: 5px;
      color: #8c9398;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 16.8px */
      text-transform: capitalize;
    }

    .insight-title {
      color: rgba(0, 0, 0, 0.8);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      text-transform: capitalize;
    }

    .insight-type {
      align-self: flex-start;
      display: flex;
      padding: 2px 16px;
      align-items: center;
      justify-content: center;
      font-family: Inter;
      font-size: 14px;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      line-height: 190%; /* 26.6px */
      text-transform: capitalize;
    }

    .insight-thumbnail img {
      max-width: 400px;
    }
  }
}
