.ant-btn.carbon-button-black {
  color: #ffffff;
  font-family: Roboto;
  padding: calc(0.875rem - 3px) auto calc(0.875rem - 3px) 15px;
  min-width: 10rem;
  height: 4rem;
  background-color: #181818 !important;
  color: #ffffff;
  font-family: Roboto;
  border: 1px solid black;
  border-radius: 0;
  &:hover{
    background-color: #4c4c4c !important;
  }
} 