.header {
  &__wrap {
    position: sticky;
    top: 0;
    height: $height-topbar;
    z-index: 997;
    background: white;
    color: black;
    width: 100%;
    padding: 0.64rem 2.56rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border-layout;

    &.blurry {
      filter: blur(6px);
    }

    .header-logo {
      cursor: pointer;

      img.header__icons-logo {
        margin-top: 0.2rem;
      }

      img.header__icons-logo.normal {
        width: 3.2rem;
        margin: 1.7rem auto !important;
      }

      position: relative;
    }
    .place-dummy {
      padding: 2.4rem;
      position: relative;
    }
    .search-account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .search-flex {
        display: flex;

        .notification-bell,
        .reports-center {
          margin-right: 0.8rem;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          .anticon {
            font-size: 2.5rem;
            color: #9ca2aa;
          }

          img {
            width: 1.6rem;
          }

          .ant-badge {
            border: 1px solid $color-border-layout;
            border-radius: 1rem;
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }

          .ant-badge-count {
            height: 1.5rem;
            min-width: 1.5rem;
            line-height: 1.5rem;
            padding: 0;

            .ant-scroll-number-only-unit {
              color: white;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  &__dropdown {
    .trial-licence-wrapper {
      position: fixed;
      top: 7rem;
      margin-left: 19rem;
    }

    display: flex;
    margin: auto;

    &.big {
      justify-content: flex-end;
    }

    .dropdown-menu__select {
      color: black;
    }
  }

  &__search-bar {
    height: 3.6rem;
    flex-grow: 1;
    text-align: center;

    .ant-select-auto-complete.ant-select .ant-input {
      border: 1px solid #eceef5 !important;
      border-radius: 0.96rem;

      color: black;
      text-indent: 0.5rem;

      &::placeholder {
        text-indent: 0.5rem;
      }
    }

    .ant-select-disabled {
      cursor: pointer;
    }

    .ant-input-suffix {
      position: absolute;
      right: 2.4rem;
      width: 0;
      font-size: 1.8rem;
      background-color: transparent;
    }

    .magnifying-glass-icon {
      cursor: pointer;
      margin-left: auto;
    }

    .ant-select-disabled span {
      &:hover {
        cursor: pointer;
      }
    }

    .search-auto-complete {
      input[disabled] {
        &:hover {
          cursor: default;
        }
      }

      input {
        border: none;
      }

      transition: opacity 0.25s, width 0.5s ease;
      width: 40%;
      min-width: 42rem;

      &.active {
        .ant-input {
          background-color: #eeeeee !important;
        }
      }
    }

    .search-input {
      height: 3.5rem;
      border-radius: 0.5rem;
      background-color: transparent !important;
    }

    &::after {
      content: '';
      margin-left: 2rem;
      display: inline-block;
      vertical-align: 2px;
      width: 2px;
      border-right: 1px $color-white dashed;
    }
  }

  &__username {
    max-width: 11rem;
    font-size: 1rem;
    color: black;
  }

  &__avatar {
    background: transparent;
    font-size: 1.5rem;
    line-height: 2.7rem;
    color: black;
    border: 1px solid $color-border-layout;
    border-radius: 1rem;
    width: 3.2rem;
    height: 3.2rem;
    cursor: pointer;
  }

  // for width under 600px
  &__search-bar-newline {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px;
    width: 100%;

    .magnifying-glass-icon {
      cursor: pointer;
      margin-left: auto;
    }

    .ant-select-disabled span {
      &:hover {
        cursor: pointer;
      }
    }

    .search-auto-complete {
      input[disabled] {
        &:hover {
          cursor: default;
        }
      }

      input {
        border: none;
      }

      transition: opacity 0.25s, width 0.5s ease;
      width: 4rem;

      &.active {
        width: 100%;
      }
    }
  }
}

@media (max-width: $max-width-secondary) {
  .header {
    &__wrap {
      grid-template-columns: 5rem auto 5rem;
    }
  }
}

@media (max-width: $max-width-small) {
  .header__search-bar {
    border-right: 1px $color-border-layout solid !important;

    &.dis {
      div,
      input {
        cursor: default !important;
      }
    }
  }
}

.header__search-bar-info {
  position: sticky;
  top: 0;
  z-index: 5;

  &:hover {
    color: $color-greyish-brown;
    cursor: default;
  }

  color: $color-greyish-brown;
}

.header__search-bar-dropdown .ant-select-dropdown-menu {
  max-height: 600px;
  padding-top: 0px;

  .ant-select-dropdown-menu-item-active {
    background: transparent;
    border: 1px solid black;
    box-shadow: $shadow-search-bar-row;
    background-color: #f7f7f7 !important;
    color: black !important;
  }
}

.header__search-bar-dropdown {
  top: 6rem !important;
  box-shadow: 7px 7px 25px silver;
  position: fixed;

  ul {
    max-height: 80vh !important;
  }
}

.header__search-bar-dropdown-newline .ant-select-dropdown-menu {
  max-height: calc(100vh - 98px);
  min-width: 400px;
  width: 100% !important;
  left: 0 !important;
  overflow-y: scroll !important;
}

.header__search-bar-dropdown-newline {
  height: calc(100vh - 98px) !important;
  width: 100% !important;
  left: 0 !important;
  top: 98px !important;
  overflow-y: scroll !important;
}

.header__search-row {
  &-inner-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-thumbnail {
    height: 85%;
    max-width: 80px;
    position: relative;
    top: 0;
    left: 0;

    &-insight-icon {
      height: 100%;
      max-width: 80px;
      position: relative;
      border-radius: 5%;
      box-shadow: $shadow-search-bar-row;
      z-index: 1;
      top: 0;
      left: 0;

      &.def {
        width: 8rem;
        background: white;
        padding: 2rem;
      }
    }

    &-insight-loader {
      height: 100%;
      width: 80px;
      position: relative;
      border-radius: 5%;
      box-shadow: $shadow-search-bar-row;
      z-index: 1;
      top: 0;
      left: 0;

      &.def {
        width: 8rem;
        background: white;
        padding: 2rem;
      }
    }

    &-app-icon {
      position: absolute;
      z-index: 2;
      bottom: -5px;
      right: -10px;
      width: 30px;
      border-radius: 15%;
    }
  }

  &-content {
    .highlight {
      background-color: black;
      color: white;
    }

    overflow: hidden;
    font-family: $font-primary;
    margin-left: 2rem;
    width: 100%;
    height: 95%;
    padding: 0.4rem 0 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      font-weight: 500;
      color: black;
      font-family: 'Roboto';
      font-size: 1.5rem;
    }

    &-subtitle {
      color: $color-brown-grey;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-data {
      color: $color-pinkish-grey;

      span:not(:last-child) {
        &::after {
          content: '|';
          margin: 0 0.5rem;
        }
      }
    }
  }
}

.header-breadcrumbs {
  position: relative;
  margin: 2rem auto 0;

  .ant-breadcrumb {
    display: inline-block;
    margin-left: 1rem;
  }

  .btn-global-back {
    border: 1px #d9d9d9 solid;
    font-weight: 500;
    height: 2.5rem;
    font-size: 1.2rem;
    text-transform: none;
    padding: 0.1rem 0.8rem;
  }
}

.request-btn-wrap {
  button,
  button:focus {
    height: 3.6rem;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;

    background: #ff2741;
    border-radius: 4px;
    color: white;

    &:hover:not([disabled]) {
      background-color: #ef1c09 !important;
      border-color: #ef1c09 !important;
    }
  }
}

.access-request-modal {
  .title {
    font-family: 'Roboto Slab';
    font-weight: 700;
    font-size: 3.2rem;
  }

  .sub-title {
    font-weight: 400;
    font-size: 2.8rem;
    width: 88%;
    margin: 1.5rem auto 2rem;
    line-height: 2.9rem;
  }

  .ant-modal-header {
    border: none;
    padding-bottom: 0rem;
  }

  .ant-modal-footer {
    display: none;
  }

  .title {
    text-align: center;
    margin-bottom: 1rem;
  }

  .required-wrapper {
    margin-top: 1rem;

    .star {
      color: red;
      display: inline-block;
      margin-right: 1rem;
    }
  }

  .input-wrap {
    margin-bottom: 3rem;
    display: flex;
    position: relative;

    .star {
      color: red;

      &.first-name {
        position: absolute;
        left: 47%;
        z-index: 3;
        top: 0.4rem;
      }

      &.last-name {
        position: absolute;
        right: 2%;
        top: 0.4rem;
        z-index: 3;
      }

      &.email {
        position: absolute;
        right: 2%;
        top: 0.4rem;
      }
    }

    .ant-input {
      height: 4rem;
      border-radius: 0;
      border: 1px black solid;
    }

    textarea {
      height: 10rem !important;
      border-radius: 0;
      border: 1px black solid;
    }
  }

  .rqst-btn-send {
    width: 100%;
    background: #ef3d2e;
    height: 5rem;
    color: white;

    &:hover:not([disabled]) {
      background-color: #ef1c09 !important;
      border-color: #ef1c09 !important;
    }
  }

  .ant-modal-close-x {
    display: none;
  }
}
